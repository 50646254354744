import React, { Component, useState, useEffect } from 'react'
import footerLogo from '../../assets/img/mass-res-white.svg'
import { useAuthContext } from '../../hooks/useAuthContext';
import { useLogout } from '../../hooks/useLogout';
import { useDocument } from '../../hooks/useDocument';

export default function Footer() {
    const { logout } = useLogout()
    const { user } = useAuthContext()
    const { document, error } = useDocument("employers", user ? user.uid : "no")

    const [isEmployer, setIsEmployer] = useState(false)
    useEffect(() => {
        if (user && document) {

            //   console.log("Employer ",document)
            setIsEmployer(true)
        }
    }, [document])
    return (
        <footer className="footer widget-footer bg-theme-DarkColor text-theme-WhiteColor clearfix">
            <div className="second-footer">
                <div className="container">
                    <div className="row">

                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area " dir='rtl'>
                            <div className="widget widget-recent-post clearfix ">
                                <h3 className="widget-title" >آخر <span className='text-theme-SkinColor'> المقالات</span></h3>
                                <ul className="widget-post ttm-recent-post-list">
                                    <li>
                                        <div className="post-detail">
                                            <div className="cat-link"><a href="#">business</a></div>
                                            <a href="#">مفتاح نجاح المشاريع في السحابة</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="post-detail">
                                            <div className="cat-link"><a href="#">business</a></div>
                                            <a href="#">كيف نتخذ قرارات بشأن حياتنا</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="post-detail">
                                            <div className="cat-link"><a href="#">business</a></div>
                                            <a href="#">يتحدثون عن شركتك</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area" dir='rtl'>
                            <div className="widget widget-contact clearfix">
                                <h3 className="widget-title">اتصل بنا !</h3>
                                <ul className="widget_contact_wrapper" >
                                    <li dir='ltr'>
                                        <i className="ttm-textcolor-skincolor flaticon-phone-call"></i>
                                        <h3> : رقم الهاتف </h3>02 . 531 . 9999 </li>
                                    <li>
                                        <i className="ttm-textcolor-skincolor flaticon-placeholder"></i>
                                        <h3>العنوان :</h3>القدس - شارع القلعة 3   </li>
                                    <li>
                                        <i className="ttm-textcolor-skincolor flaticon-email"></i>
                                        <h3>البريد الالكتروني </h3><a href="mailto:massres.dev@gmail.com"> massres.dev@gmail.com </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 widget-area">
                            <div className="widget widget_text clearfix" dir='rtl'>
                                <div className="footer-logo">
                                    {/* <img id="footer-logo-img" className="img-fluid auto_size" height="40" width="162" src="images/footer-logo.svg" alt="footer-logo" /> */}
                                    <img id="footer-logo-img" className="img-fluid auto_size" src={footerLogo} alt="footer-logo" />

                                </div>
                                <p>
                                    مهمتنا خلق الفرص الاقتصادية حتى يعيش الناس حياة أفضل
                                    هدفنا  فتح سوق العمل في العالم العربي حيث تلتقي هنا كل يوم الشركات من جميع الأحجام
                                    والمواهب المستقلة من جميع أنحاء العالم العربي  لإنجاز أشياء لا تصدق.
                                </p>
                                <div className="d-flex align-items-center pt-15 pb-25">
                                    {/* <h6 className="fw-normal">Social Share: </h6> */}
                                    <div className="social-icons">
                                        <ul className="social-icons list-inline">
                                            <li><a href="https://www.facebook.com/MassRes-113256348263272" target='_blank' rel="noopener" aria-label="facebook">
                                                <i className="ti ti-facebook"></i></a>
                                            </li>
                                            {/* <li><a href="#twitter" rel="noopener" aria-label="twitter">
                                                <i className="ti ti-twitter-alt"></i></a>
                                            </li> */}
                                            {/* <li><a href="#instagram" rel="noopener" aria-label="instagram">
                                                <i className="ti ti-instagram"></i></a>
                                            </li> */}
                                            {/* <li><a href="#pinterest" rel="noopener" aria-label="pinterest">
                                                    <i className="ti ti-pinterest"></i></a>
                                                </li> */}
                                        </ul>
                                    </div>
                                </div>


                                <div >
                                    {user && !isEmployer && <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                                        href={process.env.PUBLIC_URL + '/fill_cv'} style={{ width: "40%", textAlign: "center", margin: "5px" }}>السيرة الذاتية
                                    </a>}



                                    {user && isEmployer &&
                                        <>
                                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                                                href={process.env.PUBLIC_URL + '/start_job'} style={{ width: "40%", textAlign: "center", margin: "5px" }}>وظيفة جديدة
                                            </a>
                                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                                                href={process.env.PUBLIC_URL + '/employer_jobs/' + user.uid} style={{ width: "40%", textAlign: "center", margin: "5px" }}>وظائفي  
                                            </a>

                                        </>
                                    }
                                    {user && isEmployer &&
                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                                            href={process.env.PUBLIC_URL + '/fill_Company'} style={{ width: "40%", textAlign: "center", margin: "5px" }}>ملفك الشخصي
                                        </a>

                                    }
                                    {user && <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                                        href={process.env.PUBLIC_URL + '/register'} onClick={logout} style={{ width: "40%", textAlign: "center", margin: "5px" }}>تسجيل الخروج
                                    </a>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-footer-text">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 d-lg-flex align-items-center justify-content-between">
                            <form id="subscribe-form" className="subscribe-form">
                                <div className="mailchimp-inputbox clearfix" id="subscribe-content">
                                    <input type="email" name="EMAIL" id="txtemail" placeholder=" ... أدخل بريدك الالكتروني " required="" />
                                    <button className="submit ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-skincolor" type="submit">
                                        <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-8 col-md-6" dir='rtl'>
                            <span className="copyright" >حقوق النشر © 2023 <a href={process.env.PUBLIC_URL + '/'}>MassRes </a>جميع الحقوق محفوظة .
                            </span>
                            <ul className="footer-nav-menu">
                                <li ><a href={process.env.PUBLIC_URL + '/Privacy'} > سياسة الخصوصية </a></li>
                                <li ><a href={process.env.PUBLIC_URL + '/Terms'}>  الشروط و الأحكام </a></li>
                                <li ><a href={process.env.PUBLIC_URL + '/Accessibility'}>  امكانية الوصول </a></li>

                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </footer>
    )

}


