import React from 'react';
import imgg from '../../assets/img/pagetitle-bg.jpg'
const PageHeader = ({title,content,breadcrumb,className,subTitle, isHome}) => {
    return (
        <div className="page-header-area bg-img"
        //  style={{backgroundImage: `url(${imgg})` }}
         >
            <div className="page-header-area-inner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="page-header-content-inner">
                                <div className="page-header-content">
                                    <h2>{title}</h2>
                                    <h3 className='text-theme-SkinColor'>{subTitle}</h3>
                                    <div>{content}</div>
                                    <div className="breadcrumb-wrapper">
                                      {isHome !="false"&&  <span dir='rtl'><a href={process.env.PUBLIC_URL + '/'} title="الرئيسية"><i className="ti ti-home" />&nbsp;&nbsp;الرئيسية </a></span>}
                                       {isHome !="false"&& <span className="bread-sep">&nbsp;/&nbsp;</span>   }                                     
                                        {breadcrumb}
                                    </div>
                                    <div className={className}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageHeader;