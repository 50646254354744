import React, { Component, useState, useEffect } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import  Footer  from '../components/layout/Footer';
import ProgressBar from 'react-animated-progress-bar';
import { useParams } from 'react-router-dom';
import { useDocument } from '../hooks/useDocument';
import DOMPurify from 'dompurify';
import CreateCv from './createCv';
import emailjs from '@emailjs/browser';
import "../firebase/config"
export default function Candidate_details(props) {
    const id = props.match.params.id;
    const [data, setData] = useState([])
    const [educations, setEdu] = useState([])
    const [skills, setSkills] = useState([])
    const [languages, setLanguages] = useState([])
    const [expData, setExpData] = useState([])
    const [show, setShow] = useState(false)
    const [response,setReponse] = useState(false)
    const cats = ["", "  محاسبة و مالية ", "تكنولوجيا  ", " زراعة ", " بناء ", " صناعة ", " انتاج ", " نقل  ", " Digital Design & Social Media "]

// EmailJs From
const [fromName, setFname] = useState("")
const [fromEmail, setFemail] = useState("")
const [fromSubject, setFsubject]= useState("")
const [fromMessage, setFmsg]= useState("")

useEffect(()=>{
  window.scrollTo({ top: 40, behavior: "smooth" });
},[])
    const { document, error } = useDocument("users", id)
    const showCv = () => {
        setShow(!show)
    }
    useEffect(() => {
        if (document) {
            setData(document)
            setEdu(document.educations)
            setExpData(document.expData)
            setSkills(document.skills)
            setLanguages(document.languages)
            // console.log("doc details is : ", document)
        }
    }, [document])
    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }
   

    const handleSubmit = async (e) => {
        e.preventDefault()
        setReponse(true)
        

    var templateParams = {
        from_name:fromName,
               from_email: fromEmail,
                to_name: data.name,
                message:  fromMessage,
                fromSubject,
                to_email: data.cvEmail,
    };
     
    await emailjs.send('service_s1khhzc', 'template_fo53g3o', templateParams,"-gaOaetDVoDcH_Mt7")
        .then(function(response) {
           console.log('SUCCESS!', response.status, response.text);
           setReponse(false)
      
        }, function(error) {
           console.log('FAILED...', error);
        });

        setFemail(""); setFmsg(""); setFname(""); setFsubject("")
    }
    return (

        <div className="site-main">
            <Header />

            {/* PageHeader */}
            <PageHeader
                title="تفاصيل المرشح"
                subTitle="We connect the dots to your Future JOB!"
                breadcrumb="تفاصيل"
            />
            {/* PageHeader end */}

            {data ?
                <div className="ttm-row sidebar job-sidebar clearfix" style={{display:show?"none":"block"}}>
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
                                <div className="job_list-widget" dir='rtl'>
                                    <aside className="widget candidate-widget">
                                        <h3 className="widget-title"><i className="ti ti-files"></i>معلومات الاتصال</h3>
                                        <ul>
                                            <li className="d-flex"><b className="mr-5">المجال : </b><span>{cats[data.category]}</span></li>
                                            <li className="d-flex"><b className="mr-5">أيميل : </b><span> <a href={'mailto:'+data.email} > {data.email}</a></span></li>
                                            <li className="d-flex"><b className="mr-5">رقم الهاتف : </b><span><a href={"tel:"+data.phoneNumber}> {data.phoneNumber} </a></span></li>
                                            {data.address&&  <li className="d-flex"><b className="mr-5">العنوان : </b><span>{data.address}</span></li>}
                                            
                                        </ul>
                                    </aside>
                                    <aside className="widget job-widget" >
                                        <h3 className="widget-title"><i className="flaticon flaticon-expert"></i>المهارات </h3>
                                        {skills?
                                            skills.map((item, index) => (
                                                <div className="desc pb-30" key={index} >
                                                    {/* <h1>{item.skilLevel}</h1> */}
                                                    <div className="ttm-progress-bar clearfix" >
                                                        <h3 className="progressbar-title " >{item.skill}</h3>
                                                        <ProgressBar rect percentage={item.skilLevel}  />
                                                    </div>

                                                </div>
                                            )):<></>}

                                    </aside>
                                    {/* <aside><a href="mailto:`{email}`?subject={subject}&body={body}">Click to Send an Email</a></aside>
                                    <aside><a href="mailto:yara.huss.113@gmail.com?subject='Hello from Abstract!'&body='Just popped in to say hello'">Click HEREEEEE to Send an Email</a></aside>
                                     */}
                                    <aside className="widget form-widget">
                                        <h3 className="widget-title"><i className="ti ti-email"></i>أرسل رسالة </h3>
                                        <form className="wrap-form" onSubmit={handleSubmit}>
                                            <label>
                                                <input name="fromName" type="text" placeholder="الاسم " 
                                                required="required" 
                                                value={fromName}
                                                onChange={e=>(setFname(e.target.value))}
                                                />
                                            </label>
                                            <label>
                                                <input name="fromEmail" type="text" placeholder=" البريد الالكتروني " 
                                                required="required" 
                                                value={fromEmail}
                                                onChange={ e=>(setFemail(e.target.value))}
                                                />
                                            </label>
                                            <label>
                                                <input name="fromSubject" type="text" placeholder="الموضوع " 
                                                required="required" 
                                                value={fromSubject}
                                                onChange={e=>(setFsubject(e.target.value))}
                                                />
                                            </label>
                                            <label>
                                                <textarea name="fromMessage" rows="4" placeholder="أكتب  رسالتك ..." 
                                                required="required"
                                                value={fromMessage}
                                                onChange={e=>(setFmsg(e.target.value))}
                                                ></textarea>
                                            </label>
                                            <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100" >{!response?"ارسال ! ":" . . . جاري الارسال  . . . ."}</button>
                                        </form>
                                    </aside>
                                </div>
                                <aside className="widget widget-download">
                                    <ul className="download">
                                        <li onClick={showCv}><a href="#"  onClick={showCv} className="link text-theme-SkinColor">عرض السيرة الذاتية</a><i className="ti ti-eye" onClick={showCv}></i></li>
                                     
                                        {/* <li><a href="#">Download Cv</a><i className="ti ti-files"></i></li> */}
                                    </ul>
                                </aside>
                                <div className="overview-box">
                                    <div className="title">
                                        <div className="d-sm-flex justify-content-between align-items-center mb_10">
                                            <p className="mb-10 fw-bold">Social Links :</p>
                                            <div className="social-icons circle mb-10"> 
                                                <ul className="list-inline">
                                                    {data.facebook&&<li><a href={data.facebook} rel="noopener" aria-label="facebook" target='_blank'><i className="ti ti-facebook"></i></a></li>}
                                                   {data.twitter&& <li><a href={data.twitter} rel="noopener" aria-label="twitter" target='_blank'><i className="ti ti-twitter-alt"></i></a></li>}
                                                 {data.instagram&&   <li><a href={data.instagram} rel="noopener" aria-label="instagram" target='_blank'><i className="ti ti-instagram"></i></a></li>}
                                                   {data.link&& <li><a href={data.link} rel="noopener" aria-label="LinkedIn" target='_blank'><i className="ti ti-linkedin"></i></a></li>}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 content-area" dir='rtl'>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="candidate-data">
                                            {data.profilePicture && <div className="candidate-img">
                                                <img src={data.profilePicture} />
                                            </div>}
                                            <div className="candidate-caption">
                                                <h5>{data.name}</h5>
                                                <span>{data.occupation}</span>
                                                <div className="meta-line">
                                                    <span><i className="ti ti-bag"></i> {cats[data.category]}</span>
                                                    <span><i class="fa fa-map-marker-alt"></i> {data.address}</span>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="overview-box">
                                            {/* <div className="title">
                                                <h5>About me</h5>
                                            </div> */}
                                            <div className="desc">
                                                <p dangerouslySetInnerHTML={createMarkup(data.aboutMeObjective)}></p>
                                            </div>
                                        </div>
                                        <div className="overview-box">
                                            <div className="title">
                                                <h5>خبرة العمل</h5>
                                            </div>
                                            <div className="desc">
                                                <div className="candidate-timeline">


                                                    {expData?
                                                    expData.map((experience, index) => (
                                                        <div className="timeline-panel" key={index}>
                                                            <div className="timeline-head">
                                                                <h3>{experience.company1}</h3>

                                                                <span className="timeline-year"> {experience.startDate ? (experience.startDate[0] + "" + experience.startDate[1] + "" + experience.startDate[2] + "" + experience.startDate[3] + " - ") : ""}  {experience.endDate ? (experience.endDate[0] + "" + experience.endDate[1] + "" + experience.endDate[2] + "" + experience.endDate[3] + "") : " "} </span>
                                                            </div>
                                                            <div className="timeline-body">
                                                                <h5>{experience.position}</h5>


                                                                <p dangerouslySetInnerHTML={createMarkup(experience.convertedContent)}></p>

                                                            </div>
                                                        </div>
                                                    )):<></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overview-box">
                                            <div className="title">
                                                <h5>مؤهلات علمية</h5>
                                            </div>
                                            <div className="desc">
                                                <div className="candidate-timeline">

                                                    { educations?
                                                    educations.map((edu, index) => (
                                                        <div className="timeline-panel" key={index}>
                                                            <div className="timeline-head">
                                                                <h3>{edu.degree}</h3>

                                                                <span className="timeline-year"> {edu.schoolStartDate ? (edu.schoolStartDate[0] + "" + edu.schoolStartDate[1] + "" + edu.schoolStartDate[2] + "" + edu.schoolStartDate[3] + " - ") : ""}  {edu.schoolEndDate ? (edu.schoolEndDate[0] + "" + edu.schoolEndDate[1] + "" + edu.schoolEndDate[2] + "" + edu.schoolEndDate[3] + "") : " "} </span>
                                                            </div>
                                                            <div className="timeline-body">
                                                                <h5>{edu.schoolName}</h5>
                                                                <p dangerouslySetInnerHTML={createMarkup(edu.convertedContentSc)}></p>
                                                            </div>
                                                        </div>
                                                    )):<></>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="overview-box" >
                                            <div className="title">
                                                <h5>لغات  </h5>
                                            </div>
                                            {languages?
                                            languages.map((item, index) => (
                                                <div className="desc pb-30" key={index} >
                                                    <div className="ttm-progress-bar clearfix">
                                                        <h3 className="progressbar-title  " style={{ letterSpacing: "normal"}}>{item.language}</h3>
                                                        <ProgressBar rect percentage={item.languageLevel} />
                                                    </div>
                                                </div>
                                            )):<></>}
                                        </div>


                                       
                                    </div>
                                </div>{/* row end */}
                            </div>
                        </div>{/* row end */}
                    </div>
                </div> :
                <div className="ttm-row sidebar job-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
                                <div className="job_list-widget">
                                    <aside className="widget candidate-widget">
                                        <h3 className="widget-title"><i className="ti ti-files"></i>Candidate Informations</h3>
                                        <ul>
                                            <li className="d-flex"><b className="mr-5">Category:</b><span>Webdesigner / Webdevloper</span></li>
                                            <li className="d-flex"><b className="mr-5">Experience:</b><span>3 to 5 Years</span></li>
                                            <li className="d-flex"><b className="mr-5">Expected Salary:</b><span>$ 20k To $ 30k</span></li>
                                            <li className="d-flex"><b className="mr-5">Marital status:</b><span>Singal</span></li>
                                            <li className="d-flex"><b className="mr-5">Experience:</b><span>3 Years</span></li>
                                            <li className="d-flex"><b className="mr-5">Language:</b><span>English, French</span></li>
                                            <li className="d-flex"><b className="mr-5">Qualification:</b><span>Associate Degree</span></li>
                                            <li className="d-flex"><b className="mr-5">Type:</b><span>Full Time</span></li>
                                        </ul>
                                    </aside>

                                    <aside className="widget form-widget">
                                        <h3 className="widget-title"><i className="ti ti-email"></i>Contact Candidate</h3>
                                        <form className="wrap-form" onSubmit={handleSubmit}>
                                            <label>
                                                <input name="fromName" type="text" placeholder="Name" 
                                                required="required" 
                                                onChange={e=>(setFname(e.target.value))}
                                                />
                                            </label>
                                            <label>
                                                <input name="fromEmail" type="text" placeholder="Email" 
                                                required="required" 
                                                onChange={ e=>(setFemail(e.target.value))}
                                                />
                                            </label>
                                            <label>
                                                <input name="fromSubject" type="text" placeholder="Subject" 
                                                required="required" 
                                                onChange={e=>(setFsubject(e.target.value))}
                                                />
                                            </label>
                                            <label>
                                                <textarea name="fromMessage" rows="4" placeholder="Message" 
                                                required="required"
                                                onChange={e=>(setFmsg(e.target.value))}
                                                ></textarea>
                                            </label>
                                            <button className={response?"hover-mouse submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100":"submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"} >{response ? "Sending ..." : "send a message!"}</button>
                                            {/* <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100" >send a message!</button> */}
                                        </form>
                                    </aside>
                                </div>
                                <aside className="widget widget-download">
                                    <ul className="download">
                                        <li><a href="#">View Cv</a><i className="ti ti-eye"></i></li>
                                        <li><a href="#">Download Cv</a><i className="ti ti-files"></i></li>
                                    </ul>
                                </aside>
                                <div className="overview-box">
                                    <div className="title">
                                        <div className="d-sm-flex justify-content-between align-items-center mb_10">
                                            <p className="mb-10 fw-bold">Social Links :</p>
                                            <div className="social-icons circle mb-10">
                                                <ul className="list-inline">
                                                    <li><a href="#facebook" rel="noopener" aria-label="facebook"><i className="ti ti-facebook"></i></a></li>
                                                    <li><a href="#twitter" rel="noopener" aria-label="twitter"><i className="ti ti-twitter-alt"></i></a></li>
                                                    <li><a href="#instagram" rel="noopener" aria-label="instagram"><i className="ti ti-instagram"></i></a></li>
                                                    <li><a href="#pinterest" rel="noopener" aria-label="pinterest"><i className="ti ti-pinterest"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 content-area">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="candidate-data">
                                            <div className="candidate-img">
                                                <img src="https://via.placeholder.com/200x200?text=200x200+candidate-01.jpg" />
                                            </div>
                                            <div className="candidate-caption">
                                                <h5>Jimmy Divison</h5>
                                                <span>Web Designer Ul/Ux</span>
                                                <div className="meta-line">
                                                    <span><i className="ti ti-bag"></i> UX/UI Designer</span>
                                                    <span><i className="ti ti-headphone"></i> (+01)123 456 789</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="overview-box">
                                            <div className="title">
                                                <h5>About me</h5>
                                            </div>
                                            <div className="desc">
                                                <p>Dolor sed viverra ipsum nunc. In ornare quam viverra orci. Id diam maecenas ultricies mi eget mauris
                                                    pharetra et ultrices. Non diam phasellus vestibulum lorem sed risus ultricies. Lacinia at quis
                                                    risus sed vulputate odio ut enim blandit. Suspendisse ultrices gravida dictum fusce ut.</p>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                                                    et dolore magna aliqua.  </p>
                                            </div>
                                        </div>
                                        <div className="overview-box">
                                            <div className="title">
                                                <h5>Education Details</h5>
                                            </div>
                                            <div className="desc">
                                                <div className="candidate-timeline">
                                                    <div className="timeline-panel">
                                                        <div className="timeline-head">
                                                            <h3>Internatinal University</h3>
                                                            <span className="timeline-year">2009-2012</span>
                                                        </div>
                                                        <div className="timeline-body">
                                                            <h5>Bachlors in Fine Arts</h5>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                                incididunt.</p>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-panel">
                                                        <div className="timeline-head">
                                                            <h3>Internatinal University</h3>
                                                            <span className="timeline-year">2012-2015</span>
                                                        </div>
                                                        <div className="timeline-body">
                                                            <h5>Master In Fine Arts </h5>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                                incididunt.</p>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-panel">
                                                        <div className="timeline-head">
                                                            <h3>Diploma In Fine Arts</h3>
                                                            <span className="timeline-year">2015-2018</span>
                                                        </div>
                                                        <div className="timeline-body">
                                                            <h5>Imperieal Institute of Art Direction</h5>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                                incididunt.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overview-box">
                                            <div className="title">
                                                <h5>Education Details</h5>
                                            </div>
                                            <div className="desc">
                                                <div className="candidate-timeline">
                                                    <div className="timeline-panel">
                                                        <div className="timeline-head">
                                                            <h3>Senior Software Engineer</h3>
                                                            <span className="timeline-year">2009-2012</span>
                                                        </div>
                                                        <div className="timeline-body">
                                                            <h5>Clothing Store</h5>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                                incididunt.</p>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-panel">
                                                        <div className="timeline-head">
                                                            <h3>Self Employe Professional</h3>
                                                            <span className="timeline-year">2012-2015</span>
                                                        </div>
                                                        <div className="timeline-body">
                                                            <h5>Fashion Store</h5>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                                incididunt.</p>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-panel">
                                                        <div className="timeline-head">
                                                            <h3>Self Employe Professional</h3>
                                                            <span className="timeline-year">2014-2015</span>
                                                        </div>
                                                        <div className="timeline-body">
                                                            <h5>Jewelry Store</h5>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                                incididunt.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>{/* row end */}
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>}

                {data && show &&  <CreateCv id={id}/>}
            {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment-5"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Are You up for the <span style={{textDecoration:"underline"}}> Challenge ?</span></h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>We have over 30 years experience oft Business porro qusquam dol ipsum quia dolor sit amet.</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                                    href={process.env.PUBLIC_URL + '/'}>Hiring Now!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}


            <Footer />

        </div>
    )
}
