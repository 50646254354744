import React, { Component, useState, useEffect } from 'react'
import Menu from './Menu';
import Mobile_menu from './Mobile_menu';
import Logo from './Logo'
import Logo_white from './Logo_white';
import Header_search from './Header_search'
import { useAuthContext } from '../../hooks/useAuthContext';
import { useLogout } from '../../hooks/useLogout';
import { useCollection } from '../../hooks/useCollection';
import { useDocument } from '../../hooks/useDocument';
export default function HeaderLogo () {
    
    const { logout } = useLogout()
    const { user } = useAuthContext()

    const [isEmployer,setIsEmployer] = useState(false)
    const { document, error } = useDocument("employers", user?user.uid:"no")
    useEffect(() => {
      if (user && document) {
          
        //   console.log("doc details is : ", document?document:"Employer here")
          setIsEmployer(true)
      }else if(user && !document){
        console.log("")
      }
  }, [document])
    // componentDidMount() {
    //     window.addEventListener('scroll', this.isSticky);
    // }S

    // componentWillUnmount() {
    //     window.removeEventListener('scroll', this.isSticky);
    // }

//   const  isSticky = (e)=>{
//         const header = document.querySelector('header');
//         const scrollTop = window.scrollY;
//         scrollTop >= 250 ? header.classList.add('is-Sticky') : header.classList.remove('is-Sticky');
//     };

   
        return (
                
            <header id="masthead" className="header ttm-header-style-01">
                {/* topbar */}
               {/* <div className="top_bar bg-theme-DarkColor text-center clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="top_bar_contact_item m-0">
                                    <div className="top_bar_icon">
                                        <i className="fas fa-info-circle text-reset"></i>
                                    </div>
                                    <span>Using its extensive fish farming experience and knowledge, Fleuren & Nooijen is now a market leader helping. </span>  
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-skincolor" href="/"> Learn More<i className="fas fa-long-arrow-alt-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* topbar end */}
                {/* site-header-menu */}
                <div id="site-header-menu" className="site-header-menu ttm-bgcolor" style={{height:"100%"}}>
                    <div className="site-header-menu-inner ttm-stickable-header">
                        <div className="container-fluid" >
                            <div className="row">
                                <div className="col-lg-12" style={{padding:"15px" }}>
                                    {/* site-navigation */}
                                    <div className="site-navigation d-flex align-items-center justify-content-between">
                                        {/* site-branding */}
                                        <div className="site-branding">
                                            <Logo_white/>
                                        </div>
                                        {/* site-branding end */}
                                        <div className="border-box-block">
                                            <div className=" d-flex align-items-center justify-content-between">
                                                {/* menu */}
                                                {/* <Menu/> */}
                                                {/* menu end */}
                                                
                                            </div>
                                        </div>

                                      
                                    </div>{/* site-navigation end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* site-header-menu end */}
            </header> 
            
        )
    
}

// export default Header;