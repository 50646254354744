import React, { Component, useState, useEffect } from 'react';
import '../firebase/config'
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import Footer from '../components/layout/Footer';
import ProgressBar from 'react-animated-progress-bar';
import { timestamp } from '../firebase/config';
import { useDocument } from '../hooks/useDocument';
import DOMPurify from 'dompurify';
import emailjs from '@emailjs/browser';
import profile from "../assets/img/massavatar/massres-bus.png"
import Slider from 'react-slick';
import { useFetchNestDocs } from '../hooks/useFetchNestDocs';
import { AiFillEdit } from 'react-icons/ai';
import { RiDeleteBinFill } from 'react-icons/ri'
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { usePostNestDocs } from '../hooks/usePostNestDocs';
import FillJob from './FillJob';
import { useSet } from 'react-use';
export default function Employer_jobs(props) {
    const id = props.match.params.id;
    const [data, setData] = useState([])
    const [jobsData, setJobsData] = useState([])
    const { document, error } = useDocument("employers", id)
    const { documents: compJobs, err } = useFetchNestDocs("employers", id ? id : "", "jobs", "", ["createdAt", "desc"])
    const jobTypes = ["", "دوام كامل ", "دوام كامل - عاجل ", "دوام جزئي ", "دوام جزئي - عاجل ", "عبر الانترنت ", "عبر الانترنت - عاجل "]
    const cats = ["", " محاسبة و مالية ", "تكنولوجيا  ", " زراعة ", " بناء ", " وظائف طبية ", " انتاج ", " نقل  ", " Digital Design & Social Media "]
    const [update, setUpdate] = useState(false)
    // EmailJs From
    const [fromName, setFname] = useState("")
    const [fromEmail, setFemail] = useState("")
    const [fromSubject, setFsubject] = useState("")
    const [fromMessage, setFmsg] = useState("")
    const [response, setReponse] = useState(false)
    const { updateDocument, deleteDocument } = usePostNestDocs("employers", id, "jobs")
    const [jobToUpdate, setJobToupdate]= useState("")
    const [showFillJob,setShowFillJob]= useState(false)
    const deleteJob = (id) => {
        // setJobsData(jobsData.filter((row) => row.id !== id))
        deleteDocument(id)
    }
    const updateJob = (idd) => {
        setUpdate(true);
        setShowFillJob(true)
        setJobsData(jobsData.filter((row) => row.id !== idd))
        let jobToUpdate = jobsData.filter((row) => row.id == idd)
        
        jobToUpdate = jobToUpdate[0]
        setJobToupdate(jobToUpdate)
        // console.log("Job is : ", jobToUpdate)

        // jobToUpdate.jobName = "Farmer Here"
        // console.log("Job updated is : ", jobToUpdate.jobName)

        // setJobsData(...jobsData, jobToUpdate)

        // updateDocument(idd, jobToUpdate)
       



    }

    const buttonVariants = {
        hover: {
            scale: 1.1,
            // textShadow: "0px 0px 8px rgb(255,255,255)",
            fontWeight: "bold",
            color: "white",
            // boxShadow: "0px 0px 8px rgb(255,255,255)",

            transition: {
                duration: 0.5,
                yoyo: Infinity
            }
        }
    }
    useEffect(() => {
        if (document) {
            setData(document)

            // console.log("doc details is : ", document)
        }
    }, [document])
    useEffect(() => {
        if (compJobs) {
            setJobsData(compJobs)

            // console.log("doc details is : ", compJobs)
        }
    }, [compJobs])
    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        setReponse(true)


        var templateParams = {
            from_name: fromName,
            from_email: fromEmail,
            to_name: data.companyName,
            message: fromMessage,
            fromSubject,
            to_email: data.compEmail,
        };

        await emailjs.send('service_s1khhzc', 'template_4caki7q', templateParams, "-gaOaetDVoDcH_Mt7")
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
                setReponse(false)

            }, function (error) {
                console.log('FAILED...', error);
            });

        setFemail(""); setFmsg(""); setFname(""); setFsubject("")
    }

    var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 1,

        responsive: [{

            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };

    function flt(dbDate) {
        const timedb = new Date(dbDate.seconds * 1000);
        const today = timestamp.fromDate(new Date())
        const time = new Date(today.seconds * 1000);
        const thisDatr = new Date(time - timedb)
        var difference = time.getTime() - timedb.getTime();
        var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24)
        // console.log("diff:", daysDifference)


        return (daysDifference)
    }
    return (

        <div className="site-main">
            <Header />

            {/* PageHeader */}
            <PageHeader
                title=" قائمة الوظائف"
                subTitle=""
                breadcrumb="وظائف"
            />
            {/* PageHeader end */}

            {data ?

                <div className="ttm-row sidebar job-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">

                            <div className="col-lg-12 content-area text-right">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* featured-imagebox */}
                                        <div className="featured-imagebox featured-imagebox-employer">
                                            <div className="featured-thumbnail">
                                                {data.profilePicture && <img src={data.profilePicture} />}
                                                {!data.profilePicture && <img src={profile} />}

                                            </div>
                                            <div className="featured-content">
                                                {/* <div className="select-btn">
                                                    <i className="fa fa-heart"></i>
                                                </div> */}
                                                <div className="featured-title text-center">
                                                    <h1>{data.companyName}</h1>
                                                </div>
                                                <div className="featured-bottom" dir='rtl'>
                                                    <div className="job-meta">
                                                        <span><i className="fa fa-map-marker-alt"></i> {data.cAddress}</span>
                                                        <span><i className="fa fa-hand-holding-usd"></i> {data.payRange}</span>
                                                        {/* <span><i className="ti ti-bag"></i> {cats[data.compCat]}</span> */}

                                                        {/* <span>Skills :   HTML, CSS, JavaScript, Wordpress, PHP</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>{/* featured-imagebox end */}
                                    </div>
                                </div>

                                {!update && <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        {jobsData.length > 0 &&
                                            <><div className="row">

                                                <div className="col-lg-12">
                                                    <br></br><br></br>
                                                    <h5> الوظائف المتاحة {jobsData ? jobsData.length : ""}</h5>
                                                </div>
                                            </div>
                                                <Slider className="row slick_slider slick-arrows-style2 mb_15" {...slick_slider} vertical={true} slidesToShow={2} rows={2} arrows={true}>
                                                    {jobsData.map((job, index) => (
                                                        <div className="col-lg-12 col-md-12" key={index}>
                                                            {/* featured-imagebox */}
                                                            <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                                                                <div className="featured-thumbnail">
                                                                    {data.profilePicture && <a href={process.env.PUBLIC_URL + '/Job_details/' + job.id}><img src={data.profilePicture} /></a>}
                                                                    {!data.profilePicture && <a href={process.env.PUBLIC_URL + '/Job_details/' + job.id}><img src={profile} /></a>}
                                                                    {job.jobType == '6' && <div className="required-tag">عاجل</div>}
                                                                    {job.jobType == '2' && <div className="required-tag">عاجل</div>}
                                                                    {job.jobType == '4' && <div className="required-tag">عاجل </div>}

                                                                    {/* <img src="https://via.placeholder.com/210x204?text=210x204+job-06.png" /> */}
                                                                </div>
                                                                <div className="featured-content">
                                                                    <div className="featured-title title-white">
                                                                        <h3>{job.jobName}</h3>
                                                                    </div>
                                                                    <div className="featured-desc">
                                                                        {/* <p>Published  {flt(job.createdAt)}Days Ago.</p> */}
                                                                        <p>نشر   {flt(job.createdAt) > 0 ? " قبل " + flt(job.createdAt) + " أيام " : " اليوم "}</p>
                                                                    </div>
                                                                    <div className="featured-bottom" dir='rtl'>
                                                                        <div className="job-meta">
                                                                            <motion.button
                                                                                className='   '
                                                                                variants={buttonVariants}
                                                                                whileHover="hover"
                                                                                onClick={() => updateJob(job.id)}
                                                                            >
                                                                                <span className='text-placeHolder-color-light'><AiFillEdit className='text-theme-SkinColor' size={25} style={{ marginLeft: "5px" }} />تعديل الوظيفة </span>
                                                                            </motion.button>
                                                                            <motion.button
                                                                                className='   '
                                                                                variants={buttonVariants}
                                                                                whileHover="hover"
                                                                                onClick={() => deleteJob(job.id)}
                                                                            >
                                                                                <span className='text-placeHolder-color-light'><RiDeleteBinFill className='text-theme-SkinColor' size={22} style={{ marginLeft: "5px" }} />حذف الوظيفة </span>
                                                                            </motion.button>
                                                                        </div>

                                                                        <div className="job-time">
                                                                            {/* <span className="blue">part time</span> */}
                                                                            {job.jobType && <span className={"green" + job.jobType}> {jobTypes[job.jobType]}</span>}


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>{/* featured-imagebox end */}
                                                        </div>
                                                    ))
                                                    }
                                                </Slider>{/* row end */}

                                            </>}
                                    </div>
                                </div>}{/* row end */}
                                {update&& jobToUpdate&&
                                <FillJob 
                                jobToUpdate={jobToUpdate}
                                docId={id}
                                setJobToupdate={setJobToupdate}
                                showFillJob={showFillJob}
                                setShowFillJob={showFillJob}
                                />}
                            </div>

                        </div>{/* row end */}

                    </div>
                </div> : ""}


            {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment-5"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>there is always the possibility of getting hired</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>We have over 30 years experience oft Business porro qusquam dol ipsum quia dolor sit amet.</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                                    href={process.env.PUBLIC_URL + '/'}>Hiring Now!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}


            <Footer />

        </div>
    )
}
