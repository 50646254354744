import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Header from '../components/layout/Header';
import logo from '../assets/img/massavatar/182x240.png'
const buttonVariants = {
    hover: {
        scale: 1.1,
        textShadow: "0px 0px 8px rgb(255,255,255)",
        boxShadow: "0px 0px 8px rgb(255,255,255)",
        transition: {
            duration: 0.3,
            yoyo: 5
        }
    }
}

const containerVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.2, duration: 1.1 }
    },
    exit: {
        x: "-100vh",
        transition: { ease: 'easeInOut' }
    }
};
export class StartCv extends Component {
    render() {

        return (

            <motion.div className="site-main"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit">
                <Header />
                <section className="error-pg">
                    <div className="container"  >
                        <div className="row align-items-center">
                            <div className="col-lg-6 order-lg-1 order-1">
                                <div className="error-type row g-0">
                                    <h2 className="col">C</h2>
                                    <div className="col error-icon error-icon-mass"><img className="img-fluid bounce" src={logo} alt="errr-icon" /></div>
                                    {/* <div className="col error-icon"><img className="img-fluid bounce" src="images/error-icon.png" alt="errr-icon" /></div> */}
                                    <h2 className="col">V</h2>
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-2 order-2">
                                <header className="page-header"><h1 className="page-title text-right">ابدأ</h1></header>
                                <div className="page-content text-right"><p>أنشئ سيرتك الذاتية</p></div>
                                <div className='row btn-row text-right'>
                                    <div className='col-lg-5 '>
                                        <Link to={process.env.PUBLIC_URL + '/'}>
                                            <motion.button
                                                variants={buttonVariants}
                                                whileHover="hover"
                                            >
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                ttm-btn-color-skincolor"> الصفحة الرئيسية  </a>
                                            </motion.button>
                                        </Link>
                                    </div>
                                    <div className='col-lg-5'>
                                        <Link to={process.env.PUBLIC_URL + '/fill_cv'}>
                                            <motion.button

                                                variants={buttonVariants}
                                                whileHover="hover"
                                            >
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                ttm-btn-color-skincolor">انشاء سيرة ذاتية </a>
                                            </motion.button>
                                        </Link>

                                    </div>


                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            </motion.div>
        )
    }
}


export default StartCv;