import React, { Component, useState, useEffect } from 'react';
import '../firebase/config'
import { Link } from 'react-router-dom';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import ImageUploading from "react-images-uploading";
import Header from '../components/layout/Header';
import { GrAddCircle, GrUploadOption } from 'react-icons/gr';
import { IoMdAddCircleOutline } from 'react-icons/io'
import { AiFillEdit, AiOutlineArrowRight, AiOutlineArrowLeft, AiOutlineFilePdf, AiOutlineClose } from 'react-icons/ai';
import { GrDocumentDownload } from 'react-icons/gr'
import { RiDeleteBinFill } from 'react-icons/ri'
import { BiAddToQueue, BiBold, BiEdit, BiSave, BiUpload ,BiShoppingBag} from "react-icons/bi";
import { SlPhone } from 'react-icons/sl'
import { BsSave } from 'react-icons/bs'
import { resetIdCounter, Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ProgressBar from 'react-animated-progress-bar';
// =========
import { useAuthContext } from '../hooks/useAuthContext';
import { usePostNestDocs } from '../hooks/usePostNestDocs';
import { useFetchNestDocs } from '../hooks/useFetchNestDocs';
import { useCollection } from '../hooks/useCollection';
import { projectStorage } from '../firebase/config';
import { useDocument } from '../hooks/useDocument';
import { EditorState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertToRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { v4 as uuidv4 } from 'uuid'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas';
import logo from '../assets/img/blank-img.png'
const containerVariants = {
    hidden: {
        opacity: 0,
        x: '-100vw'
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: { type: 'spring', delay: 0.5 }
    },
    exit: {
        x: "-100vh",
        transition: { ease: 'easeInOut' }
    }
};

const nextVariants = {
    hidden: {
        x: '-20vw'
    },
    visible: {
        x: 0,
        transition: { ease: 'easeInOut' }

        // transition: { type: 'spring', stiffness: 120 }
    }
};
const nextVariantsRight = {
    hidden: {
        x: '20vw'
    },
    visible: {
        x: 0,
        transition: { ease: 'easeInOut' }

        // transition: { type: 'spring', stiffness: 120 }
    }
}
const buttonVariants = {
    hover: {
        scale: 1.1,
        // textShadow: "0px 0px 8px rgb(255,255,255)",
        fontWeight: "bold",
        boxShadow: "0px 0px 8px rgb(255,255,255)",

        transition: {
            duration: 0.5,
            yoyo: Infinity
        }
    }
}

const buttonVariantsUpload = {
    hover: {
        scale: 1.3,
        textShadow: "0px 0px 8px rgb(255,255,255)",
        // boxShadow: "0px 0px 8px rgb(255,255,255)",
        color: "#ff8d00",
        transition: {
            duration: 0.7,
            yoyo: Infinity
        }

    }
}
const warningVariants = {

    visible: {
        scale: 0.1,
        textShadow: "0px 0px 8px rgb(255,255,255)",
        boxShadow: "0px 0px 8px rgb(255,255,255)",
        transition: {
            duration: 0.7,
            yoyo: Infinity
        }
    }
}

// var mylink
export default function CreateCv(props) {
    const printRef = React.useRef();
    const printRef2 = React.useRef();
// console.log("props are: ", props)
const[isPrint, setIsPrint] = useState(true);

    const x = useMotionValue(5)
    const { user } = useAuthContext();
    const [data, setData] = useState([])
    const [coData, setCoData] = useState()

    const [isSaved, setIsSaved] = useState(false)
    const [show, setShow] = useState(true)
    const { documents, err } = useCollection(
        'users', ["isEmployer", "==", false],
    )
   
    // const { documents, error } = useFetchNestDocs(
    //     'candidates', user.uid, "resume"
    // )
    const [saving, setSaving] = useState(false)
    const [editing, setEditing] = useState(false)
    const { addDocument, updateDocument, response } = usePostNestDocs('users', user.uid, "resume")
  
    // Cv_Contact_Info
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [occupation, setOccupation] = useState("");
    const [nationality, setNationality] = useState("");
    const [dateOfBirth, setDate] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [gender, setGender] = useState("");
    const [category, setCategory] = useState("")
    // Cv_SOCIAL_LINKS_Info
    const [links, setLinks] = useState([])
    const [link, setLink] = useState("");
    const [facebook, setFacebook] = useState("");
    const [instagram, setInstagram] = useState("");
    const [twitter, setTwitter] = useState("");
    const [portfolio, setProtfolio] = useState("");

    const [profilePicture, setProfileP] = useState("")
    // Cv_Work Exp_Info
    const [expData, setExpData] = useState([])
    const [position, setPosition] = useState("")
    const [company1, setCompany1] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [jobLocation, setJobLocation] = useState("")
    const [convertedContent, setConvertedContent] = useState();
    // CV_Skills_Info
    const [skill, setSkill] = useState("")
    const [skills, setSkills] = useState([])
    const [skilLevel, setSkilLevel] = useState("0")
    const [languages, setLanguages] = useState([])
    const [language, setLanguage] = useState("")
    const [languageLevel, setLanguageLevel] = useState("0")
    // CV_EDUCATION_INFO
    const [degree, setDegree] = useState("")
    const [schoolName, setSchoolName] = useState("")
    const [schoolStartDate, setSchoolStart] = useState("")
    const [schoolEndDate, setSchoolEnd] = useState("")
    const [schoolLocation, setSchoolLocation] = useState("")
    const [schoolEditorState, setSchoolEditorState] = useState(() => EditorState.createEmpty())
    const [convertedContentSc, setConvertedContentSc] = useState();
    // CV_ABOUT_INFO
    const [aboutEditorState, setAboutEditorState] = useState(() => EditorState.createEmpty())
    const [convertedContentAb, setConvertedContentAb] = useState();

    const [isCheckedSchool, setCheckedSchool] = useState(false)
    const [education, setEdu] = useState("")
    const [educations, setEdus] = useState([])

    // const raw = convertToRaw(_contentState)
    // const [contentState, setContentState] = useState(raw)
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
    const [isChecked, setChecked] = useState(false)
    const [currentDate, setCurrentDate] = useState("")
    const [endYear, setEndYear] = useState("")

    // const [tabId, setTabId] = useState(0);
    // const [images, setImages] = React.useState([]);
    // const [isImage, setIsImage] = useState(false)

// console.log(props.id)
const { document, error } = useDocument("users", props.id)

    useEffect(() => {
        if (document) {
            setData(document)
            // console.log(data, "data")

            setExpData(document.expData ? document.expData : [])
            setSkills(document.skills ? document.skills : [])
            setEdus(document.educations ? document.educations : [])
            setLanguages(document.languages ? document.languages : [])
            setConvertedContentAb(document.convertedContentAb)
            setEmail(document.cvEmail ? document.cvEmail : "")
            setAddress(document.address ? document.address : "")
            setOccupation(document.occupation ? document.occupation : "")
            setFirstName(document.firstName ? document.firstName : "")
            setLastName(document.lastName ? document.lastName : "")
            setPhoneNumber(document.phoneNumber ? document.phoneNumber : "")
            setProfileP(document.profilePicture ? document.profilePicture : "")
            setConvertedContentAb(document.aboutMeObjective ? document.aboutMeObjective : "")
            setTwitter(document.twitter ? document.twitter : "")
            setProtfolio(document.portfolio ? document.portfolio : "")
            setFacebook(document.facebook ? document.facebook : "")
            setNationality(document.nationality ? document.nationality : "")
            setDate(document.dateOfBirth ? document.dateOfBirth : "")
            setLink(document.link ? document.link : "")
            setInstagram(document.instagram ? document.instagram : "")
            setCategory(document.category ? document.category : "")
            setGender(document.gender ? document.gender : "")
            //    setImages(document.profilePicture)


        }
    }, [document])



    useEffect(() => {
        if (props.download && data) {
            
           console.log("Start Downloading now ")
           download()
           setShow(false)
           
        }
    },[props.download])


    // End Date Handler
    const handleCheckChange = () => {
        setChecked(!isChecked);
    };
    // End Date School Handler
    const handleSchoolCheckChange = () => {
        setCheckedSchool(!isCheckedSchool);
    };
    const showCv = () => {
        // setShow(!show)
        window.location.reload(false)
        // console.log("sho cv is clicked here")
    }

    useEffect(() => {
        if (isChecked) {
            const currentD = new Date().toLocaleDateString();
            let sp = currentD.split('/')
            const cureentDate = sp[2] + "/" + sp[1] + "/" + sp[0]
            setEndDate(cureentDate)
        }
    })
    useEffect(() => {
        if (isCheckedSchool) {
            const currenD = new Date().toLocaleDateString();
            let sp = currenD.split('/')
            const cureentDate = sp[2] + "/" + sp[1] + "/" + sp[0]
            setSchoolEnd(cureentDate)
        }
    })
    // Text Editor Handler
    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();

    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
        // console.log("Editor state:", currentContentAsHTML)
    }
    const handleEditorChangeSc = (state) => {
        setSchoolEditorState(state);
        convertContentToHTMLSc();

    }
    const convertContentToHTMLSc = () => {
        let currentContentAsHTML = convertToHTML(schoolEditorState.getCurrentContent());
        setConvertedContentSc(currentContentAsHTML);
        // console.log("Editor state:", currentContentAsHTML)
    }

    const handleEditorChangeAb = (state) => {
        setAboutEditorState(state);
        convertContentToHTMLAb();

    }
    const convertContentToHTMLAb = () => {
        let currentContentAsHTML = convertToHTML(aboutEditorState.getCurrentContent());
        setConvertedContentAb(currentContentAsHTML);
        // console.log("Editor state:", currentContentAsHTML)
    }


    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }
    // Tabs Switching
    const [prev, setprev] = useState(0)
    let xx = "";
    const [tabId, setTabId] = useState(0);
    const [images, setImages] = React.useState([]);
    const [isImage, setIsImage] = useState(false)
    const maxNumber = 1;
    const maxSize = 51200; //50 mega
    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        console.log("image list", imageList, addUpdateIndex);

        setImages(imageList);

    };

    async function incrementCounter() {
        const newt = tabId + 1
        const ScrollToTop = () => window.scrollTo({ top: 40, behavior: "smooth" });
        ScrollToTop()
        setTabId(newt)

        // console.log("tab is id : ", newt)
    }
    function decrementCounter() {
        const newt = tabId - 1
        const ScrollToTop = () => window.scrollTo({ top: 40, behavior: "smooth" });
        ScrollToTop()
        setTabId(newt)
    }

    const addExp = (e) => {
        e.preventDefault()
        const newEpx = {
            id: uuidv4(),
            position: position ? position : "",
            company1: company1 ? company1 : "",
            startDate: startDate ? startDate : "",
            endDate: endDate ? endDate : "",
            jobLocation: jobLocation ? jobLocation : "",
            convertedContent: convertedContent ? convertedContent : "",
        }
        // updateDocument(user.uid, ...expData)
        setExpData([...expData, newEpx])
        // updateDocument(user.uid,[...document.expData,newEpx])
        setPosition("")
        setCompany1("")
        setPosition("")
        setJobLocation("")
        setStartDate("")
        setEndDate("")
        setChecked(false)
        const neweditorState = EditorState.push(editorState, ContentState.createFromText(''));
        setEditorState(neweditorState)




    }

    const addSkill = (e) => {
        e.preventDefault()

        const newSkill = {
            id: uuidv4(),
            skill,
            skilLevel,
        }

        setSkills([...skills, newSkill])
        setSkill("")
        setSkilLevel("")

    }


    const addEducation = (e) => {
        e.preventDefault()
        const newEdu = {
            id: uuidv4(),
            degree: degree ? degree : "",
            schoolName: schoolName ? schoolName : "",
            schoolStartDate: schoolStartDate ? schoolStartDate : "",
            schoolEndDate: schoolEndDate ? schoolEndDate : "",
            schoolLocation: schoolLocation ? schoolLocation : "",
            convertedContentSc: convertedContentSc ? convertedContentSc : "",

        }
        // console.log("content- converted:", convertedContentSc)

        setEdus([...educations, newEdu])
        setDegree("")
        setSchoolName("")
        setSchoolStart("")
        setSchoolEnd("")
        setSchoolLocation("")
        setCheckedSchool(false)
        const neweditorState = EditorState.push(schoolEditorState, ContentState.createFromText(''));
        setSchoolEditorState(neweditorState)

    }
    // Delete experience function
    const deleteSkill = (id) => setSkills(skills.filter((row) => row.id !== id))
    // Delete experience function
    const deleteExp = (id) => setExpData(expData.filter((row) => row.id !== id))
    // Language Handler
    const addLanguage = (e) => {
        e.preventDefault()
        const newLanguage = {
            id: uuidv4(),
            language,
            languageLevel,
        }

        setLanguages([...languages, newLanguage])
        setLanguage("")
        setLanguageLevel("")

    }
    // Delete experience function
    const deleteLang = (id) => setLanguages(languages.filter((row) => row.id !== id))
    // Delete experience function
    const deleteEdu = (id) => setEdus(educations.filter((row) => row.id !== id))

    

    //  PDF Print Function 
    const handleDownloadPdf = async () => {
        
        const element = printRef.current;
        element.style.background = '#FFFFFF';
        element.style.color='#000';
        const canvas = await html2canvas(element);
        // const canvas2 = await html2canvas(element2);
        // const element2 = printRef2.current;
        const d = new Date().toLocaleString() + "";
        // const footer = d + '             www.mass-portal.com';
        var imgData = canvas.toDataURL('image/png');
        // var imgData2 = canvas2.toDataURL('image/png');
        var imgWidth = 210;
        var pageHeight = 290;
        var imgHeight = (canvas.height * imgWidth / canvas.width);
        var heightLeft = imgHeight;
        var doc = new jsPDF();
        var margin = 10
        var position = 18; // give some top padding to first page
        // Data
        doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        // doc.addImage(massportallogo, 'png', 2, 0, 20, 20);
        // doc.text(footer, 30, pageHeight , { baseline: 'bottom' });
        // doc.addPage();
        // doc.addImage(imgData2, 'PNG', 2, position - 5, imgWidth-8, imgHeight);
        // doc.addImage(massportallogo, 'png', 2, 0, 20, 20);
        // doc.text(footer, 30, pageHeight , { baseline: 'bottom' });
        const docName = firstName + lastName + '_CV.pdf'
        doc.save(docName);
    };
    const download = () => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            // setTimeout(() => {
            //     setLoader(true);
            // }, 100);
        setIsPrint(true)
            const divToPrint = printRef.current
            divToPrint.style.background = '#FFFFFF';

            html2canvas(divToPrint).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = 210;
                const pageHeight = 290;

                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                const doc = new jsPDF('pt', 'mm');
                let position = 0;
                doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight + 25);
                heightLeft -= pageHeight;
                while (heightLeft >= 0) {

                    // let logo = require('images/blank-img.png');
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + 15);
                    heightLeft -= pageHeight;
                }
                const docName = firstName + lastName + '_CV.pdf'
                doc.save(docName);
                setIsPrint(false);
                // doc.save('download.pdf');
                // setLoader(false);
            });
        }, 1000);
    };

    return (
        <div className="site-main  "
        >

            {/* padding_zero-section */}

            <section className="ttm-row grid-section clearfix site-main-cv">

                <div className=" container-cv" >
                    {/* row */}


                    <div className="row ">
            
                        {/* ==================================================View CV_PDF ===================================== */}
                        {document &&
                            <div className= "col-lg-8"  style={{ margin: "auto" }}>
                                {show &&
                                    <div className='row'>
                                        <div className='col-lg-4'  style={{margin:"auto"}}>
                                            <Link >
                                                <motion.div
                                                >
                                                    <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                        variants={buttonVariants}
                                                        whileHover="hover"
                                                        // onClick={handleDownloadPdf}
                                                        onClick={download}

                                                    >
                                                        <span > <AiOutlineFilePdf size={25} className='arrow-icon-left' /></span>
                                                        <span style={{ fontSize: "large" }}> Download CV as  PDF </span>
                                                        <span > <AiOutlineFilePdf size={25} className='arrow-icon-right' /></span>

                                                    </motion.button>
                                                </motion.div>
                                            </Link>
                                        </div>
                                        {/* <div className='col-lg-4'>
                                            <Link to={process.env.PUBLIC_URL + '/fill_cv'} target='_blank'>
                                                <motion.div
                                                >
                                                    <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                        variants={buttonVariants}
                                                        whileHover="hover"
                                                    >
                                                        <span > <AiFillEdit size={25} className='arrow-icon-left' /></span>
                                                        <span style={{ fontSize: "large" }}> Edit Cv  </span>
                                                        <span > <AiFillEdit size={25} className='arrow-icon-right' /></span>
                                                    </motion.button>
                                                </motion.div>
                                            </Link>
                                        </div> */}
                                        <div className='col-lg-4'  style={{margin:"auto"}}>
                                            <Link onClick={showCv}>
                                                <motion.div
                                                >
                                                    <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                        variants={buttonVariants}
                                                        whileHover="hover"
                                                    >
                                                        <span > <AiOutlineClose size={25} className='arrow-icon-left' /></span>
                                                        <span style={{ fontSize: "large" }}> CLOSE  </span>
                                                        <span > <AiOutlineClose size={25} className='arrow-icon-right' /></span>
                                                    </motion.button>
                                                </motion.div>
                                            </Link>
                                        </div>

                                    </div>}
                            
                                <div className={isPrint?"bg-theme-WhiteColor p-20 p-lg-30 mt-lg-30 mt-50 mb-50 ":"ttm-col-bgcolor-yes ttm-bg bg-theme-GreyColor  p-20 p-lg-30 mt-lg-30 mt-50 mb-50 "}  ref={printRef} style={{letterSpacing:"normal"}}>
                                    
                                <div className="layer-content">
                                        <div className=" sidebar job-sidebar clearfix">
                                            <div className="">
                                                <div className="row">
                                                    <div className="col-lg-5 widget-area sidebar-left job_list-widget-area ">
                                                        <div className={isPrint?"job_list-widget-white job_list-widget ":" job_list-widget  "} dir='rtl'>
                                                            <aside className="widget form-widget">
                                                                <h3 className="widget-title "><i className="ti ti-email"></i>معلومات الاتصال</h3>
                                                                {document && <ul  >
                                                                    {email && <li className="d-flex"><b className="mr-5">البريد الكتروني : </b><span>{email ? email : document.cvEmail}</span></li>}
                                                                    {phoneNumber && <li className="d-flex"><b className="mr-5">رقم الهاتف : </b><span>{phoneNumber ? phoneNumber : document.phoneNumber}</span></li>}
                                                                    {address && <li className="d-flex"><b className="mr-5">العنوان : </b><span>{address ? address : document.address}</span></li>}
                                                                    {nationality && <li className="d-flex"><b className="mr-5">الجنسية : </b><span>{nationality ? nationality : document.nationality}</span></li>}
                                                                    {dateOfBirth && <li className="d-flex"><b className="mr-5">تاريخ الولادة :</b><span>{dateOfBirth ? dateOfBirth : document.dateOfBirth}</span></li>}
                                                                    {link && <li className="d-flex"><b className="mr-5">Link:</b><span>{link ? link : document.link}</span></li>}
                                                                    {facebook && <li className="d-flex"><b className="mr-5">Facebook:</b><span>{facebook ? facebook : document.facebook}</span></li>}
                                                                    {instagram && <li className="d-flex"><b className="mr-5">Instagram:</b><span>{instagram ? instagram : document.instagram}</span></li>}
                                                                    {twitter && <li className="d-flex"><b className="mr-5">Twitter:</b><span>{twitter ? twitter : document.twitter}</span></li>}
                                                                    {portfolio && <li className="d-flex"><b className="mr-5">الموقع الالكتروني</b><span>{portfolio ? portfolio : document.portfolio}</span></li>}
                                                                </ul>}
                                                            </aside>
                                                            <aside className="widget job-widget ">
                                                                <h3 className="widget-title"><i className="flaticon flaticon-expert"></i>مهارات </h3>
                                                                {skills ?
                                                                    <div className="row skills-row" >
                                                                        {skills.map((item, index) => (

                                                                            <div className='col-md-6 ttm-progress-bar clearfix' key={index}>
                                                                                <h3 className={isPrint?"progressbar-title text-black ":"progressbar-title"} style={{ fontWight: "bold" }}># {item.skill}</h3>
                                                                            </div>
                                                                        ))} </div> :
                                                                    <div className="desc pb-30" >
                                                                    </div>
                                                                }
                                                            </aside>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 content-area">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="candidate-data">
                                                                    {document && document.profilePicture ?
                                                                        <div className="candidate-img" >
                                                                            <div className="image-item">
                                                                                <img src={document.profilePicture} alt="profile picture" width="100" height="100" />
                                                                            </div>
                                                                        </div> :
                                                                        images.map((image, index) => (
                                                                            <div className="candidate-img" key={index}>
                                                                                <div className="image-item">
                                                                                    <img src={image.data_url ? image.data_url : document.profilePicture} alt="profile picture" width="100" height="100" />
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                    {document &&
                                                                        document.name ?
                                                                        <div className="candidate-caption text-right">
                                                                            <h5 className='text-right'>{firstName ? firstName : document.name}   {lastName ? lastName : ""}</h5>

                                                                            <div className="meta-line " dir='rtl'>
                                                                                <span dir=''><i className="">< BiShoppingBag size={20} /></i> {occupation ? occupation : (document.occupation ? document.occupation : "")}</span>
                                                                                <span><i className=""><SlPhone size={20} /></i> {phoneNumber ? phoneNumber : (document.phoneNumber ? document.phoneNumber : "")}</span>
                                                                            </div>
                                                                        </div> :
                                                                        <div className="candidate-caption text-right" >
                                                                            <h5>{firstName ? firstName + " " : " Name "} {lastName ? lastName : " "}</h5>

                                                                            <div className="meta-line " dir='rtl' >
                                                                                <span><i className="">< BiShoppingBag size={20} /></i>{occupation ? occupation : " Occupation"}</span>
                                                                                <span dir=''><i className=""><SlPhone size={16} /></i> {phoneNumber ? phoneNumber : " PhoneNumber"}</span>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row text-right" dir='rtl'>
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="overview-box">
                                                                    <div className="">
                                                                        {/* <h5>عني</h5> */}
                                                                    </div>
                                                                    <div className={isPrint?" desc text-black bg-theme-WhiteColor ":"desc"}>
                                                                        {convertedContentAb && <p dangerouslySetInnerHTML={createMarkup(convertedContentAb)}></p>}

                                                                    </div>
                                                                </div>
                                                                <div className="overview-box">
                                                                    <div className="title">
                                                                        <h5>خبرة العمل </h5>
                                                                    </div>
                                                                    <div className={isPrint?" desc text-black bg-theme-WhiteColor ":"desc"}>
                                                                        <div className="candidate-timeline">

                                                                            {expData ?
                                                                                expData.map((experience, index) => (
                                                                                    <div className="timeline-panel" key={index}>
                                                                                        <div className={isPrint?" text-theme-SkinColor timeline-head":"timeline-head"}>
                                                                                            <h3 className={isPrint?"text-theme-DarkColor text-bold":" "} >{experience.company1} </h3>

                                                                                            <span className="timeline-year"> {experience.startDate ? (experience.startDate[0] + "" + experience.startDate[1] + "" + experience.startDate[2] + "" + experience.startDate[3] + " - ") : ""}  {experience.endDate ? (experience.endDate[0] + "" + experience.endDate[1] + "" + experience.endDate[2] + "" + experience.endDate[3] + "") : " "} </span>
                                                                                        </div>
                                                                                        <div className="timeline-body">
                                                                                            <h5>{experience.position}</h5>


                                                                                            <p dangerouslySetInnerHTML={createMarkup(experience.convertedContent)}></p>

                                                                                        </div>
                                                                                    </div>
                                                                                )) :
                                                                                <div className="timeline-panel" >
                                                                                    <div className="timeline-head">
                                                                                        <h3>Intel</h3>

                                                                                        <span className="timeline-year"> 2018 - 2022    </span>
                                                                                    </div>
                                                                                    <div className="timeline-body">
                                                                                        <h5>Web Developer</h5>


                                                                                        <p >fron end developer</p>

                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="overview-box">
                                                                    <div className="title">
                                                                        <h5>مؤهلات علمية </h5>
                                                                    </div>
                                                                    <div className={isPrint?" desc text-black bg-theme-WhiteColor":"desc"}>
                                                                        <div className="candidate-timeline">

                                                                            {educations.map((edu, index) => (
                                                                                <div className="timeline-panel" key={index}>
                                                                                    <div className="timeline-head">
                                                                                        <h3 className={isPrint?"text-theme-DarkColor":""}>{edu.degree}</h3>

                                                                                        <span className={isPrint?"timeline-year text-theme-SkinColor":"timeline-year"}> {edu.schoolStartDate ? (edu.schoolStartDate[0] + "" + edu.schoolStartDate[1] + "" + edu.schoolStartDate[2] + "" + edu.schoolStartDate[3] + " - ") : ""}  {edu.schoolEndDate ? (edu.schoolEndDate[0] + "" + edu.schoolEndDate[1] + "" + edu.schoolEndDate[2] + "" + edu.schoolEndDate[3] + "") : " "} </span>
                                                                                    </div>
                                                                                    <div className="timeline-body">
                                                                                        <h5>{edu.schoolName}</h5>
                                                                                        <p dangerouslySetInnerHTML={createMarkup(edu.convertedContentSc)}></p>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* {skills && <div className="overview-box" >
                                                                    <div className="title">
                                                                        <h5>Skills </h5>
                                                                    </div>
                                                                    {skills.map((item, index) => (
                                                                        <div className="desc pb-30" key={index}>
                                                                            <div className="ttm-progress-bar clearfix">
                                                                                <h3 className="progressbar-title">{item.skill}</h3>
                                                                                <ProgressBar rect percentage={item.skilLevel} />
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>} */}
                                                                {languages && <div className="overview-box" >
                                                                    <div className="title ">
                                                                        <div className='row'>
                                                                            <h5 className='col-md-6'>لغات  </h5>
                                                                            <h5 className='col-md-6' style={{ direction: "rtl" }}>مستوى اللغة </h5>
                                                                        </div>

                                                                    </div>
                                                                    {languages.map((item, index) => (
                                                                        <div className={isPrint?"desc pb-30 text-black bg-theme-WhiteColor":"desc pb-30 "} key={index}>
                                                                            <div className="ttm-progress-bar clearfix row">
                                                                                <h3 className={isPrint?"progressbar-title col-md-6 text-black":"progressbar-title col-md-6"} style={{ letterSpacing: "normal", fontWeight: "bold" }}>{item.language}</h3>
                                                                                {item.languageLevel == "100" && <h3 className={isPrint?"progressbar-title col-md-6 text-black":"progressbar-title col-md-6 text-theme-SkinColor"} style={{ letterSpacing: "normal", direction: "rtl" }}> اللغة الأم  </h3>}
                                                                                {item.languageLevel == "80" && <h3 className={isPrint?"progressbar-title col-md-6 text-black":"progressbar-title col-md-6 text-theme-SkinColor"} style={{ letterSpacing: "normal", direction: "rtl" }}> ممتاز  </h3>}
                                                                                {item.languageLevel == "75" && <h3 className={isPrint?"progressbar-title col-md-6 text-black":"progressbar-title col-md-6 text-theme-SkinColor"} style={{ letterSpacing: "normal", direction: "rtl" }}> جيد  </h3>}
                                                                                {item.languageLevel == "50" && <h3 className={isPrint?"progressbar-title col-md-6 text-black":"progressbar-title col-md-6 text-theme-SkinColor"}  style={{ letterSpacing: "normal", direction: "rtl" }}> مبتدئ </h3>}
                                                                                {/* <ProgressBar rect percentage={item.languageLevel} /> */}
                                                                                <hr></hr>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>}

                    </div></div>
            </section>


        </div>
    )
}



