import React, { Component, useState, useEffect } from 'react';
import '../firebase/config'
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import Footer from '../components/layout/Footer';
import ProgressBar from 'react-animated-progress-bar';
import { timestamp } from '../firebase/config';
import { useDocument } from '../hooks/useDocument';
import DOMPurify from 'dompurify';
import emailjs from '@emailjs/browser';
import profile from "../assets/img/massavatar/massres-bus.png"
import Slider from 'react-slick';
import { useFetchNestDocs } from '../hooks/useFetchNestDocs';
export default function Employers_details(props) {
    const id = props.match.params.id;
    const [data, setData] = useState([])
    const [jobsData, setJobsData] = useState([])
    const { document, error } = useDocument("employers", id)
    const { documents: compJobs, err } = useFetchNestDocs("employers", id ? id : "", "jobs","",["createdAt", "desc"])
    const jobTypes = ["", "دوام كامل ", "دوام كامل - عاجل ", "دوام جزئي ", "دوام جزئي - عاجل ", "عبر الانترنت ", "عبر الانترنت - عاجل "]
    const cats = ["", " محاسبة و مالية ", "تكنولوجيا  ", " زراعة ", " بناء ", " وظائف طبية ", " انتاج ", " نقل  ", " Digital Design & Social Media "]

    // EmailJs From
    const [fromName, setFname] = useState("")
    const [fromEmail, setFemail] = useState("")
    const [fromSubject, setFsubject] = useState("")
    const [fromMessage, setFmsg] = useState("")
    const [response, setReponse] = useState(false)
    useEffect(()=>{
        window.scrollTo({ top: 40, behavior: "smooth" });
      },[])
    useEffect(() => {
        if (document) {
            setData(document)

            // console.log("doc details is : ", document)
        }
    }, [document])
    useEffect(() => {
        if (compJobs) {
            setJobsData(compJobs)

            // console.log("doc details is : ", compJobs)
        }
    }, [compJobs])
    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        setReponse(true)


        var templateParams = {
            from_name: fromName,
            from_email: fromEmail,
            to_name: data.companyName,
            message: fromMessage,
            fromSubject,
            to_email: data.compEmail,
        };

        await emailjs.send('service_s1khhzc', 'template_4caki7q', templateParams, "-gaOaetDVoDcH_Mt7")
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
                setReponse(false)

            }, function (error) {
                console.log('FAILED...', error);
            });

        setFemail(""); setFmsg(""); setFname(""); setFsubject("")
    }

    var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 1,

        responsive: [{

            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };

    function flt(dbDate) {
        const timedb = new Date(dbDate.seconds * 1000);
        const today = timestamp.fromDate(new Date())
        const time = new Date(today.seconds * 1000);
        const thisDatr = new Date(time - timedb)
        var difference = time.getTime() - timedb.getTime();
        var daysDifference = Math.floor(difference/1000/60/60/24)
        // console.log("diff:", daysDifference)
      
     
        return(daysDifference)
    }
    return (

        <div className="site-main">
            <Header />

            {/* PageHeader */}
            <PageHeader
                title="employers details"
                subTitle="Are you up for the challenge ?"
                breadcrumb="employers"
            />
            {/* PageHeader end */}

            {data ?

                <div className="ttm-row sidebar job-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
                                <div className="job_list-widget" dir='rtl'>
                                    <aside className="widget candidate-widget">
                                        <h3 className="widget-title"><i className="ti ti-files"></i>اتصل بنا </h3>

                                        <form className="wrap-form" onSubmit={handleSubmit}>
                                            <label>
                                                <input name="fromName" type="text" placeholder="اسمك "
                                                    required="required"
                                                    onChange={e => (setFname(e.target.value))}
                                                />
                                            </label>
                                            <label>
                                                <input name="fromEmail" type="text" placeholder="بريدك الالكتروني "
                                                    required="required"
                                                    onChange={e => (setFemail(e.target.value))}
                                                />
                                            </label>
                                            <label>
                                                <input name="fromSubject" type="text" placeholder="الموضوع "
                                                    required="required"
                                                    onChange={e => (setFsubject(e.target.value))}
                                                />
                                            </label>
                                            <label>
                                                <textarea name="fromMessage" rows="4" placeholder="رسالتك"
                                                    required="required"
                                                    onChange={e => (setFmsg(e.target.value))}
                                                ></textarea>
                                            </label>
                                            <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100" >ارسال ! </button>
                                        </form>
                                    </aside>
                                    <aside className="widget location-widget p-0">
                                        {data.compMapsrc && <div className='' style={{ wordBreak: "break-all", height: "350", marginTop: "20px" }}>
                                            <div dangerouslySetInnerHTML={{ __html: data.compMapsrc }} ></div>
                                        </div>}
                                        <div className="p-20">
                                            <div>
                                                <span className="text-theme-SkinColor">
                                                    <i className="fa fa-map-marker-alt mr-10"></i> </span> {data.cAddress}
                                            </div>
                                            <div>
                                                <a href={"mailto:" + data.compEmail}> <i className="fa fa-envelope mr-10 text-theme-SkinColor" ></i> {data.compEmail}</a>
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                                <div className="overview-box">
                                    <div className="title" dir='rtl'>
                                        <div className="d-sm-flex justify-content-between align-items-center mb_10">
                                            <div className="social-icons circle mb-10">

                                                <ul className="list-inline">
                                                    {data.facebook && <li><a href={data.facebook} rel="noopener" aria-label="facebook" target='_blank'><i className="ti ti-facebook"></i></a></li>}
                                                    {data.twitter && <li><a href={data.twitter} rel="noopener" aria-label="twitter" target='_blank'><i className="ti ti-twitter-alt"></i></a></li>}
                                                    {data.instagram && <li><a href={data.instagram} rel="noopener" aria-label="instagram" target='_blank'><i className="ti ti-instagram"></i></a></li>}
                                                    {data.link && <li><a href={data.link} rel="noopener" aria-label="LinkedIn" target='_blank'><i className="ti ti-linkedin"></i></a></li>}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 content-area text-right">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* featured-imagebox */}
                                        <div className="featured-imagebox featured-imagebox-employer">
                                            <div className="featured-thumbnail">
                                                {data.profilePicture && <img src={data.profilePicture} />}
                                                {!data.profilePicture && <img src={profile} />}

                                            </div>
                                            <div className="featured-content">
                                                {/* <div className="select-btn">
                                                    <i className="fa fa-heart"></i>
                                                </div> */}
                                                <div className="featured-title">
                                                    <h3>{data.companyName}</h3>
                                                </div>
                                                <div className="featured-bottom" dir='rtl'>
                                                    <div className="job-meta">
                                                        <span><i className="fa fa-map-marker-alt"></i> {data.cAddress}</span>
                                                        <span><i className="fa fa-hand-holding-usd"></i> {data.payRange}</span>
                                                        {/* <span><i className="ti ti-bag"></i> {cats[data.compCat]}</span> */}

                                                        {/* <span>Skills :   HTML, CSS, JavaScript, Wordpress, PHP</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>{/* featured-imagebox end */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="overview-box" dir='rtl'>
                                            <div className="desc">
                                                <div className="row text-right" >
                                                    <div className="col-md-4">
                                                        <div className="d-flex mt-15 mb-15">
                                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-sm ttm-icon_element-color-skincolor">
                                                                <i className="ti ti-briefcase"></i>
                                                            </div>
                                                            <div className="pl-10">
                                                                <h6 className="mb-1 fw-normal"> تصنيف الشركة </h6>
                                                                <span>{cats[data.compCat]}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="d-flex mt-15 mb-15">
                                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-sm ttm-icon_element-color-skincolor">
                                                                <i className="ti ti-search"></i>
                                                            </div>
                                                            <div className="pl-10">
                                                                <h6 className="mb-1 fw-normal" >  تأسست عام   </h6>
                                                                <span>{data.dateOfBirth}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-4">
                                                        <div className="d-flex mt-15 mb-15">
                                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-sm ttm-icon_element-color-skincolor">
                                                                <i className="ti ti-location-pin"></i>
                                                            </div>
                                                            <div className="pl-10">
                                                                <h6 className="mb-1 fw-normal">Locations :</h6>
                                                                <span>{data.cAddress}</span>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col-md-4">
                                                        <div className="d-flex mt-15 mb-15">
                                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-sm ttm-icon_element-color-skincolor">
                                                                <i className="far fa-building"></i>
                                                            </div>
                                                            <div className="pl-10">
                                                                <h6 className="mb-1 fw-normal"> Comapny :</h6>
                                                                <span>{cats[data.compCat]} Company</span>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-4">
                                                        <div className="d-flex mt-15 mb-15">
                                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-sm ttm-icon_element-color-skincolor">
                                                                <i className="ti ti-user"></i>
                                                            </div>
                                                            <div className="pl-10">
                                                                <h6 className="mb-1 fw-normal"> عدد الموظفين </h6>
                                                                <span>{data.numOfEmp}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-4">
                                                        <div className="d-flex mt-15 mb-15">
                                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-sm ttm-icon_element-color-skincolor">
                                                                <i className="ti ti-tag"></i>
                                                            </div>
                                                            <div className="pl-10">
                                                                <h6 className="mb-1 fw-normal">Categories :</h6>
                                                                <span>Arts, Design, Media</span>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overview-box">
                                            <div className="title">
                                                <h5>عن {data.companyName }</h5>
                                            </div>
                                            <div className="desc">
                                                <p dangerouslySetInnerHTML={createMarkup(data.aboutUs)}></p>

                                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                                                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                                    nisi ut aliquip ex ea commodo consequat.</p> */}
                                            </div>
                                        </div>
                                        <div className="overview-box">
                                            <div className="title">
                                                <h5>ماذا نقدم ؟  </h5>
                                            </div>
                                            <div className="desc">
                                                <p dangerouslySetInnerHTML={createMarkup(data.whatWePresent)}></p>

                                                {/* <ul className="ttm-list ttm-list-style-disc ttm-list-icon-color-skincolor">
                                                    <li>
                                                        <div className="ttm-list-li-content">Extra Private health insurance coverage by the company to help you getting better focus.</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">13th salary and project bonus</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">Company trip, team-building events</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">Flexible working time schedule and leave policy as we know the importance of work-life balance</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">CBe exposed to the latest technology and the most innovative ideas worldwide</div>
                                                    </li>
                                                    <li>
                                                        <div className="ttm-list-li-content">Support laptop Extra healthcare package</div>
                                                    </li>
                                                </ul> */}
                                            </div>
                                        </div>
                                        {jobsData.length > 0 &&
                                            <><div className="row">

                                                <div className="col-lg-12">
                                                    <br></br><br></br>
                                                    <h5> الوظائف المتاحة </h5>
                                                </div>
                                            </div>
                                                <Slider className="row slick_slider slick-arrows-style2 mb_15" {...slick_slider} vertical={true} slidesToShow={1} rows={2} arrows={true}>
                                                    {jobsData.map((job, index) => (
                                                        <div className="col-lg-12 col-md-12" key={index}>
                                                            {/* featured-imagebox */}
                                                            <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                                                                <div className="featured-thumbnail">
                                                                    {data.profilePicture && <a href={process.env.PUBLIC_URL + '/Job_details/' +job.id}><img src={data.profilePicture} /></a>}
                                                                    {!data.profilePicture && <a href={process.env.PUBLIC_URL + '/Job_details/' +job.id}><img src={profile} /></a>}
                                                                    {job.jobType == '6' && <div className="required-tag">عاجل</div>}
                                                                    {job.jobType == '2' && <div className="required-tag">عاجل</div>}
                                                                    {job.jobType == '4' && <div className="required-tag">عاجل </div>}

                                                                    {/* <img src="https://via.placeholder.com/210x204?text=210x204+job-06.png" /> */}
                                                                </div>
                                                                <div className="featured-content">
                                                                    <div className="featured-title title-white">
                                                                        <h3><a href={process.env.PUBLIC_URL + '/Job_details/' + job.id }>{job.jobName}</a></h3>
                                                                    </div>
                                                                    <div className="featured-desc">
                                                                        {/* <p>Published  {flt(job.createdAt)}Days Ago.</p> */}
                                                                        <p>نشر   {flt(job.createdAt)>0?" قبل " +flt(job.createdAt)+" أيام ": " اليوم "}</p>
                                                                    </div>
                                                                    <div className="featured-bottom" dir='rtl'>
                                                                        <div className="job-meta">
                                                                            <span><i className="fa fa-map-marker-alt"></i>{job.jobLocation}</span>
                                                                            <span><i className="fa fa-filter"></i>{cats[job.jobCategory]}</span>
                                                                        </div>
                                                                        <div className="job-time">
                                                                            {/* <span className="blue">part time</span> */}
                                                                            {job.jobType && <span className={"green" + job.jobType}> {jobTypes[job.jobType]}</span>}


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>{/* featured-imagebox end */}
                                                        </div>
                                                    ))
                                                    }
                                                </Slider>{/* row end */}

                                            </>}
                                    </div>
                                </div>{/* row end */}
                            </div>

                        </div>{/* row end */}

                    </div>
                </div> : ""}


            {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment-5"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>there is always the possibility of getting hired</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>We have over 30 years experience oft Business porro qusquam dol ipsum quia dolor sit amet.</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                                    href={process.env.PUBLIC_URL + '/'}>Hiring Now!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}


            <Footer />

        </div>
    )
}
