import { useEffect, useState, useRef } from "react"
import { projectFirestore } from "../firebase/config"
let collectionRef = projectFirestore.collection('employers')

export const useFetchNestDocs = () => {
   

  const [companies, setCompanies] = useState([])
  const [attendeeInfo, setAttendeeInfo] = useState(null); // <-- should be an array not an object?
  const [events, setEvents] = useState(null);
  const loading = events === null || attendeeInfo === null;


  const getEventsData = async () => {

    const eventsQuerySnapshot = await collectionRef.orderBy("createdAt", "desc").get()
    let eventsInfo = [];
    let companiesInfo = [];
    // console.log("eventsQuerySnapshot",eventsQuerySnapshot)
    eventsQuerySnapshot.forEach((doc) => {
        const mypic = doc.data().profilePicture
        const myMap = doc.data().compMapsrc
        const fb = doc.data().facebook
        const inst = doc.data().instagram
        const link = doc.data().link
        const twit = doc.data().twitter
        const wbst = doc.data().compSite
        const cname = doc.data().companyName
        const companyId = doc.id
        // console.log("TEST", companyId)

        // eventsInfo.push({ ...doc.data(), companyPic: mypic, id: doc.id });
        eventsInfo.push({ id: doc.id, companyPic: mypic, myMap, fb, cname, companyId });
        companiesInfo.push({ ...doc.data(), companyPic: mypic, id: doc.id, companyId })
        // console.log("compID",doc.id)

    })

    setEvents(eventsInfo);
    setCompanies(companiesInfo)
    // setOrg(orgSnap.data().orgName);
}
const getAttendeesData = async (events) => {
    // console.log("events",events);

    const fetchAttendeesPromises = events.map(async (event) => {
        // console.log("event",event);
        const jobsRef = projectFirestore.collection('employers').doc(event.id).collection('jobs')
        // const attendeesRef = projectFirestore.collection('employers',event,"jobs");
        // const attendeesRef = projectFirestore.collection(db, "events", event, "attendees");
        const attendeesSnap = await jobsRef.get();
        const attendeeInformation = [];

        attendeesSnap.forEach((doc) => {
            attendeeInformation.push({ ...doc.data(), id: doc.id, companyPic: event.companyPic, cName: event.cname, companyId: event.companyId })
            // console.log("Data",doc.data())
        })
        return attendeeInformation;
    })
    const attendeesForAllEvents = await Promise.all(fetchAttendeesPromises)
        .then(attendeeGroups => attendeeGroups.flat())
    setAttendeeInfo(attendeesForAllEvents);
    // console.log(attendeesForAllEvents)
}

useEffect(() => {
    getEventsData();
}, []);

useEffect(() => {
    if (events !== null) // only call when data available
        getAttendeesData(events);
    //   console.log("companies",companies)
}, [events, companies]);




  return { getEventsData, getAttendeesData,loading ,events, attendeeInfo,companies }
}