import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import Footer from '../components/layout/Footer';

export class Terms extends Component {
    render() {
        return (

            <div className="site-main">
                <Header />

                {/* PageHeader */}
                <PageHeader
                    title="الشروط و الأحكام "
                    breadcrumb="الشروط و الأحكام "
                />
                {/* PageHeader end */}


                <div className="ttm-row sidebar ttm-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12 content-area" style={{ margin: "auto", textAlign: 'right'}}>
                                {/* post */}
                                <article className="post ttm-blog-single clearfix">
                                    {/* post-featured-wrapper */}
                                    {/* <div className="ttm-post-featured-wrapper ttm-featured-wrapper">
                                        <div className="ttm-post-featured">
                                            <img className="img-fluid" src="https://via.placeholder.com/1200x800?text=1200x800+blog-01-1200x800.jpg" alt="blog-img" />
                                        </div>
                                    </div> */}
                                    {/* post-featured-wrapper end */}
                                    {/* ttm-blog-classic-content */}
                                    <div className="ttm-blog-single-content">
                                        {/* <div className="ttm-post-entry-header">
                                            <div className="post-meta">
                                                <span className="ttm-meta-line author">
                                                    <img src="https://via.placeholder.com/294x190?text=author-img-294x190.jpg
" className="img-fluid" height="80" width="80" alt="author"/>By: Edward Samuel
                                                </span>
                                                <span className="ttm-meta-line comment-links"><i className="fa fa-comments"></i>03 Comments</span>
                                                <span className="ttm-meta-line date"><i className="fa fa-calendar"></i>17 Apr 2021</span>
                                            </div>
                                        </div> */}
                                        <div className="entry-content">
                                            <h2 dir='rtl' style={{textAlign:"center"}}>موقع <span className='text-theme-SkinColor'>( MassRes )</span></h2>

                                            <div className="ttm-box-desc-text" dir='rtl'>

                                                <p style={{color:"black"}}>
                                                    تنطبق شروط وأحكام موقع الويب هذه ("الشروط والأحكام") على وصولك واستخدامك لموقع <span> <a href='https://mass-res.web.app/' target='_blank'>  www.MassRes.com   </a></span>("الموقع") ،
                                                    بما في ذلك جميع البرامج
                                                    والبيانات والتقارير والنصوص والصور والأصوات والفيديو والمحتوى المتاح من خلال أي جزء من الموقع
                                                    (يشار إليها مجتمعة باسم "المحتوى"). يتضمن المحتوى
                                                    جميع هذه العناصر ككل ، بالإضافة إلى العناصر الفردية وأجزاء منها.
                                                </p>

                                                {/* <p>Contary typesetting industry. Lorem Ipsum on hasbeen the industry’s standard live youmgdummy text ever
                                                    the 1500s, when an unknown printer took a galley of type and scrambled.</p>

                                                <blockquote>
                                                    <div className="qoute-text">Mpsum is simply dummy text of the printing and conatary  typesg industy.
                                                    Lorem Ipsum on hasbeen the industry’s standard dummy te</div>
                                                    <cite>Alex Sam Martin</cite>
                                                </blockquote>

                                                <p>Contary is simply dummy text of the printting and  contary to popular typesetti dustry. Lorem Ipsum on
                                                    has been the industry’s standard dummy text ever since the 1500s, when loream an unknown printer took a 
                                                    galley of type and scrambled it to make a type specimen book.</p> */}

                                                <div className="d-md-flex align-items-center mb-20">
                                                    {/* <img className="img-fluid alignleft" src="https://via.placeholder.com/332x324?text=single-img-07-332x324.jpg" alt="single-07" /> */}
                                                    <div className="pt-10 pb-15">
                                                        <h3>قبول الشروط </h3>

                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                MassRes  تسمح لك  ("المستخدم" أو "أنت" أو "الخاص بك") بالوصول إلى الموقع والمحتوى واستخدامهما ، وفقًا لهذه الشروط والأحكام. من خلال الوصول أو استخدام أي جزء من الموقع ، فإنك تقر بأنك قد قرأت وفهمت ووافقت على الالتزام بهذه الشروط والأحكام. إذا كنت تدخل في هذه الشروط والأحكام نيابة عن شركة أو كيان قانوني آخر ("كيان المستخدم") ، فيجب أن تتمتع بالسلطة القانونية لإلزام كيان المستخدم هذا تعاقدياً بهذه الشروط والأحكام، وفي هذه الحالة، يجب أن تكون المصطلحات "أنت" أو "الخاص بك""أو" المستخدم " سيشير إلى كيان المستخدم هذا. إذا كنت تفتقر إلى هذه السلطة القانونية للالتزام تعاقدياً أو كنت لا توافق على هذه الشروط والأحكام ، فيجب عليك عدم قبول هذه الشروط والأحكام أو الوصول إلى الموقع أو المحتوى أو استخدامه.
                                                            </div></li>
                                                            {/* <li><i className="fas fa-long-arrow-alt-right"></i><div className="ttm-list-li-content">Nulla at metus ultricies, placerat augue sed.</div></li>
                                                            <li><i className="fas fa-long-arrow-alt-right"></i><div className="ttm-list-li-content">Curabitur mollis ex vestibulum, ullamcorper.</div></li>
                                                            <li><i className="fas fa-long-arrow-alt-right"></i><div className="ttm-list-li-content">Nulla at metus ultricies, placerat augue sed.</div></li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    {/* <img className="img-fluid alignleft" src="https://via.placeholder.com/332x324?text=single-img-07-332x324.jpg" alt="single-07" /> */}
                                                    <div className="pt-10 pb-15">
                                                        <h3>تحديثات الشروط والأحكام </h3>

                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                MassRes  تحتفظ بالحق ، وفقًا لتقديرها الخاص ، في تغيير أو تعديل أجزاء من هذه الشروط والأحكام في أي وقت. سوف تقوم ماسرِس بنشر التغييرات على هذه الشروط والأحكام على الموقع وستشير في أعلى هذه الصفحة إلى تاريخ آخر مراجعة لهذه الشروط. تقع على عاتقك مسؤولية التحقق من الشروط والأحكام بشكل دوري لمعرفة التغييرات. استمرار استخدامك للموقع والمحتوى بعد تاريخ سريان أي من هذه التغييرات يشكل موافقتك على الشروط والأحكام الجديدة أو المنقحة.
                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className="d-md-flex align-items-center mb-20">
                                                    {/* <img className="img-fluid alignleft" src="https://via.placeholder.com/332x324?text=single-img-07-332x324.jpg" alt="single-07" /> */}
                                                    <div className="pt-10 pb-15">
                                                        <h3>الشروط العامة / الوصول والاستخدام </h3>

                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                إذن للوصول إلى الموقع والمحتوى واستخدامهما. وفقاً لامتثالك لهذه الشروط والأحكام والأحكام الواردة فيها ،يمكنك الوصول إلى الموقع والمحتوى أو استخدامه فقط لغرض تقييمك ل ماسرِس و لمنتجات وخدمات ماسرِس. يمكنك فقط الارتباط بالموقع أو المحتوى ، أو أي جزء منه ، كما هو مسموح به صراحة من قبل MassRes.
                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>الملكية والقيود </h3>

                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                ستبقى جميع الحقوق والملكية والمصالح في الموقع والمحتوى وتنتمي حصريًا إلى MassRes.
                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>لن تقوم  ب : </h3>

                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                بترخيص أو إعادة بيع أو تأجير أو ايجار أو نقل أو تخصيص أو مشاركة الوقت أو استغلال الموقع تجارياً بطريقة أُخرى أو جعله متاحاً لأي طرف ثالث
                                                            </div></li>
                                                            <li><i className="fas fa-long-arrow-alt-right"></i><div className="ttm-list-li-content"> استخدام الموقع والمحتوى بأي طريقة غير قانونية ( بما في ذلك على سبيل المثال بدون تقييد في انتهاك أي بيانات أو خصوصية أو قوانين مراقبة الصادرات) أو بأي طريقة تتعارض أو تعطل سلامة أو أداء الموقع والمحتوى أو عناصر ذات الصلة</div></li>
                                                            <li><i className="fas fa-long-arrow-alt-right"></i><div className="ttm-list-li-content">
                                                                تعديل أو تكييف أو اختراق الموقع و المحتوى
                                                                أو محاولة الوصول الغير مصرح به إلى الأجزاء المقيدة من الموقع والمحتوى أو الأنظمة أو الشبكات ذات الصلة أي
                                                                <span className='text-theme-SkinColor'>  ( على سبيل المثال ) </span> التحايل على أي تشفير أو إجراءات
                                                                أمنية أخرى ، أو الوصول إلى أي كود مصدر أو أي شكل أساسي آخر من أشكال التكنولوجيا أو المعلومات ، والوصول
                                                                إلى أي جزء من الموقع والمحتوى ، أو أي منتجات أو خدمات أخرى من ماسرِس لم يتم اتاحتها بسهولة لعامة الناس.

                                                            </div></li>
                                                            <li><i className="fas fa-long-arrow-alt-right"></i><div className="ttm-list-li-content">
                                                                لا يُسمح لك بنسخ أو تعديل أو تأطير أو إعادة نشر أو أداء أو عرض أو بيع أو إعادة إنتاج أو توزيع أو إنشاء أعمال مشتقة من الموقع والمحتوى ، باستثناء أنه يجوز لك تنزيل نسخة واحدة من الموقع والمحتوى وعرضه وطباعته. المواد (أي المحتوى الذي لا يتطلب اسم حساب أو كلمة مرور للوصول) على أي جهاز كمبيوتر منفرد فقط لاستخدامك الشخصي غير التجاري ، شريطة ألا تقوم بتعديل المواد بأي شكل من الأشكال وأن تحافظ على جميع حقوق النشر كما هي ، العلامة التجارية وإشعارات الملكية الأخرى. أنت توافق على عدم الوصول إلى الموقع أو المحتوى بأي وسيلة بخلاف الواجهة التي توفرها ماسريس للوصول اليها. لا يجوز لك استخدام أي "ملف كشط للصفحة" أو "رابط عميق" أو "عنكبوت" أو "روبوت أو أي برنامج أو جهاز أو خوارزمية أو منهجية تلقائية أخرى ، أو أي عملية يدوية مماثلة ، للوصول أو نسخ أو الحصول أو مراقبة أي جزء من الموقع أو أي محتوى ، أو إعادة إنتاج أو التحايل بأي شكل من الأشكال على العرض التقديمي أو هيكل التنقل الخاص بالموقع أو أي محتوى ، للحصول أو محاولة الحصول على أي محتوى أو معلومات أخرى من خلال أي وسيلة غير متاحة بشكل عام من خلال الموقع عن طريق ماسريس.
                                                            </div></li>
                                                            <li><i className="fas fa-long-arrow-alt-right"></i><div className="ttm-list-li-content">
                                                                MassRes  تحتفظ لنفسها بالحق في اتخاذ أي تدابير قانونية لمنع أي نشاط من هذا القبيل.
                                                                <span style={{ fontWeight: "bold" }}> لا يجوز لك تزوير العناوين أو التلاعب بالمعرفات بطريقة أخرى لإخفاء أصل أي رسالة أو إرسال ترسله إلى ماسريس على من خلال الموقع أو أي خدمة مقدمة على أو من خلال الموقع. لا يجوز لك التظاهر بأنك شخص آخر ، أو أنك تمثل شخصًا آخر ، أو تنتحل شخصية أي فرد أو كيان آخر. </span>
                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>تحفظ الحقوق </h3>

                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                MassRes  والمرخصين التابعين لها يمتلكون ويحتفظون بحقوقهم في جميع الشعارات وأسماء الشركات والعلامات التجارية والعلامات التجارية وحقوق التأليف والنشر والأسرار التجارية والمعرفة وبراءات الاختراع وتطبيقات براءات الاختراع التي يتم استخدامها أو المجسدة في الموقع أو المرتبطة بالموقع و المحتوى. ماسريس لا تمنح أي حقوق أو تراخيص   ( ضمنيا  ، عن طريق الإغلاق ، أو غير ذلك  ) مهما كانت بموجب هذه الشروط والأحكام.                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>استخدام الملكية الفكرية </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                الحقوق في محتوى المستخدم. من خلال نشر معلوماتك والمحتويات الأخرى ("محتوى المستخدم") على أو من خلال ترخيصاً عالمياً غير حصري ودائم وغير قابل للإلغاء وخالي من حقوق الملكية ومدفوع بالكامل وقابل للترخيص من الباطن وقابل للتحويل للاستخدام والتعديل وإعادة إنتاج وتوزيع وعرض ونشر وتنفيذ محتوى المستخدم فيما يتعلق بالموقع والمحتوى. ماسريس لها الحق، ولكن ليس الالتزام ، لمراقبة الموقع والمحتوى ومحتوى المستخدم. قد تقوم ماسريس بإزالة أو تعطيل أي محتوى مستخدم في أي وقت ولأي سبب ، أو بدون سبب على الإطلاق.

                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>النقل غير الآمن لمحتوى المستخدم </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                أنت تدرك أن تشغيل الموقع والنظام الأساسي ، بما في ذلك محتوى المستخدم ، قد يكون غير مشفر ويتضمن نقله إلى موردي الطرف الثالث وشركاء الاستضافة في ماسريس لتشغيل وصيانة الموقع والمحتوى. وفقًا لذلك ، تقر بأنك تتحمل وحدك المسؤولية عن الأمان الكافي والحماية والنسخ الاحتياطي لمحتوى المستخدم. لن تتحمل ماسريس أي مسؤولية تجاهك عن أي وصول أو استخدام غير مصرح به لأي من محتوى المستخدم ، أو أي فساد أو حذف أو إتلاف أو فقدان لأي محتوى مستخدم.

                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>استجابة </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                يمكنك تقديم أفكار أو اقتراحات أو تعليقات ("التعليقات") فيما يتعلق بالموقع والمحتوى أو أعمال أو منتجات أو خدمات ماسريس من خلال تقديم أي ملاحظات ، فإنك تقر وتوافق على أنه
                                                            </div>
                                                                <br></br>
                                                            </li>
                                                        </ul>
                                                        <ol className="ttm-list-icon-color-skincolor" style={{ marginRight: "20px" }}>

                                                            <li>
                                                                يتم تقديم ملاحظاتك طواعية ، ويجوز لـ ماسريس ، دون أي التزامات أو قيود ، استخدام واستغلال هذه التعليقات بأي طريقة ولأي غرض
                                                            </li>
                                                            <li>لن تسعى ولا يحق لهم الحصول على أي أموال أو أي شكل آخر من أشكال التعويض أو الاعتبارات أو الإسناد فيما يتعلق بتعليقاتك بغض النظر عما إذا كانت ماسريس قد نظرت في تعليقاتك أو استخدمتها بأي طريقة </li>
                                                            <li>ملاحظاتك ليست معلومات سرية أو مملوكة لك أو أي طرف ثالث.</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>إقراراتك وضماناتك </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                أنت تقر وتتعهد لـ ماسريس أن نشاطك على الموقع وامتلاك ماسريس واستخدامه لمحتوى المستخدم كما هو متوخى في هذه الشروط والأحكام <span style={{ fontWeight: "bold" }}> لا ينتهك ولن ينتهك أو ينتهك أو يسيء </span> استخدام حقوق الطبع والنشر الخاصة لأي طرف ثالث أو العلامة التجارية أو حق الخصوصية أو الدعاية ، أو حقوق ملكية أو شخصية أخرى ، ولا يحتوي محتوى المستخدم على أي مادة تشهيرية <span style={{ fontWeight: "bold" }}> أو فاحشة أو غير قانونية أو مهددة أو مسيئة أو مؤذية أو مسيئة أو مضايقة </span>.
                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>إنهاء الوصول بسبب الانتهاكات </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                قد تقوم ماسريس ، وفقاً لتقديرها الخاص ودون إشعار مسبق ، بإنهاء وصولك إلى الموقع و / أو منع وصولك المستقبلي إلى الموقع إذا قررنا أنك انتهكت هذه الشروط والأحكام أو الاتفاقيات أو الإرشادات الأخرى التي قد تكون مرتبطة باستخدامك للموقع. أنت توافق أيضاً على أن أي انتهاك من جانبك لهذه الشروط والأحكام سيسبب ضرراً لا يمكن إصلاحه ل ماسريس ، حيث تكون الأضرار المالية غير كافية
                                                                ، وتوافق على حصول ماسريس على أي أمر زجري
                                                                أو تعويض عادل تراه ماسريس ضرورياً أو مناسباً في مثل هذه الظروف
                                                                ، <span style={{ fontWeight: "bold" }}> دون تقييد العلاجات الأخرى المتاحة لماسريس </span>.


                                                            </div>

                                                            </li>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i>
                                                                <div className="ttm-list-li-content">
                                                                    علاوة على ذلك ، يجوز لـ ماسريس ، وفقاً لتقديرها الخاص ودون إشعار مسبق ، إنهاء وصولك إلى الموقع
                                                                    ، لسبب يتضمن
                                                                    (على سبيل المثال لا الحصر   )
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ol className="ttm-list-icon-color-skincolor" style={{ marginRight: "20px" }}>
                                                            <li> الطلبات المقدمة من قبل تطبيق القانون أو الوكالات الحكومية الأخرى  </li>
                                                            <li> التوقف أو التعديل المادي للموقع أو أي خدمة معروضة عليه أو من خلاله  </li>
                                                            <li> مشاكل أو مشاكل فنية غير متوقعة.</li>
                                                        </ol>
                                                    </div>
                                                </div>

                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>لا توجد ضمانات وإخلاء مسؤولية من قبل المسؤولين </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                يتم توفير الموقع والمحتوى ، وجميع مكونات الخادم والشبكة ، على أساس "كما هي" و "كما هو متاح" مع جميع الأخطاء والعيوب وبدون أي ضمانات من أي نوع ، وتخلي مسؤوليتها <span style={{ fontWeight: "bold" }}> صراحة جميع الإقرارات والضمانات الضمانات الضمنية للدقة </span> ، والكمال ، وقابلية التسويق ، والملاءمة لغرض معين أو عدم الانتهاك ، وأي إقرارات أو ضمانات تنشأ عن مسار التعامل ، ودورة الأداء أو الاستخدام.
                                                            </div></li>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                أنت تقر بأن عمليات النقل لا تضمن أن وصولك أو استخدامك أو كل من الموقع والمحتوى سيكونان بلا انقطاع أو في الوقت المناسب أو آمنًا أو خاليًا من الأخطاء أو خاليًا من الفيروسات ، ولا تقدم عمليات القتل أي ضمانات فيما يتعلق بـ من استخدام الموقع والمحتوى. لن تُنشئ أي معلومات أو نصائح أو خدمات تحصل عليها من الجماهير أو من خلال الموقع أي ضمان غير منصوص عليه صراحةً في هذه الشروط والأحكام ، ويجب ألا تعتمد على الموقع والمحتوى العام وحده كأساس لنشاطك التجاري.
                                                            </div></li>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i>
                                                                <div className="ttm-list-li-content">
                                                                    تحتفظ ماسريس بالحق في القيام بأي مما يلي ، في أي وقت ، دون إشعار:
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ol className="ttm-list-icon-color-skincolor" style={{ marginRight: "20px" }}>
                                                            <li> لتعديل أو تعليق أو إنهاء تشغيل أو الوصول إلى الموقع ، أو أي جزء من الموقع ، لأي سبب من الأسباب .  </li>
                                                            <li> لتعديل أو تغيير الموقع ، أو أي جزء منه ، لأي سبب من الأسباب .  </li>
                                                            <li> مقاطعة تشغيل الموقع ، أو أي جزء منه ، حسب الضرورة لإجراء صيانة روتينية أو غير روتينية ، أو تصحيح الأخطاء ، أو تغييرات أخرى.</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>مسؤولية محدودة </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                استبعاد الأضرار وحدود المسؤولية. لا تتقاضى ماسريس رسوماً مقابل الوصول إلى الموقع والمحتوى واستخدامهما وفقاً لهذه الشروط والأحكام. لا يُجري الموقع معاملات تجارية ؛ يتكون المحتوى من معلومات عامة ويصف بعض العناصر الأساسية لخدمة ماسريس . كمراعاة لدخولك واستخدامك للموقع والمحتوى مجاناً وفقاً لهذه الشروط والأحكام ، فإنك توافق أيضاً على أن ماسريس لن يكون مسؤولاً تجاهك عن أي أضرار عرضية أو تبعية أو غير مباشرة أو خاصة أو عقابية أو نموذجية (بما في ذلك الأضرار الناجمة عن فقدان الأعمال) ، أو فقدان الأرباح أو ما شابه) الناشئ عن هذه الشروط والأحكام أو المتعلقة بها ، بما في ذلك على سبيل المثال لا الحصر ، استخدامك أو عدم قدرتك على استخدام الموقع أو المنصة أو خدمات المطابقة أو المحتوى أو المعلومات الشخصية أو أي انقطاع أو انقطاع في الاستخدام إذا تم إخطارماسريس بإمكانية حدوث مثل هذه الأضرار وبغض النظر عن سبب الدعوى (سواء كان ذلك في العقد أو الضرر أو انتهاك الضمان أو غير ذلك).
                                                            </div></li>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                لن تتعدى المسؤولية الإجمالية للجزاءات فيما يتعلق بهذه الشروط والأحكام 1.00 دولار أمريكي بأي حال من الأحوال.

                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>القيود القضائية </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                لا تسمح بعض الولايات والولايات القضائية الأخرى باستثناء الضمانات الضمنية أو تحديد المسؤولية عن الأضرار العرضية أو التبعية ، مما يعني أن بعض القيود المذكورة أعلاه قد لا تنطبق عليك. في هذه السلطات القضائية ، ستكون مسئولية أعضاء مجلس الشعب محدودة إلى أقصى حد يسمح به القانون.

                                                            </div></li>

                                                        </ul>

                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>حل النزاعات التنازل عن هيئة المحلفين </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                تم وضع هذه الشروط والأحكام بموجب قوانين ديلاوير المطبقة على الاتفاقات المبرمة والتي سيتم تنفيذها بموجبها فقط ، دون التأثير على مبادئ تضارب القانون. في أي إجراء بين أو بين أي من الأطراف ، سواء كان ناشئاً عن هذه الشروط والأحكام أو غير ذلك
                                                                .
                                                            </div></li>

                                                        </ul>
                                                        <ol className="ttm-list-icon-color-skincolor" style={{ marginRight: "20px" }}>
                                                            <li> يوافق كل طرف بشكل نهائي وغير مشروط ويخضع للاختصاص القضائي الحصري ومكان الولاية والمحاكم الفيدرالية الموجودة في ويلمنجتون ديلاوير    </li>
                                                            <li>التنازل عن أي وجميع الحقوق المتعلقة بالمحاكمة أمام هيئة المحلفين في أي إجراء قانوني ينشأ عن أو يتعلق بهذه الاتفاقية أو أي معاملات منصوص عليها هنا.</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>لا تعتمد الاستفسار عن الأمر </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                أنت تؤكد وتوافق على أنه ، نظراً للطبيعة العامة و "كما هي" للموقع والمحتوى ، فإنك لا تعتمد بشكل خاص على أي بيانات أو مواد واردة في الموقع أو في المحتوى لاتخاذ أي قرارات تجارية. إن مسائل تخصيص الموارد والتوظيف معقدة وفريدة من نوعها. إذا اخترت مواصلة استفسارك حول ما إذا كانت خدمة ماسريس مناسبة لك ، فستتاح لك الفرصة لإجراء استفسار إضافي عن ماسريس ويجب عليك إجراء تحقيق مستقل عن مدى ملاءمة استخدام ماسريس لاحتياجات عملك الخاصة. فقط بعد هذه العناية الواجبة المناسبة ، مراجعة شاملة لعرض خدمة ماسريس بما في ذلك شروط وأحكام اتفاقيات خدمة ماسريس ، ومن خلال فحص أي عامل مستقل تقترحه ماسريس عليك ، يجب أن تتوصل إلى أي استنتاجات أو تتخذ أي قرارات حول ما إذا كانت ماسريس ، أو أي المترجم المستقل الذي يقترحه توبال مناسب لك ولاحتياجات أو متطلبات مشروعك المحدد.
                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>متفرقات </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                تشكل هذه الشروط والأحكام ، وأي إضافات وتغييرات وتعديلات و/ أو تعديلات يتم إجراؤها عليها بواسطة ماسريس ، جنباً إلى جنب مع سياسة خصوصية ماسريس ، الاتفاقية الكاملة بين الأطراف فيما يتعلق بأجزاء الموقع المتاحة بدون معرف حساب أو كلمة مرور. يخضع الوصول إلى أجزاء معينة من الموقع مقيدة بكلمة مرور ، وأي عمليات شراء أو استخدام لاحقة لخدمات ماسريس تخضع لاتفاقيات إضافية مختلفة.
                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>أي كيان أو مواطن أو مقيم في منطقة جغرافية يُحظر فيها الوصول إلى خدمات   </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                لا تُجري ماسريس أعمالًا مع أو تسعى إلى أي معاملات تجارية مع ماسريس أو استخدامها بموجب القانون المعمول به أو المرسوم أو اللائحة أو المعاهدة أو الإدارة فعل
                                                            </div></li>
                                                        </ul>
                                                        <ol className="ttm-list-icon-color-skincolor" style={{ marginRight: "20px" }}>
                                                            <li> كيان أو مواطن أو مقيم في أو يقع في منطقة جغرافية تخضع لعقوبات أو عمليات حظر أمريكية أو دولة ذات سيادة أخرى</li>
                                                            <li>فرد أو فرد يعمل من قبل كيان أو مرتبط به ، تم تحديده في قائمة الأشخاص أو الكيانات المرفوضة بوزارة التجارة الأمريكية ، أو قوائم المواطنين المعينين بشكل خاص أو قوائم الأشخاص المحظورين التابعة لوزارة الخزانة الأمريكية ، أو قوائم وزارة الخارجية الأمريكية قائمة الأطراف المحظورة أو غير مؤهلة لتلقي العناصر الخاضعة لقوانين وأنظمة مراقبة الصادرات الأمريكية أو قواعد العقوبات الاقتصادية الأخرى لأي دولة ذات سيادة (يشار إليها مجتمعة باسم "الطرف المقابل المحظور"). </li>
                                                            |<li> من خلال الوصول إلى الموقع والمحتوى ، فإنك تقر وتتعهد بأنك لست طرفاً مقابلاً محظوراً وأنك لن تسعى أو (تحاول) الحصول على خدمات من ماسريس أو علاقة تجارية معها.</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>لا يمكن تعديل هذه الشروط والأحكام </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                لا يمكن تعديل هذه الشروط والأحكام وأي إضافات وتغييرات وتعديلات و / أو تعديلات يتم إجراؤها عليها بواسطة ماسريس إلا من خلال ماسريس كما هو موضح اعلاه. لن يكون فشل ماسريس في ممارسة أو تطبيق أي حق أو شرط من هذه الشروط والأحكام تنازلاً عن هذا الحق. سيتم إرسال أي إشعارات إلى ماسريس فيما يتعلق بهذه الاتفاقية عن طريق البريد الإلكتروني المرسل إلى
                                                                <span> <a href="mailto:massres.dev@gmail.com">  contact@MassRes.com </a></span>

                                                            </div></li>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                شريطة أن ترسل أيضًا نسخة من هذا الإشعار عبر شركة النقل المعترف بها على المستوى الوطني إلى
                                                                MassRes, LLC,
                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>القسم القانوني </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                             في حالة تحديد أي شرط من هذه الشروط والأحكام على أنه غير قانوني أو غير قابل للتنفيذ ، ستتم مراجعة هذا البند أولاً لإعطاء أقصى تأثير مسموح به لهدفه الأصلي ، أو إذا لم يُسمح بمثل هذا التعديل ، فسيتم إلغاء هذا البند المحدد لذلك أن هذه الشروط والأحكام ستظل بخلاف ذلك سارية المفعول والتأثير الكامل وقابلة للتنفيذ.
                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{/* ttm-blog-classic-content end */}
                                </article>{/* post end */}
                            </div>

                        </div>{/* row end */}
                    </div>
                </div>

                <Footer />

            </div>
        )
    }
}

export default Terms;