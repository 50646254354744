import React, { Component, useState, useEffect } from 'react';
import '../firebase/config'
import Slider from 'react-slick';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import Footer from '../components/layout/Footer';
import { timestamp } from '../firebase/config';
import DOMPurify from 'dompurify';
import emailjs from '@emailjs/browser';

import { useFetchNestDocs } from '../hooks/useFetchNestDocs';
import { useDocument } from '../hooks/useDocument';
import { useFetchAllNestDocs } from '../hooks/useFetchAllNestDocs ';
import { FaLinkedinIn } from 'react-icons/fa'
import avatar from '../assets/img/massavatar/massres-bus.png'
export default function Job_details(props) {
    const id = props.match.params.id;
    const [jobsData, setJobsData] = useState("")
    const [jobReqs, setJobReqs] = useState([])
    const [jobSkills, setjobSkills] = useState([])
    const { companyJobs: job, error } = useFetchAllNestDocs("employers", "jobs", id ? id : "")
    const jobTypes = ["", "دوام كامل ", "دوام كامل - عاجل ", "دوام جزئي ", "دوام جزئي - عاجل ", "عبر الانترنت ", "عبر الانترنت - عاجل "]
    const cats = ["", "  محاسبة و مالية ", "تكنولوجيا  ", " زراعة ", " بناء ", " وظائف طبية ", " انتاج ", " نقل  ", " Digital Design & Social Media "]
    // EmailJs From
    const [fromName, setFname] = useState("")
    const [fromEmail, setFemail] = useState("")
    const [fromSubject, setFsubject] = useState("")
    const [fromMessage, setFmsg] = useState("")
    const [response, setReponse] = useState(false)
    const loading = jobsData === null || job === null;

    useEffect(() => {
        if (job) {
            setJobsData(job)
            setJobReqs(job.jobReqs)
            setjobSkills(job.jobSkills)
        }
    }, [job])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setReponse(true)
        var templateParams = {
            from_name: fromName,
            from_email: fromEmail,
            to_name: jobsData.companyName,
            message: fromMessage,
            fromSubject,
            to_email: jobsData.jobEmail,
        };

        await emailjs.send('service_s1khhzc', 'template_4caki7q', templateParams, "-gaOaetDVoDcH_Mt7")
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
                setReponse(false)

            }, function (error) {
                console.log('FAILED...', error);
            },);

        setFemail("");
        setFmsg("");
        setFname("");
        setFsubject("");
    }

    var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 1,

        responsive: [{

            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };
    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }
    function flt(dbDate) {
        const timedb = new Date(dbDate.seconds * 1000);
        const today = timestamp.fromDate(new Date())
        const time = new Date(today.seconds * 1000);
        const thisDatr = new Date(time - timedb)
        var difference = time.getTime() - timedb.getTime();
        var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24)
        // console.log("diff:", daysDifference)


        return (daysDifference)
    }


    // hide component until ready
    // consider rendering a spinner/throbber here while loading
    if (loading)
        return null;

    return (

        <div className="site-main">
            <Header />

            {/* PageHeader */}
            <PageHeader
                title="تفاصيل الوظيفة"
                breadcrumb="وظائف"
            />
            {/* PageHeader end */}


            {jobsData ?
                <div className="ttm-row sidebar job-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
                                <div className="job_list-widget" dir='rtl'>
                                    <aside className="widget job-widget">
                                        <h3 className="widget-title"><i className="ti ti-files"></i>معلومات عن الوظيفة </h3>
                                        <ul>
                                            {jobsData.jobCategory && <li className="d-flex"><b className="mr-5">تصنيف الوظيفة : </b>{cats[jobsData.jobCategory]}</li>}
                                            {jobsData.jobType && <li className="d-flex"><b className="mr-5">نوع الوظيفة : </b>{jobTypes[jobsData.jobType]}</li>}


                                            {jobsData.jobLocation && <li className="d-flex"><b className="mr-5">الموقع : </b>{jobsData.jobLocation}</li>}
                                            {jobsData.offeredSalary && <li className="d-flex"><b className="mr-5">الراتب المعروض : </b>{jobsData.offeredSalary}</li>}
                                            {jobsData.yearsOfExp && <li className="d-flex"><b className="mr-5">الخبرة المطلوبة : </b>{jobsData.yearsOfExp}</li>}
                                            {jobsData.jobQualifications && <li className="d-flex"><b className="mr-5">مؤهلات علمية : </b>{jobsData.jobQualifications}</li>}
                                            {jobsData.positionNumber && <li className="d-flex"><b className="mr-5">عدد الشواغر : </b>{jobsData.positionNumber}</li>}
                                            {/* <li className="d-flex"><b className="mr-5">نشر : </b>{flt(jobsData.createdAt) > 0 ? " قبل "+flt(jobsData.createdAt) + " أيام " : " اليوم "}</li> */}

                                            {jobsData.website && <li className="d-flex"><b className="mr-5">الموقع الالكتروني :  </b><a href={jobsData.website} target='_blank' className='text-theme-SkinColor'>{jobsData.companyName + ".com"} </a></li>}

                                        </ul>
                                    </aside>
                                    <aside className="widget form-widget">
                                        <h3 className="widget-title"><i className="ti ti-email"></i>تواصل معنا </h3>
                                        <form className="wrap-form" onSubmit={handleSubmit}>
                                            <label>
                                                <input name="fromName" type="text" placeholder="اسمك "
                                                    required="required"
                                                    value={fromName}
                                                    onChange={e => (setFname(e.target.value))}
                                                />
                                            </label>
                                            <label>
                                                <input name="fromEmail" type="text" placeholder="بريدك الالكتروني "
                                                    required="required"
                                                    value={fromEmail}
                                                    onChange={e => (setFemail(e.target.value))}
                                                />
                                            </label>
                                            <label>
                                                <input name="fromSubject" type="text" placeholder="الموضوع "
                                                    required="required"
                                                    value={fromSubject}
                                                    onChange={e => (setFsubject(e.target.value))}
                                                />
                                            </label>
                                            <label>
                                                <textarea name="fromMessage" rows="4" placeholder="رسالتك "
                                                    required="required"
                                                    value={fromMessage}
                                                    onChange={e => (setFmsg(e.target.value))}
                                                ></textarea>
                                            </label>
                                            <button className={response ? "hover-mouse submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100" : "submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"} >{response ? "جاري الارسال ..." : "ارسال ! "}</button>
                                        </form>
                                        {/* <form className="wrap-form">
                                            <label>
                                                <input name="name" type="text" placeholder="Your Name" required="required" />
                                            </label>
                                            <label>
                                                <input name="email" type="text" placeholder="Your Email" required="required" />
                                            </label>
                                            <label>
                                                <textarea name="message" rows="3" placeholder="Message" required="required"></textarea>
                                            </label>
                                            <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100" type="submit">send a message!</button>
                                        </form> */}
                                    </aside>
                                    <aside className="widget location-widget p-0">
                                        {/* <iframe width="100%" height="350" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26361414.263400003!2d-113.75849480805297!3d36.24080384347216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1578680778274!5m2!1sen!2sin">
                                        </iframe> */}
                                        {jobsData.compMapsrc && <div className='' style={{ wordBreak: "break-all", height: "350", marginTop: "20px" }}>
                                            <div dangerouslySetInnerHTML={{ __html: jobsData.compMapsrc }} ></div>

                                        </div>}
                                        <div className="p-20">
                                            {jobsData.jobAddress && <div>
                                                <span className="text-theme-SkinColor">
                                                    <i className="fa fa-map-marker-alt mr-10"></i></span>{jobsData.jobAddress}
                                            </div>}
                                            {jobsData.jobEmail && <div>
                                                <a href={"mailto:" + jobsData.jobEmail}><i className="fa fa-envelope mr-10 text-theme-SkinColor"></i>{jobsData.jobEmail}</a>
                                            </div>}
                                        </div>
                                    </aside>
                                </div>
                            </div>
                            <div className="col-lg-8 content-area text-right" dir='rtl'>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        {/* featured-imagebox */}
                                        <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor m-0">
                                            <div className="featured-thumbnail">
                                               <a href={process.env.PUBLIC_URL + '/employers_details/' + jobsData.companyId}>  <img src={jobsData.companyPic ? jobsData.companyPic : avatar} /></a>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3>{jobsData.jobName}</h3>
                                                </div>
                                                <div className="featured-desc">
                                                    <p style={{ marginRight: "12px" }} >تم النشر  {flt(jobsData.createdAt) > 0 ? " قبل " + flt(jobsData.createdAt) + " أيام " : " اليوم "}</p>
                                                    {/* <p style={{ marginRight: "12px" }}>
                                                        الشركة  :   <a href={process.env.PUBLIC_URL + '/employers_details/' + jobsData.companyId}>  <span style={{ fontWeight: "bold" }} > {jobsData.companyName} </span> </a>
                                                    </p> */}

                                                </div>
                                                <div className="featured-bottom">
                                                    <div className="job-meta">
                                                        <span><i className="fa fa-map-marker-alt"></i>{jobsData.jobLocation}</span>
                                                        <span><i className="fa fa-filter"></i>{cats[jobsData.jobCategory]}</span>
                                                    </div>
                                                    <div className="job-time">
                                                        {/* <span className="green">full time</span> */}
                                                        {jobsData.jobType && <span className={"green" + jobsData.jobType}> {jobTypes[jobsData.jobType]}</span>}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>{/* featured-imagebox end */}
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="overview-box">
                                            <div className="title">
                                                <h5>تفاصيل الوظيفة </h5>
                                            </div>
                                            <div>
                                                {jobsData.jobDescription &&
                                                    <div className="desc">
                                                        <p dangerouslySetInnerHTML={createMarkup(jobsData.jobDescription)}></p>
                                                    </div>
                                                }

                                            </div>
                                            {/* <div className="desc">
                                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                                labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                                                maecenas accumsan lacus vel facilisis. ”</p>
                                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                                labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                                                maecenas accumsan lacus vel facilisis. ”</p>
                                        </div> */}
                                        </div>
                                        <div className="overview-box">
                                            <div className="title">
                                                <h5>المعرفة والقدرات المطلوبة</h5>
                                            </div>
                                            <div className="desc">
                                                <ul className="ttm-list ttm-list-style-icon ttm-textcolor-darkgrey">
                                                    {jobReqs ?

                                                        jobReqs.map((req, index) => (
                                                            <li key={index}>
                                                                <i className="ti ti-check-box"></i>
                                                                <div className="ttm-list-li-content">{req.req}</div>
                                                            </li>
                                                        ))
                                                        : <>

                                                        </>}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="overview-box">
                                            <div className="title">
                                                <h5>المهارات المطلوبة</h5>
                                            </div>
                                            <div className="desc">
                                                <ul className="ttm-list ttm-list-style-icon ttm-textcolor-darkgrey">
                                                    {jobSkills ?

                                                        jobSkills.map((skill, index) => (
                                                            <li key={index}>
                                                                <i className="ti ti-check-box"></i>
                                                                <div className="ttm-list-li-content">{skill.jobSkill}</div>
                                                            </li>
                                                        ))
                                                        : <>

                                                        </>}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="overview-box mb-60">
                                            <div className="title" >
                                                <div className="d-sm-flex justify-content-between mb_10">
                                                    <h5 className="mb-10">Social Links</h5>
                                                    <div className="social-icons circle mb-10">
                                                        <ul className="list-inline">
                                                            {jobsData.link && <li><a href={jobsData.link ? jobsData.link : "#LinkedIn"} rel="noopener" target='_blank' aria-label="LinkedIn"><FaLinkedinIn /></a></li>}
                                                            {jobsData.facebook && <li><a href={jobsData.facebook ? jobsData.facebook : "#facebook"} rel="noopener" target='_blank' aria-label="facebook"><i className="ti ti-facebook"></i></a></li>}
                                                            {jobsData.twitter && <li><a href={jobsData.twitter ? jobsData.twitter : "#twitter"} rel="noopener" target='_blank' aria-label="twitter"><i className="ti ti-twitter-alt"></i></a></li>}
                                                            {jobsData.instagram && <li><a href={jobsData.instagram ? jobsData.instagram : "#instagram"} rel="noopener" target='_blank' aria-label="instagram"><i className="ti ti-instagram"></i></a></li>}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { /*           <div className="row">
                                    <div className="col-lg-12">
                                        <h5>Related Jobs :</h5>
                                    </div>
                                </div>
                                <Slider className="row slick_slider slick-arrows-style2 mb_15" {...slick_slider} vertical={true} slidesToShow={1} rows={2} arrows={true}>
                                    <div className="col-lg-12 col-md-12">
                                      
                                        <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                                            <div className="featured-thumbnail">
                                                <img src="https://via.placeholder.com/210x204?text=210x204+job-06.png" />
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3><a href={process.env.PUBLIC_URL + '/Job_details'}>Vacancy For the Human Resource</a></h3>
                                                </div>
                                                <div className="featured-desc">
                                                    <p>Published 2Days Ago.</p>
                                                </div>
                                                <div className="featured-bottom">
                                                    <div className="job-meta">
                                                        <span><i className="fa fa-map-marker-alt"></i>California</span>
                                                        <span><i className="fa fa-filter"></i>Automotive Jobs</span>
                                                    </div>
                                                    <div className="job-time">
                                                        <span className="blue">part time</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        
                                        <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                                            <div className="featured-thumbnail">
                                                <img src="https://via.placeholder.com/210x204?text=210x204+job-02.png" />
                                                <div className="required-tag">Urgent</div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3><a href={process.env.PUBLIC_URL + '/Job_details'}>Vacancy For the Business Analyst</a></h3>
                                                </div>
                                                <div className="featured-desc">
                                                    <p>Published 2Days Ago.</p>
                                                </div>
                                                <div className="featured-bottom">
                                                    <div className="job-meta">
                                                        <span><i className="fa fa-map-marker-alt"></i>California</span>
                                                        <span><i className="fa fa-filter"></i>Automotive Jobs</span>
                                                    </div>
                                                    <div className="job-time">
                                                        <span className="danger-color">part time</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                   
                                        <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                                            <div className="featured-thumbnail">
                                                <img src="https://via.placeholder.com/210x204?text=210x204+job-03.png" />
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3><a href={process.env.PUBLIC_URL + '/Job_details'}><a href={process.env.PUBLIC_URL + '/Job_details'}>Opening For Social Media Manager</a></a></h3>
                                                </div>
                                                <div className="featured-desc">
                                                    <p>Published 2Days Ago.</p>
                                                </div>
                                                <div className="featured-bottom">
                                                    <div className="job-meta">
                                                        <span><i className="fa fa-map-marker-alt"></i>California</span>
                                                        <span><i className="fa fa-filter"></i>Automotive Jobs</span>
                                                    </div>
                                                    <div className="job-time">
                                                        <span className="deep-orange">full time</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                      
                                        <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                                            <div className="featured-thumbnail">
                                                <img src="https://via.placeholder.com/210x204?text=210x204+job-04.png" />
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3><a href={process.env.PUBLIC_URL + '/Job_details'}><a href={process.env.PUBLIC_URL + '/Job_details'}>Opening For The Content Creator</a></a> </h3>
                                                </div>
                                                <div className="featured-desc">
                                                    <p>Published 2Days Ago.</p>
                                                </div>
                                                <div className="featured-bottom">
                                                    <div className="job-meta">
                                                        <span><i className="fa fa-map-marker-alt"></i>California</span>
                                                        <span><i className="fa fa-filter"></i>Automotive Jobs</span>
                                                    </div>
                                                    <div className="job-time">
                                                        <span className="green">full time</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>

*/ }
                            </div>
                        </div>{/* row end */}
                    </div>
                </div> : <></>}


            {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment-5"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3> lost your job? find a new one today</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>We have over 30 years experience oft Business porro qusquam dol ipsum quia dolor sit amet.</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                                    href={process.env.PUBLIC_URL + '/job_list'}>Jobs !</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}


            <Footer />

        </div>
    )
}
