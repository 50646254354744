import React, { Component, useState, useEffect } from 'react';
import '../firebase/config';
import Slider from 'react-slick';
import Header from '../components/layout/Header';
// import Header from '../components/layout/Header2'
import { Banner } from '../components/banner/Home3_banner';
import Footer from '../components/layout/Footer';
import CountUp from 'react-countup';
import ProgressBar from 'react-animated-progress-bar';
import Jobs from '../assets/img/service/jobs.jpg'
import '../hooks/useFetchAllNestedDocsMK';
import { useFetchNestDocs } from '../hooks/useFetchAllNestedDocsMK';
import { useAuthContext } from '../hooks/useAuthContext'
import avatar from '../assets/img/massavatar/massres-bus.png';
import { timestamp } from '../firebase/config';
// import ScrollToTop from '../components/layout/Gotop';
export default function Home() {

    const { user } = useAuthContext()

    const [jobs, setJobs] = useState([])
    const { getEventsData, getAttendeesData, events, attendeeInfo, companies } = useFetchNestDocs();
    const jobTypes = ["", "دوام كامل ", "دوام كامل - عاجل ", "دوام جزئي ", "دوام جزئي - عاجل ", "عبر الانترنت ", "عبر الانترنت - عاجل "]
    const cats = ["", "  محاسبة و مالية ", "تكنولوجيا  ", " زراعة ", " بناء ", " وظائف طبية ", " انتاج ", " نقل  ", " Digital Design & Social Media "]
    const locations=["","القدس","عمان","القاهرة","دمشق","بيروت","الجزائر","المغرب","","","" ]
    const [locationCat, setLocationCat] = useState("")
    const [searchCat,setSearchCat]=useState("")
    const [nameSearch, setNameSearch]= useState("")
    const [filtered,setFiltered] = useState([])
    const [searching, setSearching] = useState(false)
    useEffect(() => {
        getEventsData();
    }, []);

    useEffect(() => {
        if (events !== null) // only call when data available
            getAttendeesData(events);

        //   console.log("companies",companies)
    }, [events, companies]);

    useEffect(() => {
        if (attendeeInfo) {
            setJobs(attendeeInfo)
        }
    }, [attendeeInfo])
    const loading = events === null || attendeeInfo === null;
  // --------- search -----------
  const handleSearch = (e) => {
    e.preventDefault()
    setSearching(true)
    let search = []
    let data = jobs;
    data.map((d)=>{
        let jobName = d.jobName.toLowerCase().trim()
        if(jobName.includes(nameSearch.toLocaleLowerCase().trim()) &&!locationCat &&!searchCat)
        {
            search.push(d)
        }
    else    if(d.jobCategory == searchCat && d.jobLocation == locationCat &&jobName.includes(nameSearch.toLocaleLowerCase().trim()))
        {
          search.push(d)
          
        }
    else  if(d.jobCategory == searchCat && !locationCat &&jobName.includes(nameSearch.toLocaleLowerCase().trim()))
      {

        search.push(d)
        
      }else if ( d.jobLocation == locationCat && !searchCat &&jobName.includes(nameSearch.toLocaleLowerCase().trim()))
      {
        search.push(d)
      }
      else if (d.jobCategory == searchCat && d.jobLocation == locationCat && !searchCat &&jobName.includes(nameSearch.toLocaleLowerCase().trim()))
      {
        search.push(d)
      }
      
      })
   
      setFiltered(search)
      
  }


    var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 1,

        responsive: [{

            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };

    function flt(dbDate) {
        const timedb = new Date(dbDate.seconds * 1000);
        const today = timestamp.fromDate(new Date())
        const time = new Date(today.seconds * 1000);
        const thisDatr = new Date(time - timedb)
        var difference = time.getTime() - timedb.getTime();
        var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24)
        // console.log("diff:", daysDifference)


        return (daysDifference)
    }
    const cat = [1, 3, 4, 5, 6, 7, 8]
    if (loading)
        return null;

    return (
        <div className="site-main">
            
            <Header />

            {/* Banner */}
            {/* <Banner /> */}
            {/* Banner end */}
            {/* form */}
        <br></br>
        <br></br>
        <br></br>
            <div className="form-section clearfix ">
                <div className="container">
                    <div className="row " dir='rtl'>
                        <div className="col-lg-12 ">
                            <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-30 p-lg-20 mt_60 mt-xl-50 ">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer "></div>
                                <div className="layer-content ">
                                    <form id="search_Form" className="search_Form wrap-form m-1 border rounded rounded-3 overflow-hidden" onSubmit={handleSearch} >
                                        <label>
                                            <i className="ti ti-search"></i>
                                            <input type="text" id="filter" placeholder="المسمى الوظيفي " onChange={e =>(setNameSearch(e.target.value))}/>
                                        </label>

                                        <label>
                                            <i className="ti ti-location-pin"></i>
                                            <select name="locations" aria-hidden="true" placeholder="Locations"
                                                onChange={e => (setLocationCat(e.target.value))}>
                                                <option value="">المواقع</option>
                                                <option value="1">القدس</option>
                                                <option value="2">عمان</option>
                                                <option value="3">القاهرة</option>
                                                <option value="4">دمشق</option>
                                                <option value="5">بيروت</option>
                                                <option value="6">الجزائر</option>
                                                <option value="7">المغرب</option>
                                            </select>
                                        </label>
                                        <label>
                                            <i className="ti ti-list"></i>
                                            <select name="categories" aria-hidden="true" placeholder="Categories"
                                             onChange={e => (setSearchCat(e.target.value))}>
                                                <option value="">الفئات</option>
                                                <option value="1"> محاسبة و مالية</option>
                                                <option value="2">تكنولوجيا</option>
                                                <option value="3">زراعة</option>
                                                <option value="4">بناء </option>
                                                <option value="5">وظائف طبية</option>
                                                <option value="6">انتاج</option>
                                                <option value="7">نقل</option>
                                                <option value="8">Digital Design & Social Media</option>

                                            </select>
                                        </label>

                                        <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor h-auto" type="submit">
                                            ابحث عن عمل
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* form end */}
               {/* job-list-section */}
               {searching&&
               <section className="ttm-row job-list-section bg-theme-GreyColor ttm-bg ttm-bgimage-yes clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12 ">
                            {/* section title */}
                            <div className="section-title style2 " dir='rtl'>
                                <div className="title-header">
                                    <h2>نتائج  <span className="text-theme-SkinColor">البحث ! </span></h2>
                                   {filtered.length>0? <h3 className="title">{cats[searchCat]?cats[searchCat]:""} - {locations[locationCat]?locations[locationCat]:"" } {" "+ nameSearch?nameSearch:""} </h3>: <h3 className="title">لا يوجد نتائج !</h3>}
                                </div>
                            </div>
                            {/* section title end */}
                        </div>
                    </div>{/* row end */}
                    {/* Slider */}
                    <Slider className="row slick_slider mb_10 pt-20 slick-arrows-style2" {...slick_slider} slidesToShow={filtered.length<2?1:2}
                        rows={1} arrows={true} responsive={[{ breakpoint: 992, settings: { slidesToShow: 1, slidesToScroll: 1 } }]}>
                        {filtered && filtered.map((job, index) => (
                            <div className="col-lg-6 col-md-12 " dir='rtl' key={index}>
                                {/* featured-imagebox */}
                                <div className="featured-imagebox featured-imagebox-job grey-jobs ">
                                    <div className="featured-thumbnail  div-border div-border-bottom">
                                       <a href={process.env.PUBLIC_URL + '/Job_details/' + job.id}>  <img src={job.companyPic ? job.companyPic : avatar} /> </a>
                                        {job.jobType == '6' && <div className="required-tag">عاجل</div>}
                                        {job.jobType == '2' && <div className="required-tag">عاجل</div>}
                                        {job.jobType == '4' && <div className="required-tag">عاجل</div>}
                                    </div>
                                    <div className="featured-content div-border-bottom">
                                        <div className="featured-title title-white">
                                            <h3><a href={process.env.PUBLIC_URL + '/Job_details/' + job.id}>{job.jobName}</a></h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>
                                                الشركة  :   <a href={process.env.PUBLIC_URL + '/employers_details/' + job.companyId}>  <span className="text-theme-SkinColor text-bold" > {job.cName} </span> </a>
                                            </p>
                                            {/* <p>Published 2Days Ago.</p> */}
                                            <p style={{ marginRight: "12px" }} >تم النشر  {flt(job.createdAt) > 0 ? " قبل " + flt(job.createdAt) + " أيام " : " اليوم "}</p>

                                        </div>
                                        <div className="featured-bottom">
                                            <div className="job-meta text-placeHolder-color-light">
                                                <span><i className="fa fa-map-marker-alt"></i>{locations[job.jobLocation]}</span>
                                                <span><i className="fa fa-filter"></i>{cats[job.jobCategory]}</span>
                                            </div>
                                            <div className="job-time">
                                                {/* <span className="danger-color">part time</span> */}
                                                {job.jobType && <span className={"green" + job.jobType}> {jobTypes[job.jobType]}</span>}

                                            </div>
                                        </div>
                                    </div>
                                </div>{/* featured-imagebox end */}
                            </div>))}
                    </Slider>{/* row end */}
                </div>
            </section>}
            {/* job-list-section */}
         {!searching&&   <section className="ttm-row job-list-section bg-theme-GreyColor ttm-bg ttm-bgimage-yes clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12 ">
                            {/* section title */}
                            <div className="section-title style2 " dir='rtl'>
                                <div className="title-header">
                                    <h3>ابحث عن  <span className="text-theme-SkinColor">الأفضل !</span></h3>
                                    <h2 className="title">أحدث الوظائف المعروضة</h2>
                                </div>
                                <div className="title-desc">
                                    <p>لدينا العديد من الوظائف المعروضة مؤخرا  , في     <span className="text-theme-SkinColor">   MassRes </span>
                                        نحن نصل  النقاط لتجد وظيفتك المستقبلية.
                                    </p>
                                </div><br></br>
                            </div>{/* section title end */}
                        </div>
                    </div>{/* row end */}


                    {/* Slider */}
                    <Slider className="row slick_slider mb_10 pt-20 slick-arrows-style2" {...slick_slider} slidesToShow={2}
                        rows={2} arrows={true} responsive={[{ breakpoint: 992, settings: { slidesToShow: 1, slidesToScroll: 1 } }]}>
                        {jobs && jobs.slice(0, 10).map((job, index) => (
                            <div className="col-lg-6 col-md-12" dir='rtl' key={index}>
                                {/* featured-imagebox */}
                                <div className="featured-imagebox featured-imagebox-job grey-jobs ">
                                    <div className="featured-thumbnail">
                                        <img src={job.companyPic ? job.companyPic : avatar} />
                                        {job.jobType == '6' && <div className="required-tag">عاجل</div>}
                                        {job.jobType == '2' && <div className="required-tag">عاجل</div>}
                                        {job.jobType == '4' && <div className="required-tag">عاجل</div>}
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title title-white">
                                            <h3><a href={process.env.PUBLIC_URL + '/Job_details/' + job.id}>{job.jobName}</a></h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>
                                                الشركة  :   <a href={process.env.PUBLIC_URL + '/employers_details/' + job.companyId}>  <span className="text-theme-SkinColor text-bold" > {job.cName} </span> </a>
                                            </p>
                                            {/* <p>Published 2Days Ago.</p> */}
                                            <p style={{ marginRight: "12px" }} >تم النشر  {flt(job.createdAt) > 0 ? " قبل " + flt(job.createdAt) + " أيام " : " اليوم "}</p>

                                        </div>
                                        <div className="featured-bottom">
                                            <div className="job-meta text-placeHolder-color-light">
                                                <span><i className="fa fa-map-marker-alt"></i>{job.jobLocation}</span>
                                                <span><i className="fa fa-filter"></i>{cats[job.jobCategory]}</span>
                                            </div>
                                            <div className="job-time">
                                                {/* <span className="danger-color">part time</span> */}
                                                {job.jobType && <span className={"green" + job.jobType}> {jobTypes[job.jobType]}</span>}

                                            </div>
                                        </div>
                                    </div>
                                </div>{/* featured-imagebox end */}
                            </div>))}
                    </Slider>{/* row end */}
                </div>
            </section>}
            {/* job-list end */}
            {/* features-section */}
            <section className="ttm-row features-section clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section title */}
                            <div className="section-title title-style-center_text">
                                <div className="title-header">
                                    <h3>احصل على <span className="text-theme-SkinColor">ما يلائمك</span></h3>

                                    <h2 className="title">تفحص الوظائف حسب المجال </h2>
                                </div>
                            </div>{/* section title end */}
                        </div>
                    </div>{/* row end */}
                    {/* row */}
                    <div className="row row-equal-height mb_10">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <div className="ttm-box-view-overlay">
                                    {/* finance-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" ></div>

                                        <div className="layer-content"></div>
                                    </div>{/* finance-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/cat-icon1.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>محاسبة و مالية   </h3>
                                    </div>
                                    <div className="featured-desc" dir='rtl'>
                                        <p>85 وظيفة<br></br>مدير الفرع المالي</p>
                                    </div>
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                                        href={process.env.PUBLIC_URL + '/job_list/1'}  > ! قائمة الوظائف </a>
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1 ">
                                <div className="ttm-box-view-overlay">
                                    {/* technology-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  ></div>
                                        <div className="layer-content"></div>
                                    </div>{/* technology-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/cat-icon2.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>  تكنولوجيا</h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p dir='rtl'>  110 وظيفة <br></br>Software, Hardware, SEO</p>
                                    </div>
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                                        // href={process.env.PUBLIC_URL + '/job_list'}>Apply Jobs!</a>
                                        href={process.env.PUBLIC_URL + '/job_list/2'}  > ! قائمة الوظائف </a>

                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1 ">
                                <div className="ttm-box-view-overlay">
                                    {/* agriculture-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  ></div>
                                        <div className="layer-content"></div>
                                    </div>{/* agriculture-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/cat-icon3.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>  زراعة </h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p dir='rtl'>100 وظيفة<br></br>عالم أغذية زراعية</p>
                                    </div>
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                                        // href={process.env.PUBLIC_URL + '/job_list/3'}>Apply Jobs!</a>
                                        href={process.env.PUBLIC_URL + '/job_list/3'}  > ! قائمة الوظائف </a>

                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <div className="ttm-box-view-overlay">
                                    {/* construction-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  ></div>
                                        <div className="layer-content"></div>
                                    </div>{/* construction-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/cat-icon4.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>  بناء</h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p dir='rtl'>120 وظيفة<br></br>منسق التسويق</p>
                                    </div>
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                                        // href={process.env.PUBLIC_URL + '/job_list/4'}>Apply Jobs!</a>
                                        href={process.env.PUBLIC_URL + '/job_list/4'}  > ! قائمة الوظائف </a>

                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <div className="ttm-box-view-overlay">
                                    {/* manufacturing-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" ></div>
                                        <div className="layer-content"></div>
                                    </div>{/* manufacturing-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/cat-icon5.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>  وظائف طبية</h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p dir='rtl'>100 وظيفة<br></br>مفتش مراقبة الجودة</p>
                                    </div>
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                                        // href={process.env.PUBLIC_URL + '/job_list/5'}>Apply Jobs!</a>
                                        href={process.env.PUBLIC_URL + '/job_list/5'}  > ! قائمة الوظائف </a>

                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <div className="ttm-box-view-overlay">
                                    {/* production-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  ></div>
                                        <div className="layer-content"></div>
                                    </div>{/* production-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/cat-icon6.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>  إنتاج</h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p dir='rtl'>90 وظيفة<br></br>مساعد مدير المصنع</p>
                                    </div>
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                                        // href={process.env.PUBLIC_URL + '/job_list/6'}>Apply Jobs!</a>
                                        href={process.env.PUBLIC_URL + '/job_list/6'}  > ! قائمة الوظائف </a>

                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <div className="ttm-box-view-overlay">
                                    {/* transport-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  ></div>
                                        <div className="layer-content"></div>
                                    </div>{/* transport-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/cat-icon7.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>  نقل</h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p dir='rtl'>125 وظيفة<br></br>وكلاء الشحن</p>
                                    </div>
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                                        // href={process.env.PUBLIC_URL + '/job_list/7'}>Apply Jobs!</a>
                                        href={process.env.PUBLIC_URL + '/job_list/7'}  > ! قائمة الوظائف </a>

                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <div className="ttm-box-view-overlay">
                                    {/* insurance-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" ></div>
                                        <div className="layer-content"></div>
                                    </div>{/* insurance-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/cat-icon8.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>  digital design & social media</h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p dir='rtl'>110 وظيفة<br></br>Social Media Content Creator</p>
                                    </div>
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                                        // href={process.env.PUBLIC_URL + '/job_list/8'}>Apply Jobs!</a>
                                        href={process.env.PUBLIC_URL + '/job_list/8'}  > ! قائمة الوظائف </a>

                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                    </div>{/* row end */}
                </div>
            </section>
            {/* features-section end */}

            {/* services-section */}
            <section className="ttm-row services-section clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title title-style-center_text">
                                <div className="title-header">
                                    <h3>Mass <span className="text-theme-SkinColor">Res</span></h3>
                                    <h2 className="title">نخلق فرص اقتصادية لحياة أفضل</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* row */}
                    <Slider className="row slick_slider slick-arrows-style1" {...slick_slider} slidesToShow={3} arrows={true}>

                        <div className="col-md-4 col-sm-6">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-services style2">
                                <div className="featured-content">
                                    <div className="featured-title ">
                                        <h3 ><a href={process.env.PUBLIC_URL + '/job_list'} className="link-white">وظائف</a></h3>
                                    </div>
                                </div>
                                <div className="ttm-box-view-overlay border-rad_6">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img className="img-fluid" src={Jobs} alt="MassRes-Logo" />
                                    </div>{/* featured-thumbnail end */}
                                    <div className="featured-hover-content">
                                        <div className="featured-desc">
                                            <p dir='rtl'>قل وداعا للبطالة ! </p>
                                        </div>
                                        <div className="bottom-footer">
                                            <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-skincolor"
                                                href={process.env.PUBLIC_URL + '/job_list'}>قائمة الوظائف </a>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox end */}
                        </div>
                        <div className="col-md-4 col-sm-6">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-services style2">
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3><a href={process.env.PUBLIC_URL + '/Employers_list'} className="link-white">شركات </a></h3>
                                    </div>
                                </div>
                                <div className="ttm-box-view-overlay border-rad_6">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img className="img-fluid" src={Jobs} alt="MassRes-Logo" />
                                    </div>{/* featured-thumbnail end */}
                                    <div className="featured-hover-content">
                                        <div className="featured-desc">
                                            <p dir='rtl'>هناك دائمًا شركات تبحث عنك !</p>
                                        </div>
                                        <div className="bottom-footer">
                                            <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-skincolor"
                                                href={process.env.PUBLIC_URL + '/Employers_list'}>قائمة الشركات </a>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox end */}
                        </div>
                        <div className="col-md-4 col-sm-6">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-services style2">
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3><a href={process.env.PUBLIC_URL + '/Candidate_list'} className="link-white">موظفين</a></h3>
                                    </div>
                                </div>
                                <div className="ttm-box-view-overlay border-rad_6">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img className="img-fluid" src={Jobs} alt="MassRes-Logo" />
                                    </div>{/* featured-thumbnail end */}
                                    <div className="featured-hover-content">
                                        <div className="featured-desc">
                                            <p>هل انت جاهز للتحدي ؟ </p>
                                        </div>
                                        <div className="bottom-footer">
                                            <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-skincolor"
                                                href={process.env.PUBLIC_URL + '/candidate_list'}>قائمة الموظفين </a>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox end */}
                        </div>
                    </Slider>
                    {/* <div className="row">
                        <div className="col-lg-12">
                            <div className="pt-30 m-auto text-center">
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                href={process.env.PUBLIC_URL + '/job_list'}>More Jobs By Category</a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            {/* services-section end */}


            {/* about-section */}

            {/* about-section */}


            {/* padding_zero-section */}

            {/* padding_zero-section end */}

            {/* testimonial-section */}
            <section className="ttm-row testimonial-section clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title title-style-center_text">
                                <div className="title-header" dir='rtl'>
                                    <h3>أصحاب <span className="text-theme-SkinColor"> المهارات !</span></h3>
                                    <h2 className="title">ماذا يقول أصحاب المهارات !</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Slider className="row slick_slider slick-arrows-style1" {...slick_slider} slidesToShow={3} arrows={true}>
                        <div className="col-lg-12">
                            {/* testimonials */}
                            <div className="testimonials ttm-testimonial-box-view-style1">
                                <div className="testimonial-content">
                                    <blockquote className="testimonial-text">لطالما كان من السهل جدًا العمل مع أعضاء الفريق في MassRes. إنها شراكة سهلة للغاية.</blockquote>
                                    <div className="star-ratings">
                                        <ul className="rating">
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="/images/pics/01.jpg" alt="testimonial-img" />
                                        </div>
                                    </div>
                                    <div className="testimonial-caption">
                                        <label>Secretary </label>
                                        <h3>Hala Adeeleh</h3>
                                    </div>
                                </div>
                            </div> {/* testimonials end */}
                        </div>
                        <div className="col-lg-12">
                            {/* testimonials */}
                            <div className="testimonials ttm-testimonial-box-view-style1">
                                <div className="testimonial-content">
                                    <blockquote className="testimonial-text">MassRes هي وكالة توظيف وطنية مبنية بشعارنا المميز ، توقع المزيد ، نحن نفعل.</blockquote>
                                    <div className="star-ratings">
                                        <ul className="rating">
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="/images/pics/02.jpg" alt="testimonial-img" />
                                        </div>
                                    </div>
                                    <div className="testimonial-caption">
                                        <label>Manager</label>
                                        <h3>Hassan Massarwe</h3>
                                    </div>
                                </div>
                            </div> {/* testimonials end */}
                        </div>
                        <div className="col-lg-12">
                            {/* testimonials */}
                            <div className="testimonials ttm-testimonial-box-view-style1">
                                <div className="testimonial-content">
                                    <blockquote className="testimonial-text">كانت هذه تجربتي الأولى في العمل مع مجند وكانت رائعة. لم يكن بإمكاني طلب المزيد</blockquote>
                                    <div className="star-ratings">
                                        <ul className="rating">
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="/images/pics/03.jpg" alt="testimonial-img" />
                                        </div>
                                    </div>
                                    <div className="testimonial-caption">
                                        <label>Web Developer</label>
                                        <h3>Yara Huss</h3>
                                    </div>
                                </div>
                            </div> {/* testimonials end */}
                        </div>
                        <div className="col-lg-12">
                            {/* testimonials */}
                            <div className="testimonials ttm-testimonial-box-view-style1">
                                <div className="testimonial-content">
                                    <blockquote className="testimonial-text">MassRes هي وكالة توظيف وطنية مبنية بشعارنا المميز ، توقع المزيد ، نحن نفعل.</blockquote>
                                    <div className="star-ratings">
                                        <ul className="rating">
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                            <li className="active"><i className="fa fa-star"></i></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="/images/pics/02.jpg" alt="testimonial-img" />
                                        </div>
                                    </div>
                                    <div className="testimonial-caption">
                                        <label>Manager</label>
                                        <h3>Hassan Massarwe</h3>
                                    </div>
                                </div>
                            </div> {/* testimonials end */}
                        </div>
                    </Slider>
                </div>
            </section>
            {/* testimonial-section end */}

            {/* aboutus-section */}
            <section className="ttm-row about-section bg-img2 bg-theme-DarkColor ttm-bg ttm-bgimage-yes text-theme-WhiteColor clearfix" style={{ backgroundImage: 'url("/images/pics/row-bgimage-2.jpg")' }}>
                <div className="ttm-row-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor"></div>
                <div className="container">
                    {/* row */}
                    <div className="row align-items-center" dir='rtl'>
                        <div className="col-xl-5 col-lg-4 col-md-12" dir='rtl'>
                            {/* section title */}
                            <div className="section-title">
                                <div className="title-header">
                                    <h3>MASS <span className="text-theme-SkinColor">RES</span></h3>
                                    {/* <h2 className="title">وكالة التوظيف الأكثر موثوقية و <span className="text-theme-SkinColor"> احترافية</span></h2> */}
                                    <h2 className='title'> نحن نصل  النقاط لتجد  <span className="text-theme-SkinColor"> وظيفتك المستقبلية.</span></h2>
                                </div>
                            </div>{/* section title end */}
                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white mt-15"
                                href={process.env.PUBLIC_URL + '/candidate_list'}> موظفين ! </a>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-5">
                            <div className="pt-lg-30">
                                {/* fid */}
                                <div className="ttm-fid inside ttm-fid-view-topicon style1">
                                    <div className="ttm-fid-icon-wrapper">
                                        <i className="flaticon flaticon-recruiting"></i>
                                    </div>
                                    <div className="ttm-fid-contents">
                                        <h4><CountUp start={0} end={2887} duration={20} delay={2} /></h4>
                                        <h3 className="ttm-fid-title">وظيفة</h3>
                                    </div>
                                </div>{/* fid end */}
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-8 col-sm-7">
                            <div className="pt-lg-20 pb-30">
                                <p className="text-theme-WhiteColor">لدينا خبرة في الحلول المالية والتجارية .</p>
                            </div>
                            {/* ttm-progress-bar */}
                            <div className="ttm-progress-bar style2 clearfix">
                                <h3 className="progressbar-title">الخبرة الاستشارية</h3>
                                <ProgressBar rect percentage="90" />
                            </div>
                            {/* ttm-progress-bar end */}
                            {/* ttm-progress-bar */}
                            <div className="ttm-progress-bar style2 clearfix">
                                <h3 className="progressbar-title">الخطة الاستثمارية</h3>
                                <ProgressBar rect percentage="85" />
                            </div>
                            {/* ttm-progress-bar end */}
                        </div>


                        <div className="col-lg-12">
                            <div className="pt-100 pt-md-0"></div>
                        </div>
                    </div>
                </div>
            </section>
            {/* aboutus-section end */}


            {/* padding_zero-section */}
            <section className="ttm-row padding_zero-section clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="pt-5 bg-theme-SkinColor mt_100 mt-md-60"></div>
                            <div className="bg-theme-WhiteColor box-shadow pt-md-40 pb-md-30">
                                <div className="row g-0 ttm-vertical_sep">
                                    <div className="col-lg-3 col-md-6 col-sm-6">
                                        {/* fid */}
                                        <div className="ttm-fid inside ttm-fid-view-topicon style2">
                                            <div className="ttm-fid-icon-wrapper">
                                                <i className="flaticon flaticon-headhunting"></i>
                                            </div>
                                            <div className="ttm-fid-contents">
                                                <h4><CountUp start={0} end={8705} duration={20} delay={2} className="count-up" /></h4>
                                                <h3 className="ttm-fid-title">وظائف  </h3>
                                            </div>
                                        </div>{/* fid end */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6">
                                        {/* fid */}
                                        <div className="ttm-fid inside ttm-fid-view-topicon style2">
                                            <div className="ttm-fid-icon-wrapper">
                                                <i className="flaticon flaticon-technical-support"></i>
                                            </div>
                                            <div className="ttm-fid-contents">
                                                <h4><CountUp start={0} end={480} duration={20} delay={2} className="count-up" /></h4>
                                                <h3 className="ttm-fid-title">موظفون </h3>
                                            </div>
                                        </div>{/* fid end */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6">
                                        {/* fid */}
                                        <div className="ttm-fid inside ttm-fid-view-topicon style2">
                                            <div className="ttm-fid-icon-wrapper">
                                                <i className="flaticon flaticon-recruitment-4"></i>
                                            </div>
                                            <div className="ttm-fid-contents">
                                                <h4><CountUp start={0} end={6260} duration={20} delay={2} className="count-up" /></h4>
                                                <h3 className="ttm-fid-title">شركات </h3>
                                            </div>
                                        </div>{/* fid end */}
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6">
                                        {/* fid */}
                                        <div className="ttm-fid inside ttm-fid-view-topicon style2">
                                            <div className="ttm-fid-icon-wrapper">
                                                <i className="flaticon flaticon-recruitment-3"></i>
                                            </div>
                                            <div className="ttm-fid-contents">
                                                <h4><CountUp start={0} end={9774} duration={20} delay={2} className="count-up" /></h4>
                                                <h3 className="ttm-fid-title">خبراء</h3>
                                            </div>
                                        </div>{/* fid end */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* padding_zero-section end */}


            {/* blog-section */}
            <section className="ttm-row blog-section clearfix text-right">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section title */}
                            <div className="section-title title-style-center_text">
                                <div className="title-header">
                                    <h3>وسع <span className="text-theme-SkinColor">معرفتك</span></h3>
                                    <h2 className="title">آخر الأخبار والمقالات</h2>
                                </div>
                            </div>{/* section title end */}
                        </div>
                    </div>{/* row end */}
                    {/* Slider */}
                    <Slider className="row slick_slider mb_10" {...slick_slider} slidesToShow={3} arrows={false} autoplay={false}>
                        <div className="col-md-12">
                            {/* featured-imagebox-post */}
                            <div className="featured-imagebox featured-imagebox-post style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="/images/pics/blog-01-600x430.jpg" alt="blog-image" />
                                    <div className="ttm-box-post-date">
                                        <span className="ttm-entry-date">
                                            <time className="entry-date" dateTime="2021-03-18T04:16:25+00:00">18
                                                <span className="entry-month entry-year">Mar</span></time>
                                        </span>
                                    </div>
                                </div>{/* featured-thumbnail end */}
                                <div className="featured-content">
                                    <div className="post-meta">
                                        <span className="ttm-meta-line byline">Admin</span>
                                        <span className="ttm-meta-line category-link">business</span>
                                    </div>
                                    <div className="featured-title">
                                        <h3><a href="#">يتحدثون عن شركتك</a></h3>
                                    </div>
                                    <div className="featured-desc">
                                        {/* <p>Whatever you do in planning, facilitating. Goal should revolve support </p> */}
                                    </div>
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" href="#">اقرأ المزيد !</a>
                                </div>
                            </div>{/* featured-imagebox-post end */}
                        </div>
                        <div className="col-md-12">
                            {/* featured-imagebox-post */}
                            <div className="featured-imagebox featured-imagebox-post style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="/images/pics/blog-02-600x430.jpg" alt="blog-image" />
                                    <div className="ttm-box-post-date">
                                        <span className="ttm-entry-date">
                                            <time className="entry-date" dateTime="2021-03-18T04:16:25+00:00">28
                                                <span className="entry-month entry-year">June</span></time>
                                        </span>
                                    </div>
                                </div>{/* featured-thumbnail end */}
                                <div className="featured-content">
                                    <div className="post-meta">
                                        <span className="ttm-meta-line byline">Admin</span>
                                        <span className="ttm-meta-line category-link">business</span>
                                    </div>
                                    <div className="featured-title">
                                        <h3><a href="#">كيف نتخذ قرارات بشأن حياتنا</a></h3>
                                    </div>
                                    <div className="featured-desc">
                                        {/* <p>A business leader must consider issues beyond choosing a moving!</p> */}
                                    </div>
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" href="#">اقرأ المزيد !</a>
                                </div>
                            </div>{/* featured-imagebox-post end */}
                        </div>
                        <div className="col-md-12">
                            {/* featured-imagebox-post */}
                            <div className="featured-imagebox featured-imagebox-post style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="/images/pics/blog-03-600x430.jpg" alt="blog-image" />
                                    <div className="ttm-box-post-date">
                                        <span className="ttm-entry-date">
                                            <time className="entry-date" dateTime="2021-03-18T04:16:25+00:00">20
                                                <span className="entry-month entry-year">JAN</span></time>
                                        </span>
                                    </div>
                                </div>{/* featured-thumbnail end */}
                                <div className="featured-content">
                                    <div className="post-meta">
                                        <span className="ttm-meta-line byline">Admin</span>
                                        <span className="ttm-meta-line category-link">محاسبة و مالية</span>
                                    </div>
                                    <div className="featured-title">
                                        <h3><a href="#">مفتاح نجاح المشاريع في السحابة</a></h3>
                                    </div>
                                    <div className="featured-desc">
                                        {/* <p>This can be innate or external and results can be surprising performance</p> */}
                                    </div>
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" href="#">اقرأ المزيد !</a>
                                </div>
                            </div>{/* featured-imagebox-post end */}
                        </div>
                        <div className="col-md-12">
                            {/* featured-imagebox-post */}
                            <div className="featured-imagebox featured-imagebox-post style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="https://via.placeholder.com/600x430?text=600x430+blog-04-600x430.jpg" alt="blog-image" />
                                    <div className="ttm-box-post-date">
                                        <span className="ttm-entry-date">
                                            <time className="entry-date" dateTime="2021-03-18T04:16:25+00:00">18
                                                <span className="entry-month entry-year">Mar</span></time>
                                        </span>
                                    </div>
                                </div>{/* featured-thumbnail end */}
                                <div className="featured-content">
                                    <div className="post-meta">
                                        <span className="ttm-meta-line byline">Admin</span>
                                        <span className="ttm-meta-line category-link">business</span>
                                    </div>
                                    <div className="featured-title">
                                        <h3><a href={process.env.PUBLIC_URL + '/blog_details'}>How to Handle Negativity in Former People?</a></h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Whatever you do in planning, facilitating. Goal should revolve support</p>
                                    </div>
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" href="#">اقرأ المزيد !</a>
                                </div>
                            </div>{/* featured-imagebox-post end */}
                        </div>
                    </Slider>
                    {/* Slider end */}
                </div>
            </section>
            {/* blog-section end */}


            {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container" >
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between" dir='rtl'>
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            {/* <h3>وكالة توظيف معتمدة و موثوقة </h3> */}
                                            <h3 >سيكون لديك دائمًا إمكانية  <span className='theme-DarkColor'> الحصول على وظيفة</span></h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>لدينا خبرة في الأعمال</p>
                                        </div>
                                    </div>

                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                                    href={process.env.PUBLIC_URL + '/Employers_list'}>شركات توظيف ! </a>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}
            {/* <ScrollToTop /> */}
            <Footer />

        </div>
    )
}
// }


// export default Home;
