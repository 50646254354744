import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import ImageUploading from "react-images-uploading";
import Header from '../components/layout/Header';
import Profile from '../assets/img/massavatar/massres-bus.png';

import { GrAddCircle, GrUploadOption } from 'react-icons/gr';
import { IoMdAddCircleOutline } from 'react-icons/io'
import { AiFillEdit, AiOutlineArrowRight, AiOutlineArrowLeft, AiOutlineFilePdf } from 'react-icons/ai';
import { GrDocumentDownload } from 'react-icons/gr'
import { RiDeleteBinFill } from 'react-icons/ri'
import { BiAddToQueue, BiBold, BiEdit, BiSave, BiUpload } from "react-icons/bi";
import { SlPhone } from 'react-icons/sl'
import { BsSave } from 'react-icons/bs'
import { FaLinkedinIn } from 'react-icons/fa'
import { resetIdCounter, Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ProgressBar from 'react-animated-progress-bar';
// =========
import { useAuthContext } from '../hooks/useAuthContext';
import { usePostNestDocs } from '../hooks/usePostNestDocs';
import { useFetchNestDocs } from '../hooks/useFetchNestDocs';
import { useCollection } from '../hooks/useCollection';
import { projectStorage } from '../firebase/config';
import { useDocument } from '../hooks/useDocument';
import { EditorState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { v4 as uuidv4 } from 'uuid'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas';
import logo from '../assets/img/blank-img.png'
const containerVariants = {
    hidden: {
        opacity: 0,
        x: '-100vw'
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: { type: 'spring', delay: 0.5 }
    },
    exit: {
        x: "-100vh",
        transition: { ease: 'easeInOut' }
    }
};

const nextVariants = {
    hidden: {
        x: '-20vw'
    },
    visible: {
        x: 0,
        transition: { ease: 'easeInOut' }

        // transition: { type: 'spring', stiffness: 120 }
    }
};
const nextVariantsRight = {
    hidden: {
        x: '20vw'
    },
    visible: {
        x: 0,
        transition: { ease: 'easeInOut' }

        // transition: { type: 'spring', stiffness: 120 }
    }
}
const buttonVariants = {
    hover: {
        scale: 1.1,
        // textShadow: "0px 0px 8px rgb(255,255,255)",
        fontWeight: "bold",
        boxShadow: "0px 0px 8px rgb(255,255,255)",

        transition: {
            duration: 0.5,
            yoyo: Infinity
        }
    }
}

const buttonVariantsUpload = {
    hover: {
        scale: 1.3,
        // textShadow: "0px 0px 8px rgb(255,255,255)",
        // boxShadow: "0px 0px 8px rgb(255,255,255)",
        color: "#bc4123",
        transition: {
            duration: 0.7,
            yoyo: Infinity
        }

    }
}
const warningVariants = {

    visible: {
        scale: 0.1,
        textShadow: "0px 0px 8px rgb(255,255,255)",
        boxShadow: "0px 0px 8px rgb(255,255,255)",
        transition: {
            duration: 0.7,
            yoyo: Infinity
        }
    }
}

// var mylink
export default function FillCompany() {
    const jobTypes = ["", "FULL TIME", "FULL TIME - Urgent", "PART TIME", "PART TIME - Urgent", "ONLINE", "ONLINE - Urgent"]
    const cats = ["", "محاسبة", "تكنولوجيا", "زراعة", "بناء", "وظائف طبية", "انتاج", "نقل", "تأمين"]
    const [isEmployer, setIsEmployer] = useState(false)

    const printRef = React.useRef();
    const printRef2 = React.useRef();

    const x = useMotionValue(5)
    const { user } = useAuthContext();
    const [data, setData] = useState([])
    const [coData, setCoData] = useState()

    const [isSaved, setIsSaved] = useState(false)
    const [nextPage, setNextPage] = useState(false)

    const [show, setShow] = useState(false)
    const { documents, err } = useCollection(
        'users', ["isEmployer", "==", false],
    )
    if (documents) {
        // console.log("documents : ", documents)
    }


    // const { documents, error } = useFetchNestDocs(
    //     'candidates', user.uid, "resume"
    // )
    const [saving, setSaving] = useState(false)
    const [editing, setEditing] = useState(false)
    const { addDocument, updateDocument, response } = usePostNestDocs('employers', user.uid, "resume")
    const { document, error } = useDocument('employers', user.uid)
    useEffect(() => {
        if (user && document) {
            //   console.log("employerrrrr")

            // console.log("doc details is : ", document?document:"Employer here")
            setIsEmployer(true)
        } else if (user && !document) {
            console.log(" ")
        }
    }, [document])
    //    Company_Info
    const [companyName, setCompName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("");
    const [dateOfBirth, setDate] = useState("");
    const [numOfEmp, setNumOfEmp] = useState("")
    const [payRange, setPayRange] = useState("")
    const [cAddress, setCaddress] = useState("")
    const [compLocation, setCompLocation] = useState("")
    const [compCat, setCompCat] = useState("")
    const [compEmail, setCemail] = useState("")
    const [compSite, setCsite] = useState("")
    const [compMap, setCompMap] = useState("")
    const [compMapsrc, setCompMapsrc] = useState("")

    // Job_Info


    const [jobName, setJobName] = useState("")
    const [jobEmail, setJobEmail] = useState("")
    const [experienceYear, setExperienceYear] = useState("")
    const [positionNumber, setPositionNumber] = useState("")
    const [jobCat, setJCategory] = useState("")
    const [jobType, setJobType] = useState("")
    const [jobLocation, setJobLocation] = useState("")
    const [jobQuali, setQual] = useState("")
    const [offeredSalary, setOfferedSalary] = useState("")
    // Job_Description
    const [editorStatewyp, estEditorStwyp] = useState(() => EditorState.createEmpty())
    const [convertedContentwyp, setConvertedContentwyp] = useState();
    // Job_Requirements
    const [reqs, setReqs] = useState([])
    const [req, setReq] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("");
    const [occupation, setOccupation] = useState("");
    const [nationality, setNationality] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");


    // Cv_SOCIAL_LINKS_Info
    const [links, setLinks] = useState([])
    const [link, setLink] = useState("");
    const [facebook, setFacebook] = useState("");
    const [instagram, setInstagram] = useState("");
    const [twitter, setTwitter] = useState("");
    const [profilePicture, setProfileP] = useState("")
    // Cv_Work Exp_Info
    const [expData, setExpData] = useState([])
    const [position, setPosition] = useState("")
    const [company1, setCompany1] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [convertedContentabc, setConvertedContentabc] = useState();
    // CV_Skills_Info
    const [skill, setSkill] = useState("")
    const [skills, setSkills] = useState([])
    const [skilLevel, setSkilLevel] = useState("0")
    const [languages, setLanguages] = useState([])
    const [language, setLanguage] = useState("")
    const [languageLevel, setLanguageLevel] = useState("0")
    // CV_EDUCATION_INFO
    const [category, setCategory] = useState("")

    const [degree, setDegree] = useState("")
    const [schoolName, setSchoolName] = useState("")
    const [schoolStartDate, setSchoolStart] = useState("")
    const [schoolEndDate, setSchoolEnd] = useState("")
    const [schoolLocation, setSchoolLocation] = useState("")
    const [schoolEditorState, setSchoolEditorState] = useState(() => EditorState.createEmpty())
    const [convertedContentSc, setConvertedContentSc] = useState();
    // CV_ABOUT_INFO
    const [aboutEditorState, setAboutEditorState] = useState(() => EditorState.createEmpty())
    const [convertedContentAb, setConvertedContentAb] = useState();

    const [isCheckedSchool, setCheckedSchool] = useState(false)
    const [education, setEdu] = useState("")
    const [educations, setEdus] = useState([])

    // const raw = convertToRaw(_contentState)
    // const [contentState, setContentState] = useState(raw)
    const [abCeditorState, setabcEditorState] = useState(() => EditorState.createEmpty())
    const [isChecked, setChecked] = useState(false)
    const [currentDate, setCurrentDate] = useState("")
    const [endYear, setEndYear] = useState("")

    // const [tabId, setTabId] = useState(0);
    // const [images, setImages] = React.useState([]);
    // const [isImage, setIsImage] = useState(false)
    const [showComp, setShowComp] = useState(true)

    useEffect(() => {
        if (document) {
            setData(document)
            console.log("doc: ", document)
            setCompName(document.companyName ? document.companyName : "")
            setPhoneNumber(document.phoneNumber ? document.phoneNumber : "")
            setDate(document.dateOfBirth ? document.dateOfBirth : "")
            setNumOfEmp(document.numOfEmp ? document.numOfEmp : "")
            setPayRange(document.payRange ? document.payRange : "")
            setCaddress(document.cAddress ? document.cAddress : "")
            setCompLocation(document.compLocation ? document.compLocation : "")
            setCompMapsrc(document.compMapsrc ? document.compMapsrc : "")
            setCompCat(document.compCat ? document.compCat : "")
            setCemail(document.compEmail ? document.compEmail : "")
            setCsite(document.compSite ? document.compSite : "")
            setCompMap(document.compMap ? document.compMap : "")
            setConvertedContentabc(document.aboutUs ? document.aboutUs : "")
            setConvertedContentwyp(document.whatWePresent ? document.whatWePresent : "")
            setFacebook(document.facebook ? document.facebook : "")
            setLink(document.link ? document.link : "")
            setInstagram(document.instagram ? document.instagram : "")
            setTwitter(document.twitter ? document.twitter : "")
            setProfileP(document.profilePicture ? document.profilePicture : "")
          
        }
    }, [document])


    useEffect(() => {
        if (compMap) {

            let repSrc = compMap.replace("style", "id")
            repSrc = repSrc.replace('loading="lazy"', "")
            repSrc = repSrc.replace('allowfullscreen=""', "")
            repSrc = repSrc.replace('width', "widthhh")
            repSrc = repSrc.replace('height', "heighttt")
            repSrc = repSrc.replace('><', 'height="350" width="100%" ><')
            setCompMapsrc(repSrc)

        }
    }, [compMap])


    const handleEdit = () => {
        setEditing(true)
        // console.log("editing here")
    }
    // End Date Handler
    const handleCheckChange = () => {
        setChecked(!isChecked);
    };
    // End Date School Handler
    const handleSchoolCheckChange = () => {
        setCheckedSchool(!isCheckedSchool);
    };
    const showCv = () => {
        setShow(!show)
        // console.log("show cv is clicked here")
    }

    useEffect(() => {
        if (isChecked) {
            const currentD = new Date().toLocaleDateString();
            let sp = currentD.split('/')
            const cureentDate = sp[2] + "/" + sp[1] + "/" + sp[0]
            setEndDate(cureentDate)
        }
    })
    useEffect(() => {
        if (isCheckedSchool) {
            const currenD = new Date().toLocaleDateString();
            let sp = currenD.split('/')
            const cureentDate = sp[2] + "/" + sp[1] + "/" + sp[0]
            setSchoolEnd(cureentDate)
        }
    })
    // Text Editor Handler
    const handleEditorChange = (state) => {
        setabcEditorState(state);
        console.log("editorState = ", state)
        convertContentToHTML();

    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(abCeditorState.getCurrentContent());
        setConvertedContentabc(currentContentAsHTML);
        // console.log("Editor state:", currentContentAsHTML)
    }
    const handleEditorChangeSc = (state) => {
        setSchoolEditorState(state);
        convertContentToHTMLSc();

    }
    const convertContentToHTMLSc = () => {
        let currentContentAsHTML = convertToHTML(schoolEditorState.getCurrentContent());
        setConvertedContentSc(currentContentAsHTML);
        // console.log("Editor state:", currentContentAsHTML)
    }


    const handleEditorChangewyp = (state) => {
        estEditorStwyp(state);
        // console.log("state: ", state)
        convertContentToHTMLwyp();

    }
    const convertContentToHTMLwyp = () => {
        let currentContentAsHTML = convertToHTML(editorStatewyp.getCurrentContent());
        setConvertedContentwyp(currentContentAsHTML);
        // console.log("Editor state:", currentContentAsHTML)
    }



    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }
    // Tabs Switching
    const [prev, setprev] = useState(0)
    let xx = "";
    const [tabId, setTabId] = useState(0);
    const [images, setImages] = React.useState([]);
    const [isImage, setIsImage] = useState(false)
    const maxNumber = 1;
    const maxSize = 5000000; //5 mega = 5000000
    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        // console.log("image list", imageList, addUpdateIndex);

        setImages(imageList);

    };

    async function incrementCounter() {
        const newt = tabId + 1
        const ScrollToTop = () => window.scrollTo({ top: 40, behavior: "smooth" });
        ScrollToTop()
        setTabId(newt)

        // console.log("tab is id : ", newt)
    }
    function decrementCounter() {
        const newt = tabId - 1
        const ScrollToTop = () => window.scrollTo({ top: 40, behavior: "smooth" });
        ScrollToTop()
        setTabId(newt)
    }

    const addExp = (e) => {
        e.preventDefault()
        const newEpx = {
            id: uuidv4(),
            position: position ? position : "",
            company1: company1 ? company1 : "",
            startDate: startDate ? startDate : "",
            endDate: endDate ? endDate : "",
            jobLocation: jobLocation ? jobLocation : "",
            convertedContent: convertedContentabc ? convertedContentabc : "",
        }
        // updateDocument(user.uid, ...expData)
        setExpData([...expData, newEpx])
        // updateDocument(user.uid,[...document.expData,newEpx])
        setPosition("")
        setCompany1("")
        setPosition("")
        setJobLocation("")
        setStartDate("")
        setEndDate("")
        setChecked(false)
        const neweditorState = EditorState.push(abCeditorState, ContentState.createFromText(''));
        setabcEditorState(neweditorState)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSaving(true)
        // console.log("name is ? ", firstName ," ", lastName)
        const storageRef = projectStorage.ref()
        function imgUpload() {
            const imgName = images[images.length - 1].file
            const ref = storageRef.child("employers/profile-pictures/" + imgName.name)
            const uploadTask = ref.put(imgName);
            uploadTask.on('state_changed',
                (snapshot) => {
                    console.log('Image Three Upload is ' + 100 + '% done');
                },
                (error) => {
                    console.log("Somthing wrong happened, image is not uploaded");
                },
                () => {
                    ref.getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // console.log(downloadURL)
                        setProfileP(downloadURL)
                        updateDocument(user.uid, { profilePicture: downloadURL })
                    });
                }

            );


        }
        images.length > 0 ? imgUpload() : console.log("")
        let fnameLname = firstName + " " + lastName
        await updateDocument(user.uid, {
            companyName,
            phoneNumber,
            dateOfBirth,
            numOfEmp,
            payRange,
            cAddress,
            compLocation,
            compCat,
            aboutUs: convertedContentabc,
            whatWePresent: convertedContentwyp,
            compEmail,
            link,
            facebook,
            instagram,
            twitter,
            compSite,
            compMapsrc,
        })
        if (tabId == 4) {
            setShow(true)
        }
        setIsSaved(true)
        setSaving(false)
        if (tabId == 2) {
            // console.log("go next ")
            setNextPage(true)
        }
    }
    function goHome() {
        setTimeout(window.location.href = process.env.PUBLIC_URL + "/register", 2);
    }
    useEffect(() => {
        if (nextPage) {

            setTimeout(window.location.href = process.env.PUBLIC_URL + "/start_job", 5000);
        }
    }, [nextPage])

    //  PDF Print Function 
    const handleDownloadPdf = async () => {

        const element = printRef.current;
        element.style.background = '#FFFFFF';

        const canvas = await html2canvas(element);
        const d = new Date().toLocaleString() + "";
        var imgData = canvas.toDataURL('image/png');
        var imgWidth = 210;
        var pageHeight = 290;
        var imgHeight = (canvas.height * imgWidth / canvas.width);
        var heightLeft = imgHeight;
        var doc = new jsPDF();
        var margin = 10
        var position = 18; // give some top padding to first page
        // Data
        doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        const docName = firstName + lastName + '_CV.pdf'
        doc.save(docName);
    };
    const download = () => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            const divToPrint = printRef.current
            divToPrint.style.background = '#FFFFFF';

            html2canvas(divToPrint).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = 210;
                const pageHeight = 290;

                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                const doc = new jsPDF('pt', 'mm');
                let position = 0;
                doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight + 25);
                heightLeft -= pageHeight;
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + 15);
                    heightLeft -= pageHeight;
                }
                const docName = firstName + lastName + '_CV.pdf'
                doc.save(docName);
            });
        }, 1000);
    };

    return (
        <div className="site-main  "
        >
            {isEmployer ?

                <>
                    <Header />
                    {/* padding_zero-section */}
                    <section className="ttm-row grid-section clearfix site-main-cv">

                        <div className=" container-cv" dir='rtl'>
                            {/* row */}
                            <div className="row " >
                                {!show && <motion.div className="col-lg-5"
                                style={{backgroundColor:"#404966"}}
                                    variants={containerVariants}
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit">
                                    <div className="ttm-col-bgcolor-yes ttm-bg bg-theme-GreyColor h-auto p-40 p-lg-30 mt-lg-30 mt-50 mb-50">
                                        <div className="layer-content">
                                            {/* section title */}
                                            <div className="section-title title-style-center_text">
                                                {tabId == 0 &&
                                                    <div className="title-header">
                                                        <h4 className="title">اخبرنا قليلا   <span className="text-theme-SkinColor">عن نفسك</span></h4>
                                                        <h3 className='subtitle-cv'>أخبرنا عن الشركة ، وكيف يمكن للموظفين التواصل معك.</h3>

                                                    </div>}
                                                {tabId == 1 &&
                                                    <div className="title-header">
                                                        <h4 className="title">تفاصيل <span className="text-theme-SkinColor"> الشركة</span></h4>
                                                        <h3 className='subtitle-cv'>أخبرنا عن الشركة ، وكيف يمكن للموظفين التواصل معك ، والمزيد. </h3>

                                                    </div>}
                                                {tabId == 2 &&
                                                    <div className="title-header">
                                                        <h4 className="title">لنكات تواصل مع   <span className="text-theme-SkinColor">الشركة </span></h4>
                                                    </div>}

                                            </div>{/* section title end */}
                                            {tabId == 0 &&
                                                <div className="row text-center" dir='ltr'>
                                                    <div className="title-header text-right">
                                                        <h5>صورة   <span className="text-theme-SkinColor"> شخصية </span></h5>

                                                        {/* <h2 className="title">Request a Callback</h2> */}
                                                    </div>
                                                    <ImageUploading
                                                        multiple
                                                        value={images}
                                                        onChange={onChange}
                                                        maxNumber={maxNumber}
                                                        maxFileSize={maxSize}
                                                        dataURLKey="data_url"
                                                        acceptType={['jpg', 'gif', 'png', 'jpeg']}
                                                    >
                                                        {({
                                                            imageList,
                                                            onImageUpload,
                                                            onImageRemoveAll,
                                                            onImageUpdate,
                                                            onImageRemove,
                                                            isDragging,
                                                            dragProps,
                                                            errors
                                                        }) => (
                                                            // write your building UI
                                                            <>
                                                                {/* Errors Div  */}
                                                                {
                                                                    errors &&
                                                                    <motion.div className='warning'
                                                                        //  variants={warningVariants} 
                                                                        style={{ x, opacity: 1, scale: 1 }}
                                                                    >

                                                                        {errors.acceptType && <span>نوع الملف المحدد غير مسموح به</span>}
                                                                        {errors.maxFileSize && <span className='warning'>الحجم الأقصى للصورة هو (5 ميغا بايت) اختر صورة أخرى </span>}
                                                                        {errors.resolution && <span>الملف المحدد لا يتطابق مع الدقة المطلوبة</span>}
                                                                    </motion.div>
                                                                }
                                                                <div className='col-md-6 ttm-box-col-wrapper' >

                                                                    {imageList.length == 0 &&
                                                                        <img src={Profile} />
                                                                    }
                                                                    {imageList.map((image, index) => (
                                                                        <div key={index} className="image-item">
                                                                            <img src={image.data_url ? image.data_url : Profile} alt="profile picture" width="100" />
                                                                            <figcaption style={{ fontSize: "small", marginTop: "10px" }}><span className='text-placeHolder-color-light'>الأبعاد الموصى بها </span> <br></br> <span className='text-theme-SkinColor'>(W:100 x H:100) px</span> </figcaption>
                                                                        </div>
                                                                    ))}
                                                                </div>

                                                                <div className='col-md-6 ttm-box-col-wrapper'>


                                                                    {imageList.length == 0 &&
                                                                        <motion.button
                                                                            className='  cv-btn '
                                                                            variants={buttonVariantsUpload}
                                                                            whileHover="hover"
                                                                            onClick={onImageUpload}
                                                                            {...dragProps}
                                                                        >
                                                                            <br></br>
                                                                            <span className='text-placeHolder-color-light'>أضف صورة</span>
                                                                            <BiUpload className='cv-icon text-placeHolder-color-light' size={20} />
                                                                        </motion.button>
                                                                    }
                                                                    {imageList.map((image, index) => (
                                                                        <div className="image-item">
                                                                            <div className="image-item__btn-wrapper" key={index}>
                                                                                <div className='col-md-6 text-right'>
                                                                                    <motion.button
                                                                                        className='    cv-btn  '
                                                                                        variants={buttonVariantsUpload}
                                                                                        whileHover="hover"
                                                                                        onClick={() => onImageUpdate(index)}
                                                                                    >

                                                                                        <span className='text-placeHolder-color-light'>تعديل الصورة </span>
                                                                                        <AiFillEdit className='cv-icon text-placeHolder-color-light' size={20} />
                                                                                    </motion.button>
                                                                                </div>
                                                                                <div className='col-md-6 text-right'>
                                                                                    <motion.button className='  cv-btn  '
                                                                                        variants={buttonVariantsUpload}
                                                                                        whileHover="hover"
                                                                                        onClick={() => onImageRemove(index)}>

                                                                                        <span className='text-placeHolder-color-light'>حذف الصورة </span>
                                                                                        <RiDeleteBinFill className='cv-icon text-placeHolder-color-light' size={20} />
                                                                                    </motion.button>
                                                                                </div>

                                                                                {/* <button onClick={() => onImageRemove(index)}>Remove</button> */}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                &nbsp;
                                                                <hr></hr>

                                                                {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                                                            </>
                                                        )}
                                                    </ImageUploading>
                                                </div>}
                                            <form id="contact_form" className="contact_form wrap-form" onSubmit={handleSubmit}>

                                                <Tabs>
                                                    {/*  ======================= Tab_One Start  ==================================== */}


                                                    {tabId == 0 && <TabPanel>

                                                        <motion.div className="row ttm-boxes-spacing-10px"
                                                            variants={prev ? nextVariantsRight : nextVariants}
                                                            initial="hidden"
                                                            animate="visible"
                                                            exit="exit">
                                                            <div className="title-header">
                                                                <h5>تفاصيل   <span className="text-theme-SkinColor">الشركة</span></h5>
                                                                <br></br>
                                                                {/* <h2 className="title">Request a Callback</h2> */}
                                                            </div>
                                                            {/*================ HEeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeer========================================== */}
                                                            <div className='col-md-12'></div>


                                                            <div className="col-md-6 ttm-box-col-wrapper">
                                                                <label>
                                                                    <input name="companyName" type="text" placeholder="اسم الشركة "
                                                                        // required="required"
                                                                        required={companyName ? "false" : "required"}
                                                                        value={companyName}
                                                                        onChange={e => (setCompName(e.target.value))}
                                                                    />
                                                                </label>
                                                                <div className="example-cv"> مثال : ماث أكاديمي</div>
                                                                {/* <p>eg. Mohammed</p> */}
                                                            </div>
                                                            <div className="col-md-6 ttm-box-col-wrapper">
                                                                <label>
                                                                    <input name="phone" type="text" placeholder={phoneNumber ? phoneNumber : "رقم الهاتف"}
                                                                        required={phoneNumber ? "false" : "required"}
                                                                        value={phoneNumber}
                                                                        onChange={e => (setPhoneNumber(e.target.value))}
                                                                    />
                                                                </label>
                                                                <p className="example-cv"> مثال : + 972596574154</p>

                                                            </div>
                                                            <div className="col-md-6 ttm-box-col-wrapper">
                                                                <label>
                                                                    <input
                                                                        type="text"
                                                                        name="dob"
                                                                        placeholder='تاريخ انشاء الشركة   '
                                                                        onChange={e => (setDate(e.target.value))}

                                                                        onFocus={(e) => (e.target.type = "date")}
                                                                        onBlur={(e) => (e.target.type = "text")}
                                                                    />
                                                                    {/* <input name="dof" type="date" placeholder="Date of birth" required="required" /> */}
                                                                </label>
                                                                <p className="example-cv">مثال : Jun 23 1994</p>

                                                            </div>
                                                            <div className="col-md-6 ttm-box-col-wrapper">
                                                                <label>
                                                                    <input name="numOfEmp" type="text" placeholder="عدد الموظفين "
                                                                        // required="required"

                                                                        value={numOfEmp}
                                                                        onChange={e => (setNumOfEmp(e.target.value))}
                                                                    />
                                                                </label>
                                                                <div className="example-cv">مثال : 50 </div>
                                                                {/* <p>eg. Mohammed</p> */}
                                                            </div>
                                                            <div className="col-md-6 ttm-box-col-wrapper">
                                                                <label>
                                                                    <input name="payRange" type="text" placeholder="نطاق الرواتب"
                                                                        // required="required"

                                                                        value={payRange}
                                                                        onChange={e => (setPayRange(e.target.value))}
                                                                    />
                                                                </label>
                                                                <div className="example-cv">مثال :  $700 - $900/شهر</div>
                                                                {/* <p>eg. Mohammed</p> */}
                                                            </div>
                                                            <div className="col-md-6 ttm-box-col-wrapper">

                                                                <label>
                                                                    <input name="cAddress" type="text" placeholder="عنوان الشركة "
                                                                        value={cAddress}
                                                                        onChange={e => (setCaddress(e.target.value))}
                                                                    />
                                                                </label>
                                                                <p className="example-cv"> مثال : شارع القلعة , القدس </p>
                                                            </div>
                                                            <div className="col-md-6 ttm-box-col-wrapper">
                                                                <label>
                                                                    <i className="ti ti-list" ></i>
                                                                    <select
                                                                        style={{ lineHeight: "1.8" }}
                                                                        name="موقع الشركة"
                                                                        aria-hidden="true"
                                                                        placeholder=" Job Location"
                                                                        onChange={e => (setCompLocation(e.target.value))}>
                                                                        <option value=""> موقع الشركة</option>
                                                                        <option value="القدس">القدس</option>
                                                                        <option value="عمان">عمان</option>
                                                                        <option value="القاهرة ">القاهرة </option>
                                                                        <option value="دمشق">دمشق </option>
                                                                        <option value="بيروت">بيروت </option>
                                                                        <option value="الجزائر">الجزائر</option>
                                                                        <option value="المغرب">المغرب</option>
                                                                        <option value="أخرى">أخرى</option>


                                                                    </select>
                                                                </label>
                                                            </div>
                                                            <div className="col-md-6 ttm-box-col-wrapper">
                                                                <label>
                                                                    <i className="ti ti-list" ></i>
                                                                    <select
                                                                        style={{ lineHeight: "1.8" }}
                                                                        name="Company Industry"
                                                                        aria-hidden="true"
                                                                        placeholder=" Company Industry"
                                                                        onChange={e => (setCompCat(e.target.value))}>
                                                                        <option value=""> تصنيف الشركة</option>
                                                                        <option value="1">محاسبة</option>
                                                                        <option value="2">تكنولوجيا</option>
                                                                        <option value="3">زراعة</option>
                                                                        <option value="4">بناء </option>
                                                                        <option value="5">وظائف طبية </option>
                                                                        <option value="6">انتاج</option>
                                                                        <option value="7">نقل</option>
                                                                        <option value="8">تأمين </option>

                                                                    </select>
                                                                </label>
                                                            </div>





                                                            <div className="col-md-12 ttm-box-col-wrapper">
                                                                <label>
                                                                    <input name="compEmail" type="text" placeholder="البريد الالكتروني "
                                                                        value={compEmail}
                                                                        onChange={e => (setCemail(e.target.value))}
                                                                    />
                                                                </label>
                                                                <p className="example-cv">   مثال : MH.m@gmail.com </p>


                                                            </div>


                                                        </motion.div>


                                                    </TabPanel>}


                                                    {/*  ======================= Tab_One Start  ==================================== */}


                                                    {tabId == 1 && <TabPanel>

                                                        <motion.div className="row ttm-boxes-spacing-10px text-placeHolder-color-light "
                                                            variants={prev ? nextVariantsRight : nextVariants}
                                                            initial="hidden"
                                                            animate="visible"
                                                            exit="exit">
                                                            <div className="col-md-12 ttm-box-col-wrapper">
                                                                <hr></hr>
                                                                <div className="title-header">
                                                                    <h5>تفاصيل    <span className="text-theme-SkinColor"> الشركة </span></h5>
                                                                    <br></br>
                                                                    {/* <h2 className="title">Request a Callback</h2> */}
                                                                </div>
                                                                <div dir='ltr' style={{ border: "1px solid black" }}>
                                                                    <Editor
                                                                    editorStyle={{ height: '200px', overflow:"auto", color:"white"}} 
                                                                   
                                                                        editorState={abCeditorState}
                                                                        // placeholder="e.g You have at least 3 years’ experience working as a Product Designer."
                                                                        onEditorStateChange={handleEditorChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 ttm-box-col-wrapper">
                                                                <hr></hr>
                                                                <div className="title-header">
                                                                    <h5>ماذا تقدم   <span className="text-theme-SkinColor"> الشركة ؟</span></h5>
                                                                    <p>مثال - تغطية تأمين صحي خاصة إضافية من قبل الشركة لمساعدتك في الحصول على تركيز أفضل.</p>
                                                                     <p>مثال - راتب كبير ومكافآت المشروع</p>
                                                                     <p>مثال - رحلات مع فريق الشركة ، فعاليات  بناء الفريق ...</p><br></br>
                                                                </div>
                                                                <div dir='ltr' style={{ border: "1px solid black" }} >
                                                                    <Editor
                                                                     editorStyle={{ height: '200px', overflow:"auto", color:"white"}} 
                                                                        editorState={editorStatewyp}
                                                                        // placeholder="e.g You have at least 3 years’ experience working as a Product Designer."
                                                                        onEditorStateChange={handleEditorChangewyp}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </motion.div>
                                                    </TabPanel>}
                                                    {tabId == 2 && <TabPanel>

                                                        <motion.div className="row ttm-boxes-spacing-10px"
                                                            variants={prev ? nextVariantsRight : nextVariants}
                                                            initial="hidden"
                                                            animate="visible"
                                                            exit="exit">
                                                            <div className="title-header">
                                                                <h5>Social  <span className="text-theme-SkinColor">Links</span></h5>
                                                                <br></br>
                                                                {/* <h2 className="title">Request a Callback</h2> */}
                                                            </div>
                                                            {/*================ HEeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeer========================================== */}
                                                            <div className='col-md-12'></div>
                                                            <div className="col-md-6 ttm-box-col-wrapper">
                                                                <label>
                                                                    <input name="linkedin" type="text" placeholder="LinkedIn"
                                                                        onChange={e => (setLink(e.target.value))}
                                                                    />
                                                                </label>
                                                                <p className="example-cv"> مثال : linkedin.com/ahmedmassarwe </p>
                                                            </div>
                                                            <div className="col-md-6 ttm-box-col-wrapper">
                                                                <label>
                                                                    <input name="Facebook" type="text" placeholder="Facebook"
                                                                        onChange={e => (setFacebook(e.target.value))}
                                                                    />
                                                                </label>
                                                                <p className="example-cv"> مثال : facebook.com/ahmedmassarwe </p>
                                                            </div>
                                                            <div className="col-md-6 ttm-box-col-wrapper">
                                                                <label>
                                                                    <input name="Instagram" type="text" placeholder="Instagram"
                                                                        onChange={e => (setInstagram(e.target.value))}
                                                                    />
                                                                </label>
                                                                <p className="example-cv"> مثال : instagram.com/ahmedmassarwe </p>
                                                            </div>
                                                            <div className="col-md-6 ttm-box-col-wrapper">
                                                                <label>
                                                                    <input name="Twitter" type="text" placeholder="Twitter"
                                                                        onChange={e => (setTwitter(e.target.value))}
                                                                    />
                                                                </label>
                                                                <p className="example-cv"> مثال : twitter.com/ahmedmassarwe </p>
                                                            </div>
                                                            <div className="col-md-12 ttm-box-col-wrapper">
                                                                <label>
                                                                    <input name="compSite" type="text" placeholder="الموقع الالكتروني"
                                                                        onChange={e => (setCsite(e.target.value))}
                                                                        value={compSite}
                                                                    />
                                                                </label>
                                                                <p className="example-cv"> مثال : portfolio.com/name  </p>
                                                            </div>
                                                            <div className="col-md-12 ttm-box-col-wrapper">
                                                                <label>
                                                                    <input name="compMap" type="text" placeholder="موقع الشركة - إذا كان لديك موقع على خرائط جوجل فالرجاء تضمينه هنا"
                                                                        value={compMap}
                                                                        onChange={e => (setCompMap(e.target.value))}
                                                                    />
                                                                </label>
                                                            </div>

                                                            <hr></hr>
                                                            <p className="example-cv " style={{ wordBreak: "break-all" }}>مثال -   https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26361414.263400003!2d-113.75849480805297!3d36.24080384347216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1578680778274!5m2!1sen!2sin</p>
                                                        </motion.div>
                                                    </TabPanel>}
                                                    <TabList>
                                                        <div className='row form-btn-tabs'>
                                                            {tabId == 0 &&
                                                                <div className='col-md-6'>
                                                                    <Tab >

                                                                        <Link >
                                                                            <motion.div onClick={() => { setprev(0); incrementCounter(); }}
                                                                            >
                                                                                <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                                                    variants={buttonVariants}
                                                                                    whileHover="hover"
                                                                                >
                                                                                    {/* <span > <AiOutlineArrowRight size={25} className='arrow-icon-left' /></span> */}
                                                                                    <span style={{ fontSize: "large" }}> التالي</span>
                                                                                    <span > <AiOutlineArrowRight size={25} className='arrow-icon-right' /></span>
                                                                                </motion.button>

                                                                            </motion.div>
                                                                        </Link>
                                                                    </Tab></div>}
              
                                                            {tabId == 1 &&
                                                                <div className='col-md-6'>
                                                                    <Tab onClick={() => { setprev(0); incrementCounter(); }}>

                                                                        <Link >
                                                                            <motion.div
                                                                            >
                                                                                <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                                                    variants={buttonVariants}
                                                                                    whileHover="hover"
                                                                                >
                                                                                    {/* <span > <AiOutlineArrowRight size={25} className='arrow-icon-left' /></span> */}
                                                                                    <span style={{ fontSize: "large" }}> التالي </span>
                                                                                    <span > <AiOutlineArrowRight size={25} className='arrow-icon-right' /></span>
                                                                                </motion.button>
                                                                            </motion.div>
                                                                        </Link>
                                                                    </Tab>  </div>}
                                                                    {tabId > 0 &&
                                                                <div className='col-md-6'>
                                                                    <Tab >

                                                                        <Link >
                                                                            <motion.div onClick={() => { decrementCounter(); setprev(1); }}
                                                                            >
                                                                                <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                                                    variants={buttonVariants}
                                                                                    whileHover="hover"
                                                                                >
                                                                                    <span > <AiOutlineArrowLeft size={25} className='arrow-icon-left' /></span>
                                                                                    <span style={{ fontSize: "large" }}> السابق  </span>
                                                                                    {/* <span > <AiOutlineArrowLeft size={25} className='arrow-icon-right' /></span> */}
                                                                                </motion.button>
                                                                            </motion.div>
                                                                        </Link>
                                                                    </Tab>
                                                                </div>}
                                                            <div className='col-md-6 btn-save' >
                                                                <motion.div >
                                                                    <motion.button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100 "
                                                                        variants={buttonVariants}
                                                                        whileHover="hover"
                                                                    >
                                                                        <span><BsSave size={22} style={{ marginRight: "10px" }} /></span>
                                                                        <span style={{ fontSize: "large" }}> {saving ? " جاري الحفظ ..." : " حفظ  "} </span>
                                                                    </motion.button>
                                                                </motion.div>
                                                            </div>
                                                            {tabId == 4 &&
                                                                isSaved ? <div className='col-md-6'>

                                                                <Tab onClick={showCv} >

                                                                    <Link >
                                                                        <motion.div
                                                                        >
                                                                            <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                                                variants={buttonVariants}
                                                                                whileHover="hover"

                                                                            >
                                                                                <span style={{ fontSize: "large" }}> View CV as PDF </span>
                                                                                <span > <AiOutlineArrowRight size={25} className='arrow-icon-right' /></span>
                                                                            </motion.button>
                                                                        </motion.div>
                                                                    </Link>


                                                                </Tab>
                                                            </div> : ""}
                                                        </div>

                                                    </TabList>

                                                </Tabs>
                                            </form>
                                        </div>
                                    </div>
                                </motion.div>}
                                {/* ==================================================View Job_PDF ===================================== */}
                         
                                {/* ==================================================View Company_Details ===================================== */}
                                {
                                    showComp &&
                                    <div className={show ? "col-lg-8 " : "col-lg-7"} 
                                    style={{backgroundColor:"#404966"}}
                                    >


                                        <div className="ttm-col-bgcolor-yes ttm-bg bg-theme-GreyColor  p-20 p-lg-30 mt-lg-30 mt-50 mb-50" ref={printRef} dir='ltr'>
                                            <div className="layer-content">
                                                <div className=" sidebar job-sidebar clearfix">
                                                    <div className="">
                                                        <div className="row">
                                                            <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
                                                                <div className="job_list-widget">
                                                                    <aside className="widget job-widget" dir='rtl' >
                                                                        <h3 className="widget-title"><i className="ti ti-files"></i>تفاصيل الشركة  : </h3>
                                                                        <ul>
                                                                            {compSite && <li className="d-flex"><b className="mr-5">الموقع الالكتروني : </b><a href={compSite} target='_blank'>قم بزيارة موقعنا</a></li>}

                                                                            {data.compCat && <li className="d-flex"><b className="mr-5">تصنيف الشركة : </b>{cats[data.compCat]}</li>}
                                                                            {data.compLocation && <li className="d-flex"><b className="mr-5">الموقع :</b>{data.compLocation}</li>}
                                                                            {data.dateOfBirth && <li className="d-flex"><b className="mr-5">تأسست عام :</b>{data.dateOfBirth}</li>}
                                                                            {/* <li className="d-flex"><b className="mr-5">Posted:</b>1 Week Ago</li> */}
                                                                            {data.numOfEmp && <li className="d-flex"><b className="mr-5">عدد الموظفين : </b>{data.numOfEmp}</li>}
                                                                        </ul>
                                                                    </aside>
                                                                    <aside className="widget location-widget p-0">
                                                                        {compMapsrc && <div className='' style={{ wordBreak: "break-all", height: "350", marginTop: "20px" }}>
                                                                            <div dangerouslySetInnerHTML={{ __html: compMapsrc }} ></div>
                                                                        </div>}
                                                                        <div className="p-20" dir='rtl' >
                                                                            <div>
                                                                                <span className="text-theme-SkinColor">
                                                                                    <i className="fa fa-map-marker-alt mr-10"></i></span>{data.cAddress ? data.cAddress : ""}
                                                                            </div>
                                                                            <div>
                                                                                <a href={data.email ? "mailto:" + data.email : "mailto:info@example.com"}><i className="fa fa-envelope mr-10"></i>{data.email ? data.email : "info@example.com"}</a>
                                                                            </div>
                                                                        </div>
                                                                    </aside>
                                                                </div>
                                                                <div className="overview-box">
                                                                    <div className="title" dir='rtl'>
                                                                        <div className="d-sm-flex justify-content-between align-items-center mb_10">
                                                                            <p className="mb-10 fw-bold">تواصل معنا  :</p>
                                                                            <div className="social-icons circle mb-10">
                                                                                <ul className="list-inline">
                                                                                    {facebook && <li><a href={facebook ? facebook : "#facebook"} target='_blank' rel="noopener" aria-label="facebook"><i className="ti ti-facebook"></i></a></li>}
                                                                                    {twitter && <li><a href={twitter ? twitter : "#twitter"} target='_blank' rel="noopener" aria-label="twitter"><i className="ti ti-twitter-alt"></i></a></li>}
                                                                                    {instagram && <li><a href={instagram ? instagram : "#instagram"} target='_blank' rel="noopener" aria-label="instagram"><i className="ti ti-instagram"></i></a></li>}
                                                                                    {link && <li><a href={link ? link : "#LinkedIn"} rel="noopener" target='_blank' aria-label="LinkedIn"><FaLinkedinIn /></a></li>}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="col-lg-8 content-area">
                                                                <div className="row">
                                                                    <div className="col-lg-12 col-md-12">
                                                                        {/* featured-imagebox */}
                                                                        <div className="featured-imagebox featured-imagebox-employer bg-theme-GreyColor m-0">
                                                                            {/* <div className="featured-thumbnail">
                                                                        <img src="https://via.placeholder.com/210x204?text=210x204+job-01.png" />
                                                                    </div> */}
                                                                            {images.length > 0 ?
                                                                                images.map((image, index) => (

                                                                                    <div className="featured-thumbnail" key={index}>
                                                                                        <img src={image.data_url ? image.data_url : document.profilePicture} alt="profile picture" width="100" height="100" />

                                                                                    </div>
                                                                                )) : <div className="featured-thumbnail">
                                                                                    <img src={profilePicture ? profilePicture : Profile} />
                                                                                    <p>{profilePicture}</p>
                                                                                </div>
                                                                            }
                                                                            <div className="featured-content text-right" dir='rtl'>
                                                                                <div className="featured-title">
                                                                                    <h3>{data.companyName ? data.companyName : "MassRes"}</h3>
                                                                                </div>
                                                                                <div className="featured-bottom">
                                                                                    <div className="job-meta">



                                                                                        <span><i className="fa fa-filter"> </i> {data.compCat ? cats[data.compCat] : "Automotive Jobs"}</span>
                                                                                        <span>  <i className="fa fa-map-marker-alt"></i> {data.compLocation ? data.compLocation : "Jerusalem"}</span>
                                                                                        <span><i className="fa fa-hand-holding-usd"></i> {data.payRange}</span>

                                                                                        {/* <span><i className="fa fa-map-marker-alt"></i>{data.cAddress}</span> */}
                                                                                        {/* <span>Skills :   HTML, CSS, JavaScript, Wordpress, PHP</span> */}
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>{/* featured-imagebox end */}
                                                                    </div>
                                                                </div>
                                                                <div className="row text-right" >
                                                                    <div className="col-lg-12 col-md-12">
                                                                        <div className="overview-box">
                                                                            <div className="title">
                                                                                <h5> {companyName} تفاصيل </h5>
                                                                            </div>
                                                                            <div>
                                                                                {convertedContentabc &&
                                                                                    <div className="desc">
                                                                                        <p dangerouslySetInnerHTML={createMarkup(convertedContentabc)}></p>
                                                                                    </div>
                                                                                }

                                                                            </div>
                                                                            {!convertedContentabc && <div className="desc">
                                                                                <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                                    labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                                                                                    maecenas accumsan lacus vel facilisis. ”</p>
                                                                                <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                                    labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                                                                                    maecenas accumsan lacus vel facilisis. ”</p>
                                                                            </div>}
                                                                        </div>
                                                                        <div className="overview-box">
                                                                            <div className="title" dir='rtl'>
                                                                                <h5  >ماذا تقدم  {companyName} </h5>

                                                                            </div>
                                                                            <div>
                                                                                {convertedContentwyp &&
                                                                                    <div className="desc">
                                                                                        <p dangerouslySetInnerHTML={createMarkup(convertedContentwyp)}></p>
                                                                                    </div>
                                                                                }

                                                                            </div>
                                                                            {!convertedContentwyp &&
                                                                                <div className="desc">
                                                                                    <ul className="ttm-list ttm-list-style-disc ttm-list-icon-color-skincolor">
                                                                                        <li>
                                                                                            <div className="ttm-list-li-content">Extra Private health insurance coverage by the company to help you getting better focus.</div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="ttm-list-li-content">13th salary and project bonus</div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="ttm-list-li-content">Company trip, team-building events</div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="ttm-list-li-content">Flexible working time schedule and leave policy as we know the importance of work-life balance</div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="ttm-list-li-content">CBe exposed to the latest technology and the most innovative ideas worldwide</div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="ttm-list-li-content">Support laptop Extra healthcare package</div>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}



                            </div></div>
                    </section>


                </> : goHome}  </div>
    )
}



