// import firebase from 'firebase/app'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import "firebase/storage"
import "firebase/analytics";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBC64dNXcmxOVsQSFDOI6fJSTqr1wBIH6Q",
  authDomain: "mass-res.firebaseapp.com",
  projectId: "mass-res",
  storageBucket: "mass-res.appspot.com",
  messagingSenderId: "390763730149",
  appId: "1:390763730149:web:930fd73db8c73a9c50b00c",
  measurementId: "G-54WQ7CHB5V"
};

// Init MassPortal firebase
firebase.initializeApp(firebaseConfig)
  
// init Mass  services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const auth = firebase.auth()
const projectStorage = firebase.storage()
// Initialize Analytics and get a reference to the service
const analytics = firebase.analytics();
// timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectAuth, auth,timestamp, projectStorage}