// https://stackoverflow.com/questions/70885906/how-to-fetch-data-from-all-documents-in-a-subcollection-in-firebase-firestore-us
import React, { Component, useEffect, useState } from 'react';
import '../firebase/config';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import ReactPaginate from 'react-paginate';
import Footer from '../components/layout/Footer';
import { projectFirestore } from '../firebase/config';
import avatar from '../assets/img/massavatar/massres-bus.png'
import { timestamp } from '../firebase/config';
import { BsCheck2All } from 'react-icons/bs';
import {GrNext, GrPrevious} from 'react-icons/gr'
let collectionRef = projectFirestore.collection('employers')


export default function Job_list(props) {

    const [companies, setCompanies] = useState([])
    const [attendeeInfo, setAttendeeInfo] = useState(null); // <-- should be an array not an object?
    const [events, setEvents] = useState(null);
    const loading = events === null || attendeeInfo === null;
    const jobTypes = ["", "دوام كامل ", "دوام كامل - عاجل ", "دوام جزئي ", "دوام جزئي - عاجل ", "عبر الانترنت ", "عبر الانترنت - عاجل "]
    const cats = ["", "  محاسبة و مالية ", "تكنولوجيا  ", " زراعة ", " بناء ", " وظائف طبية ", " انتاج ", " نقل  ", " Digital Design & Social Media "]
    const locations=["","القدس","عمان","القاهرة","دمشق","بيروت","الجزائر","","","","" ]


    // Pagination and Filtering
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageData, setcpData] = useState([])
    const PER_PAGE = 10;
    const offset = currentPage * PER_PAGE;
    const [pageCount, setpCount] = useState()
    const [isChecked, setChecked] = useState(false)

    const [category, setCategory] = useState("")
    const [filterDate, setFilterDate] = useState("")

    const ScrollToTop = () => window.scrollTo({ top: 40, behavior: "smooth" });

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
        ScrollToTop()
    }
    // End Date Handler
    const handleCheckChange = () => {
        setChecked(!isChecked);
    };
 useEffect(()=>{
    const categoryNumber = props.match.params.cat;
    setCategory(categoryNumber)
    // console.log(props)
    // console.log("props are: ", categoryNumber)
 },[])
    const getEventsData = async () => {

        const eventsQuerySnapshot = await collectionRef.orderBy("createdAt", "desc").get()
        let eventsInfo = [];
        let companiesInfo = [];
        // console.log("eventsQuerySnapshot",eventsQuerySnapshot)
        eventsQuerySnapshot.forEach((doc) => {
            const mypic = doc.data().profilePicture
            const myMap = doc.data().compMapsrc
            const fb = doc.data().facebook
            const inst = doc.data().instagram
            const link = doc.data().link
            const twit = doc.data().twitter
            const wbst = doc.data().compSite
            const cname = doc.data().companyName
            const companyId = doc.id
            // console.log("TEST", companyId)

            // eventsInfo.push({ ...doc.data(), companyPic: mypic, id: doc.id });
            eventsInfo.push({ id: doc.id, companyPic: mypic, myMap, fb, cname, companyId });
            companiesInfo.push({ ...doc.data(), companyPic: mypic, id: doc.id, companyId })
            // console.log("compID",doc.id)

        })

        setEvents(eventsInfo);
        setCompanies(companiesInfo)
        // setOrg(orgSnap.data().orgName);
    }

    const getAttendeesData = async (events) => {
        // console.log("events",events);

        const fetchAttendeesPromises = events.map(async (event) => {
            // console.log("event",event);
            const jobsRef = projectFirestore.collection('employers').doc(event.id).collection('jobs')
            // const attendeesRef = projectFirestore.collection('employers',event,"jobs");
            // const attendeesRef = projectFirestore.collection(db, "events", event, "attendees");
            const attendeesSnap = await jobsRef.get();
            const attendeeInformation = [];

            attendeesSnap.forEach((doc) => {
                attendeeInformation.push({ ...doc.data(), id: doc.id, companyPic: event.companyPic, cName: event.cname, companyId: event.companyId })
                // console.log("Data",doc.data())
            })
            return attendeeInformation;
        })
        const attendeesForAllEvents = await Promise.all(fetchAttendeesPromises)
            .then(attendeeGroups => attendeeGroups.flat())
        setAttendeeInfo(attendeesForAllEvents);
        // console.log(attendeesForAllEvents)
    }



    useEffect(() => {
        getEventsData();
    }, []);

    useEffect(() => {
        if (events !== null) // only call when data available
            getAttendeesData(events);
        //   console.log("companies",companies)
    }, [events, companies]);


    useEffect(() => {
        if (attendeeInfo) {
            setcpData(attendeeInfo
                .slice(offset, offset + PER_PAGE))
            setpCount(Math.ceil(attendeeInfo.length / PER_PAGE));
        }
    }, [attendeeInfo, currentPage])

    useEffect(() => {
        if (isChecked) {
          
            window.location.href='/job_list'

        }

        else
            if (!filterDate && category && attendeeInfo ) {
                setcpData(attendeeInfo)
                console.log("filter by category :",  category)
                console.log(attendeeInfo.filter((row) => row.jobCategory == category))

                setcpData(attendeeInfo.filter((row) => row.jobCategory == category))
            } else
                if (filterDate && category) {
                    setcpData(attendeeInfo)
                    // console.log("filter by date and cat :",  category , filterDate)

                    setcpData(attendeeInfo.filter((row) => row.jobCategory == category && flt(filterDate, row.createdAt)))
                } else
                    if (filterDate && !category) {
                        setcpData(attendeeInfo)
                        // console.log("filter by date :",  filterDate)

                        setcpData(attendeeInfo.filter((row) => flt(filterDate, row.createdAt)))
                    } else
                        if (filterDate) {
                            setcpData(attendeeInfo)
                            //  console.log("=filter by Date= :", filterDate)
                            setcpData(attendeeInfo.filter((row) => flt(filterDate, row.createdAt)))
                        }

        function flt(filterDate, dbDate) {
            const dateFilter = parseFloat(filterDate)
            const today = timestamp.fromDate(new Date())
            const time = new Date(today.seconds * 1000);
            const cretedDate = dbDate
            const timedb = new Date(cretedDate.seconds * 1000);
            const last7Days = new Date((time - timedb))
            const thisDatr = new Date(time - dateFilter)
            // console.log(((timedb < time) && (timedb > thisDatr)))
            return ((timedb < time) && (timedb > thisDatr))
        }

    }, [category, filterDate, isChecked, attendeeInfo])

    
    function fltt(dbDate) {
        const timedb = new Date(dbDate.seconds * 1000);
        const today = timestamp.fromDate(new Date())
        const time = new Date(today.seconds * 1000);
        const thisDatr = new Date(time - timedb)
        var difference = time.getTime() - timedb.getTime();
        var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24)
        // console.log("diff:", daysDifference)


        return (daysDifference)
    }
    // hide component until ready
    // consider rendering a spinner/throbber here while loading
    if (loading)
        return null;



    return (

        <div className="site-main">
            <Header />

            {/* PageHeader */}
            <PageHeader
                title="قائمة الوظائف  "
                breadcrumb="وظائف "
                className="pb-65 pb-lg-0"
            />
            {/* PageHeader end */}


            {/* form */}
            {/* <div className="form-section clearfix">
                <div className="container">
                    <div className="row" >
                        <div className="col-lg-12" >

                            <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg border rounded p-40 p-lg-20 mt_70 mt-lg-50">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                <div className="layer-content">
                                    <form id="search_Form" className="search_Form wrap-form" method="post" action="#" data-mailchimp="true">
                                        <label>
                                            <i className="ti ti-search"></i>
                                            <input type="text" id="filter" placeholder="Job Title or Keywords" />
                                        </label>
                                        <label>
                                            <i className="ti ti-location-pin"></i>
                                            <input type="email" name="EMAIL" id="txtemail" placeholder="location" />
                                        </label>
                                        <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor" type="submit">Find Jobs</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* form end */}


            <div className="ttm-row sidebar job-sidebar clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
                            <div className="job_list-widget" dir='rtl'>
                                <aside className="widget job-widget">
                                    <h3 className="widget-title">
                                        {/* <i className="flaticon flaticon-gender"></i> */}
                                        <BsCheck2All className='widget-icon' size={25}  />
                                        الكل </h3>
                                    <form id="list4" className="list-filter">
                                        <div  >
                                            <label className="radio">
                                                <input type="radio" value="all" name="all"
                                                    checked={isChecked}
                                                    onChange={handleCheckChange}

                                                /> الكل
                                            </label>
                                            {/* <input className="w-auto mr-10 mt-5"
                                                id="cookies-consent"
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={handleCheckChange}

                                            /> */}

                                        </div>
                                    </form>
                                </aside>
                                <aside className="widget job-widget">
                                    <h3 className="widget-title"><i className="flaticon flaticon-calendar-1"></i>تاريخ الاضافة </h3>
                                    <form id="list1" className="list-filter">
                                        <div  >
                                            <label className="radio">
                                                <input type="radio" value="86400000" name="post_date"
                                                    onChange={e => (setFilterDate(e.target.value))}
                                                /> اليوم
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="604800000" name="post_date"
                                                    onChange={e => (setFilterDate(e.target.value))}
                                                /> آخر 7 ايام
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="1209600000" name="post_date"
                                                    onChange={e => (setFilterDate(e.target.value))}
                                                /> آخر 14 يومًا
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="1814400000" name="post_date"
                                                    onChange={e => (setFilterDate(e.target.value))}
                                                />آخر 21 يومًا
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="2592000000" name="post_date"
                                                    onChange={e => (setFilterDate(e.target.value))}
                                                /> آخر 30 يومًا
                                            </label>
                                        </div>
                                    </form>
                                </aside>
                                <aside className="widget job-widget">
                                    <h3 className="widget-title"><i className="flaticon flaticon-subfolder-1"></i>فئات</h3>
                                    <form id="list2" className="list-filter">
                                        <div  >
                                            <label className="radio">
                                                <input type="radio" value="1" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                />  محاسبة و مالية
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="2" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> تكنولوجيا
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="3" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> زراعة
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="4" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> بناء
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="5" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> وظائف طبية
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="6" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> انتاج
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="7" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> نقل
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="8" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> Digital Design & Social Media
                                            </label>
                                        </div>
                                    </form>
                                </aside>
                            </div>
                            <aside className="widget widget-banner text-theme-WhiteColor">
                                <div className="ttm-col-bgcolor-yes bg-theme-DarkColor text-theme-WhitColor col-bg-img-seven ttm-col-bgimage-yes ttm-bg p-40">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor" style={{ backgroundImage: 'url(/images/pics/col-bgimage-7.jpg)' }}>
                                        <div className="ttm-col-wrapper-bg-layer-inner bg-theme-DarkColor"></div>
                                    </div>
                                    <div className="layer-content text-center" >
                                        <div className="widget-banner-inner">
                                            <h3 className="mb-15">Make a Difference with Online Resume!</h3>
                                            <p className="mb-30" dir='rtl'>حضر سيرتك الذاتية في دقائق بمساعدة MassRes !</p>
                                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                // href={process.env.PUBLIC_URL + '/'}>appoinments!</a>
                                                href={process.env.PUBLIC_URL + '/start_cv'}>Create Your CV !</a>

                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>

                        <div className="col-lg-8 content-area">
                            <div className="row" dir='rtl'>
                                <div className="col-md-12">
                                    <div className="showing-result-block d-sm-flex align-items-center justify-content-between">
                                        <span className="showing-result">عرض  1–10   من  {attendeeInfo.length} {cats[category]? "وظائف  ال"+ cats[category].trim():"وظائف   "}  :</span>
                                        {/* <span className="showing-result">Showing 1–10 of  {attendeeInfo.length} Jobs Results :</span> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row" dir='rtl'>
                                {currentPageData &&
                                    currentPageData.map((job, index) => (
                                        <div className="col-lg-12 col-md-12" key={index}>
                                            {/* featured-imagebox */}
                                            <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                                                <div className="featured-thumbnail">
                                                    <a href={process.env.PUBLIC_URL + '/Job_details/' + job.id}>   <img src={job.companyPic ? job.companyPic : avatar} /></a>
                                                    {job.jobType == '6' && <div className="required-tag">عاجل</div>}
                                                    {job.jobType == '2' && <div className="required-tag">عاجل</div>}
                                                    {job.jobType == '4' && <div className="required-tag">عاجل</div>}
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title title-white">
                                                        <h3><a href={process.env.PUBLIC_URL + '/Job_details/' + job.id}>{job.jobName}</a></h3>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>
                                                            الشركة  :   <a href={process.env.PUBLIC_URL + '/employers_details/' + job.companyId}>  <span style={{ fontWeight: "bold" }} > {job.cName} </span> </a>
                                                        </p>
                                            <p style={{ marginRight: "12px" }} >تم النشر  {fltt(job.createdAt) > 0 ? " قبل " + fltt(job.createdAt) + " أيام " : " اليوم "}</p>

                                                    </div>
                                                    <div className="featured-bottom">
                                                        <div className="job-meta">
                                                            <span><i className="fa fa-map-marker-alt"></i>{locations[job.jobLocation]}</span>
                                                            <span><i className="fa fa-filter"></i>{cats[job.jobCategory]}</span>
                                                        </div>
                                                        <div className="job-time">
                                                            {job.jobType && <span className={"green" + job.jobType}> {jobTypes[job.jobType]}</span>}

                                                            {/* <span className="green">full time</span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>{/* featured-imagebox end */}
                                        </div>
                                    ))
                                }






                                <div className="col-lg-12 col-md-12">
                                    {/* featured-imagebox */}
                                    {/* <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                                        <div className="featured-thumbnail">
                                            <img src="https://via.placeholder.com/210x204?text=210x204+job-04.png" />
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3><a href={process.env.PUBLIC_URL + '/Job_details'}><a href={process.env.PUBLIC_URL + '/Job_details'}>Opening For The Content Creator</a></a></h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>Published 2Days Ago.</p>
                                            </div>
                                            <div className="featured-bottom">
                                                <div className="job-meta">
                                                    <span><i className="fa fa-map-marker-alt"></i>California</span>
                                                    <span><i className="fa fa-filter"></i>Automotive Jobs</span>
                                                </div>
                                                <div className="job-time">
                                                    <span className="green">full time</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* featured-imagebox end */}
                                </div>
                                <div className="col-lg-12">
                                    <div className="job-pagination-block" dir='ltr'>
                                        <ReactPaginate

                                            previousLabel={ <GrPrevious /> }
                                            nextLabel={ <GrNext /> }
                                            pageCount={pageCount}
                                            pageLinkClassName={"page-nav-link "}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination  "}
                                            previousLinkClassName={"page-nav-link no"}
                                            nextLinkClassName={"page-nav-link no"}
                                            linkClassName={"page-nav-link current"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={""}
                                            activeLinkClassName={"current"}
                                        />
                                    </div>
                                    {/* <div className="job-pagination-block">
                                        <a className="page-nav-link">prev</a>
                                        <a className="page-nav-link current">1</a>
                                        <a className="page-nav-link" href="#">2</a>
                                        <a className="page-nav-link" href="#">3</a>
                                        <a className="page-nav-link" href="#">....</a>
                                        <a className="page-nav-link">next</a>
                                    </div> */}
                                </div>
                            </div>{/* row end */}
                        </div>
                    </div>{/* row end */}
                </div>
            </div>


            {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between" dir='rtl'>
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment-5"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>هناك دائما إمكانية الحصول على وظيفة</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>اتخذ خطوتك الأولى و أنشئ سيرتك الذاتية </p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                                    href={process.env.PUBLIC_URL + '/start_cv'}>Create CV!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}


            <Footer />

        </div>
    )
}
