import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import Footer from '../components/layout/Footer';

export class Privacy extends Component {
    render() {
        return (

            <div className="site-main">
                <Header />

                {/* PageHeader */}
                <PageHeader
                    title="سياسة الخصوصية  "
                    breadcrumb="سياسة الخصوصية  "
                />
                {/* PageHeader end */}


                <div className="ttm-row sidebar ttm-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12 content-area" style={{ margin: "auto", textAlign: 'right' }}>
                                {/* post */}
                                <article className="post ttm-blog-single clearfix">

                                    <div className="ttm-blog-single-content">

                                        <div className="entry-content">
                                            <h2 dir='rtl' style={{ textAlign: "center" }}>سياسة الخصوصية  <span className='text-theme-SkinColor'>( MassRes )</span></h2>

                                            <div className="ttm-box-desc-text" dir='rtl'>

                                                <p style={{ color: "black" }}>
                                                    مرحباً بكم في موقع ماسريس الالكتروني (الموقع). تقوم ماسريس  ("ماسريس" / و "نحن" و / أو "لنا" و / أو "خاصتنا") بتشغيل هذا الموقع لتزويد مستخدمينا ("أنت" و "الخاص بك") بمعلومات حول ماسريس ومنصتها.
                                                </p>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    {/* <img className="img-fluid alignleft" src="https://via.placeholder.com/332x324?text=single-img-07-332x324.jpg" alt="single-07" /> */}
                                                    <div className="pt-10 pb-15">
                                                        <h3>MassRes </h3>

                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                يتم استخدام ماسريس من قبل المستقلين المحترفين ("المستقلين") الذين يرغبون في تقديم خدمات التطوير والهندسة وغيرها من الخدمات ("الخدمات") والعملاء الذين يحتاجون إلى الخدمات ("العملاء"). يُشار إلى العاملين لحسابهم الخاص الذين اجتازوا عملية الفرز لدينا وتم قبولهم في شبكتنا من المحترفين المستقلين ("الشبكة") باسم "المواهب". لأغراض هذه السياسة ، فإن مصطلح "المستقلون" أوسع نطاقاً ويتضمن كلاً من المواهب والعاملين لحسابهم الخاص الذين ليسوا موهوبين. تختلف البيانات الشخصية التي نتلقاها من العاملين لحسابهم الخاص والعملاء وكيف نتعامل معها ، لذلك نوضح ممارسات البيانات الخاصة بنا للعاملين لحسابهم الخاص والعملاء في قسمين مختلفين أدناه. انقر فوق الذي ينطبق عليك: المستقلين ؛ العملاء.

                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    {/* <img className="img-fluid alignleft" src="https://via.placeholder.com/332x324?text=single-img-07-332x324.jpg" alt="single-07" /> */}
                                                    <div className="pt-10 pb-15">
                                                        <h3>نطاق </h3>

                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                تحدد سياسة الخصوصية هذه كيفية قيام ماسريس بجمع المعلومات والاحتفاظ بها واستخدامها ، بما في ذلك البيانات الشخصية ("البيانات الشخصية") ، حول العاملين لحسابهم الخاص والعملاء وزوار الموقع الآخرين والأفراد ، بما في ذلك البائعين والشركاء وقرّاء المدونات والمتقدمين للوظائف ، مثل بالإضافة إلى مستخدمي عروض برامج ماسريس مثل أداة مشاركة الصور وتحريرها.

                                                            </div></li>
                                                            <li><i className="fas fa-long-arrow-alt-right"></i><div className="ttm-list-li-content">
                                                                تغطي سياسة الخصوصية هذه أيضاً البيانات التي تجمعها ماسريس شخصياً ، على سبيل المثال في المؤتمرات التجارية والمعارض التجارية.

                                                            </div></li>
                                                            {/* <li><i className="fas fa-long-arrow-alt-right"></i><div className="ttm-list-li-content">العاملون لحسابهم الخاص</div></li> */}
                                                            {/* <li><i className="fas fa-long-arrow-alt-right"></i><div className="ttm-list-li-content"></div></li> */}
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className=''>
                                                    <h2 style={{ textAlign: "center", textDecoration: "underline", textUnderlineOffset: "13px" }}>
                                                        العاملون لحسابهم  <span className='text-theme-SkinColor'> الخاص </span>
                                                    </h2>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    {/* <img className="img-fluid alignleft" src="https://via.placeholder.com/332x324?text=single-img-07-332x324.jpg" alt="single-07" /> */}
                                                    <div className="pt-10 pb-15">
                                                        <h3>المعلومات التي نجمعها </h3>

                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                المعلومات التي تقدمها لنا
                                                            </div></li>

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                عندما تتقدم بطلب للعمل كمستقل على الموقع ، فأنت تطلب أن يتم قبولك في شبكتنا. من أجل قبولك في الشبكة ومطابقة مؤهلاتك مع احتياجات عملائنا ، نحتاج إلى إجراء عملية فحص لتقييم مهاراتك اللغوية وشخصيتك ومعرفتك الخاصة بالمجال وكفاءتك واحترافك. قد نحتاج أيضاً إلى فحص المترجمين المستقلين المحتملين لمهارات فنية محددة ، وفقاً لما يطلبه عملاؤنا. في هذا السياق ، سيُطلب منك تقديم معلومات عن نفسك ، والتي تشمل:

                                                            </div></li>

                                                        </ul>
                                                        <ol className="ttm-list-icon-color-skincolor" style={{ marginRight: "20px" }}>
                                                            <li>اسمك، معلومات التواصل، العنوان، موقعك</li>
                                                            <li>خبرة في العمل، التعليم.</li>
                                                            <li>صورة فوتوغرافية، هوية صادرة عن الحكومة.</li>
                                                            <li>معلومات الشبكة الاجتماعية، معلومات العائلة، معلومات الفواتير.</li>
                                                            <li>التحقق من هويتك وبياناتك البيومترية</li>
                                                        </ol>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                إذا طلبت أن يتم قبولك في شبكتنا ، بموافقتك المستنيرة الخطية الصريحة (والتي قد تقدمها في وقت تقديم طلب للقبول) ، فقد نجمع معلومات بيومترية عنك للتحقق من هويتك وتأكيدها وكما هو موضح بالتفصيل أقل. قد نطلب منك التقاط صورة لك ولتحديد هويتك الصادرة عن الحكومة من خلال هاتفك المحمول ("بيانات التحقق").
                                                            </div></li>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                سوف نستخدم تقنية التعرف على الوجه لتحليل هندسة وجهك من خلال الصور واستخراج المعلومات البيومترية أو المعرفات البيومترية المتعلقة بك (إجمالاً ، "البيانات البيومترية"). سيتم استخدام بيانات التحقق والبيانات البيومترية للتحقق من هويتك وتأكيدها. ومع ذلك ، لن تتلقى ماسريس البيانات البيومترية ولكنها ستتلقى فقط الدرجات التي تشير إلى صحة الهوية الصادرة عن الحكومة ، وما إذا كان قد تم التحقق من هويتك وتأكيدها ، واسمك من هويتك الصادرة عن الحكومة.
                                                            </div></li>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                الموافقة على جمع ومعالجة بيانات القياسات الحيوية الخاصة بك وبيانات التحقق الخاصة بك كما هو موضح في سياسة الخصوصية هذه طوعية ، وإذا لم توافق على جمع بياناتك البيومترية وبيانات التحقق ، فسنسمح لك بتزويدنا ببياناتك الصادرة عن الحكومة. تحديد هويتك وتأكيدها عبر مؤتمر الفيديو ، وقد يطلب منك تزويدنا بصورة شخصية. سوف تحتفظ ماسريس صورتك الذاتية واسمك لأغراض منع الاحتيال بناءً على مصالحنا المشروعة. إذا وافقت على جمع بيانات القياسات الحيوية وبيانات التحقق الخاصة بك ، ولكنك غيرت رأيك فيما بعد ، فيحق لك سحب موافقتك عن طريق . <span> <a href='mailto:massres.dev@gmail.com'>  privacy@MassRes.com </a></span> الاتصال بنا على
                                                            </div></li>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                إذا سحبت موافقتك ، فسنطبق تفضيلاتك من الآن فصاعداً ولن يؤثر ذلك على قانونية معالجة بياناتك البيومترية أو بيانات التحقق قبل التاريخ الفعلي لسحب موافقتك. قد تتم مشاركة بيانات المقاييس الحيوية وبيانات التحقق الخاصة بك مع مزودي الخدمة الخارجيين الذين يقدمون خدمات الدعم فيما يتعلق بتكنولوجيا القياسات الحيوية التي تنفذها شركة مايتيك والخدمات التي تقدمها. لمزيد من المعلومات حول كيفية تعامل مايتيك مع بيانات التحقق وبيانات المقاييس الحيوية ، يرجى مراجعة موقع مايتيك وسياسة الخصوصية. علاوة على ذلك ، قد تتم مشاركة صورتك واسمك مع مزودي الخدمة من الأطراف الثالثة الذين يساعدوننا في برامج تكنولوجيا المعلومات والأمن والاحتيال لدينا ، ومستشارينا المحترفين ، ووفقاً لما يقتضيه القانون أو اللوائح.

                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>فحص الخلفية </h3>

                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">


                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                قد يقوم مزود خدمات التحقق من الخلفية لدينا بجمع بيانات إضافية عنك (بما في ذلك معلومات حول الإدانات أو الجرائم الجنائية) إذا وعندما نجري فحصاً / فحوصات خلفية. إذا كان ذلك ينطبق عليك ، فسيتم التماس موافقتك على جمع هذه البيانات الإضافية في ذلك الوقت ، وفقاً للقوانين المعمول بها.

                                                            </div></li>

                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>المعلومات التي تشاركها </h3>

                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">


                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                عندما تشارك في مجتمع خبراء ماسريس على موقعنا ، سنجمع المعلومات التي تختار مشاركتها في هذا السياق.

                                                            </div></li>

                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>إنشاء الملف الشخصي </h3>

                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                سنستخدم بعض بياناتك الشخصية لتمكينك من إنشاء ملف تعريف ليتم عرضه داخل الخدمات ؛ هذا الملف الشخصي قابل للبحث ويمكن أن يشاهده العملاء الذين يبحثون عن مترجمين مستقلين.

                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>ملاحظات العميل </h3>

                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                بمجرد قبولك في الشبكة ومطابقتك مع عميل ، سوف نتلقى من عملائنا معلومات تتعلق بأداء خدماتك لذلك العميل ، حسب الضرورة للحفاظ على معايير النزاهة والتميز في شبكتنا.
                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3> المعلومات التي تم الحصول عليها من أطراف ثالثة </h3>

                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                نجمع معلومات عنك نتلقاها من الشركات التابعة لنا ، للحصول على معلومات تتعلق بنطاق البيانات التي تم جمعها. تنطبق عمليات الكشف والممارسات الواردة في سياسة الخصوصية هذه على تلك البيانات التي نتلقاها من أطراف ثالثة.
                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>البيانات التي يتم جمعها تلقائيًا </h3>

                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                عندما تتفاعل مع ماسريس من خلال الموقع أو الخدمات ، نقوم بجمع معلومات معينة تلقائياً. يرجى قراءة قسم "زوار الموقع" أدناه لمزيد من المعلومات.

                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>كيف نستخدم المعلومات </h3>
                                                        <ol className="ttm-list-icon-color-skincolor" >

                                                            <li style={{ fontWeight: "bold" }}> لتقديم الخدمة والاستجابة للطلبات :   </li>
                                                            <p>تستخدم ماسريس البيانات الشخصية التي تقدمها بطريقة تتوافق مع سياسة الخصوصية هذه. إذا قدمت بيانات شخصية لسبب معين ، فسنستخدم البيانات الشخصية فيما يتعلق بالسبب الذي تم توفيرها من أجله.</p>
                                                            <p>على وجه الخصوص ، نستخدم البيانات الشخصية التي تقدمها لنا (الموضحة أعلاه) من أجل معالجة طلبك لتصبح فريلانسر وإدارة عقدنا معك بمجرد أن تصبح عضوًا في الشبكة. بالنسبة للأفراد في الاتحاد الأوروبي والمملكة المتحدة ، يعد استخدامنا للبيانات الشخصية التي تزودنا بها ضروريًا من أجل تنفيذ طلبك لتصبح فريلانسر قبل الدخول في عقد معنا ، وبمجرد قبولك في شبكتنا ، تنفيذ عقدنا معك.</p>
                                                            <li > <p style={{ fontWeight: "bold" }}>
                                                                إذا طلبت منا حذف بياناتك ونحن مطالبون بتنفيذ طلبك ، للاحتفاظ بالبيانات الأساسية لتحديد هويتك ومنع المزيد من المعالجة غير المرغوب فيها.

                                                            </p> </li>
                                                            <li > <p style={{ fontWeight: "bold" }}>ضروري لمصالح مشروعة معينة :   </p>
                                                                <ol>
                                                                    <li>لإرسال معلومات إدارية إليك ، على سبيل المثال ، المعلومات المتعلقة بالموقع والتغييرات التي تطرأ على الشروط والأحكام والسياسات الخاصة بنا.</li>
                                                                    <li>لإجراء تحليلات حول كيفية استخدامك للموقع وخدمتنا لأغراضنا الداخلية ، أي توفير عروضنا وصيانتها وقياسها وتحسينها وتحديد اتجاهات الاستخدام.</li>
                                                                    <li>نحن نستخدم البيانات المتعلقة باستخدامك وتفاعلك مع الموقع والخدمات ، بما في ذلك استخدامك لميزات المنتج والمعلومات التي نحصل عليها من خلال ملفات تعريف الارتباط والتقنيات الأخرى ، لفهم احتياجاتك واهتماماتك بشكل أفضل من أجل تخصيص تجربتك مع خدماتنا.</li>
                                                                    <li>لتسويق خدماتنا وعرض المستقلين (على وجه الخصوص ، يمكننا عرض ملفات تعريف معينة للعاملين لحسابهم الخاص لزيادة عدد الزيارات من العملاء المحتملين).</li>
                                                                    <li>لمنع الاحتيال أو النشاط الإجرامي ، وإساءة استخدام منتجاتنا أو خدماتنا ، وضمان أمن أنظمة تكنولوجيا المعلومات لدينا ، والهندسة المعمارية والشبكات.</li>
                                                                    <li>الامتثال للالتزامات القانونية والإجراءات القانونية </li>
                                                                    <li>الاستجابة للطلبات الواردة من السلطات العامة والحكومية بما في ذلك السلطات العامة والحكومية خارج بلد إقامتك </li>
                                                                    <li>تطبيق الشروط والأحكام الخاصة بنا </li>
                                                                    <li> حماية عملياتنا  </li>
                                                                    <li>حماية حقوقنا وخصوصيتنا وسلامتنا أو ممتلكاتنا و / أو حقوقك أو حقوق الآخرين  </li>
                                                                    <li>السماح لنا بمتابعة سبل الانتصاف المتاحة أو الحد من الأضرار التي قد نتعرض لها.</li>
                                                                </ol>
                                                            </li>
                                                            <li> <p style={{ fontWeight: "bold" }}> بموافقتك المستنيرة والتي أعطيت بحرية </p> </li>
                                                            <p>إذا شاركت في مؤتمر عبر الفيديو ، بموافقتك المسبقة ، فقد يتم تسجيل المؤتمر ونسخه. الموافقة طوعية ، ويمكنك سحب موافقتك في أي وقت. قد تتضمن المعلومات التي تم جمعها صوتك وصورتك ورسائل الاجتماع وأي بيانات شخصية أخرى تختار مشاركتها. بالإضافة إلى ذلك ، قد يتمكن المشاركون في مؤتمر الفيديو من عرض اسم العرض وعنوان البريد الإلكتروني وصورة ملف التعريف. باستخدام خدمات المؤتمرات التي تقدمها شركة زووم للاتصالات المرئية. (زووم)، قد تقوم زووم بجمع واستخدام ومشاركة البيانات الشخصية الإضافية المتعلقة بك ، والتي سيتم استخدامها وفقًا لبيان خصوصية زووم.  </p>

                                                        </ol>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <h2 style={{ textAlign: "center", textDecoration: "underline", textUnderlineOffset: "13px" }}>
                                                        <span className='text-theme-SkinColor'> العملاء </span>
                                                    </h2>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>المعلومات التي نجمعها </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                المعلومات التي تزودنا بها
                                                            </div></li>
                                                            <p style={{ marginRight: "30px" }}>نقوم بجمع البيانات الشخصية من عملائنا المحتملين عندما نتلقى طلبًا لتوظيف العاملين لحسابهم الخاص. يتضمن ذلك الأسماء ومعلومات الاتصال لممثلي العميل المحتملين ، وأي معلومات تتعلق باحتياجات العميل.</p>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                المعلومات التي تم الحصول عليها من أطراف ثالثة
                                                            </div></li>
                                                            <p style={{ marginRight: "30px" }}>قد نكمل المعلومات التي جمعناها منك (مثل عنوان بريدك الإلكتروني) بمعلومات إضافية عنك وعن شركتك تم الحصول عليها من قواعد البيانات أو الخدمات المتاحة للجمهور والجهات الخارجية التي توفر معلومات حول رجال الأعمال والشركات (بما في ذلك اسم الفرد ، المسمى الوظيفي ومعلومات الاتصال التجارية ومعلومات الشركة).</p>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                البيانات التي يتم جمعها تلقائياً
                                                            </div></li>
                                                            <p style={{ marginRight: "30px" }}>عندما تتفاعل مع ماسريس من خلال الموقع أو الخدمات ، نقوم تلقائياً بجمع معلومات معينة. يرجى قراءة قسم "زوار الموقع" أدناه لمزيد من المعلومات.</p>

                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>كيف نستخدم المعلومات </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                لتقديم الخدمات والرد على الطلبات
                                                            </div>
                                                            </li>
                                                            <p style={{ marginRight: "30px" }}>
                                                                تستخدم ماسريس البيانات الشخصية التي تقدمها بطريقة تتوافق مع سياسة الخصوصية هذه. إذا قدمت بيانات شخصية لسبب معين ، فقد نستخدم البيانات الشخصية فيما يتعلق بالسبب الذي تم توفيرها من أجله. على سبيل المثال ، إذا اتصلت بنا عبر البريد الإلكتروني ، فسنستخدم البيانات الشخصية التي تقدمها للإجابة على سؤالك أو حل مشكلتك. أيضاً ، إذا قدمت بيانات شخصية من أجل الوصول إلى الخدمات ، فسنستخدم بياناتك الشخصية لتزويدك بإمكانية الوصول إلى هذه الخدمات ، والحفاظ على حسابك ، والاتصال بك فيما يتعلق باستخدامك للخدمات و / أو الموقع أو إلى إخطارك بالتغييرات المهمة على الخدمات و / أو الموقع ، ومراقبة استخدامك لهذه الخدمات.
                                                            </p>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                لأغراض التسويق
                                                            </div>
                                                            </li>
                                                            <p style={{ marginRight: "30px" }}>
                                                                قد نستخدم تفاصيل الاتصال الخاصة بك لإخبارك عن الخدمات التي نعتقد أنها ستكون ذات فائدة لك أو الأحداث القادمة أو العروض الترويجية الأخرى. إذا فعلنا ذلك ، فستحتوي كل رسالة تسويقية نرسلها إليك على تعليمات تسمح لك "بإلغاء الاشتراك" في تلقي اتصالات التسويق المستقبلية. لاحظ ، مع ذلك ، أنه بصفتك مستخدماً لخدماتنا ، لا يمكنك إلغاء الاشتراك في بعض الاتصالات الإدارية الضرورية بشكل معقول للخدمات ، مثل إشعارات الفواتير أو الخدمة. بالإضافة إلى ذلك ، إذا كنت ترغب في أي وقت في عدم تلقي أي اتصالات تسويقية مستقبلية أو إذا كنت ترغب في حذف اسمك من قوائمنا البريدية ، فيرجى الاتصال بنا كما هو موضح أدناه.
                                                            </p>
                                                            <p style={{ marginRight: "30px" }}> حيثما يقتضي القانون المعمول به (على سبيل المثال ، إذا كنت) ، فلن نرسل لك معلومات تسويقية عبر البريد الإلكتروني إلا إذا وافقت على قيامنا بذلك في الوقت الذي تزودنا فيه ببياناتك الشخصية. عندما تزودنا بموافقتك على أن يتم الاتصال بك لأغراض تسويقية ، يحق لك سحب موافقتك في أي وقت باتباع التعليمات "لإلغاء الاشتراك" في تلقي اتصالات تسويقية في كل بريد إلكتروني تسويقي نرسله إليك أو عن طريق الاتصال بنا كما هو مبين أدناه. </p>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                كما هو ضروري لبعض المصالح المشروعة

                                                            </div>

                                                            </li>

                                                        </ul>
                                                        <ol className="ttm-list-icon-color-skincolor" style={{ marginRight: "20px" }}>
                                                            <p style={{ fontWeight: "bold", marginBottom: "0px" }}>نستخدم بياناتك الشخصية للمصالح المشروعة الموضحة أدناه:</p>
                                                            <li> لإرسال معلومات إدارية إليك ، على سبيل المثال ، المعلومات المتعلقة بالموقع والتغييرات التي تطرأ على الشروط والأحكام والسياسات الخاصة بنا.  </li>
                                                            <li> للرد على استفساراتك وتلبية طلباتك ، مثل إرسال المواد والنشرات الإخبارية المطلوبة ، بالإضافة إلى المعلومات والمواد المتعلقة بمنتجاتنا وخدماتنا.   </li>
                                                            <li> لإجراء تحليلات حول كيفية استخدامك للموقع والخدمات التي نقدمها لأغراضنا الداخلية (أي لتوفير وصيانة وقياس وتحسين عروضنا ، وتحديد اتجاهات الاستخدام وتحديد فعالية حملاتنا الترويجية) ولإبلاغ إستراتيجيتنا التسويقية وإضفاء الطابع الشخصي على اتصالاتنا معك (بما في ذلك توفير معلومات عن ميزاتنا والإعلانات الأخرى المتعلقة بالتسويق والخدمات ذات الصلة بالمحتوى والميزات التي تتعامل معها).</li>
                                                            <li> لتكملة المعلومات التي جمعناها منك بالمعلومات التي تم الحصول عليها من جهات خارجية (الموضحة أعلاه) من أجل تحديث سجلاتنا وتوسيعها وتحليلها وتحديد العملاء الجدد وتقديم المنتجات والخدمات التي قد تهمك. </li>
                                                            <li>لمنع الاحتيال أو النشاط الإجرامي ، وإساءة استخدام منتجاتنا أو خدماتنا ، وضمان أمن أنظمة تكنولوجيا المعلومات لدينا ، والهندسة المعمارية والشبكات.</li>
                                                            <li>الامتثال للالتزامات القانونية والإجراءات القانونية </li>
                                                            <li>الاستجابة للطلبات الواردة من السلطات العامة والحكومية بما في ذلك السلطات العامة والحكومية خارج بلد إقامتك </li>
                                                            <li>تطبيق الشروط والأحكام الخاصة بنا </li>
                                                            <li>حماية عملياتنا </li>
                                                            <li>حماية حقوقنا وخصوصيتنا وسلامتنا أو ممتلكاتنا و / أو حقوقك أو حقوق الآخرين </li>
                                                            <li>السماح لنا بمتابعة العلاجات المتاحة أو الحد من الأضرار التي قد نتعرض لها.</li>

                                                        </ol>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                إذا طلبت منا حذف بياناتك ونحن مطالبون بتنفيذ طلبك ، للاحتفاظ بالبيانات الأساسية لتحديد هويتك ومنع المزيد من المعالجة غير المرغوب فيها.

                                                            </div></li>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                بموافقتك المستنيرة التي أعطيت بحرية
                                                            </div></li>
                                                            <p style={{ marginRight: "30px" }}> إذا شاركت في مؤتمر عبر الفيديو ، بموافقتك المسبقة ، فقد يتم تسجيل المؤتمر ونسخه. الموافقة طوعية ، ويمكنك سحب موافقتك في أي وقت. قد تتضمن المعلومات التي تم جمعها صوتك وصورتك ورسائل الاجتماع وأي بيانات شخصية أخرى تختار مشاركتها. بالإضافة إلى ذلك ، قد يتمكن المشاركون في مؤتمر الفيديو من عرض اسم العرض وعنوان البريد الإلكتروني وصورة ملف التعريف. باستخدام خدمات المؤتمرات التي تقدمها شركة زووم للاتصالات المرئية. (زووم)، قد تقوم زووم بجمع واستخدام ومشاركة البيانات الشخصية الإضافية المتعلقة بك ، والتي سيتم استخدامها وفقًا لبيان خصوصية زووم.   </p>
                                                        </ul>

                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>بالنسبة للأفراد  </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i>
                                                                <div className="ttm-list-li-content">
                                                                    يرجى الاطلاع على قسم "الافراد" أدناه للحصول على معلومات حول مصالحنا المشروعة وحقوقك.
                                                                </div>
                                                            </li>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i>
                                                                <div className="ttm-list-li-content">
                                                                    إذا كانت ماسريس تعتزم استخدام أي بيانات شخصية بأي طريقة لا تتفق مع سياسة الخصوصية هذه ، فسيتم إبلاغك بهذا الاستخدام المتوقع قبل أو في الوقت الذي يتم فيه جمع البيانات الشخصية ووفقاً للقانون المعمول به.
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <h2 style={{ textAlign: "center", textDecoration: "underline", textUnderlineOffset: "13px" }}>
                                                        المتقدمون   <span className='text-theme-SkinColor'> للوظيفة </span>
                                                    </h2>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>المعلومات التي نجمعها  </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i>
                                                                <div className="ttm-list-li-content">
                                                                    عملية التقديم والتقييم
                                                                </div>
                                                            </li>
                                                            <p style={{ marginRight: "30px" }}> عندما تتقدم بطلب للحصول على عمل من خلال موقعنا ، فإن مزود خدمات التوظيف لدينا سيجمع البيانات الشخصية التي تقدمها في سيرتك الذاتية وخطاب التغطية. الأمر متروك لك لتقرير ما تقوم بتضمينه في هذه المستندات. ومع ذلك ، فإن أنواع المعلومات التي قد ترغب في تضمينها هي: اسمك وتفاصيل الاتصال بك وتاريخ التوظيف والتعليم.</p>
                                                            <p style={{ marginRight: "30px" }}> إذا تمت دعوتك لإجراء مزيد من التقييمات (مثل مقابلة) فيما يتعلق بطلبك وشاركت في مثل هذه التقييمات ، فسنجمع المزيد من البيانات الشخصية التي تقدمها لنا كجزء من هذه العملية. تتضمن أنواع المعلومات التي قد تفصح عنها معلومات حول مؤهلاتك ومعلومات حول خبرتك في العمل. يمكنك اختيار الكشف عن سجل الرواتب أو توقعات الراتب. إذا لم تزودنا بمعلومات معينة عند الطلب ، فقد يؤثر ذلك على قدرتنا على تقييم مدى ملاءمتك لدور معنا أو قد لا نتمكن من تقديم عرض توظيف لك. بموافقتك المسبقة ، قد نسجل مقابلتك (مقابلاتك) لتقييم ترشيحك بشكل أفضل ولأغراض التدريب. موافقتك طوعية ، ويمكنك سحب موافقتك في أي وقت ، بما في ذلك أثناء المقابلة. إذا سحبت موافقتك أو لم توافق عليها ، فلن يكون لها أي تأثير على ترشيحك.</p>
                                                            <p style={{ marginRight: "30px" }}>خلال عملية التوظيف ، قد نقوم بإنشاء بيانات شخصية تتعلق بتقييم طلبك. على سبيل المثال ، قد نسجل آراء أولئك الذين يفكرون في طلبك حول مدى ملاءمتك للوظيفة التي تقدمت لها والاحتفاظ بملاحظات المقابلة.</p>
                                                            <p style={{ marginRight: "30px" }}> لدينا مصلحة مشروعة في تسهيل عملية المقابلة وإبلاغك بعروض التوظيف ، واتخاذ قرارات توظيف مستنيرة واختيار المرشحين المناسبين لأدوار معنا ، وتحسين عمليات التوظيف والتوظيف لدينا.</p>
                                                            <p style={{ marginRight: "30px" }}>  سنحتفظ ببيانات الاتصال الخاصة بك ونستأنف لإبلاغك بالفرص الوظيفية الحالية والمستقبلية (ما لم تخبرنا أنك لا تريد منا الاحتفاظ ببياناتك لهذا الغرض) وفقاً لمصلحتنا المشروعة في القيام بذلك.</p>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i>
                                                                <div className="ttm-list-li-content">
                                                                    إذا كان طلبك ناجحاً
                                                                </div>
                                                            </li>
                                                            <p style={{ marginRight: "30px" }}>إذا كان طلبك ناجحاً ، فسنجمع المزيد من البيانات الشخصية عنك فيما يتعلق بإجراء فحوصات مرجعية وخلفية حيثما كان ذلك مطلوباً أو مسموحاً به بموجب القانون المحلي المعمول به (بما في ذلك ، إذا كان ينطبق عليك ، فئات خاصة من البيانات الشخصية ، وموافقتك على جمع هذه البيانات الإضافية. سيتم طلب البيانات في هذا الوقت ، وفقاً للقوانين المعمول بها). سنقوم أيضاً بجمع نسخ من وثائق الهوية ذات الصلة منك (مثل جواز السفر أو رخصة القيادة وإثبات العنوان ونسخة من تصريح العمل الخاص بك (عند الاقتضاء) وصورة ونسخة من توقيعك). نستخدم هذه المعلومات للامتثال لمتطلبات الهجرة وللتحقق من هويتك لأغراض الأمن الداخلي الخاصة بنا. لدينا مصلحة مشروعة في جمع هذه البيانات واستخدامها للامتثال لالتزاماتنا القانونية ولتنفيذ عقد العمل الخاص بك معنا. </p>
                                                            <p style={{ marginRight: "30px" }}>إذا تم تعيينك ، فسيتم استخدام بياناتك الشخصية كجزء من سجل موظفك بموجب سياسات خصوصية الموظفين لدينا. </p>
                                                            <p style={{ marginRight: "30px" }}>يرجى الاطلاع على قسم "الافراد " أدناه للحصول على معلومات حول حقوقك فيما يتعلق بالبيانات الشخصية التي نحتفظ بها عنك. </p>

                                                            <li><i className="fas fa-long-arrow-alt-left"></i>
                                                                <div className="ttm-list-li-content">
                                                                    البيانات التي يتم جمعها تلقائياً
                                                                </div>
                                                            </li>
                                                            <p style={{ marginRight: "30px" }}>عندما تتفاعل مع ماسريس من خلال موقعنا فيما يتعلق بتطبيقك ، فإننا نجمع معلومات معينة تلقائيًا. يرجى قراءة قسم "زوار الموقع" أدناه لمزيد من المعلومات. </p>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <h2 style={{ textAlign: "center", textDecoration: "underline", textUnderlineOffset: "13px" }}>
                                                        زوار    <span className='text-theme-SkinColor'> الموقع </span>
                                                    </h2>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3> </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i>
                                                                <div className="ttm-list-li-content">
                                                                    عندما تتفاعل مع ماسريس من خلال الموقع أو الخدمات (سواء بصفتك فريلانسر ، كعميل ، طالب وظيفة ، قارئ مدونة ، مستخدم نهائي للبرنامج ، أو زائر آخر لموقعنا) ، نحن ومقدمو الخدمات لدينا يتصرفون نيابة عنا، سيقوم تلقائياً بجمع معلومات عنك من خلال ملفات تعريف الارتباط (ملفات نصية صغيرة موضوعة على جهازك) وغيرها من التقنيات. يرجى الاطلاع على سياسة ملفات تعريف الارتباط الخاصة بنا لمعرفة المزيد حول كيفية استخدامنا لملفات تعريف الارتباط والتقنيات الأخرى.
                                                                </div>
                                                            </li>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i>
                                                                <div className="ttm-list-li-content">
                                                                    صفتك زائرًا لموقعنا ، تقوم خوادمنا بتسجيل المعلومات ("بيانات السجل") ، بما في ذلك المعلومات التي يرسلها متصفحك تلقائيًا كلما زرت الموقع. تتضمن بيانات السجل هذه عنوان بروتوكول الإنترنت ("IP")
                                                                    الخاص بك (الذي نفهم منه البلد الذي تتصل منه في وقت زيارتك للموقع) ، ونوع المتصفح والإعدادات ، وتاريخ طلبك ووقته.
                                                                </div>
                                                            </li>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i>
                                                                <div className="ttm-list-li-content">
                                                                    عندما تكون المعلومات التي نجمعها تلقائياً على موقعنا هي بيانات شخصية ، فإن أساسنا القانوني لاستخدام هذه المعلومات هو أنها ضرورية لأغراض مصالحنا المشروعة في الحفاظ على التشغيل الآمن لموقعنا وكذلك في معرفة كيفية استخدام الموقع. يتفاعل الزوار مع موقعنا لتحسين استخدامك له.
                                                                </div>
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <h2 style={{ textAlign: "center", textDecoration: "underline", textUnderlineOffset: "13px" }}>
                                                        المستخدمون     <span className='text-theme-SkinColor'> النهائيون  </span>للبرنامج
                                                    </h2>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>عندما تستخدم برنامج <span className='text-theme-SkinColor'> MassRes </span>  </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                سنقوم بجمع بيانات مستوى الحساب (اسمك والبريد الإلكتروني وكلمة المرور) للوصول وتخزين البيانات التاريخية وتشغيل البرنامج. إذا قمت بإنشاء حساب باستخدام بيانات اعتماد من خدمة جهة خارجية أو خدمات تسجيل الدخول مثل حساب جوجل الخاص بك ، فستقوم هذه الخدمات بمصادقة هويتك وتزويدك بخيار مشاركة بيانات شخصية معينة ، مثل الاسم وعنوان (عناوين البريد الإلكتروني) )، معنا. يعد استخدام بيانات مستوى الحساب ضرورياً لتنفيذ عقد معنا فيما يتعلق باستخدامك للبرنامج أو من مصلحتنا المشروعة الاستجابة لطلباتك والتأكد من أننا نقدم خدماتنا بأفضل طريقة ممكنة.                                                             </div></li>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                عند استخدام البرنامج ، قد نجمع إحصاءات الاستخدام للتحليلات ولدعم البرنامج واستكشاف الأخطاء وإصلاحها لمنحك تجربة مستخدم أفضل. لدينا مصلحة مشروعة في إجراء إحصائيات الاستخدام للتأكد من أن برنامجنا يعمل على النحو المنشود ولإجراء تحسينات على خدماتنا. نحن نستخدم مزودي الخدمة للمساعدة في مصادقة المستخدمين والاستضافة السحابية لتخزين واسترجاع لقطات الشاشة.                                                            </div></li>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i>
                                                                <div className="ttm-list-li-content">
                                                                    يحتوي برنامج ماسريس على مكونات عبر الإنترنت وملفات تعريف الارتباط مطلوبة للوظائف الكاملة. لمزيد من المعلومات حول استخدام ملفات تعريف الارتباط ، يرجى الاطلاع على سياسة ملفات تعريف الارتباط. يعد  الارتباط  جزءًا من المجال الرئيسي لـ ماسريس ويمكن العثور على معلومات حول ملفات تعريف الارتباط التي تم تعيينها عند استخدام سياسة ملفات تعريف الارتباط الخاصة بـ ماسريس.
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ol className="ttm-list-icon-color-skincolor" style={{ marginRight: "20px" }}>
                                                            <li> لتعديل أو تعليق أو إنهاء تشغيل أو الوصول إلى الموقع ، أو أي جزء من الموقع ، لأي سبب من الأسباب .  </li>
                                                            <li> لتعديل أو تغيير الموقع ، أو أي جزء منه ، لأي سبب من الأسباب .  </li>
                                                            <li> مقاطعة تشغيل الموقع ، أو أي جزء منه ، حسب الضرورة لإجراء صيانة روتينية أو غير روتينية ، أو تصحيح الأخطاء ، أو تغييرات أخرى.</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <h2 style={{ textAlign: "center", textDecoration: "underline", textUnderlineOffset: "13px" }}>
                                                        <span className='text-theme-SkinColor'> الأحداث  </span>
                                                    </h2>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                قد نجمع بيانات شخصية منك عندما تحضر أحد أحداثنا ، على سبيل المثال في مؤتمرات الأعمال والمعارض التجارية. تتضمن البيانات التي نجمعها المعلومات التي تقدمها لنا طواعية ، مثل بطاقة العمل أو تفاصيل الاتصال الخاصة بك. سوف نستخدم هذه المعلومات لتطوير علاقة عمل وبطريقة تتفق مع الغرض الذي أعطيت المعلومات من أجله.
                                                            </div></li>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                حيثما يقتضي القانون المعمول به (على سبيل المثال ، إذا كنت فرداً) ، فسنطلب موافقتك قبل أو عند إرسال معلومات التسويق إليك عبر البريد الإلكتروني. عندما تزودنا بموافقتك على أن يتم الاتصال بك لأغراض تسويقية ، يحق لك سحب موافقتك في أي وقت باتباع التعليمات "لإلغاء الاشتراك" في تلقي اتصالات تسويقية في كل بريد إلكتروني تسويقي نرسله إليك أو عن طريق الاتصال بنا كما هو مبين أدناه.
                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <h2 style={{ textAlign: "center", textDecoration: "underline", textUnderlineOffset: "13px" }}>
                                                        إفصاحنا عن    <span className='text-theme-SkinColor'>  بياناتك الشخصية    </span> ومعلومات أخرى
                                                    </h2>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                ماسريس ليس في مجال بيع المعلومات الخاصة بك. نحن نعتبر هذه المعلومات جزءاً حيوياً من علاقتنا معك. ومع ذلك ، هناك ظروف معينة قد نشارك فيها بياناتك الشخصية مع جهات خارجية معينة
                                                            </div></li>
                                                        </ul>

                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>تحويلات الأعمال</h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                أثناء قيامنا بتطوير أعمالنا ، قد نبيع أو نشتري أعمالاً أو أصولاً. في حالة بيع الشركة أو الاندماج أو إعادة التنظيم أو الحل أو حدث مماثل ، قد تكون البيانات الشخصية جزءاً من الأصول المنقولة.
                                                            </div></li>

                                                        </ul>

                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>الوكلاء والاستشاريين ومقدمي الخدمات الآخرين </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                ماسريس، مثل العديد من الشركات ، تستأجر أحياناً شركات أخرى لأداء وظائف معينة متعلقة بالأعمال. تشمل هذه الأطراف شركات تحليلات مواقع الويب ومقدمي خدمات الإعلان الرقمي ومقدمي خدمات الاستضافة والحوسبة السحابية وموفري (سي ار ام) وحلول برامج التسويق والمبيعات ومقدمي خدمات الفوترة ومعالجة المدفوعات ومقدمي خدمات التحقق من الخلفية. عندما نوظف شركة أخرى لأداء وظيفة من هذا النوع ، فإننا نوفر لهم فقط المعلومات التي يحتاجونها لأداء وظيفتهم المحددة. وفقاً لتعليماتنا ، يجوز لهذه الأطراف الوصول إلى البيانات الشخصية أو معالجتها أو تخزينها في سياق أداء واجباتهم تجاهنا ومن أجل أداء الخدمات التي وظفناهم                                                             </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>المتطلبات القانونية </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                قد تكشف ماسريس عن بياناتك الشخصية إذا طُلب منها ذلك بموجب القانون أو بحسن نية أن هذا الإجراء ضروري من أجل                                                             </div></li>
                                                        </ul>
                                                        <ol className="ttm-list-icon-color-skincolor" style={{ marginRight: "20px" }}>
                                                            <li> الامتثال لالتزام قانوني  </li>
                                                            <li> حماية حقوق أو ممتلكات ماسريس والدفاع عنها  </li>
                                                            <li> التصرف في الظروف العاجلة لحماية السلامة الشخصية لمستخدمي الموقع أو الجمهور </li>
                                                            <li>الحماية من المسؤولية القانونية</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>متحكم بيانات   </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                ماسريس و المتحكم في البيانات لمعالجة بياناتك الشخصية. مراقب البيانات مسؤول عن تحديد كيفية استخدام البيانات الشخصية الخاصة بك. يرجى الاطلاع على قسم "الاتصال ب ماسريس" أدناه لمعرفة كيفية الاتصال بنا ، والذي يوفر أيضاً تفاصيل الاتصال بممثلنا في العالم لأغراض اللائحة العامة لحماية البيانات. </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>حقوقك </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                وفقاً لقانون العالمي المعمول به ، تتمتع بالحقوق التالية فيما يتعلق ببياناتك الشخصية:
                                                            </div></li>

                                                        </ul>
                                                        <ol className="ttm-list-icon-color-skincolor" style={{ marginRight: "20px" }}>
                                                            <li> حق الوصول. إذا سألتنا ، فسنؤكد ما إذا كنا نعالج بياناتك الشخصية ، وإذا كان الأمر كذلك ، سنزودك بنسخة من تلك البيانات الشخصية مع بعض التفاصيل الأخرى. إذا طلبت نسخًا إضافية ، فقد نحتاج إلى فرض رسوم معقولة.  </li>
                                                            <li> حق التصحيح. إذا كانت بياناتك الشخصية غير دقيقة أو غير كاملة ، فيحق لك أن تطلب منا تصحيحها أو إكمالها. إذا شاركنا بياناتك الشخصية مع آخرين ، فسنخبرهم عن التصحيح حيثما أمكن ذلك. إذا سألتنا ، وحيثما كان ذلك ممكناً وقانوناً ، فسنخبرك أيضاً بمن شاركنا بياناتك الشخصية حتى تتمكن من الاتصال بهم مباشرة.   </li>
                                                            <li> الحق في المحو. يمكنك أن تطلب منا حذف بياناتك الشخصية أو إزالتها ، مثل المكان الذي تسحب فيه موافقتك. إذا شاركنا بياناتك مع الآخرين ، فسنخبرهم عن المحو حيثما أمكن ذلك. إذا طلبت منا ذلك ، وحيثما كان ذلك ممكناً وقانوناً ، فسنخبرك أيضاً بمن شاركنا بياناتك الشخصية معه حتى تتمكن من الاتصال بهم مباشرة. </li>
                                                            <li>الحق في تقييد المعالجة. يمكنك أن تطلب منا تقييد أو "حظر" معالجة بياناتك الشخصية في ظروف معينة ، مثل عندما تعترض على دقة البيانات أو تعترض على معالجتنا لها. سنخبرك قبل أن نرفع أي قيود على المعالجة. إذا شاركنا بياناتك الشخصية مع آخرين ، فسنخبرهم بالقيود حيثما أمكن ذلك. إذا سألتنا ، وحيثما كان ذلك ممكناً وقانوناً ، فسنخبرك أيضاً بمن شاركنا بياناتك الشخصية حتى تتمكن من الاتصال بهم مباشرة.</li>
                                                            <li>الحق في نقل البيانات. لديك الحق في الحصول على بياناتك الشخصية منا التي وافقت على تقديمها لنا أو التي تم توفيرها لنا حسب الضرورة فيما يتعلق بعقدنا معك. سنقدم لك بياناتك الشخصية بتنسيق منظم وشائع الاستخدام ويمكن قراءته آلياً. يمكنك إعادة استخدامه في مكان آخر.</li>
                                                            <li>الحق في الاعتراض. يمكنك أن تطلب منا في أي وقت التوقف عن معالجة بياناتك الشخصية ، وسنفعل ذلك: (1) إذا كنا نعتمد على مصلحة مشروعة لمعالجة بياناتك الشخصية - ما لم نثبت أسباباً مشروعة مقنعة للمعالجة أو (2) إذا كنا نعالج بياناتك الشخصية للتسويق المباشر.</li>
                                                            <li>الحقوق فيما يتعلق باتخاذ القرار الآلي والتنميط. لديك الحق في التحرر من القرارات التي تستند فقط إلى المعالجة الآلية لبياناتك الشخصية ، بما في ذلك التنميط ، والتي تحدث تأثيراً قانونياً كبيراً عليك ، ما لم يكن هذا التنميط ضرورياً للدخول في أو تنفيذ عقد بينك وبين لنا ، أو بموافقتك الصريحة. لا نقوم حالياً بمعالجة بياناتك الشخصية لمثل هذا النوع من اتخاذ القرار الآلي ، بما في ذلك التنميط ، ولكن إذا اخترنا القيام بذلك في المستقبل ، فسنقدم لك إشعاراً واختياراً ، وفقاً لقانون حماية البيانات في العالم.</li>
                                                            <li>الحق في سحب الموافقة. إذا اعتمدنا على موافقتك على معالجة بياناتك الشخصية ، فيحق لك سحب هذه الموافقة في أي وقت ، ولكن هذا لن يؤثر على أي معالجة لبياناتك تمت بالفعل.</li>
                                                            <li>الحق في تقديم شكوى إلى هيئة حماية البيانات. إذا كانت لديك مخاوف بشأن ممارسات الخصوصية لدينا ، بما في ذلك الطريقة التي تعاملنا بها مع بياناتك الشخصية ، فيمكنك إبلاغ سلطة حماية البيانات المخولة لسماع هذه المخاوف.</li>
                                                            <li>يمكنك ممارسة حقوقك عن طريق الاتصال بنا كما هو موضح في قسم "الاتصال ب ماسريس" أدناه.</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>مصلحة مشروعة </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                تعني "المصالح المشروعة" اهتماماتنا في إدارة منظمتنا وإدارتها وتقديم أفضل الخدمات لك. توضح سياسة الخصوصية هذه عندما نعالج بياناتك الشخصية لمصالحنا المشروعة ، ما هي هذه المصالح وحقوقك. لن نستخدم بياناتك الشخصية في الأنشطة التي يتجاوز فيها التأثير عليك مصالحنا ، ما لم نحصل على موافقتك أو كانت تلك الأنشطة مطلوبة أو مسموحاً بها بموجب القانون. لديك الحق في الاعتراض على المعالجة التي تستند إلى مصالحنا المشروعة. لمزيد من المعلومات حول حقوقك ، يرجى الاطلاع على قسم "حقوقك" أعلاه. </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>عمليات نقل البيانات </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                عندما تتقدم بطلب للعمل كمستقل أو تستخدم خدماتنا كعميل ، أو تستخدم موقعنا بطريقة أخرى ، فسيتم نقل بياناتك الشخصية إلى خوادم في الولايات المتحدة حسب الضرورة لتزويدك بالخدمات التي طلبتها أو إدارة عقدنا معك أو للرد على طلباتك كما هو موضح في سياسة الخصوصية هذه ، وقد يتم نقل البيانات إلى مزودي الخدمة لدينا الذين يدعمون عملياتنا التجارية (الموضحة أعلاه). قد يكون لدى الولايات المتحدة قوانين لحماية البيانات أقل صرامة من القوانين السارية في البلد الذي تقيم فيه أو تختلف عنها. عندما ننقل بياناتك الشخصية خارج المنطقة الاقتصادية الأوروبية أو المملكة المتحدة ، سنتخذ خطوات لضمان حصول بياناتك الشخصية على مستوى مناسب من الحماية عند معالجتها واستمرار حماية حقوقك.                                                              </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>الاحتفاظ بالبيانات </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                سياستنا هي الاحتفاظ ببياناتك الشخصية فقط طالما كان ذلك ضرورياً بشكل معقول لتحقيق الأغراض التي تم جمعها ومعالجتها من أجلها ، بما في ذلك لأغراض تلبية أي متطلبات قانونية أو تنظيمية أو محاسبية أو إعداد تقارير. إذا اخترت تلقي اتصالات تسويقية منا ، فإننا نحتفظ بمعلومات حول تفضيلاتك التسويقية حتى تقوم بإلغاء الاشتراك في تلقي هذه الاتصالات ووفقاً لسياساتنا.
                                                            </div></li>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                سياستنا هي الاحتفاظ ببياناتك الشخصية فقط طالما كان ذلك ضرورياً بشكل معقول لتحقيق الأغراض التي تم جمعها ومعالجتها من أجلها ، بما في ذلك لأغراض تلبية أي متطلبات قانونية أو تنظيمية أو محاسبية أو إعداد تقارير. إذا اخترت تلقي اتصالات تسويقية منا ، فإننا نحتفظ بمعلومات حول تفضيلاتك التسويقية حتى تقوم بإلغاء الاشتراك في تلقي هذه الاتصالات ووفقاً لسياساتنا.
                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>اختياراتك </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                يمكنك استخدام الموقع دون تقديم أي بيانات شخصية. إذا اخترت عدم تقديم أي بيانات شخصية ، فقد لا تتمكن من استخدام خدمات معينة.

                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>الاستثناءات </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                            لا تنطبق سياسة الخصوصية هذه على أي بيانات شخصية تجمعها ماسريس بخلاف البيانات الشخصية التي يتم جمعها من خلال الموقع أو الخدمات. لا تنطبق سياسة الخصوصية هذه على أي معلومات غير مرغوب فيها تقدمها إلى ماسريس من خلال هذا الموقع أو من خلال أي وسيلة أخرى (دون المساس بحقوقك بموجب القانون المعمول به). يتضمن ذلك ، على سبيل المثال لا الحصر ، المعلومات المنشورة في أي مناطق عامة بالموقع ، مثل المنتديات (يُشار إليها إجمالاً باسم "المناطق العامة") ، وأي أفكار لمنتجات جديدة أو تعديلات على المنتجات الحالية ، وغير ذلك من عمليات الإرسال غير المرغوب فيها (إجمالاً ، " معلومات غير مطلوبة "). تُعتبر جميع المعلومات غير المطلوبة غير سرية ، ويكون لـ ماسريس الحرية في إعادة إنتاج هذه المعلومات غير المطلوبة واستخدامها والإفصاح عنها وتوزيعها واستغلالها دون تحديد أو إسناد. 
                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>الأطفال </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                            لا تجمع ماسريس عن قصد بيانات شخصية من أطفال تقل أعمارهم عن 13 عاماً. إذا كان عمرك أقل من 13 عاماً ، فيرجى عدم إرسال أي بيانات شخصية من خلال الموقع. نحن نشجع الآباء والأوصياء القانونيين على مراقبة استخدام أطفالهم للإنترنت وللمساعدة في تطبيق سياسة الخصوصية الخاصة بنا من خلال توجيه أطفالهم إلى عدم تقديم بيانات شخصية على هذا الموقع مطلقاً دون إذن منهم. إذا كان لديك سبب للاعتقاد بأن طفلاً يقل عمره عن 13 عاماً قد قدم بيانات شخصية إلى ماسريس من خلال هذا الموقع ، فيرجى الاتصال بنا وسنسعى لحذف تلك المعلومات من قواعد البيانات الخاصة بنا. 

                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>روابط لمواقع ويب أخرى </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                            قد يحتوي هذا الموقع على روابط لمواقع أخرى لا تديرها أو تتحكم فيها ماسريس ("مواقع الطرف الثالث"). ستخضع المعلومات التي تشاركها مع مواقع الطرف الثالث لسياسات الخصوصية وشروط الخدمة الخاصة بمواقع الطرف الثالث وليس لسياسة الخصوصية هذه. من خلال توفير هذه الروابط ، فإننا لا نعني أننا نصادق أو راجعنا مواقع الطرف الثالث. نقترح الاتصال بهذه المواقع مباشرة للحصول على معلومات حول ممارسات وسياسات الخصوصية الخاصة بهم. 
                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>الأمن </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                            تتخذ ماسريس خطوات معقولة ومناسبة لحماية البيانات الشخصية المقدمة عبر الموقع أو الخدمات من الضياع أو سوء الاستخدام أو الوصول غير المصرح به أو الكشف أو التغيير أو التدمير. ومع ذلك ، لا يوجد إنترنت أو بريد إلكتروني أو أي وسيلة نقل إلكترونية أخرى آمنة تماماً أو خالية من الأخطاء ، لذلك يجب أن تولي عناية خاصة في تحديد المعلومات التي ترسلها إلينا بهذه الطريقة. 

                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>شروط وأحكام أخرى </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                            يخضع وصولك إلى هذا الموقع واستخدامه لشروط وأحكام موقع الويب الخاص بنا. 

                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>التغييرات في سياسة الخصوصية ماسريس  </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                            قد يتغير الموقع وعملنا من وقت لآخر. نتيجة لذلك ، قد يكون من الضروري في بعض الأحيان لـ ماسريس إجراء تغييرات على سياسة الخصوصية هذه. ماسريس تحتفظ لنفسها بالحق في تحديث أو تعديل سياسة الخصوصية هذه في أي وقت ومن وقت لآخر دون إشعار مسبق ، ما لم يقتضي القانون المعمول به خلاف ذلك. يرجى مراجعة هذه السياسة بشكل دوري ، وخاصة قبل تقديم أي بيانات شخصية. إن استمرارك في استخدام الموقع بعد أي تغييرات أو مراجعات على سياسة الخصوصية هذه يشير إلى موافقتك على شروط سياسة الخصوصية المنقحة هذه ، دون المساس بحقوقك بموجب القانون المعمول به. 
                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>الاتصال ب ماسريس  </h3>
                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                            يرجى الاتصال بنا إذا كان لديك أي أسئلة حول سياسة الخصوصية ماسريس أو ممارسات المعلومات لهذا الموقع.

                                                            </div></li>
                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                                <a href='mailto:massres.dev@gmail.com'> privacy@MassRes.com </a>
                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                              
                                               

                                            </div>
                                        </div>
                                    </div>{/* ttm-blog-classic-content end */}
                                </article>{/* post end */}
                            </div>

                        </div>{/* row end */}
                    </div>
                </div>

                <Footer />

            </div>
        )
    }
}

export default Privacy;