import React, { Component, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
// import "./firebase/config"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './css/vendor/flaticon.css';
import './css/vendor/fontawesome.min.css';
import './css/vendor/themify-icons.css';
import { AuthProvider } from './contexts/AuthContext';
import { useAuthContext } from './hooks/useAuthContext';
import { useDocument } from './hooks/useDocument';
import Home from './pages/Home';
import Home2 from './pages/Home2';
import Home3 from './pages/Home3';

import About_us from './pages/About_us';
import Services from './pages/Services';
import Services_details from './pages/Services_details';
import Blog_classic from './pages/Blog_classic';
import Blog_grid from './pages/Blog_grid';
import Blog_details from './pages/Blog_details';
import Contact_01 from './pages/Contact_01';
import Contact_02 from './pages/Contact_02';
import Job_list from './pages/Job_list';
import Job_details from './pages/Job_details';
import Carrer_01 from './pages/Carrer_01';
import Error_404 from './pages/Error_405';
import Error_405 from './pages/Error_404';
import Employers_list from './pages/Employers_list';
import Employers_details from './pages/Employers_details';
import Employer_jobs from './pages/Employer_jobs';
import Candidate_list from './pages/Candidate_list';
import Candidate_details from './pages/Candidate_details';
import Login from './pages/Login';
import Register from './pages/Register';

import StartCv from './pages/cleanStart';
import FillCv from './pages/FillCv';
import StartCompany from './pages/compnay-start';
import StartJob from './pages/Job-start';
import ScrollToTop from './components/layout/Gotop';
import Terms from './pages/Terms';
import Accessibility from './pages/Accessibility';
import Privacy from './pages/Privacy';
import FillJob from './pages/FillJob';
import FillCompany from './pages/FillCompany';
function App() {
  const { authIsReady, user } = useAuthContext()
  const [isEmployer,setIsEmployer] = useState(false)
  const { document:doc, error } = useDocument("employers", user?user.uid:"no")

  // This will run one time after the component mounts
  useEffect(() => {
    const onPageLoad = () => {
      if (user && doc) {
        setIsEmployer(true)
    }
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, [doc]);

  return (
    <div className="page">
      {authIsReady && (

        <AuthProvider>
          <AnimatePresence exitBeforeEnter >


            <Router>
              {<Switch>
                {/* <Route exact path={`${process.env.PUBLIC_URL + '/'}`} component={ user?Home: Login } /> */}
                <Route exact path={'/'} component={user ? Home : Register} />

                {/* <Route exact path={`${process.env.PUBLIC_URL + '/Register'}`} component={ isEmployer ? StartCompany : Register} /> */}
                <Route exact path={`${process.env.PUBLIC_URL + '/Login'}`} component={user ? Home : Login} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Register'}`} component={Register} />
                {/* <Route exact path={`${process.env.PUBLIC_URL + '/Register'}`} component={user && !isEmployer ? StartCv : Register} /> */}
                <Route exact path={`${process.env.PUBLIC_URL + '/Start_cv'}`} component={!isEmployer ? StartCv : Register} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Fill_cv'}`} component={!isEmployer ? FillCv : Register} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Start_job'}`} component={user ? StartJob : Register} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Fill_job'}`} component={user&& isEmployer ? FillJob : Register} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Start_company'}`} component={isEmployer ? StartCompany : Register} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Fill_company'}`} component={ user ? FillCompany : Register} />
                
                <Route exact path={`${process.env.PUBLIC_URL + '/Home2'}`} component={Home2} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Home3'}`} component={Home3} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Terms'}`} component={Terms} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Accessibility'}`} component={Accessibility} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Privacy'}`} component={Privacy} />

                <Route exact path={`${process.env.PUBLIC_URL + '/About_us'}`} component={About_us} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Services'}`} component={Services} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Services_details'}`} component={Services_details} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Blog_classic'}`} component={Blog_classic} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Blog_grid'}`} component={Blog_grid} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Blog_details'}`} component={Blog_details} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Contact_01'}`} component={Contact_01} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Contact_02'}`} component={Contact_02} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Job_list'}`} component={Job_list} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Job_details'}`} component={user?Job_details:Login} />
                <Route path={'/Job_details/:id'} component={user ? Job_details: Login} />
                <Route path={'/job_list/:cat'} component={user ? Job_list: Login} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Carrer_01'}`} component={Carrer_01} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Error_404'}`} component={Error_404} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Error_405'}`} component={Error_405} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Employers_list'}`} component={Employers_list} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Employers_details'}`} component={Employers_details} />
                <Route  path={'/Employer_jobs/:id'} component={isEmployer? Employer_jobs: Login} />
               
                <Route path={'/Employers_details/:id'} component={user ? Employers_details: Login} />

                <Route exact path={`${process.env.PUBLIC_URL + '/Candidate_list'}`} component={Candidate_list} />
                <Route  path={'/Candidate_details/:id'} component={user ? Candidate_details : Login} />
                <Route exact path={`${process.env.PUBLIC_URL + '/Candidate_details'}`} component={user ? Candidate_details : Login} />
                
                
                <Route  path={`${process.env.PUBLIC_URL + '*'}`} component={Error_405} />
                </Switch>}
            </Router>
          </AnimatePresence>
        </AuthProvider>)}
        <ScrollToTop />

    </div>
  );
}

export default App;


