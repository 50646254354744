import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/layout/Header';

import PageHeader from "../components/layout/PageHeader";
import Footer from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSignup } from '../hooks/useSignup';
import { useAuthContext } from '../hooks/useAuthContext';
import { MdOutlinePrivacyTip } from 'react-icons/md'
import { FiUserCheck } from 'react-icons/fi'
import { useCollection } from '../hooks/useCollection';
import ReactDOM from 'react-dom';
import Privacy from './Privacy'
import '../css/modal-st.css'
import Banner from '../components/banner/Home3_banner';
import HeaderLogo from '../components/layout/HeaderLogo';

export default function Register() {

    const { authIsReady, user } = useAuthContext()
    const [isChecked, setChecked] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [displayName, setDisplayName] = useState('')
    const { signup, isPending, error } = useSignup()
    const [reg, setReg] = useState(true)
    const [sin, setSin] = useState(true)
    const phonePattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
    const handleSubmit = (e) => {
        e.preventDefault()
        const dbName = 'users'
        const isEmployer = false;
        signup(email, password, displayName, phoneNumber, isEmployer, dbName)
    }
    const handleSubmit1 = (e) => {
        e.preventDefault()
        const dbName = 'employers'
        const isEmployer = true;

        signup(email, password, displayName, phoneNumber, isEmployer, dbName);
        if(user && !error){
            window.location.href='/Start_Company';
        }

    }
    const handleCheckChange = () => {
        setChecked(!isChecked);
    };
    const showSin = () => {
        setSin(!sin)
        // console.log("show cv is clicked here")
    }
    const showReg = () => {
        setReg(!reg)
        // console.log("show cv is clicked here")
    }

    return (

        <div className="site-main" >
            <HeaderLogo />

            {/* PageHeader */}
            {/* <PageHeader
                title="وظف أفضل المهارات في مشروعك"
                breadcrumb="انشاء حساب"
                isHome="false"
            /> */}
            {/* PageHeader end */}
            <Banner page="register" />

            {/* register */}
            <div className="ttm-row register-section clearfix" id='register'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-50 p-lg-20">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                <div className="layer-content" >
                                    <div className="text-center mb-20">
                                        <h3>انضم كصاحب عمل أو مرشح للعمل </h3>
                                    </div>


                                    <div className="ttm-tabs ttm-tab-style-02">
                                        <Tabs>
                                            <TabList className="tabs" dir="rtl">

                                                {reg && <Tab className={sin ? "tab" : "tab  text-center"}
                                                    onClick={showSin}>
                                                    <a>
                                                        <i className="flaticon flaticon-research"></i>
                                                        <span>موظف</span><h5>انشاء حساب كموظف</h5>
                                                    </a>
                                                </Tab>}
                                                {sin && <Tab className={reg ? "tab" : "tab  text-center"} onClick={showReg}>
                                                    <a>
                                                        <i className="flaticon flaticon-job-search"></i>
                                                        <span>صاحب عمل</span><h5>انشاء حساب كصاحب عمل</h5>
                                                    </a>
                                                </Tab>}

                                            </TabList>
                                            <div className="content-tab" id='layer-content'>


                                                {reg && <TabPanel>

                                                    <form id="login_form" onSubmit={handleSubmit} className="login_form wrap-form" dir='rtl'>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <label>
                                                                    <i className="ti ti-email"></i>
                                                                    <input type="email" id="txtemail"
                                                                        placeholder="بريدك الالكتروني"
                                                                        required="required"
                                                                        onInvalid={e => e.target.setCustomValidity('الرجاء التأكد من البريد الالكتروني ')}
                                                                        onInput={e => e.target.setCustomValidity('')}
                                                                        onChange={(e) => setEmail(e.target.value)}
                                                                        value={email}
                                                                    />
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <label>
                                                                    <i className="ti ti-lock"></i>
                                                                    <input type="password" id="password"
                                                                        placeholder="كلمة المرور"

                                                                        onChange={(e) => setPassword(e.target.value)}
                                                                        value={password}
                                                                    />
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <label>
                                                                    <i className="ti ti-user"></i>
                                                                    <input type="text" id="txtname"
                                                                        placeholder="اسم المستخدم "
                                                                        required="required"
                                                                        onInvalid={e => e.target.setCustomValidity('الرجاء التأكد من الاسم ')}
                                                                        onInput={e => e.target.setCustomValidity('')}
                                                                        value={displayName}
                                                                        onChange={(e) => setDisplayName(e.target.value)}

                                                                    />
                                                                </label>
                                                            </div>

                                                            {/* <div className="col-lg-6">
                                                                <label>
                                                                    <i className="ti ti-lock"></i>
                                                                    <input type="password" id="cpassword"
                                                                        placeholder="Confirm Password *"
                                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                                        value={confirmPassword}
                                                                    />
                                                                </label>
                                                            </div> */}
                                                            <div className="col-lg-6">
                                                                <label>
                                                                    <i className="ti ti-mobile"></i>
                                                                    <input type="tel" id="txtphone"
                                                                        placeholder="رقم الهاتف"
                                                                        pattern="([+][0-9]{3}[0-9]{9})|([0-9]{10})" title="الرجاء ادخال الرقم بشكل صحيح, مثل :970529390494+ , 0529390494"
                                                                        required="required"
                                                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                                                        value={phoneNumber}
                                                                    />
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label className="mt-0">
                                                                    <div className="d-md-flex align-items-center justify-content-between">
                                                                        <div className="cookies-checkbox mt-15">
                                                                            <div className="d-flex flex-row justify-content-start">
                                                                                <span style={{ marginRight: "10px", fontWeight: "bold", fontSize: "large" }}> <a href={process.env.PUBLIC_URL + '/Terms'} target='_blank'><MdOutlinePrivacyTip size={25} />  الشروط والأحكام الخاصة بنا <MdOutlinePrivacyTip size={25} /></a></span>


                                                                            </div>
                                                                        </div>
                                                                        <div className="mt-15">
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label className="mt-0">
                                                                    <div className="d-md-flex align-items-center justify-content-between">
                                                                        <div className="cookies-checkbox mt-15">
                                                                            <div className="d-flex flex-row justify-content-start" >

                                                                                <input className="w-auto mr-10 mt-5"
                                                                                    id="cookies-consent"
                                                                                    required
                                                                                    onInvalid={e => e.target.setCustomValidity('الرجاء الموافقة على شروط الخصوصية')}
                                                                                    onInput={e => e.target.setCustomValidity('')}

                                                                                    name="cookies-consent"
                                                                                    type="checkbox"
                                                                                    // value="yes"
                                                                                    checked={isChecked}

                                                                                    onChange={handleCheckChange}

                                                                                />
                                                                                <span style={{ marginRight: "10px" }} className={isChecked ? "green-color" : ""}> أنت توافق على الشروط والأحكام وسياسة الخصوصية الخاصة بنا </span>

                                                                            </div>
                                                                        </div>
                                                                        <div className="mt-15" dir='rtl'>


                                                                            <p style={{ fontWeight: "bold", fontSize: "normal" }}>
                                                                                <FiUserCheck size={30} style={{  marginLeft: "10px" }} className='text-theme-SkinColor' />

                                                                                <span >لديك بالفعل حساب ؟  </span>
                                                                                <a href={process.env.PUBLIC_URL + '/login'} className="text-theme-SkinColor"> تسجيل الدخول هنا</a></p>
                                                                        </div>

                                                                    </div>
                                                                </label>
                                                            </div>

                                                            {error &&
                                                                <div>
                                                                    <div className="mt-15">
                                                                        <h4 className="" style={{ color: 'red', fontWeight: 'bold' }}>{error}</h4>
                                                                    </div>
                                                                </div>}
                                                            <div className="col-lg-12">
                                                                {!isPending && <label className="mb-0">
                                                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                                        type="submit" >انشاء حساب</button>
                                                                </label>}
                                                                {isPending && <label className="mb-0">
                                                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                                        type="submit" >.... جاري انشاء حساب جديد</button>
                                                                </label>}
                                                            </div>
                                                        </div>
                                                    </form>
                                                </TabPanel>}
                                                {sin && <TabPanel>
                                                    <form id="login_form" onSubmit={handleSubmit1} className="login_form wrap-form" dir='rtl'>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <label>
                                                                    <i className="ti ti-email"></i>
                                                                    <input type="email" id="txtemail"
                                                                        placeholder=" بريدك الالكتروني"
                                                                        required="required"
                                                                        onInvalid={e => e.target.setCustomValidity('الرجاء التأكد من البريد الالكتروني')}
                                                                        onInput={e => e.target.setCustomValidity('')}
                                                                        onChange={(e) => setEmail(e.target.value)}
                                                                        value={email}
                                                                    />
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <label>
                                                                    <i className="ti ti-lock"></i>
                                                                    <input type="password" id="password"
                                                                        placeholder="كلمة المرور"

                                                                        onChange={(e) => setPassword(e.target.value)}
                                                                        value={password}
                                                                    />
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <label>
                                                                    <i className="ti ti-user"></i>
                                                                    <input type="text" id="txtname"
                                                                        placeholder="اسم المستخدم "
                                                                        required="required"
                                                                        onInvalid={e => e.target.setCustomValidity('الرجاء التأكد من الاسم')}
                                                                        onInput={e => e.target.setCustomValidity('')}
                                                                        value={displayName}
                                                                        onChange={(e) => setDisplayName(e.target.value)}

                                                                    />
                                                                </label>
                                                            </div>


                                                            {/* <div className="col-lg-6">
                                                                <label>
                                                                    <i className="ti ti-lock"></i>
                                                                    <input type="password" id="cpassword"
                                                                        placeholder="Confirm Password *"
                                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                                        value={confirmPassword}
                                                                    />
                                                                </label>
                                                            </div> */}
                                                            <div className="col-lg-6">
                                                                <label>
                                                                    <i className="ti ti-mobile"></i>
                                                                    <input type="tel" id="txtphone"
                                                                        placeholder="رقم الهاتف"
                                                                        pattern="([+][0-9]{3}[0-9]{9})|([0-9]{10})" title="الرجاء ادخال الرقم بشكل صحيح, مثل :970529390494+ , 0529390494"
                                                                        required="required"
                                                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                                                        value={phoneNumber}
                                                                    />
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label className="mt-0">
                                                                    <div className="d-md-flex align-items-center justify-content-between">
                                                                        <div className="cookies-checkbox mt-15">
                                                                            <div className="d-flex flex-row justify-content-start">
                                                                                <span style={{ marginRight: "10px", fontWeight: "bold", fontSize: "large" }}> <a href={process.env.PUBLIC_URL + '/Terms'} target='_blank'><MdOutlinePrivacyTip size={25} />  الشروط والأحكام الخاصة بنا <MdOutlinePrivacyTip size={25} /></a></span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mt-15">
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label className="mt-0">
                                                                    <div className="d-md-flex align-items-center justify-content-between">
                                                                        <div className="cookies-checkbox mt-15">
                                                                            <div className="d-flex flex-row justify-content-start">

                                                                                <input className="w-auto mr-10 mt-5"
                                                                                    id="cookies-consent"
                                                                                    required
                                                                                    onInvalid={e => e.target.setCustomValidity('الرجاء الموافقة على شروط الخصوصية')}
                                                                                    onInput={e => e.target.setCustomValidity('')}
                                                                                    name="cookies-consent"
                                                                                    type="checkbox"
                                                                                    checked={isChecked}

                                                                                    onChange={handleCheckChange}

                                                                                />
                                                                                <span style={{ marginRight: "10px", fontSize: "normal" }} className={isChecked ? "green-color" : ""}> أنت توافق على الشروط والأحكام وسياسة الخصوصية الخاصة بنا </span>

                                                                            </div>
                                                                        </div>
                                                                        <div className="mt-15" dir='rtl'>


                                                                            <p style={{ fontWeight: "bold", fontSize: "normal" }}>
                                                                                <FiUserCheck size={30} style={{ color: "#ff8d00", marginLeft: "10px" }} />

                                                                                <span >لديك بالفعل حساب ؟  </span>
                                                                                <a href={process.env.PUBLIC_URL + '/login'} className="text-theme-SkinColor"> تسجيل الدخول هنا</a></p>
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            {error &&
                                                                <div>
                                                                    <div className="mt-15">
                                                                        <h4 className="" style={{ color: 'red', fontWeight: 'bold' }}>{error}</h4>
                                                                    </div>
                                                                </div>}
                                                            <div className="col-lg-12">
                                                                {!isPending && <label className="mb-0">
                                                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                                        type="submit" >انشاء حساب</button>
                                                                </label>}
                                                                {isPending && <label className="mb-0">
                                                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                                        type="submit" >.... جاري انشاء حساب جديد</button>
                                                                </label>}
                                                            </div>
                                                        </div>
                                                    </form>

                                                </TabPanel>}
                                            </div>
                                        </Tabs>
                                    </div>
                                    {/* <div className="login-social-buttons">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <button id="login-with-facebook" className="social-account-button w-100 facebook-button">
                                                        <i className="ti ti-facebook"></i><span>Login With facebook</span>
                                                    </button>
                                                </div>
                                                <div className="col-md-6">
                                                    <button id="login-with-google" className="social-account-button w-100 google-button">
                                                        <i className="ti ti-google"></i><span>Login With Google</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* login end */}


            <Footer />

        </div>
    )
}

