import { useReducer, useEffect, useState } from "react"
import { projectFirestore, timestamp } from "../firebase/config"

let initialState = {
  document: null,
  isPending: false,
  error: null,
  success: null,
}

const firestoreReducer = (state, action) => {
  switch (action.type) {
    case 'IS_PENDING':
      return { isPending: true, document: null, success: false, error: null }
    case 'ADDED_DOCUMENT':
      return { isPending: false, document: action.payload, success: true, error: null }
    case 'DELETED_DOCUMENT':
      return { isPending: false, document: null, success: true, error: null }
    case 'ERROR':
      return { isPending: false, document: null, success: false, error: action.payload }
    case "UPDATED_DOCUMENT":
      return { isPending: false, document: action.payload, success: true,  error: null }
    default:
      return state
  }
}

export const usePostNestDocs = (collection, id , nestedCollection) => {
  const [response, dispatch] = useReducer(firestoreReducer, initialState)
  const [isCancelled, setIsCancelled] = useState(false)

  // collection ref
  const ref = projectFirestore.collection(collection).doc(id).collection(nestedCollection)
  const ref2 = projectFirestore.collection(collection)

  // only dispatch if not cancelled
  const dispatchIfNotCancelled = (action) => {
    if (!isCancelled) {
      dispatch(action)
    }
  }
  
  // add a document
  const addDocument = async (doc) => {
    dispatch({ type: 'IS_PENDING' })
    // console.log("addding doc : ", doc)
    try {
      const createdAt = timestamp.fromDate(new Date())
      const addedDocument = await ref.add({ ...doc, createdAt,})
      dispatchIfNotCancelled({ type: 'ADDED_DOCUMENT', payload: addedDocument })
    }
    catch (err) {
      dispatchIfNotCancelled({ type: 'ERROR', payload: err.message })
      console.log("Erroe here : ", err.message)
    }
  }

  // delete a document
  const deleteDocument = async (id) => {
    dispatch({ type: 'IS_PENDING' })

    try {
      await ref.doc(id).delete()
      dispatchIfNotCancelled({ type: 'DELETED_DOCUMENT' })
    }
    catch (err) {
      dispatchIfNotCancelled({ type: 'ERROR', payload: 'could not delete' })
    }
  }
  // update a document
  const updateDocument = async (id, updates) => {
    dispatch({ type: "IS_PENDING" })
    
    try {
      const createdAt = timestamp.fromDate(new Date())
console.log("updates are?", updates)
      const updatedDocument = await ref2.doc(id).update({...updates,createdAt})
      dispatchIfNotCancelled({ type: "UPDATED_DOCUMENT", payload: updatedDocument })
    } 
    catch (error) {
      dispatchIfNotCancelled({ type: "ERROR", payload: error })
      console.log("error updating:", error)
      return null
    }
  }

  // update a nested document
  const updateNestedDocument = async (id, updates) => {
    dispatch({ type: "IS_PENDING" })
    
    try {
      const createdAt = timestamp.fromDate(new Date())
console.log("updates are?", updates)
      const updatedDocument = await ref.doc(id).update({...updates,createdAt})
      dispatchIfNotCancelled({ type: "UPDATED_DOCUMENT", payload: updatedDocument })
    } 
    catch (error) {
      dispatchIfNotCancelled({ type: "ERROR", payload: error })
      console.log("error updating:", error)
      return null
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { addDocument,
     deleteDocument,
     updateNestedDocument,
      updateDocument,
      response }

}
