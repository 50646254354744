import React, {Component} from 'react';
import whiteLogo from '../../assets/img/mass-res-white.svg'

class Logo_white extends Component {
    render() {
        return (

            <a className="home-link" href={process.env.PUBLIC_URL + '/'} title="Hireco" rel="home">
                {/* <img id="logo-img" height="42" width="170" className="img-fluid auto_size" src="images/logo-new-white.png" alt="logo-img"/> */}
                <img id="logo-img" height="42" width="170" className="img-fluid auto_size" src={whiteLogo}alt="logo-img"/>

             </a>
        );
    }
}

export default Logo_white;