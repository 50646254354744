import { useEffect, useState, useRef } from "react"
import { projectFirestore } from "../firebase/config"

export const useFetchAllNestDocs = (collection, nestedCollection, nestedId) => {
  const [error, setError] = useState(null)
  const [allJobs, setAllJobs] = useState(null)
  const [companyJobs, setJob] = useState(null)
  let collectionRef = projectFirestore.collection(collection)
  useEffect(async () => {
    // ========================================
    const unsubscribe =   collectionRef.onSnapshot(snapshot => {
      let jobs = []
      snapshot.docs.forEach(docc => {
        // console.log("Parent Document ID: ", docc.id);
        let mypic = docc.data().profilePicture
        let myMap = docc.data().compMapsrc
        let fb = docc.data().facebook
        let inst = docc.data().instagram
        let link = docc.data().link
        let twit = docc.data().twitter
        let wbst = docc.data().compSite
        let cname = docc.data().companyName
        let companyId = docc.id
        // console.log("picture is : ", mypic)

        let subCollectionDocs = collectionRef.doc(docc.id).collection(nestedCollection)
        subCollectionDocs.onSnapshot(snapshot => {

          snapshot.docs.forEach( doc => {
            // console.log("Sub Document ID: ", doc.data());
            jobs.push({ ...doc.data(), companyPic: mypic, id: doc.id})
            if (doc.id == nestedId) {
              // console.log("found the job", doc.data())
              let jj = doc.data()
              jj.companyPic = mypic
              jj.compMapsrc = myMap
              jj.facebook = fb
              jj.twitter = twit
              jj.link = link
              jj.instagram = inst
              jj.website = wbst
              jj.companyName = cname
              jj.companyId=companyId
              setJob(jj)
              // console.log('jj',jj)
            }

          });
          // let mj = jobs 
          setAllJobs(jobs)
          // console.log("all mj jobs after nesting :", jobs)
          // setAllJobs(jobs)
          setError(null)

        }
        )
      });
    }
      , error => {
        console.log(error)
        setError("cant fetch jobs")
      })

    // unsubscribe on unmount
    // console.log("last one : ", allJobs)
    return () => unsubscribe()

  }, [collection,nestedId])


  return { allJobs, companyJobs, error }
}