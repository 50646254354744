import React, { Component, useState, useEffect } from 'react'
import Menu from './Menu';
import Mobile_menu from './Mobile_menu';
import Logo from './Logo'
import Logo_white from './Logo_white';
import Header_search from './Header_search'
import { useAuthContext } from '../../hooks/useAuthContext';
import { useLogout } from '../../hooks/useLogout';
import { useCollection } from '../../hooks/useCollection';
import { useDocument } from '../../hooks/useDocument';
import { MdOutlineWidgets, MdOutlineAddCircleOutline, MdOutlinePlaylistAddCheck } from 'react-icons/md';
import { RiUserStarLine, RiUserSettingsLine, RiLogoutCircleLine } from 'react-icons/ri';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
export default function Header() {
    //MODAL_User
    const [showUser, setshowUser] = useState(false)
    const handleCloseUser = () => setshowUser(false);
    const handleMore = () => window.location.href = "/mass-manage-form"
    const handleShowUser = () => setshowUser(true);
    const [userDetails, setUserDetails] = useState("")
    const { logout } = useLogout()
    const { user } = useAuthContext()

    const { document, error } = useDocument("employers", user ? user.uid : "no")

    const [isEmployer, setIsEmployer] = useState(false)
    useEffect(() => {
        if (user && document) {

            //   console.log("Employer ",document)
            setIsEmployer(true)
        }
    }, [document])

    // componentDidMount() {
    //     window.addEventListener('scroll', this.isSticky);
    // }S

    // componentWillUnmount() {
    //     window.removeEventListener('scroll', this.isSticky);
    // }

    //   const  isSticky = (e)=>{
    //         const header = document.querySelector('header');
    //         const scrollTop = window.scrollY;
    //         scrollTop >= 250 ? header.classList.add('is-Sticky') : header.classList.remove('is-Sticky');
    //     };
    const tooltip = (
        <Tooltip id="tooltip" bsClass='myClass '    >
           <p> <strong>اعدادات</strong> {isEmployer?" الشركة  ": " الموظف "} </p>
        </Tooltip>
    );

    return (

        <header id="masthead" className="header ttm-header-style-01 ">
            {/* topbar */}
            {/* <div className="top_bar bg-theme-DarkColor text-center clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="top_bar_contact_item m-0">
                                    <div className="top_bar_icon">
                                        <i className="fas fa-info-circle text-reset"></i>
                                    </div>
                                    <span>Using its extensive fish farming experience and knowledge, Fleuren & Nooijen is now a market leader helping. </span>  
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-right ttm-btn-color-skincolor" href="/"> Learn More<i className="fas fa-long-arrow-alt-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            {/* topbar end */}
            {/* site-header-menu */}
            <div id="site-header-menu" className="site-header-menu ttm-bgcolor-white ">
                <div className="site-header-menu-inner ttm-stickable-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* site-navigation */}
                                <div className="site-navigation d-flex align-items-center justify-content-between">
                                    {/* site-branding */}
                                    <div className="site-branding">
                                        {/* <Logo /> */}
                                        <Logo_white />
                                    </div>
                                    {/* site-branding end */}
                                    <div className="border-box-block" dir='rtl'>
                                        <div className=" d-flex align-items-center justify-content-between">
                                            {/* menu */}
                                            <Menu />
                                            <div className="mobile-menu"><Mobile_menu isUser={user} /></div>
                                            {/* menu end */}
                                            <div className="header_extra ml-auto d-flex align-items-center">
                                                {/* <Header_search/> */}
                                                {/* <div className="header_social">
                                                        <ul className="social-icons">
                                                            <li><a href="https://www.facebook.com/MassRes-113256348263272" target='_blank' rel="noopener" aria-label="facebook"><i className="ti ti-facebook"></i></a></li>
                                                            <li><a href="/" rel="noopener" aria-label="twitter"><i className="ti ti-twitter-alt"></i></a></li>
                                                            <li><a href="/" rel="noopener" aria-label="google"><i className="ti ti-google"></i></a></li>
                                                            <li><a href="/" rel="noopener" aria-label="linkedin"><i className="ti ti-linkedin"></i></a></li>
                                                        </ul>
                                                    </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    {user &&
                                        <>
                                            <div className='row text-center text-placeHolder-color-light' dir='rtl' style={{ padding: "10px" }} >
                                          {  <OverlayTrigger placement="bottom" overlay={tooltip}>
                                                <p className='col-md-2' ><a href='#' onClick={handleShowUser}> <span><MdOutlineWidgets className='text-theme-SkinColor' size={25} /></span> </a></p>
                                                </OverlayTrigger>
                                                }
                                                {/* <p className='col-md-2' ><a href='#' onClick={handleShowUser}> <span><MdOutlineWidgets className='text-theme-SkinColor' size={25} /></span> </a></p> */}
                                                <p className='col-md-10 text-right  '>مرحبا -  <span className="text-theme-SkinColor" >{user.displayName}</span></p>
                                                <p className='col-md-10'>{user.email}</p>
                                                
                                            </div>

                                        </>}
                                    {/* User_Details _ MODAL */}

                                    <Modal id="" show={showUser} onHide={handleCloseUser}
                                        dialogClassName="modal-calc user-details "
                                        dir='rtl'
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered >
                                        <Modal.Header className="bg-theme-DarkColor " >
                                            <h3 className=' text-theme-SkinColor ' style={{ width: "100%" }} >
                                                <span>{user.displayName} </span>
                                                <span style={{ float: "left" }}> <RiUserStarLine size={35} className='privacy' />  </span>
                                            </h3>
                                        </Modal.Header>
                                      {isEmployer?  <Modal.Body id="appp" className='modal-calc card-body  privacy-large'  >
                                            <br />
                                            <p> <a href='/fill_Company' className='text-theme-DarkColor'><span> <RiUserSettingsLine size={30} className='text-theme-DarkColor' /></span> ملفك الشخصي    </a></p>
                                            <p> <a href='/fill_job' className='text-theme-DarkColor'> <span> <MdOutlineAddCircleOutline size={30} className='text-theme-DarkColor' /> </span> وظيفة جديدة    </a></p>
                                            <p> <a href={'/employer_jobs/' + user.uid} className='text-theme-DarkColor'> <span> <MdOutlinePlaylistAddCheck size={30} className='text-theme-DarkColor' /> </span> الوظائف المعروضة    </a></p>
                                            {/* <p> <a href='/fill_Company'  className='text-theme-DarkColor'> <span> <RiLogoutCircleLine size={30}  className='text-theme-DarkColor'/></span> تسجيل الخروج     </a></p> */}
                                            <br />
                                            {/* <ReactCalculator className="calc-react" /> */}
                                        </Modal.Body>:
                                          <Modal.Body id="appp" className='modal-calc card-body  privacy-large'  >
                                          <br />
                                          <p> <a href='/fill_cv' className='text-theme-DarkColor'><span> <RiUserSettingsLine size={30} className='text-theme-DarkColor' /></span> ملفك الشخصي    </a></p>
                                          {/* <p> <a href='/fill_Company' className='text-theme-DarkColor'> <span> <MdOutlineAddCircleOutline size={30} className='text-theme-DarkColor' /> </span> وظيفة جديدة    </a></p> */}
                                          {/* <p> <a href={'/employer_jobs/' + user.uid} className='text-theme-DarkColor'> <span> <MdOutlinePlaylistAddCheck size={30} className='text-theme-DarkColor' /> </span> الوظائف المعروضة    </a></p> */}
                                          {/* <p> <a href='/fill_Company'  className='text-theme-DarkColor'> <span> <RiLogoutCircleLine size={30}  className='text-theme-DarkColor'/></span> تسجيل الخروج     </a></p> */}
                                          <br />
                                          {/* <ReactCalculator className="calc-react" /> */}
                                      </Modal.Body>}
                                        <Modal.Footer className=' bg-theme-DarkColor ' dir='ltr' >

                                            <a onClick={handleCloseUser} className='ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor' >
                                                إغلاق
                                            </a>

                                            <br />
                                        </Modal.Footer>
                                    </Modal>
                                  
                                    {/* {!user&& <div className="header_btn">
                                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                            href={process.env.PUBLIC_URL + '/register'}>حساب جديد</a>
                                        </div>}
                                        {user&&
                                        <div className="header_btn">
                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                       onClick={logout} href={process.env.PUBLIC_URL + '/register'}>تسجيل الخروج</a>
                                    </div>
                                    } 
                                      {user&& !isEmployer &&
                                        <div className="header_btn" style={{marginLeft:"5px"}}>
                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                        href={process.env.PUBLIC_URL + '/fill_cv'}>Edit Your profile</a>
                                    </div>
                                    }
                                    {user && isEmployer &&
                                        <div className="header_btn" style={{marginLeft:"5px"}}>
                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                        href={process.env.PUBLIC_URL + '/Start_job'}>Create Job</a>
                                    </div>
                                    }
                                    {user && isEmployer &&
                                        <div className="header_btn" style={{marginLeft:"5px"}}>
                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                        href={process.env.PUBLIC_URL + '/Start_Company'}>Edit yor profile</a>
                                    </div>
                                    } */}
                                </div>{/* site-navigation end */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* site-header-menu end */}
        </header>

    )

}

// export default Header;