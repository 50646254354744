import React, { Component, useState, useEffect } from 'react';
import '../firebase/config';
import ReactPaginate from 'react-paginate';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import Footer from '../components/layout/Footer';
import Profile from '../assets/img/massavatar/massprofile.png'
import { useCollection } from '../hooks/useCollection';
import { timestamp } from '../firebase/config';
import { BsCheck2All } from 'react-icons/bs';
import {GrNext, GrPrevious} from 'react-icons/gr'

export default function Employers_list  () {

    const [data, setData] = useState([])

    const { documents, err } = useCollection(
        'employers', ["createdAt", "!=", null],
         ["createdAt", "desc"] // Get Names From Z to A
    )
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageData, setcpData] = useState([])
    const PER_PAGE = 10;
    const offset = currentPage * PER_PAGE;
    const [pageCount, setpCount] = useState()
    const [startAt, setStartAt] = useState()
    const [filter, setFilter] = useState([])
    const [gender, setGender] = useState("")
    const [all, setAll] = useState("")
    const [isChecked, setChecked] = useState(false)

    const [category, setCategory] = useState("")
    const [filterDate, setFilterDate] = useState("")
    useEffect(() => {
        if (documents) {
            setData(documents.filter((row) => row.createdAt))
        }
    }, [documents])
    useEffect(() => {
        if (data) {
            setcpData(data
                .slice(offset, offset + PER_PAGE))
            setpCount(Math.ceil(data.length / PER_PAGE));
        }
    }, [data, currentPage])

    const ScrollToTop = () => window.scrollTo({ top: 40, behavior: "smooth" });

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
        ScrollToTop()
    }

    useEffect(() => {
        if (isChecked) {
            // setData(documents)
            window.location.reload(false)

        } 
             
                    else
                        if ( !filterDate && category) {
                            setData(documents)
                            // console.log("filter by category :",  category)

                            setData(documents.filter((row) => row.compCat == category))
                        } else
                            if ( filterDate && category) {
                                setData(documents)
                                // console.log("filter by date and cat :",  category , filterDate)

                                setData(documents.filter((row) => row.compCat == category && flt(filterDate, row.createdAt)))
                            } else
                                if ( filterDate && !category) {
                                    setData(documents)
                                    // console.log("filter by date :",  filterDate)

                                    setData(documents.filter((row) =>  flt(filterDate, row.createdAt)))
                                }else
                                if (filterDate) {
                                    setData(documents)
                                    //  console.log("=filter by Date= :", filterDate)
                                    setData(documents.filter((row) => flt(filterDate, row.createdAt)))
                                }

        function flt(filterDate, dbDate) {
            const dateFilter = parseFloat(filterDate)
            const today = timestamp.fromDate(new Date())
            const time = new Date(today.seconds * 1000);
            const cretedDate = dbDate
            const timedb = new Date(cretedDate.seconds * 1000);
            const last7Days = new Date((time - timedb))
            const thisDatr = new Date(time - dateFilter)
            console.log(((timedb < time) && (timedb > thisDatr)))
            return ((timedb < time) && (timedb > thisDatr))
        }

    }, [ category, filterDate, isChecked])


    // End Date Handler
    const handleCheckChange = () => {
        setChecked(!isChecked);
    };
        return (

            <div className="site-main">
                <Header/>
            
                {/* PageHeader */} 
                <PageHeader
                    title="قائمة الشركات "
                    breadcrumb="شركات "
                />
                {/* PageHeader end */}


                <div className="ttm-row sidebar job-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
                                <div className="job_list-widget" dir='rtl'>
                                <aside className="widget job-widget">
                                    <h3 className="widget-title">
                                        {/* <i className="flaticon flaticon-gender"></i> */}
                                        <BsCheck2All size={25} className='widget-icon' />
                                        الكل </h3>
                                    <form id="list4" className="list-filter">
                                        <div  >
                                            <label className="radio">
                                                <input type="radio" value="all" name="all"
                                                    checked={isChecked}
                                                    onChange={handleCheckChange}

                                                /> الكل 
                                            </label>
                                            {/* <input className="w-auto mr-10 mt-5"
                                                id="cookies-consent"
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={handleCheckChange}

                                            /> */}

                                        </div>
                                    </form>
                                </aside>
                                    <aside className="widget job-widget">
                                        <h3 className="widget-title"><i className="flaticon flaticon-calendar-1"></i>تاريخ الاضافة </h3>
                                        <form id="list1" className="list-filter">
                                        <div  >
                                            <label className="radio">
                                                <input type="radio" value="86400000" name="post_date"
                                                    onChange={e => (setFilterDate(e.target.value))}
                                                /> اليوم
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="604800000" name="post_date"
                                                    onChange={e => (setFilterDate(e.target.value))}
                                                /> آخر 7 ايام
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="1209600000" name="post_date"
                                                    onChange={e => (setFilterDate(e.target.value))}
                                                /> آخر 14 يومًا
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="1814400000" name="post_date"
                                                    onChange={e => (setFilterDate(e.target.value))}
                                                />آخر 21 يومًا
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="2592000000" name="post_date"
                                                    onChange={e => (setFilterDate(e.target.value))}
                                                /> آخر 30 يومًا
                                            </label>
                                        </div>
                                        </form>
                                    </aside>
                                    <aside className="widget job-widget">
                                        <h3 className="widget-title"><i className="flaticon flaticon-subfolder-1"></i>فئات</h3>                                        
                                        <form id="list2"  className="list-filter">
                                        <div  >
                                            <label className="radio">
                                                <input type="radio" value="1" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                />  محاسبة و مالية
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="2" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> تكنولوجيا
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="3" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> زراعة
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="4" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> بناء
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="5" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> وظائف طبية
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="6" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> انتاج
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="7" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> نقل
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="8" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> Digital Design & Social Media
                                            </label>
                                        </div>
                                        </form>
                                    </aside>
                                </div>
                                <aside className="widget widget-banner text-theme-WhiteColor">
                                    <div className="ttm-col-bgcolor-yes bg-theme-DarkColor text-theme-WhitColor col-bg-img-seven ttm-col-bgimage-yes ttm-bg p-40">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor" style={{ backgroundImage: 'url(/images/pics/col-bgimage-7.jpg)' }}>
                                            <div className="ttm-col-wrapper-bg-layer-inner bg-theme-DarkColor"></div>
                                        </div>
                                        <div className="layer-content text-center">
                                            <div className="widget-banner-inner">
                                                <h3 className="mb-15">Make a Difference with Online Resume!</h3>
                                                <p className="mb-30">Your Resume in Minutes with Jobs Resume Assistant is Ready!</p>
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                                // href={process.env.PUBLIC_URL + '/'}>appoinments!</a>
                                                href={process.env.PUBLIC_URL + '/start_cv'}>Create Your CV !</a>

                                            </div>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                            <div className="col-lg-8 content-area">
                                <div className="row">
                                    <div className="col-md-12 " dir='rtl'>
                                        <div className="showing-result-block d-sm-flex align-items-center justify-content-between">
                                        <span className="showing-result">عرض  1–10   من  {data.length} شركات  :</span>
                                        </div>
                                    </div>
                                </div>
                           {data? <div className="row">
                           {currentPageData.map((employer, index) => (
                                <div className="col-lg-12 col-md-12" key={index} dir='rtl'>
                                    {/* featured-imagebox */}
                                    <div className="featured-imagebox featured-imagebox-employer">
                                        <div className="featured-thumbnail">
                                            <img src={employer.profilePicture?employer.profilePicture:Profile} />
                                        </div>
                                        <div className="featured-content" style={{marginRight:"10px"}}>
                                            <div className="select-btn">
                                                {/* <i className="fa fa-heart"></i> */}
                                            </div>
                                            <div className="featured-title title-white">
                                                {/* <h3>{employer.companyName}</h3> */}
                                                <h3> <a href={process.env.PUBLIC_URL + "/employers_details/" + employer.id}> {employer.companyName} </a></h3>

                                            </div>
                                            <div className="featured-bottom">
                                                <div className="job-meta">
                                                    <span><i className="fa fa-map-marker-alt"></i>{employer.cAddress}</span>
                                                    <span><i className="fa fa-hand-holding-usd"></i>{employer.payRange}</span>
                                                    {/* <span>Skills :   HTML, CSS, JavaScript, Wordpress, PHP</span> */}
                                                </div>
                                                <div className="view-block">
                                                    <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                                    href={process.env.PUBLIC_URL + "/employers_details/" + employer.id}>تفاصيل الشركة </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{/* featured-imagebox end */}
                                </div>))}
                                
                                <div className="col-lg-12">
                                <div className="job-pagination-block">
                                    <ReactPaginate

                                        previousLabel={<GrPrevious />}
                                        nextLabel={<GrNext />}
                                        pageCount={pageCount}
                                        pageLinkClassName={"page-nav-link "}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination  "}
                                        previousLinkClassName={"page-nav-link no"}
                                        nextLinkClassName={"page-nav-link no"}
                                        linkClassName={"page-nav-link current"}
                                        disabledClassName={"pagination__link--disabled"}
                                        activeClassName={""}
                                        activeLinkClassName={"current"}
                                    />
                                </div>
                            </div>
                                
                            </div> :""}
                                
                                {/* row end */}
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>


                {/* action-section */}
                <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-md-flex align-items-center justify-content-between">
                                    {/* featured-icon-box */}
                                    <div className="featured-icon-box icon-align-before-content style2">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                                <i className="flaticon flaticon-recruitment-5"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                           

                                                <h3> We connect the dots to <span className=""> Your Future Job.</span></h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>We have over 30 years experience oft Business porro qusquam dol ipsum quia dolor sit amet.</p>
                                            </div>
                                        </div>
                                    </div>{/* featured-icon-box end */}
                                    <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" href='/contact-us'>Contact Us!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* action-section end */}

                
            <Footer/>
                        
            </div>
          )
      }
