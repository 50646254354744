import React, { useState, useEffect } from 'react';
import HeaderLogo from '../components/layout/HeaderLogo'
import Header from '../components/layout/Header';

import PageHeader from "../components/layout/PageHeader";
import Footer from '../components/layout/Footer';
import {FiUserCheck} from 'react-icons/fi'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useLogin } from '../hooks/useLogin'
export default function Login() {
    const { login, error, isPending } = useLogin()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        login(email, password)
    }



    return (

        <div className="site-main">
            <HeaderLogo />

            {/* PageHeader */}
            <PageHeader
                title="وظف أفضل المهارات في مشروعك"
                breadcrumb="تسجيل الدخول "
                isHome = "false"
            />
            {/* PageHeader end */}


            {/* login */}
            <div className="ttm-row login-section clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-50 p-lg-20">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                <div className="layer-content">
                                    <div className="text-center mb-20">
                                        <h3>تسجيل الدخول لحسابك</h3>
                                    </div>
                                    <div className="ttm-tabs ttm-tab-style-02">
                                        <Tabs>
                                            <TabList className="tabs">
                                                <Tab className="tab">
                                                    <a>
                                                        <i className="flaticon flaticon-research"></i>
                                                        <span></span><h5 style={{textAlign:"right"}}>تسجيل الدخول </h5>
                                                    </a>
                                                </Tab>
                                                {/* <Tab className="tab">
                                                        <a>
                                                            <i className="flaticon flaticon-job-search"></i>
                                                            <span>Employer</span><h5>Login as a Employer</h5>
                                                        </a>
                                                    </Tab> */}
                                            </TabList>
                                            <div className="content-tab">
                                                <TabPanel>
                                                    <form id="login_form" onSubmit={handleSubmit} className="login_form wrap-form" dir='rtl'>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <label>
                                                                    <i className="ti ti-email"></i>
                                                                    <input type="email" id="txtemail"
                                                                        placeholder="بريدك الالكتروني "
                                                                        required="required"
                                                                        onInvalid={e => e.target.setCustomValidity('Enter a valid User Email')}
                                                                        onInput={e => e.target.setCustomValidity('')}
                                                                        onChange={(e) => setEmail(e.target.value)}
                                                                        value={email}
                                                                    />
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label>
                                                                    <i className="ti ti-lock"></i>
                                                                    <input type="password" id="password"
                                                                        placeholder="كلمة المرور "
                                                                        onChange={(e) => setPassword(e.target.value)}
                                                                        value={password}
                                                                    />
                                                                </label>
                                                            </div>
                                                                <div className="col-lg-12 mt-20 "  >


                                                                    <p style={{ fontWeight: "bold", fontSize: "normal" }}>
                                                                        <FiUserCheck size={30} style={{ marginLeft: "10px" }} className="text-theme-SkinColor" />

                                                                        <span > لا تملك حساب ؟  </span>
                                                                        <a href={process.env.PUBLIC_URL + '/Register'} className="text-theme-SkinColor"> انشاء حساب جديد  هنا</a></p>
                                                                </div>
                                                                {/* <label>
                                                                    <div className="forgot-pwd text-center mt-5">
                                                                        <p><a href="#" className="text-theme-SkinColor">Forgot Password?</a></p>
                                                                        <p dir='rtl'>ليس لديك حساب ? <a href={process.env.PUBLIC_URL + '/register'} className="text-theme-SkinColor">انشاء حساب جديد</a></p>
                                                                    </div>
                                                                </label> */}
                                                           
                                                            {error &&
                                                                <div>
                                                                    <div className="mt-15">
                                                                        <h4 className="" style={{ color: 'red', fontWeight: 'bold' }}>{error}</h4>
                                                                    </div>
                                                                </div>}
                                                            {!isPending && <div className="col-lg-6 mx-auto">
                                                                <label className="mb-0">
                                                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="submit">دخول</button>
                                                                </label>
                                                            </div>}
                                                            {isPending && <div className="col-lg-6 mx-auto">
                                                                <label className="mb-0">
                                                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" type="submit"> .... جاري الدخول </button>
                                                                </label>
                                                            </div>}
                                                        </div>
                                                    </form>
                                                </TabPanel>
                                                <TabPanel>
                                                    <form id="login_form" className="login_form wrap-form" dir='rtl'>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <label>
                                                                    <i className="ti ti-email"></i>
                                                                    <input type="email" id="txtemail" placeholder="Email Address" />
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label>
                                                                    <i className="ti ti-lock"></i>
                                                                    <input type="password" id="password" placeholder="Password" />
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label>
                                                                    <div className="forgot-pwd text-center mt-5">
                                                                        <p><a href="#" className="text-theme-SkinColor">Forgot Password?</a></p>
                                                                        <input className="w-auto mr-10" id="cookies-consent" name="cookies-consent" type="checkbox" value="yes" />
                                                                        <span>Remember Password</span>
                                                                    </div>
                                                                </label>
                                                            </div>

                                                            <div className="col-lg-6 mx-auto">
                                                                <label className="mb-0">
                                                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                                        type="submit">Login</button>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </TabPanel>
                                            </div>
                                        </Tabs>
                                    </div>
                                    {/* <div className="login-social-buttons">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <button id="login-with-facebook" className="social-account-button w-100 facebook-button">
                                                        <i className="ti ti-facebook"></i><span>Login With facebook</span>
                                                    </button>
                                                </div>
                                                <div className="col-md-6">
                                                    <button id="login-with-google" className="social-account-button w-100 google-button">
                                                        <i className="ti ti-google"></i><span>Login With Google</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* login end */}


            <Footer />

        </div>
    )
}
