import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import  Footer  from '../components/layout/Footer';

export class Accessibility extends Component {
    render() {
        return (

            <div className="site-main">
                <Header />

                {/* PageHeader */}
                <PageHeader
                    title="إمكانية الوصول"
                    breadcrumb="إمكانية الوصول "
                />
                {/* PageHeader end */}


                <div className="ttm-row sidebar ttm-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12 content-area" style={{ margin: "auto", textAlign: 'right'}}>
                                {/* post */}
                                <article className="post ttm-blog-single clearfix">
                                    {/* post-featured-wrapper */}
                                    {/* <div className="ttm-post-featured-wrapper ttm-featured-wrapper">
                                        <div className="ttm-post-featured">
                                            <img className="img-fluid" src="https://via.placeholder.com/1200x800?text=1200x800+blog-01-1200x800.jpg" alt="blog-img" />
                                        </div>
                                    </div> */}
                                    {/* post-featured-wrapper end */}
                                    {/* ttm-blog-classic-content */}
                                    <div className="ttm-blog-single-content">
                                        {/* <div className="ttm-post-entry-header">
                                            <div className="post-meta">
                                                <span className="ttm-meta-line author">
                                                    <img src="https://via.placeholder.com/294x190?text=author-img-294x190.jpg
" className="img-fluid" height="80" width="80" alt="author"/>By: Edward Samuel
                                                </span>
                                                <span className="ttm-meta-line comment-links"><i className="fa fa-comments"></i>03 Comments</span>
                                                <span className="ttm-meta-line date"><i className="fa fa-calendar"></i>17 Apr 2021</span>
                                            </div>
                                        </div> */}
                                        <div className="entry-content">
                                            <h2 dir='rtl' style={{textAlign:"center"}}>إمكانية الوصول <span className='text-theme-SkinColor'>( MassRes )</span></h2>

                                            <div className="ttm-box-desc-text" dir='rtl'>


                                                <div className="d-md-flex align-items-center mb-20">
                                                    {/* <img className="img-fluid alignleft" src="https://via.placeholder.com/332x324?text=single-img-07-332x324.jpg" alt="single-07" /> */}
                                                    <div className="pt-10 pb-15">
                                                        <h3>بيان الوصول إلى الويب </h3>

                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                            MassRes ملتزمة بتسهيل وتحسين إمكانية الوصول وسهولة استخدام موقعها على شبكة الإنترنت. تسعى MassRes جاهدة لضمان أن خدمات ومحتويات موقعها الإلكتروني متاحة للأشخاص ذوي الإعاقة ، بما في ذلك مستخدمي تقنية قارئ الشاشة. تحقيقًا لهذه الغاية ، والتي تجعل موقع الويب متوافقًا أيضًا مع قانون موقع الويب متاحًا قدر الإمكان ، يمكن مواجهة بعض المشكلات بواسطة تقنيات مساعدة مختلفة نظرًا لأن نطاق التكنولوجيا المساعدة واسع ومتنوع.
                                                            </div></li>
                                                            {/* <li><i className="fas fa-long-arrow-alt-right"></i><div className="ttm-list-li-content">Nulla at metus ultricies, placerat augue sed.</div></li>
                                                            <li><i className="fas fa-long-arrow-alt-right"></i><div className="ttm-list-li-content">Curabitur mollis ex vestibulum, ullamcorper.</div></li>
                                                            <li><i className="fas fa-long-arrow-alt-right"></i><div className="ttm-list-li-content">Nulla at metus ultricies, placerat augue sed.</div></li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                              

                                                <div className="d-md-flex align-items-center mb-20">
                                                    <div className="pt-10 pb-15">
                                                        <h3>اتصل بنا </h3>

                                                        <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">

                                                            <li><i className="fas fa-long-arrow-alt-left"></i><div className="ttm-list-li-content">
                                                            إذا كانت لديك ، في أي وقت ، أسئلة أو مخاوف محددة حول إمكانية الوصول إلى أي صفحة ويب معينة على هذا الموقع ، فيرجى الاتصال بنا على <span> <a href='mailto:massres.dev@gmail.com'> legal@MassRes.com </a></span>. إذا واجهت مشكلة تتعلق بإمكانية الوصول ، فيرجى التأكد من تحديد صفحة الويب وطبيعة المشكلة في بريدك الإلكتروني ، وسنبذل كل الجهود المعقولة لجعل هذه الصفحة أو المعلومات الواردة فيها في متناولك.                                                            </div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>{/* ttm-blog-classic-content end */}
                                </article>{/* post end */}
                            </div>

                        </div>{/* row end */}
                    </div>
                </div>

                <Footer />

            </div>
        )
    }
}

export default Accessibility;