import React, {Component} from 'react';
import myLogo from '../../assets/img/massres-dark-logo.svg'
class Logo extends Component {
    render() {
        return (

            <a className="home-link" href={process.env.PUBLIC_URL + '/'} title="Hireco" rel="home">
                <img id="logo-img" height="42" width="170" className="img-fluid auto_size" src={myLogo} alt="logo-img"/>
                {/* <img id="logo-img" height="42" width="170" className="img-fluid auto_size" src="images/logo-new.png" alt="logo-img"/> */}


             </a>
        );
    }
}

export default Logo;