import React, { Component } from "react";
import Slider from 'react-slick';
import logo from '../../assets/img/massavatar/182x240.png'



export class Banner extends Component {
    state = {
        show: false,
    }
    render() {
        var slick_slider = {
            dots: false,
            arrow: false,
            autoplay: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 1,

            responsive: [{

                breakpoint: 1199,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {

                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {

                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        };
        // console.log("props: ", this.props)

        return (

            <Slider className="slick_slider banner_slider banner_slider_3" {...slick_slider} slidesToShow={1} autoplay={false} fade="true" dir=''>
                <div className="slide bg-layer">
                    <div className="slide_img" style={{ backgroundImage: 'url(https://via.placeholder.com/1920x730?text=slider-mainbg-005-1920x730.jpg)' }}></div>
                    <div className="slide__content pb-50 pb-xl-0 pt-130 pt-xl-0">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6" >
                                    <div className="d-none d-xl-block text-center position-relative" dir="rtl">
                                        <div className="z-index-1 position-relative">

                                      <img  className="rounded-circle box-shadow m-auto img-fuild" src="images/slides/single-01.jpg"></img>
                                    </div>
                                        <div className="ttm-fid-icon-wrapper cmp-logo four">
                                        <img className="img-fluid" src="images/slides/icon-3.png" />
                                        </div>
                                        <div className="cmp-logo one">
                                            <img className="img-fluid" src="images/slides/icon-2.png" />
                                        </div>
                                        <div className="cmp-logo two">
                                            <img className="img-fluid" src="images/slides/icon-5.png"  />
                                        </div>
                                        <div className="cmp-logo three">
                                            <img className="img-fluid" src="images/slides/icon-4.png"  />
                                        </div>
                                        {/* <div className="cmp-logo four">
                                        <img className="img-fluid" src="https://via.placeholder.com/210x204?text=logo4.png" />
                                    </div> */}
                                        <div className="cmp-logo five">
                                            <img className="img-fluid" src="images/slides/icon-1.png" />
                                        </div>
                                        <div className="arrow-one">
                                            <img src="images/slides/arrow-1.png" />
                                        </div>
                                        <div className="arrow-two">
                                            <img src="images/slides/arrow-2.png" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-8" dir="rtl">
                                    <div className="slide__content--headings ttm-textcolor-white">
                                        <h2 className="animated animate__fadeInDown">Mass<span className="text-theme-SkinColor fw-normal">Res </span></h2>
                                        <h2 className="animated animate__fadeInDown"> وظف أفضل المهارات في مشروعك </h2>
                                        <div className="mt-20 mb-30 mb-md-15">
                                            <p className="animated animate__fadeInDown d-none d-md-block">
                                                مهمتنا خلق الفرص الاقتصادية حتى يعيش الناس حياة أفضل هدفنا فتح سوق العمل في العالم العربي حيث تلتقي هنا كل يوم الشركات من جميع الأحجام والمواهب المستقلة من جميع أنحاء العالم العربي لإنجاز أشياء لا تصدق.</p>

                                        </div>
                                        {this.props.page!="register" &&<div className="animated animate__fadeInUp" data-delay="1.4">
                                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                href={process.env.PUBLIC_URL + '/job_list'}>وظائف  </a>
                                        </div>}
                                        {this.props.page=="register" &&<div className="animated animate__fadeInUp" data-delay="1.4">
                                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                href="#register">حساب جديد  </a>
                                        </div>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Slider>

        )
    }
}

export default Banner;