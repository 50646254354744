import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import Profile from '../assets/img/massavatar/massprofile.png'
import ImageUploading from "react-images-uploading";
import Header from '../components/layout/Header';
import { GrAddCircle, GrUploadOption } from 'react-icons/gr';
import { IoMdAddCircleOutline } from 'react-icons/io'
import { AiFillEdit, AiOutlineArrowRight, AiOutlineArrowLeft, AiOutlineFilePdf } from 'react-icons/ai';
import { MdOutlineLanguage } from 'react-icons/md';
import { GrDocumentDownload } from 'react-icons/gr'
import { RiDeleteBinFill } from 'react-icons/ri'
import { BiAddToQueue, BiBold, BiEdit, BiSave, BiUpload, BiShoppingBag } from "react-icons/bi";
import { SlPhone } from 'react-icons/sl'
import { BsSave } from 'react-icons/bs'
import { resetIdCounter, Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ProgressBar from 'react-animated-progress-bar';
// =========
import { useAuthContext } from '../hooks/useAuthContext';
import { usePostNestDocs } from '../hooks/usePostNestDocs';
import { useFetchNestDocs } from '../hooks/useFetchNestDocs';
import { useCollection } from '../hooks/useCollection';
import { projectStorage } from '../firebase/config';
import { useDocument } from '../hooks/useDocument';
import { EditorState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertToRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { v4 as uuidv4 } from 'uuid'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas';
import logo from '../assets/img/blank-img.png'
const containerVariants = {
    hidden: {
        opacity: 0,
        x: '-100vw'
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: { type: 'spring', delay: 0.5 }
    },
    exit: {
        x: "-100vh",
        transition: { ease: 'easeInOut' }
    }
};

const nextVariants = {
    hidden: {
        x: '-20vw'
    },
    visible: {
        x: 0,
        transition: { ease: 'easeInOut' }

        // transition: { type: 'spring', stiffness: 120 }
    }
};
const nextVariantsRight = {
    hidden: {
        x: '20vw'
    },
    visible: {
        x: 0,
        transition: { ease: 'easeInOut' }

        // transition: { type: 'spring', stiffness: 120 }
    }
}
const buttonVariants = {
    hover: {
        scale: 1.1,
        // textShadow: "0px 0px 8px rgb(255,255,255)",
        fontWeight: "bold",
        boxShadow: "0px 0px 8px rgb(255,255,255)",

        transition: {
            duration: 0.5,
            yoyo: Infinity
        }
    }
}

const buttonVariantsUpload = {
    hover: {
        scale: 1.3,
        textShadow: "0px 0px 8px rgb(255,255,255)",
        // boxShadow: "0px 0px 8px rgb(255,255,255)",
        color: "#ff8d00",
        transition: {
            duration: 0.7,
            yoyo: Infinity
        }

    }
}
const warningVariants = {

    visible: {
        scale: 0.1,
        textShadow: "0px 0px 8px rgb(255,255,255)",
        boxShadow: "0px 0px 8px rgb(255,255,255)",
        transition: {
            duration: 0.7,
            yoyo: Infinity
        }
    }
}

// var mylink
export default function FillCv() {

    const printRef = React.useRef();
    const printRef2 = React.useRef();
    const[isPrint, setIsPrint] = useState(false);
    const x = useMotionValue(5)
    const { user } = useAuthContext();
    const [data, setData] = useState([])
    const [coData, setCoData] = useState()

    const [isSaved, setIsSaved] = useState(false)
    const [show, setShow] = useState(false)
    const { documents, err } = useCollection(
        'users', ["isEmployer", "==", false],
    )
    // if (documents) {
    //     console.log("documents : ", documents)
    // }
    const langarr = { '50': 'Beginner', '75': 'Good', '80': 'Excellent', '100': 'Native' }
    // const { documents, error } = useFetchNestDocs(
    //     'candidates', user.uid, "resume"
    // )
    const [saving, setSaving] = useState(false)
    const [editing, setEditing] = useState(false)
    const { addDocument, updateDocument, response } = usePostNestDocs('users', user.uid, "resume")
    const { document, error } = useDocument('users', user.uid)
    // Cv_Contact_Info
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [occupation, setOccupation] = useState("");
    const [nationality, setNationality] = useState("");
    const [dateOfBirth, setDate] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [gender, setGender] = useState("");
    const [category, setCategory] = useState("")
    // Cv_SOCIAL_LINKS_Info
    const [links, setLinks] = useState([])
    const [link, setLink] = useState("");
    const [facebook, setFacebook] = useState("");
    const [instagram, setInstagram] = useState("");
    const [twitter, setTwitter] = useState("");
    const [portfolio, setProtfolio] = useState("");
    const [profilePicture, setProfileP] = useState("")
    // Cv_Work Exp_Info
    const [expData, setExpData] = useState([])
    const [position, setPosition] = useState("")
    const [company1, setCompany1] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [jobLocation, setJobLocation] = useState("")
    const [convertedContent, setConvertedContent] = useState();
    // CV_Skills_Info
    const [skill, setSkill] = useState("")
    const [skills, setSkills] = useState([])
    const [skilLevel, setSkilLevel] = useState("0")
    const [languages, setLanguages] = useState([])
    const [language, setLanguage] = useState("")
    const [languageLevel, setLanguageLevel] = useState("0")
    // CV_EDUCATION_INFO
    const [degree, setDegree] = useState("")
    const [schoolName, setSchoolName] = useState("")
    const [schoolStartDate, setSchoolStart] = useState("")
    const [schoolEndDate, setSchoolEnd] = useState("")
    const [schoolLocation, setSchoolLocation] = useState("")
    const [schoolEditorState, setSchoolEditorState] = useState(() => EditorState.createEmpty())
    const [convertedContentSc, setConvertedContentSc] = useState();
    // CV_ABOUT_INFO
    const [aboutEditorState, setAboutEditorState] = useState(() => EditorState.createEmpty())
    const [convertedContentAb, setConvertedContentAb] = useState();

    const [isCheckedSchool, setCheckedSchool] = useState(false)
    const [education, setEdu] = useState("")
    const [educations, setEdus] = useState([])

    // const raw = convertToRaw(_contentState)
    // const [contentState, setContentState] = useState(raw)
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
    const [isChecked, setChecked] = useState(false)
    const [currentDate, setCurrentDate] = useState("")
    const [endYear, setEndYear] = useState("")

    // const [tabId, setTabId] = useState(0);
    // const [images, setImages] = React.useState([]);
    // const [isImage, setIsImage] = useState(false)


    useEffect(() => {
        if (document) {
            setData(document)
            // console.log(data, "data")

            setExpData(document.expData ? document.expData : [])
            setSkills(document.skills ? document.skills : [])
            setEdus(document.educations ? document.educations : [])
            setLanguages(document.languages ? document.languages : [])
            setConvertedContentAb(document.convertedContentAb)
            setEmail(document.cvEmail ? document.cvEmail : "")
            setAddress(document.address ? document.address : "")
            setOccupation(document.occupation ? document.occupation : "")
            setFirstName(document.firstName ? document.firstName : "")
            setLastName(document.lastName ? document.lastName : "")
            setPhoneNumber(document.phoneNumber ? document.phoneNumber : "")
            setProfileP(document.profilePicture ? document.profilePicture : "")
            setConvertedContentAb(document.aboutMeObjective ? document.aboutMeObjective : "")
            setTwitter(document.twitter ? document.twitter : "")
            setProtfolio(document.portfolio ? document.portfolio : "")
            setFacebook(document.facebook ? document.facebook : "")
            setNationality(document.nationality ? document.nationality : "")
            setDate(document.dateOfBirth ? document.dateOfBirth : "")
            setLink(document.link ? document.link : "")
            setInstagram(document.instagram ? document.instagram : "")
            setCategory(document.category ? document.category : "")
            setGender(document.gender ? document.gender : "")
            //    setImages(document.profilePicture)


        }
    }, [document])






    // End Date Handler
    const handleCheckChange = () => {
        setChecked(!isChecked);
    };
    // End Date School Handler
    const handleSchoolCheckChange = () => {
        setCheckedSchool(!isCheckedSchool);
    };
    const showCv = () => {
        setShow(!show)
    }

    useEffect(() => {
        if (isChecked) {
            const currentD = new Date().toLocaleDateString();
            let sp = currentD.split('/')
            const cureentDate = sp[2] + "/" + sp[1] + "/" + sp[0]
            setEndDate(cureentDate)
        }
    })
    useEffect(() => {
        if (isCheckedSchool) {
            const currenD = new Date().toLocaleDateString();
            let sp = currenD.split('/')
            const cureentDate = sp[2] + "/" + sp[1] + "/" + sp[0]
            setSchoolEnd(cureentDate)
        }
    })
    // Text Editor Handler
    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();

    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
        // console.log("Editor state:", currentContentAsHTML)
    }
    const handleEditorChangeSc = (state) => {
        setSchoolEditorState(state);
        convertContentToHTMLSc();

    }
    const convertContentToHTMLSc = () => {
        let currentContentAsHTML = convertToHTML(schoolEditorState.getCurrentContent());
        setConvertedContentSc(currentContentAsHTML);
        // console.log("Editor state:", currentContentAsHTML)
    }

    const handleEditorChangeAb = (state) => {
        setAboutEditorState(state);
        convertContentToHTMLAb();

    }
    const convertContentToHTMLAb = () => {
        let currentContentAsHTML = convertToHTML(aboutEditorState.getCurrentContent());
        setConvertedContentAb(currentContentAsHTML);
        // console.log("Editor state:", currentContentAsHTML)
    }


    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }
    // Tabs Switching
    const [prev, setprev] = useState(0)
    let xx = "";
    const [tabId, setTabId] = useState(0);
    const [images, setImages] = React.useState([]);
    const [isImage, setIsImage] = useState(false)
    const maxNumber = 1;
    const maxSize = 5000000; //50 mega

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        console.log("image list", imageList, addUpdateIndex);

        setImages(imageList);

    };

    async function incrementCounter() {
        const newt = tabId + 1
        const ScrollToTop = () => window.scrollTo({ top: 40, behavior: "smooth" });
        ScrollToTop()
        setTabId(newt)

        // console.log("tab is id : ", newt)
    }
    function decrementCounter() {
        const newt = tabId - 1
        const ScrollToTop = () => window.scrollTo({ top: 40, behavior: "smooth" });
        ScrollToTop()
        setTabId(newt)
    }

    const addExp = (e) => {
        e.preventDefault()
        const newEpx = {
            id: uuidv4(),
            position: position ? position : "",
            company1: company1 ? company1 : "",
            startDate: startDate ? startDate : "",
            endDate: endDate ? endDate : "",
            jobLocation: jobLocation ? jobLocation : "",
            convertedContent: convertedContent ? convertedContent : "",
        }
        // updateDocument(user.uid, ...expData)
        setExpData([...expData, newEpx])
        // updateDocument(user.uid,[...document.expData,newEpx])
        setPosition("")
        setCompany1("")
        setPosition("")
        setJobLocation("")
        setStartDate("")
        setEndDate("")
        setChecked(false)
        const neweditorState = EditorState.push(editorState, ContentState.createFromText(''));
        setEditorState(neweditorState)




    }

    const addSkill = (e) => {
        e.preventDefault()

        const newSkill = {
            id: uuidv4(),
            skill,
            skilLevel,
        }

        setSkills([...skills, newSkill])
        setSkill("")
        setSkilLevel("50")

    }


    const addEducation = (e) => {
        e.preventDefault()
        const newEdu = {
            id: uuidv4(),
            degree: degree ? degree : "",
            schoolName: schoolName ? schoolName : "",
            schoolStartDate: schoolStartDate ? schoolStartDate : "",
            schoolEndDate: schoolEndDate ? schoolEndDate : "",
            schoolLocation: schoolLocation ? schoolLocation : "",
            convertedContentSc: convertedContentSc ? convertedContentSc : "",

        }
        console.log("content- converted:", convertedContentSc)

        setEdus([...educations, newEdu])
        setDegree("")
        setSchoolName("")
        setSchoolStart("")
        setSchoolEnd("")
        setSchoolLocation("")
        setCheckedSchool(false)
        const neweditorState = EditorState.push(schoolEditorState, ContentState.createFromText(''));
        setSchoolEditorState(neweditorState)

    }
    // Delete experience function
    const deleteSkill = (id) => setSkills(skills.filter((row) => row.id !== id))
    // Delete experience function
    const deleteExp = (id) => setExpData(expData.filter((row) => row.id !== id))
    // Language Handler
    const addLanguage = (e) => {
        e.preventDefault()
        const newLanguage = {
            id: uuidv4(),
            language,
            languageLevel,
        }

        setLanguages([...languages, newLanguage])
        setLanguage("")
        setLanguageLevel("50")

    }
    // Delete experience function
    const deleteLang = (id) => setLanguages(languages.filter((row) => row.id !== id))
    // Delete experience function
    const deleteEdu = (id) => setEdus(educations.filter((row) => row.id !== id))

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSaving(true)
        // console.log("name is ? ", firstName ," ", lastName)
        const storageRef = projectStorage.ref()
        function imgUpload() {
            const imgName = images[images.length - 1].file
            const ref = storageRef.child("cv-profile-pictures/" + imgName.name)
            const uploadTask = ref.put(imgName);
            uploadTask.on('state_changed',
                (snapshot) => {
                    console.log('Image Three Upload is ' + 100 + '% done');
                },
                (error) => {
                    console.log("Somthing wrong happened, image is not uploaded");
                },
                () => {
                    ref.getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // console.log(downloadURL)
                        setProfileP(downloadURL)
                        updateDocument(user.uid, { profilePicture: downloadURL })
                    });
                }

            );


        }
        images.length > 0 ? imgUpload() : console.log("")
        let fnameLname = firstName + " " + lastName
        await updateDocument(user.uid, {
            firstName,
            lastName,
            name: fnameLname,
            gender,
            category,
            phoneNumber,
            cvEmail: email,
            address,
            occupation,
            nationality,
            dateOfBirth,
            link,
            facebook,
            instagram,
            twitter,
            portfolio,
            expData,
            skills,
            languages,
            educations,
            aboutMeObjective: convertedContentAb,
            // profilePicture: profilePicture ? profilePicture : document.profilePicture,
        })
        if (tabId == 4) {
            setShow(true)
        }
        setIsSaved(true)
        setSaving(false)


    }

    const download = () => {
        window.scrollTo(0, 0);
        setIsPrint(true)
        setTimeout(() => {
            // setTimeout(() => {
            //     setLoader(true);
            // }, 100);
        
            const divToPrint = printRef.current
           


            html2canvas(divToPrint).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = 210;
                const pageHeight = 290;

                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                const doc = new jsPDF('pt', 'mm');
                let position = 0;
                doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight + 25);
                heightLeft -= pageHeight;
                while (heightLeft >= 0) {

                    // let logo = require('images/blank-img.png');
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + 15);
                    heightLeft -= pageHeight;
                }
                const docName = firstName + lastName + '_CV.pdf'
                doc.save(docName);
                setIsPrint(false);
                showCv()
                // doc.save('download.pdf');
                // setLoader(false);
            });
        }, 1000);
    };

    return (
        <div className="site-main  "
        >
            <Header />

            {/* padding_zero-section */}

            <section className="ttm-row grid-section clearfix site-main-cv">

                <div className=" container-cv" dir='rtl'>
                    {/* row */}


                    <div className="row ">
                        {!show && <motion.div className="col-lg-6"
                            variants={containerVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit">
                            <div className="ttm-col-bgcolor-yes ttm-bg bg-theme-GreyColor h-auto p-40 p-lg-30 mt-lg-30 mt-50 mb-50">
                                <div className="layer-content">
                                    {/* section title */}
                                    <div className="section-title title-style-center_text">
                                        {tabId == 0 &&
                                            <div className="title-header">
                                                <h4 className="title">اخبرنا قليلا   <span className="text-theme-SkinColor">عن نفسك</span></h4>
                                                <h3 className='subtitle-cv'>أخبرنا من أنت وكيف يمكن لأصحاب العمل التواصل معك وما هي مهنتك. </h3>

                                            </div>}
                                        {tabId == 1 &&
                                            <div className="title-header">
                                                <h4 className="title">رائع ! دعنا نملأ    <span className="text-theme-SkinColor">تجربة العمل  </span> الخاصة بك</h4>
                                                <h3 className='subtitle-cv'>ابدأ بالوظيفة الأحدث و أكمل بشكل عكسي  ، فقط أضف الوظائف الأحدث وذات الصلة إذا كان لديك الكثير من الخبرة.</h3>

                                            </div>}
                                        {tabId == 2 &&
                                            <div className="title-header">
                                                <h4 className="title">أوشكت على الانتهاء من الأساسيات !  <span className="text-theme-SkinColor">فقط أضف قائمة من المهارات</span></h4>
                                                <h3 className='subtitle-cv'>يجب أن تعكس المهارات التي تضيفها متطلبات الوظيفة التي تتقدم لها. </h3>

                                            </div>}
                                        {tabId == 3 &&
                                            <div className="title-header">
                                                <h4 className="title">رائع! ماذا الآن, <span className="text-theme-SkinColor">هل لديك مؤهلات؟ </span></h4>
                                                <h3 className='subtitle-cv'>ابدأ بأحدث فترة تعليمية لك واستمر إلى الوراء ، إذا كان لديك الكثير ، فما عليك سوى إضافة أحدثها وذات الصلة.</h3>

                                            </div>}
                                        {tabId == 4 &&
                                            <div className="title-header">
                                                <h4 className="title">  الآن، دعنا نعمل على    <span className="text-theme-SkinColor"> هدف  </span> سيرتك الذاتية </h4>
                                                <h3 className='subtitle-cv'>يظهر هذا في الجزء العلوي من سيرتك الذاتية ، أبهر أصحاب العمل ببيان افتتاحي قوي يلخص نقاط قوتك وخبراتك.</h3>

                                            </div>}
                                    </div>{/* section title end */}
                                    {tabId == 0 &&
                                        <div className="row text-center" dir='ltr'>
                                            <div className="title-header text-right">
                                                <h5>صورة   <span className="text-theme-SkinColor"> شخصية </span></h5>

                                                {/* <h2 className="title">Request a Callback</h2> */}
                                            </div>
                                            <ImageUploading
                                                multiple
                                                value={images}
                                                onChange={onChange}
                                                maxNumber={maxNumber}
                                                maxFileSize={maxSize}
                                                dataURLKey="data_url"
                                                acceptType={['jpg', 'gif', 'png', 'jpeg']}
                                            >
                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemoveAll,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps,
                                                    errors
                                                }) => (
                                                    // write your building UI
                                                    <>
                                                        {/* Errors Div  */}
                                                        {
                                                            errors &&
                                                            <motion.div className='warning'
                                                                //  variants={warningVariants} 
                                                                style={{ x, opacity: 1, scale: 1 }}
                                                            >

                                                                {errors.acceptType && <span>نوع الملف المحدد غير مسموح به</span>}
                                                                {errors.maxFileSize && <span className='warning'>الحجم الأقصى للصورة هو (5 ميغا بايت) اختر صورة أخرى </span>}
                                                                {errors.resolution && <span>الملف المحدد لا يتطابق مع الدقة المطلوبة</span>}
                                                            </motion.div>
                                                        }
                                                        <div className='col-md-6 ttm-box-col-wrapper' >

                                                            {imageList.length == 0 &&
                                                                <img src={Profile} />
                                                            }
                                                            {imageList.map((image, index) => (
                                                                <div key={index} className="image-item">
                                                                    <img src={image.data_url ? image.data_url : "images/cv-pics/profile.png"} alt="profile picture" width="100" />
                                                                    <figcaption style={{ fontSize: "small", marginTop: "10px" }}><span >الأبعاد الموصى بها </span> <br></br> <span style={{ color: "#ff8d00" }}>(W:100 x H:100) px</span> </figcaption>
                                                                </div>
                                                            ))}
                                                        </div>

                                                        <div className='col-md-6 ttm-box-col-wrapper'>

                                                        {imageList.length == 0 &&
                                                                        <motion.button
                                                                            className='  cv-btn '
                                                                            variants={buttonVariantsUpload}
                                                                            whileHover="hover"
                                                                            onClick={onImageUpload}
                                                                            {...dragProps}
                                                                        >
                                                                            <br></br>
                                                                            <span className='text-placeHolder-color-light'>أضف صورة</span>
                                                                            <BiUpload className='cv-icon text-placeHolder-color-light' size={20} />
                                                                        </motion.button>
                                                                    }
                                                        {imageList.map((image, index) => (
                                                                        <div className="image-item">
                                                                            <div className="image-item__btn-wrapper" key={index}>
                                                                                <div className='col-md-6 text-right'>
                                                                                    <motion.button
                                                                                        className='    cv-btn  '
                                                                                        variants={buttonVariantsUpload}
                                                                                        whileHover="hover"
                                                                                        onClick={() => onImageUpdate(index)}
                                                                                    >

                                                                                        <span className='text-placeHolder-color-light'>تعديل الصورة </span>
                                                                                        <AiFillEdit className='cv-icon text-placeHolder-color-light' size={20} />
                                                                                    </motion.button>
                                                                                </div>
                                                                                <div className='col-md-6 text-right'>
                                                                                    <motion.button className='  cv-btn  '
                                                                                        variants={buttonVariantsUpload}
                                                                                        whileHover="hover"
                                                                                        onClick={() => onImageRemove(index)}>

                                                                                        <span className='text-placeHolder-color-light'>حذف الصورة </span>
                                                                                        <RiDeleteBinFill className='cv-icon text-placeHolder-color-light' size={20} />
                                                                                    </motion.button>
                                                                                </div>

                                                                                {/* <button onClick={() => onImageRemove(index)}>Remove</button> */}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                        </div>
                                                        &nbsp;
                                                        <hr></hr>

                                                        {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                                                    </>
                                                )}
                                            </ImageUploading>
                                        </div>}
                                    <form id="contact_form" className="contact_form wrap-form" onSubmit={handleSubmit}>

                                        <Tabs>


                                            {/*  ======================= Tab_One Start  ==================================== */}
                                            {/* <motion.div
                                                initial="hidden"
                                                animate="visible"
                                                exit="exit"> */}
                                            {tabId == 0 && <TabPanel>

                                                <motion.div className="row ttm-boxes-spacing-10px"
                                                    variants={prev ? nextVariantsRight : nextVariants}
                                                    initial="hidden"
                                                    animate="visible"
                                                    exit="exit">
                                                    <div className="title-header">
                                                        <h5>معلومات   <span className="text-theme-SkinColor"> الاتصال </span></h5>
                                                        <br></br>
                                                        {/* <h2 className="title">Request a Callback</h2> */}
                                                    </div>
                                                    {/*================ HEeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeer========================================== */}
                                                    <div className='col-md-12'></div>


                                                    <div className="col-md-4 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="fname" type="text" placeholder={firstName ? firstName : "الاسم الاول"}
                                                                // required="required"
                                                                required={firstName ? "false" : "required"}
                                                                value={firstName}
                                                                onChange={e => (setFirstName(e.target.value))}
                                                            />
                                                        </label>
                                                        <div className="example-cv"> مثال : محمد </div>
                                                        {/* <p>eg. Mohammed</p> */}
                                                    </div>
                                                    <div className="col-md-4 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="lname" type="text" placeholder={lastName ? lastName : "الكنية"}
                                                                required={lastName ? "false" : "required"}
                                                                value={lastName}
                                                                onChange={e => (setLastName(e.target.value))}


                                                            />
                                                        </label>
                                                        <div className="example-cv"> مثال : أحمد</div>

                                                    </div>
                                                    <div className='col-md-4 '>
                                                        <div className="list-filter  d-flex flex-row ttm-box-col-wrapper  ">
                                                            <label className="radio">
                                                                <input type="radio" value="male" name="gender"
                                                                    onChange={e => (setGender(e.target.value))}
                                                                />ذكر
                                                            </label>
                                                            <label className="radio">
                                                                <input type="radio" value="female" name="gender"
                                                                    onChange={e => (setGender(e.target.value))}

                                                                />أنثى
                                                            </label>
                                                        </div>
                                                        {/* <div className="example-cv"> e.g Massarwe</div> */}

                                                    </div>
                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <i className="ti ti-list" ></i>
                                                            <select
                                                                style={{ lineHeight: "1.8" }}
                                                                name="Job Category"
                                                                aria-hidden="true"
                                                                placeholder=" مجال التخصص"
                                                                onChange={e => (setCategory(e.target.value))}>
                                                                <option value=""> مجال التخصص</option>
                                                                <option value="1">محاسبة و مالية</option>
                                                                <option value="2">تكنولوجيا</option>
                                                                <option value="3">زراعة</option>
                                                                <option value="4">بناء </option>
                                                                <option value="5">وظائف طبية </option>
                                                                <option value="6">انتاج</option>
                                                                <option value="7">نقل</option>
                                                                <option value="8">تأمين </option>

                                                            </select>
                                                        </label>
                                                    </div>
                                                    <div className="col-md-6 ttm-box-col-wrapper" >

                                                        <label>
                                                            <input name="occupation" type="text" placeholder={occupation ? occupation : "المهنة"}
                                                                required={occupation ? "false" : "required"}
                                                                onChange={e => (setOccupation(e.target.value))}
                                                                value={occupation}
                                                            />
                                                        </label>
                                                        <p className="example-cv">مثال : مدير مبيعات </p>

                                                    </div>

                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="nationality" type="text" placeholder="الجنسية "
                                                                onChange={e => (setNationality(e.target.value))}
                                                            />
                                                        </label>
                                                        <p className="example-cv"> مثال : فلسطيني </p>

                                                    </div>
                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <input
                                                                type="text"
                                                                name="dob"
                                                                placeholder='تاريخ الولادة'
                                                                onChange={e => (setDate(e.target.value))}

                                                                onFocus={(e) => (e.target.type = "date")}
                                                                onBlur={(e) => (e.target.type = "text")}
                                                            />
                                                            {/* <input name="dof" type="date" placeholder="Date of birth" required="required" /> */}
                                                        </label>
                                                        <p className="example-cv">مثال : Jun 23 1994</p>

                                                    </div>
                                                    <div className="col-md-12 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="address" type="text" placeholder="العنوان "
                                                                onChange={e => (setAddress(e.target.value))}
                                                            />
                                                        </label>
                                                        <p className="example-cv"> مثال : شارع القلعة , القدس </p>

                                                    </div>
                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="email" type="text" placeholder=" البريد الالكتروني "
                                                                onChange={e => (setEmail(e.target.value))}
                                                            />
                                                        </label>
                                                        <p className="example-cv">   مثال : ahmed.m@gmail.com </p>

                                                    </div>


                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="phone" type="text" placeholder={phoneNumber ? phoneNumber : "رقم الهاتف"}
                                                                required={phoneNumber ? "false" : "required"}
                                                                value={phoneNumber}
                                                                onChange={e => (setPhoneNumber(e.target.value))}
                                                            />
                                                        </label>
                                                        <p className="example-cv"> مثال :  +972596574154  </p>

                                                    </div>

                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="linkedin" type="text" placeholder="LinkedIn"
                                                                onChange={e => (setLink(e.target.value))}
                                                            />
                                                        </label>
                                                        <p className="example-cv"> مثال : linkedin.com/ahmedmassarwe </p>

                                                    </div>
                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="Facebook" type="text" placeholder="Facebook"
                                                                onChange={e => (setFacebook(e.target.value))}
                                                            />
                                                        </label>
                                                        <p className="example-cv"> مثال : facebook.com/ahmedmassarwe </p>

                                                    </div>
                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="Instagram" type="text" placeholder="Instagram"
                                                                onChange={e => (setInstagram(e.target.value))}
                                                            />
                                                        </label>
                                                        <p className="example-cv"> مثال : instagram.com/ahmedmassarwe </p>

                                                    </div>
                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="Twitter" type="text" placeholder="Twitter"
                                                                onChange={e => (setTwitter(e.target.value))}
                                                            />
                                                        </label>
                                                        <p className="example-cv"> مثال : twitter.com/ahmedmassarwe </p>

                                                    </div>
                                                    <div className="col-md-12 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="portfolio" type="text" placeholder="الموقع الالكتروني "
                                                                onChange={e => (setProtfolio(e.target.value))}
                                                            />
                                                        </label>
                                                        <p className="example-cv"> مثال : portfolio.com/name  </p>

                                                    </div>


                                                </motion.div>


                                            </TabPanel>}
                                            {/* </motion.div> */}

                                            {/*  ======================= Tab_One End  ==================================== */}
                                            {/*  ======================= Tab_Two   Start ================================= */}
                                            {/* <motion.div variants={containerVariants}
                                                initial="hidden"
                                                animate="visible"
                                                exit="exit"> */}
                                            {tabId == 1 &&
                                                <TabPanel>
                                                    <motion.div className="row ttm-boxes-spacing-10px" id="tab2"
                                                        variants={prev ? nextVariantsRight : nextVariants}
                                                        initial="hidden"
                                                        animate="visible"
                                                        exit="exit">
                                                        {/* <div className="title-header">
                                                        <h5>Contact  <span className="text-theme-SkinColor">Great! Let's fill out your work Experience</span></h5>
                                                        <br></br>
                                                    </div> */}





                                                        <div className='col-md-12'></div>
                                                        <div className="col-md-6 ttm-box-col-wrapper">
                                                            <label>
                                                                <input name="position" type="text" placeholder="الوظيفة \ المنصب "
                                                                    // required="required"
                                                                    value={position}
                                                                    onChange={e => (setPosition(e.target.value))}

                                                                />
                                                            </label>
                                                            <p className="example-cv">مثال : مدير مبيعات </p>

                                                            {/* <p>eg. Mohammed</p> */}
                                                        </div>
                                                        <div className="col-md-6 ttm-box-col-wrapper">
                                                            <label>
                                                                <input name="company1" type="text" placeholder="الشركة "
                                                                    value={company1}
                                                                    onChange={e => (setCompany1(e.target.value))}

                                                                // required="required"
                                                                />
                                                            </label>
                                                            <p className="example-cv"> مثال : Intel </p>

                                                        </div>
                                                        <div className="col-md-6 ttm-box-col-wrapper">
                                                            <label>
                                                                <input
                                                                    type="text"
                                                                    name="startDate"
                                                                    placeholder='تاريخ البدء'
                                                                    value={startDate}
                                                                    onFocus={(e) => (e.target.type = "date")}
                                                                    onBlur={(e) => (e.target.type = "text")}
                                                                    onChange={e => (setStartDate(e.target.value))}

                                                                />
                                                                {/* <input name="dof" type="date" placeholder="Date of birth" required="required" /> */}
                                                            </label>
                                                            <p className="example-cv">مثال : Jun 23 2019</p>


                                                        </div>

                                                        <div className="col-md-6 ttm-box-col-wrapper">
                                                            <label>
                                                                <input
                                                                    type="text"
                                                                    name="endDate"
                                                                    placeholder='End Date'
                                                                    value={endDate}
                                                                    //    value={ isChecked?currentDate:endDate}
                                                                    onFocus={(e) => (e.target.type = "date")}
                                                                    onBlur={(e) => (e.target.type = "text")}
                                                                    onChange={e => (setEndDate(e.target.value))}

                                                                />
                                                                {/* <input name="dof" type="date" placeholder="Date of birth" required="required" /> */}
                                                            </label>
                                                            {/* <p className="example-cv"> e.g March 2017 </p> */}
                                                            <p>
                                                                <label className="mt-0">
                                                                    <div className="d-md-flex align-items-center justify-content-between">
                                                                        <div className="mt-15">
                                                                            <p className="example-cv">مثال : Jun 23 2022</p>
                                                                        </div>
                                                                        <div className="cookies-checkbox mt-15">
                                                                            <div className="d-flex flex-row justify-content-start">

                                                                                <input className="w-auto mr-10 mt-5"
                                                                                    id="cookies-consent"
                                                                                    type="checkbox"
                                                                                    checked={isChecked}
                                                                                    onChange={handleCheckChange}
                                                                                />
                                                                                <span style={{ marginRight: "10px" }}> حتى الآن </span>
                                                                                {/* <p>Is "My Value" checked? {isChecked.toString()}</p> */}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </label>
                                                            </p>

                                                        </div>

                                                        <div className="col-md-12 ttm-box-col-wrapper">
                                                            <label>
                                                                <input name="jobLocation" type="text" placeholder="الموقع ( مدينة )"
                                                                    // required="required"
                                                                    value={jobLocation}
                                                                    onChange={e => (setJobLocation(e.target.value))}

                                                                />
                                                            </label>
                                                            <div className="example-cv"> مثال : القدس </div>
                                                        </div>
                                                        <div>
                                                            <div className="col-md-12 ttm-box-col-wrapper">
                                                                <h4 className="title">التفاصيل </h4>
                                                                <div style={{ border: "1px solid black", padding: '2px', minHeight: '300px' }} dir="ltr">
                                                                    <Editor
                                                                        editorState={editorState}
                                                                        onEditorStateChange={handleEditorChange}
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-sm-12 col-md-4 col-lg-4'></div>
                                                        <div className='col-md-6' >
                                                            <motion.div style={{ marginTop: "10px", width:"100%" }} >
                                                                <motion.button
                                                                    className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-black w-90"
                                                                    variants={buttonVariants}
                                                                    whileHover="hover"
                                                                    onClick={addExp}
                                                                >
                                                                    <span><IoMdAddCircleOutline size={25} className='arrow-icon-left' /></span>
                                                                    <span style={{ fontSize: "large" }}> احفظ تجربة العمل</span>

                                                                </motion.button>
                                                            </motion.div>
                                                        </div>

                                                        <div className="  ">

                                                            <div className=" sidebar job-sidebar " >
                                                                <div className=" widget-area sidebar-left " >
                                                                    <div className="job_list-widget" style={{ border: "none" }}>
                                                                        {expData ?
                                                                            expData.map((experience, index) => (
                                                                                <motion.div className="widget form-widget " key={index} style={{ marginBottom: "1px", padding: "0 30px 0 " }}
                                                                                    variants={nextVariantsRight}
                                                                                    initial="hidden"
                                                                                    animate="visible"
                                                                                    exit="exit" >
                                                                                    <h3 className="widget-title h-50 " >
                                                                                        <span className='col-md-11'> {experience.position} <p className='exp-year'>{experience.company1}  {experience.startDate ? (experience.startDate[0] + "" + experience.startDate[1] + "" + experience.startDate[2] + "" + experience.startDate[3] + " - ") : ""}  {experience.endDate ? (experience.endDate[0] + "" + experience.endDate[1] + "" + experience.endDate[2] + "" + experience.endDate[3] + "") : " "}</p></span>
                                                                                        <span className=' col-md-1'>
                                                                                            <motion.button variants={buttonVariantsUpload}
                                                                                                whileHover="hover"
                                                                                                onClick={() => deleteExp(experience.id)}>
                                                                                                <RiDeleteBinFill size={25} className="text-theme-SkinColor"/>
                                                                                            </motion.button>
                                                                                        </span>
                                                                                    </h3>
                                                                                </motion.div>))
                                                                            :
                                                                            <></>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </motion.div>
                                                </TabPanel>}
                                            {tabId == 2 &&
                                                <TabPanel>
                                                    <motion.div className="row ttm-boxes-spacing-10px" id="tab2"
                                                        variants={prev ? nextVariantsRight : nextVariants}
                                                        initial="hidden"
                                                        animate="visible"
                                                        exit="exit">
                                                        {/* Skills Section   */}
                                                        <div className="col-md-12 ttm-box-col-wrapper">
                                                            <h5 className="title"> <AiFillEdit size={30} className='text-theme-SkinColor' /> مهارات </h5>
                                                        </div>
                                                        <div className=" col-md-12  ttm-col-bgcolor-yes ttm-bg bg-theme-GreyColor "  style={{paddingBottom:"0" }}>
                                                            <div className=" sidebar job-sidebar " >
                                                                <div className="row widget-area "  >
                                                                    <div className="job_list-widget" style={{ border: "none", padding: "0 10px 0" }}>
                                                                        <div className="widget " style={{ marginBottom: "1px", padding: "0 30px 0 " }}>
                                                                            <h3 className="widget-title  " >
                                                                                <span className='col-md-9'>
                                                                                    <label>
                                                                                        <input name="skill" type="text" placeholder="مثال : مهارات الاتصال اللفظية والكتابية "
                                                                                            // required="required"
                                                                                            value={skill}
                                                                                            onChange={e => (setSkill(e.target.value))}
                                                                                        />
                                                                                    </label>

                                                                                    <div>

                                                                                        <div className="cookies-checkbox mt-15">

                                                                                            <div className="d-flex flex-row justify-content-start">

                                                                                                <input className="w-auto mr-10 mt-5"
                                                                                                    type="radio" value="50" name="languageLevel"
                                                                                                    onChange={e => (setSkilLevel(e.target.value))}
                                                                                                />
                                                                                                <span style={{ marginRight: "10px" }} className="radio"> مبتدئ </span>
                                                                                                <input className="w-auto mr-10 mt-5"
                                                                                                    type="radio" value="75" name="languageLevel"
                                                                                                    // checked={isChecked}

                                                                                                    onChange={e => (setSkilLevel(e.target.value))}
                                                                                                />
                                                                                                <span style={{ marginRight: "10px" }}> جيد </span>
                                                                                                <input className="w-auto mr-10 mt-5"
                                                                                                    type="radio" value="80" name="languageLevel"
                                                                                                    onChange={e => (setSkilLevel(e.target.value))}

                                                                                                />
                                                                                                <span style={{ marginRight: "10px" }}> جيد جدا </span>
                                                                                                <input className="w-auto mr-10 mt-5"
                                                                                                    type="radio" value="100" name="languageLevel"
                                                                                                    onChange={e => (setSkilLevel(e.target.value))}
                                                                                                />
                                                                                                <span style={{ marginRight: "10px" }}> ممتاز </span>


                                                                                                {/* <p>Is "My Value" checked? {isChecked.toString()}</p> */}
                                                                                            </div>
                                                                                        </div>


                                                                                    </div>
                                                                                </span>
                                                                                <span className=' col-md-3 ' >
                                                                                    <motion.button
                                                                                        variants={buttonVariantsUpload}
                                                                                        style={{ marginLeft: "25px" }}
                                                                                        className='arrow-icon-left text-placeHolder-color-light'
                                                                                        whileHover="hover"
                                                                                        onClick={addSkill}
                                                                                    >
                                                                                        <BiAddToQueue size={25} className='cv-icon text-placeHolder-color-light' />
                                                                                        أضف مهارة
                                                                                    </motion.button>
                                                                                </span>

                                                                            </h3>
                                                                            <div className='row w-100' dir='ltr'>
                                                                                {skills.map((item, index) => (
                                                                                    <motion.div className="col-md-4 widget form-widget" key={index} style={{ marginBottom: "1px", padding: "0 10px 0 " }}
                                                                                        variants={nextVariantsRight}>
                                                                                        <h3 className="widget-title " style={{ width: "95%" }}>
                                                                                            <span className='col-md-11'> {item.skill}</span>
                                                                                            <span className=' col-md-1'>
                                                                                                <motion.button variants={buttonVariantsUpload}
                                                                                                    whileHover="hover"
                                                                                                    onClick={() => deleteSkill(item.id)}
                                                                                                >
                                                                                                    <RiDeleteBinFill size={25} className="text-theme-SkinColor" />
                                                                                                </motion.button>
                                                                                            </span>
                                                                                        </h3>
                                                                                    </motion.div>))}
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Languages Section   */}
                                                        <div className="col-md-12 ttm-box-col-wrapper">
                                                            <br></br>
                                                            <h5 className="title">  <MdOutlineLanguage size={30} className='text-theme-SkinColor' />  لغات </h5>
                                                        </div>
                                                        <div className=" col-md-12  ttm-col-bgcolor-yes ttm-bg bg-theme-GreyColor " style={{paddingBottom:"0" }}>

                                                            <div className=" sidebar job-sidebar " >
                                                                <div className="row widget-area job_list-widget-area"  >
                                                                    <div className="job_list-widget" style={{ border: "none", padding: "0 10px 0" }}>
                                                                        <div className="widget form-widget" style={{ marginBottom: "1px", padding: "0 30px 0 " }}>
                                                                            <h3 className="widget-title  " >
                                                                                <span className='col-md-9'>
                                                                                    <label>
                                                                                        <input name="language" type="text" placeholder="مثال : انجليزي "
                                                                                            // required="required"
                                                                                            value={language}
                                                                                            onChange={e => (setLanguage(e.target.value))}
                                                                                        />
                                                                                    </label>
                                                                                    <div>

                                                                                        <div className="cookies-checkbox mt-15" dir=''>

                                                                                            <div className="d-flex flex-row justify-content-start">

                                                                                                <input className="w-auto mr-10 mt-5"
                                                                                                    type="radio" value="50" name="languageLevel"
                                                                                                    onChange={e => (setLanguageLevel(e.target.value))}
                                                                                                />
                                                                                                <span style={{ marginRight: "10px" }}> مبتدئ </span>
                                                                                                <input className="w-auto mr-10 mt-5"
                                                                                                    type="radio" value="75" name="languageLevel"
                                                                                                    // checked={isChecked}

                                                                                                    onChange={e => (setLanguageLevel(e.target.value))}
                                                                                                />
                                                                                                <span style={{ marginRight: "10px" }}> جيد </span>
                                                                                                <input className="w-auto mr-10 mt-5"
                                                                                                    type="radio" value="80" name="languageLevel"
                                                                                                    onChange={e => (setLanguageLevel(e.target.value))}

                                                                                                />
                                                                                                <span style={{ marginRight: "10px" }}> ممتاز  </span>
                                                                                                <input className="w-auto mr-10 mt-5"
                                                                                                    type="radio" value="100" name="languageLevel"
                                                                                                    onChange={e => (setLanguageLevel(e.target.value))}
                                                                                                />
                                                                                                <span style={{ marginRight: "10px" }}> اللغة الأم </span>


                                                                                                {/* <p>Is "My Value" checked? {isChecked.toString()}</p> */}
                                                                                            </div>
                                                                                        </div>


                                                                                    </div>
                                                                                </span>
                                                                                <span className=' col-md-3'>
                                                                                    <motion.button
                                                                                        variants={buttonVariantsUpload}
                                                                                        className='cv-icon text-placeHolder-color-light'
                                                                                        whileHover="hover"
                                                                                        onClick={addLanguage}

                                                                                    >
                                                                                        <BiAddToQueue size={25} className='cv-icon text-placeHolder-color-light' />
                                                                                        أضف لغة
                                                                                    </motion.button>
                                                                                </span>
                                                                            </h3>
                                                                            <div className='row w-100' dir='ltr'>
                                                                                {languages.map((item, index) => (
                                                                                    <motion.div className="col-md-4 widget form-widget" key={index} style={{ marginBottom: "1px", padding: "0 10px 0 " }}
                                                                                        variants={nextVariantsRight}>
                                                                                        <h3 className="widget-title " style={{ width: "95%" }}>
                                                                                            <span className='col-md-11'> {item.language}  -
                                                                                                {item.languageLevel == "100" && <span className="progressbar-title col-md-6" style={{ color: "#ff8d00", direction: "rtl" }}> اللغة الأم  </span>}
                                                                                                {item.languageLevel == "80" && <span className="progressbar-title col-md-6" style={{ color: "#ff8d00", direction: "rtl" }}> ممتاز  </span>}
                                                                                                {item.languageLevel == "75" && <span className="progressbar-title col-md-6" style={{ color: "#ff8d00", direction: "rtl" }}> جيد  </span>}
                                                                                                {item.languageLevel == "50" && <span className="progressbar-title col-md-6" style={{ color: "#ff8d00", direction: "rtl" }}> مبتدئ </span>}
                                                                                            </span>
                                                                                            <span className=' col-md-1'>
                                                                                                <motion.button variants={buttonVariantsUpload}
                                                                                                    whileHover="hover"
                                                                                                    onClick={() => deleteLang(item.id)}
                                                                                                >
                                                                                                    <RiDeleteBinFill size={25} className="text-theme-SkinColor" />
                                                                                                </motion.button>
                                                                                            </span>

                                                                                        </h3>

                                                                                    </motion.div>))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </motion.div>
                                                </TabPanel>
                                            }
                                            {/* ================================Tab_Two End ============================================ */}
                                            {tabId == 3 && <TabPanel>
                                                <motion.div className="row ttm-boxes-spacing-10px" id="tab2"
                                                    variants={prev ? nextVariantsRight : nextVariants}
                                                    initial="hidden"
                                                    animate="visible"
                                                    exit="exit">

                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="degree" type="text" placeholder="الدرجة العلمية"
                                                                // required="required"
                                                                value={degree}
                                                                onChange={e => (setDegree(e.target.value))}

                                                            />
                                                        </label>
                                                        <div className="example-cv"> مثال : درجة البكالوريوس في إدارة الأعمال </div>
                                                        {/* <p>eg. Mohammed</p> */}
                                                    </div>
                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="schoolName" type="text" placeholder="اسم المدرسة \ الكلية \ الجامعة ... "
                                                                value={schoolName}
                                                                onChange={e => (setSchoolName(e.target.value))}

                                                            // required="required"
                                                            />
                                                        </label>
                                                        <div className="example-cv"> مثال : ماث أكاديمي </div>
                                                    </div>
                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <input
                                                                type="text"
                                                                name="startDate"
                                                                placeholder='تاريخ البدء'
                                                                value={schoolStartDate}
                                                                onFocus={(e) => (e.target.type = "date")}
                                                                onBlur={(e) => (e.target.type = "text")}
                                                                onChange={e => (setSchoolStart(e.target.value))}

                                                            />
                                                        </label>
                                                        <p className="example-cv">مثال : Jun 23 2020</p>
                                                    </div>
                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <input
                                                                type="text"
                                                                name="schoolEndDate"
                                                                placeholder='تاريخ الانتهاء '
                                                                value={schoolEndDate}
                                                                onFocus={(e) => (e.target.type = "date")}
                                                                onBlur={(e) => (e.target.type = "text")}
                                                                onChange={e => (setSchoolEnd(e.target.value))}
                                                            />
                                                        </label>
                                                        <p>
                                                            <label className="mt-0">
                                                                <div className="d-md-flex align-items-center justify-content-between">
                                                                    <div className="mt-15">
                                                                        <p className="example-cv">مثال : Jun 23 2023</p>
                                                                    </div>
                                                                    <div className="cookies-checkbox mt-15">
                                                                        <div className="d-flex flex-row justify-content-start">
                                                                            <input className="w-auto mr-10 mt-5"
                                                                                id="cookies-consent"
                                                                                type="checkbox"
                                                                                checked={isCheckedSchool}
                                                                                onChange={handleSchoolCheckChange}
                                                                            />
                                                                            <span style={{ marginRight: "10px" }}> لم أتخرج بعد  </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </p>

                                                    </div>

                                                    <div className="col-md-12 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="schoolLocation" type="text" placeholder="المكان ( المدينة )"
                                                                value={schoolLocation}
                                                                onChange={e => (setSchoolLocation(e.target.value))}
                                                            />
                                                        </label>
                                                        <div className="example-cv"> مثال : القدس </div>
                                                    </div>
                                                    <div>
                                                        <div className="col-md-12 ttm-box-col-wrapper">
                                                            <h4 className="title">التفاصيل و الإنجازات</h4>
                                                            <div style={{ border: "1px solid black", padding: '2px', minHeight: '300px' }} dir="ltr">
                                                                <Editor
                                                                    editorState={schoolEditorState}
                                                                    onEditorStateChange={handleEditorChangeSc}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className='col-sm-12 col-md-4'></div>
                                                    <div className='col-md-6 btn-save' >
                                                        <motion.div style={{ marginTop: "10px" }} >
                                                            <motion.button
                                                                className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-black w-90"
                                                                variants={buttonVariants}
                                                                whileHover="hover"
                                                                onClick={addEducation}
                                                            >
                                                                <span style={{ fontSize: "large" }}> أضف المؤهل العلمي </span>
                                                                <span> <IoMdAddCircleOutline size={25} className='arrow-icon-right' /> </span>
                                                            </motion.button>
                                                        </motion.div>
                                                    </div>

                                                    <div className=" col-md-12  ttm-col-bgcolor-yes ttm-bg bg-theme-GreyColor" style={{paddingBottom:"0"}}>

                                                        <div className=" sidebar job-sidebar " >
                                                            <div className="row widget-area  "  >
                                                                <div className="job_list-widget" style={{ border: "none" }}>
                                                                    {educations.map((edu, index) => (
                                                                        <motion.div className="widget " key={index} style={{ marginBottom: "1px", padding: "0 30px 0 " }}
                                                                            variants={nextVariantsRight}
                                                                        >
                                                                            <h3 className="widget-title h-50 " >
                                                                                <span className='col-md-11'> {edu.degree} <p className='exp-year'>{edu.schoolName}  -  {edu.schoolStartDate ? (edu.schoolStartDate[0] + "" + edu.schoolStartDate[1] + "" + edu.schoolStartDate[2] + "" + edu.schoolStartDate[3] + " - ") : ""}  {edu.schoolEndDate ? (edu.schoolEndDate[0] + "" + edu.schoolEndDate[1] + "" + edu.schoolEndDate[2] + "" + edu.schoolEndDate[3] + "") : " "}</p></span>
                                                                                <span className=' col-md-1'>
                                                                                    <motion.button variants={buttonVariantsUpload}
                                                                                        whileHover="hover"
                                                                                        onClick={() => deleteEdu(edu.id)}>
                                                                                        <RiDeleteBinFill size={25} className='text-theme-SkinColor' />
                                                                                    </motion.button>
                                                                                </span>
                                                                            </h3>
                                                                        </motion.div>))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </motion.div>
                                            </TabPanel>}
                                            {/* ======================Tab END ============================== */}
                                            {tabId == 4 && <TabPanel>
                                                <motion.div className="row ttm-boxes-spacing-10px" id="tab2"
                                                    variants={prev ? nextVariantsRight : nextVariants}
                                                    initial="hidden"
                                                    animate="visible"
                                                    exit="exit">
                                                    <div>
                                                        <div className="col-md-12 ttm-box-col-wrapper">
                                                            <h4 className="title">الأهداف \ الموضوعية  </h4>
                                                            <div style={{ border: "1px solid black", padding: '2px', minHeight: '300px' }} dir='ltr'>
                                                                <Editor
                                                                    editorState={aboutEditorState}
                                                                    onEditorStateChange={handleEditorChangeAb}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </motion.div>
                                            </TabPanel>}
                                            <TabList>
                                                <div className='row form-btn-tabs'>
                                                    {tabId == 0 &&
                                                        <div className='col-md-6 btn-save' >
                                                            <motion.div >
                                                                <motion.button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100 "
                                                                    variants={buttonVariants}
                                                                    whileHover="hover"
                                                                >
                                                                    <span><BsSave size={22} style={{ marginRight: "10px" }} /></span>
                                                                    <span style={{ fontSize: "large" }}> {saving ? " جاري الحفظ ..." : " حفظ  "} </span>
                                                                </motion.button>
                                                            </motion.div>
                                                        </div>}
                                                  
                                                    {tabId == 0 &&
                                                        <div className='col-md-6'>
                                                            <Tab >
                                                                <Link >
                                                                    <motion.div onClick={() => { setprev(0); incrementCounter(); }}
                                                                    >
                                                                        <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                                            variants={buttonVariants}
                                                                            whileHover="hover"
                                                                        >
                                                                            {/* <span > <AiOutlineArrowRight size={25} className='arrow-icon-left' /></span> */}
                                                                            <span style={{ fontSize: "large" }}> التالي </span>
                                                                            <span > <AiOutlineArrowRight size={25} className='arrow-icon-right' /></span>
                                                                        </motion.button>

                                                                    </motion.div>
                                                                </Link>
                                                            </Tab></div>}
                                                    {tabId == 1 &&
                                                        <div className='col-md-6'>
                                                            <Tab onClick={() => { setprev(0); incrementCounter(); }}>
                                                                <Link >
                                                                    <motion.div
                                                                    >
                                                                        <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                                            variants={buttonVariants}
                                                                            whileHover="hover"
                                                                        >
                                                                            {/* <span > <AiOutlineArrowRight size={25} className='arrow-icon-left' /></span> */}
                                                                            <span style={{ fontSize: "large" }}> التالي </span>
                                                                            <span > <AiOutlineArrowRight size={25} className='arrow-icon-right' /></span>
                                                                        </motion.button>
                                                                    </motion.div>
                                                                </Link>
                                                            </Tab>
                                                        </div>}
                                                    {tabId == 2 &&
                                                        <div className='col-md-6'>
                                                            <Tab onClick={() => { setprev(0); incrementCounter(); }}>
                                                                <Link >
                                                                    <motion.div
                                                                    >
                                                                        <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                                            variants={buttonVariants}
                                                                            whileHover="hover"
                                                                        >
                                                                            <span style={{ fontSize: "large" }}> التالي </span>
                                                                            <span > <AiOutlineArrowRight size={25} className='arrow-icon-right' /></span>
                                                                        </motion.button>
                                                                    </motion.div>
                                                                </Link>
                                                            </Tab>
                                                        </div>}
                                                    {tabId == 3 &&
                                                        <div className='col-md-6'>
                                                            <Tab onClick={() => { setprev(0); incrementCounter(); }}>
                                                                <Link >
                                                                    <motion.div
                                                                    >
                                                                        <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                                            variants={buttonVariants}
                                                                            whileHover="hover"
                                                                        >
                                                                            <span style={{ fontSize: "large" }}> التالي </span>
                                                                            <span > <AiOutlineArrowRight size={25} className='arrow-icon-right' /></span>
                                                                        </motion.button>
                                                                    </motion.div>
                                                                </Link>
                                                            </Tab>
                                                        </div>}
                                                        {tabId > 0 &&
                                                        <div className='col-md-6'>
                                                            <Tab >
                                                                <Link >
                                                                    <motion.div onClick={() => { decrementCounter(); setprev(1); }}
                                                                    >
                                                                        <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                                            variants={buttonVariants}
                                                                            whileHover="hover"
                                                                        >
                                                                            <span > <AiOutlineArrowLeft size={25} className='arrow-icon-left' /></span>
                                                                            <span style={{ fontSize: "large" }}> السابق </span>
                                                                            {/* <span > <AiOutlineArrowLeft size={25} className='arrow-icon-right' /></span> */}
                                                                        </motion.button>
                                                                    </motion.div>
                                                                </Link>
                                                            </Tab>
                                                        </div>}
                                                    {tabId > 0 &&
                                                        <div className='col-md-6 btn-save' >
                                                            <motion.div >
                                                                <motion.button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100 "
                                                                    variants={buttonVariants}
                                                                    whileHover="hover"
                                                                >
                                                                    <span><BsSave size={22} style={{ marginRight: "10px" }} /></span>
                                                                    <span style={{ fontSize: "large" }}> {saving ? " جاري الحفظ ..." : " حفظ  "} </span>
                                                                </motion.button>
                                                            </motion.div>
                                                        </div>}
                                                    {tabId == 4 &&
                                                        isSaved ? <div className='col-md-6'>
                                                        <Tab onClick={showCv} >
                                                            <Link >
                                                                <motion.div
                                                                >
                                                                    <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                                        variants={buttonVariants}
                                                                        whileHover="hover"

                                                                    >
                                                                        <span style={{ fontSize: "large" }}>عرض كملف PDF </span>
                                                                        <span > <AiOutlineArrowRight size={25} className='arrow-icon-right' /></span>
                                                                    </motion.button>
                                                                </motion.div>
                                                            </Link>
                                                        </Tab>
                                                    </div> : ""}
                                                </div>
                                            </TabList>
                                        </Tabs>
                                    </form>
                                </div>
                            </div>
                        </motion.div>}
                        {/* ==================================================View CV_PDF ===================================== */}
                        {document &&
                            <div className={show ? "col-lg-8 div-center " : "col-lg-6"}
                                dir='ltr'>
                                {show &&
                                    <div className='row' dir='rtl'>
                                        <div className='col-lg-6'>
                                            <Link >
                                                <motion.div
                                                >
                                                    <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                        variants={buttonVariants}
                                                        whileHover="hover"
                                                        onClick={download}
                                                    >
                                                        <span > <AiOutlineFilePdf size={25} className='arrow-icon-left' /></span>
                                                        <span style={{ fontSize: "large" }}> تنزيل كملف PDF </span>
                                                        <span > <AiOutlineFilePdf size={25} className='arrow-icon-right' /></span>

                                                    </motion.button>
                                                </motion.div>
                                            </Link>
                                        </div>
                                        <div className='col-lg-6'>
                                            <Link >
                                                <motion.div
                                                >
                                                    <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                        variants={buttonVariants}
                                                        whileHover="hover"
                                                        onClick={showCv}
                                                    >
                                                        <span > <AiFillEdit size={25} className='arrow-icon-left' /></span>
                                                        <span style={{ fontSize: "large" }}> تعديل السيرة الذاتية   </span>
                                                        <span > <AiFillEdit size={25} className='arrow-icon-right' /></span>
                                                    </motion.button>
                                                </motion.div>
                                            </Link>
                                        </div>

                                    </div>}
                                {!show &&
                                    <div className='row'>
                                        <div className='col-lg-8'></div>
                                        <div className='col-lg-4' style={{ display: firstName ? "block" : "none", marginBottom: "-80px" }} dir='rtl' >

                                            <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                variants={buttonVariants}
                                                whileHover="hover"
                                                onClick={showCv}
                                            >
                                                <span > <AiOutlineFilePdf size={25} className='arrow-icon-left' /></span>
                                                <span style={{ fontSize: "large" }}> عرض كملف PDF  </span>
                                                <span > <AiOutlineFilePdf size={25} className='arrow-icon-right' /></span>
                                            </motion.button>
                                        </div>
                                    </div>}
                                <div className={isPrint?"bg-theme-WhiteColor p-20 p-lg-30 mt-lg-30 mt-50 mb-50 ":"ttm-col-bgcolor-yes ttm-bg bg-theme-GreyColor  p-20 p-lg-30 mt-lg-30 mt-50 mb-50 "} ref={printRef}>
                                    <div className="layer-content">
                                        <div className=" sidebar job-sidebar clearfix">
                                            <div className="">
                                                <div className="row">
                                                    <div className="col-lg-5 widget-area sidebar-left job_list-widget-area ">
                                                        <div className={isPrint?"job_list-widget-white job_list-widget ":" job_list-widget  "} dir='rtl'>
                                                            <aside className="widget form-widget">
                                                                <h3 className="widget-title "><i className="ti ti-email"></i>معلومات الاتصال</h3>
                                                                {document && <ul  >
                                                                    {email && <li className="d-flex"><b className="mr-5">البريد الكتروني : </b><span>{email ? email : document.cvEmail}</span></li>}
                                                                    {phoneNumber && <li className="d-flex"><b className="mr-5">رقم الهاتف : </b><span>{phoneNumber ? phoneNumber : document.phoneNumber}</span></li>}
                                                                    {address && <li className="d-flex"><b className="mr-5">العنوان : </b><span>{address ? address : document.address}</span></li>}
                                                                    {nationality && <li className="d-flex"><b className="mr-5">الجنسية : </b><span>{nationality ? nationality : document.nationality}</span></li>}
                                                                    {dateOfBirth && <li className="d-flex"><b className="mr-5">تاريخ الولادة :</b><span>{dateOfBirth ? dateOfBirth : document.dateOfBirth}</span></li>}
                                                                    {link && <li className="d-flex"><b className="mr-5">Link:</b><span>{link ? link : document.link}</span></li>}
                                                                    {facebook && <li className="d-flex"><b className="mr-5">Facebook:</b><span>{facebook ? facebook : document.facebook}</span></li>}
                                                                    {instagram && <li className="d-flex"><b className="mr-5">Instagram:</b><span>{instagram ? instagram : document.instagram}</span></li>}
                                                                    {twitter && <li className="d-flex"><b className="mr-5">Twitter:</b><span>{twitter ? twitter : document.twitter}</span></li>}
                                                                    {portfolio && <li className="d-flex"><b className="mr-5">الموقع الالكتروني</b><span>{portfolio ? portfolio : document.portfolio}</span></li>}
                                                                </ul>}
                                                            </aside>
                                                            <aside className="widget job-widget ">
                                                                <h3 className="widget-title"><i className="flaticon flaticon-expert"></i>مهارات </h3>
                                                                {skills ?
                                                                    <div className="row skills-row" >
                                                                        {skills.map((item, index) => (

                                                                            <div className='col-md-6 ttm-progress-bar clearfix' key={index}>
                                                                                <h3 className={isPrint?"progressbar-title text-black ":"progressbar-title"} style={{ fontWight: "bold" }}># {item.skill}</h3>
                                                                            </div>
                                                                        ))} </div> :
                                                                    <div className="desc pb-30" >
                                                                    </div>
                                                                }
                                                            </aside>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 content-area">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="candidate-data">
                                                                    {document && document.profilePicture ?
                                                                        <div className="candidate-img" >
                                                                            <div className="image-item">
                                                                                <img src={document.profilePicture} alt="profile picture" width="100" height="100" />
                                                                            </div>
                                                                        </div> :
                                                                        images.map((image, index) => (
                                                                            <div className="candidate-img" key={index}>
                                                                                <div className="image-item">
                                                                                    <img src={image.data_url ? image.data_url : document.profilePicture} alt="profile picture" width="100" height="100" />
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                    {document &&
                                                                        document.name ?
                                                                        <div className="candidate-caption text-right">
                                                                            <h5>{firstName ? firstName : document.name}   {lastName ? lastName : ""}</h5>

                                                                            <div className="meta-line " dir='rtl'>
                                                                                <span dir=''><i className="">< BiShoppingBag size={20} /></i> {occupation ? occupation : (document.occupation ? document.occupation : "")}</span>
                                                                                <span><i className=""><SlPhone size={20} /></i> {phoneNumber ? phoneNumber : (document.phoneNumber ? document.phoneNumber : "")}</span>
                                                                            </div>
                                                                        </div> :
                                                                        <div className="candidate-caption text-right" >
                                                                            <h5>{firstName ? firstName + " " : " Name "} {lastName ? lastName : " "}</h5>

                                                                            <div className="meta-line " dir='rtl' >
                                                                                <span><i className="">< BiShoppingBag size={20} /></i>{occupation ? occupation : " Occupation"}</span>
                                                                                <span dir=''><i className=""><SlPhone size={16} /></i> {phoneNumber ? phoneNumber : " PhoneNumber"}</span>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row text-right" dir='rtl'>
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="overview-box">
                                                                    <div className="">
                                                                        {/* <h5>عني</h5> */}
                                                                    </div>
                                                                    <div className={isPrint?" desc text-black bg-theme-WhiteColor ":"desc"}>
                                                                        {convertedContentAb && <p dangerouslySetInnerHTML={createMarkup(convertedContentAb)}></p>}

                                                                    </div>
                                                                </div>
                                                                <div className="overview-box">
                                                                    <div className="title">
                                                                        <h5>خبرة العمل </h5>
                                                                    </div>
                                                                    <div className={isPrint?" desc text-black bg-theme-WhiteColor ":"desc"}>
                                                                        <div className="candidate-timeline">

                                                                            {expData ?
                                                                                expData.map((experience, index) => (
                                                                                    <div className="timeline-panel" key={index}>
                                                                                        <div className={isPrint?" text-theme-SkinColor timeline-head":"timeline-head"}>
                                                                                            <h3 className={isPrint?"text-theme-DarkColor text-bold":" "} >{experience.company1} </h3>

                                                                                            <span className="timeline-year"> {experience.startDate ? (experience.startDate[0] + "" + experience.startDate[1] + "" + experience.startDate[2] + "" + experience.startDate[3] + " - ") : ""}  {experience.endDate ? (experience.endDate[0] + "" + experience.endDate[1] + "" + experience.endDate[2] + "" + experience.endDate[3] + "") : " "} </span>
                                                                                        </div>
                                                                                        <div className="timeline-body">
                                                                                            <h5>{experience.position}</h5>


                                                                                            <p dangerouslySetInnerHTML={createMarkup(experience.convertedContent)}></p>

                                                                                        </div>
                                                                                    </div>
                                                                                )) :
                                                                                <div className="timeline-panel" >
                                                                                    <div className="timeline-head">
                                                                                        <h3>Intel</h3>

                                                                                        <span className="timeline-year"> 2018 - 2022    </span>
                                                                                    </div>
                                                                                    <div className="timeline-body">
                                                                                        <h5>Web Developer</h5>


                                                                                        <p >fron end developer</p>

                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="overview-box">
                                                                    <div className="title">
                                                                        <h5>مؤهلات علمية </h5>
                                                                    </div>
                                                                    <div className={isPrint?" desc text-black bg-theme-WhiteColor":"desc"}>
                                                                        <div className="candidate-timeline">

                                                                            {educations.map((edu, index) => (
                                                                                <div className="timeline-panel" key={index}>
                                                                                    <div className="timeline-head">
                                                                                        <h3 className={isPrint?"text-theme-DarkColor":""}>{edu.degree}</h3>

                                                                                        <span className={isPrint?"timeline-year text-theme-SkinColor":"timeline-year"}> {edu.schoolStartDate ? (edu.schoolStartDate[0] + "" + edu.schoolStartDate[1] + "" + edu.schoolStartDate[2] + "" + edu.schoolStartDate[3] + " - ") : ""}  {edu.schoolEndDate ? (edu.schoolEndDate[0] + "" + edu.schoolEndDate[1] + "" + edu.schoolEndDate[2] + "" + edu.schoolEndDate[3] + "") : " "} </span>
                                                                                    </div>
                                                                                    <div className="timeline-body">
                                                                                        <h5>{edu.schoolName}</h5>
                                                                                        <p dangerouslySetInnerHTML={createMarkup(edu.convertedContentSc)}></p>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* {skills && <div className="overview-box" >
                                                                    <div className="title">
                                                                        <h5>Skills </h5>
                                                                    </div>
                                                                    {skills.map((item, index) => (
                                                                        <div className="desc pb-30" key={index}>
                                                                            <div className="ttm-progress-bar clearfix">
                                                                                <h3 className="progressbar-title">{item.skill}</h3>
                                                                                <ProgressBar rect percentage={item.skilLevel} />
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>} */}
                                                                {languages && <div className="overview-box" >
                                                                    <div className="title ">
                                                                        <div className='row'>
                                                                            <h5 className='col-md-6'>لغات  </h5>
                                                                            <h5 className='col-md-6' style={{ direction: "rtl" }}>مستوى اللغة </h5>
                                                                        </div>

                                                                    </div>
                                                                    {languages.map((item, index) => (
                                                                        <div className={isPrint?"desc pb-30 text-black bg-theme-WhiteColor":"desc pb-30 "} key={index}>
                                                                            <div className="ttm-progress-bar clearfix row">
                                                                                <h3 className={isPrint?"progressbar-title col-md-6 text-black":"progressbar-title col-md-6"} style={{ letterSpacing: "normal", fontWeight: "bold" }}>{item.language}</h3>
                                                                                {item.languageLevel == "100" && <h3 className={isPrint?"progressbar-title col-md-6 text-black":"progressbar-title col-md-6 text-theme-SkinColor"} style={{ letterSpacing: "normal", direction: "rtl" }}> اللغة الأم  </h3>}
                                                                                {item.languageLevel == "80" && <h3 className={isPrint?"progressbar-title col-md-6 text-black":"progressbar-title col-md-6 text-theme-SkinColor"} style={{ letterSpacing: "normal", direction: "rtl" }}> ممتاز  </h3>}
                                                                                {item.languageLevel == "75" && <h3 className={isPrint?"progressbar-title col-md-6 text-black":"progressbar-title col-md-6 text-theme-SkinColor"} style={{ letterSpacing: "normal", direction: "rtl" }}> جيد  </h3>}
                                                                                {item.languageLevel == "50" && <h3 className={isPrint?"progressbar-title col-md-6 text-black":"progressbar-title col-md-6 text-theme-SkinColor"}  style={{ letterSpacing: "normal", direction: "rtl" }}> مبتدئ </h3>}
                                                                                {/* <ProgressBar rect percentage={item.languageLevel} /> */}
                                                                                <hr></hr>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                    </div></div>
            </section>


        </div>
    )
}



