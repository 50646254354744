import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import Footer from '../components/layout/Footer';
import { useCollection } from '../hooks/useCollection';
import { timestamp } from '../firebase/config';
import { BsCheck2All } from 'react-icons/bs';
import { GrNext, GrPrevious } from 'react-icons/gr'
import AvatarJ from '../assets/img/massavatar/mass-avatarj.jpg'
import AvatarP from '../assets/img/massavatar/mass-avatarp.png'
import Profile from '../assets/img/massavatar/massprofile.png'
import "../firebase/config"
export default function Candidate_list() {

    // constructor() {
    //     super();
    //     this.state = {
    //       name: "React"
    //     };
    //     this.onChangeValue = this.onChangeValue.bind(this);

    // }

    // onChangeValue(event) {
    //     console.log(event.target.value);
    // }
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageData, setcpData] = useState([])
    const PER_PAGE = 10;
    const offset = currentPage * PER_PAGE;
    const [pageCount, setpCount] = useState()
    const [startAt, setStartAt] = useState()
    const [filter, setFilter] = useState([])
    const [gender, setGender] = useState("")
    const [all, setAll] = useState("")
    const [isChecked, setChecked] = useState(false)

    const [category, setCategory] = useState("")
    const [filterDate, setFilterDate] = useState("")
    const [data, setData] = useState([])


    const { documents, err } = useCollection(
        'users', ["createdAt", "!=", null],
        // ["name", "desc"] // Get Names From Z to A
    )

    // useEffect(() => {
    //     { documents, err } = useCollection(
    //         'users', filter.length>0?filter: ["privacyPolicy","==", "accepted"],
    //         // ["name", "desc"] // Get Names From Z to A
    //     )
    // }, [gender])

    const today = timestamp.fromDate(new Date())
    // console.log("today is :", today)

    useEffect(() => {
        if (documents) {
            setData(documents.filter((row) => row.createdAt))
        }
    }, [documents])




    useEffect(() => {
        if (isChecked) {
            // setData(documents)
            window.location.reload(false)

        } else
            if (gender && !category && !filterDate) {
                setData(documents)
                // console.log("filter by gender :", gender)

                setData(documents.filter((row) => row.gender == gender))
            }
            else
                if (gender && category && !filterDate) {
                    setData(documents)
                    // console.log("filter by gender and cat and date:", gender, category)

                    setData(documents.filter((row) => row.gender == gender && row.category == category))
                } else
                    if (gender && category && filterDate) {
                        setData(documents)
                        // console.log("filter by gender and cat and date:", gender, category)

                        setData(documents.filter((row) => row.gender == gender && row.category == category && flt(filterDate, row.createdAt)))
                    }
                    else
                        if (!gender && !filterDate && category) {
                            setData(documents)
                            // console.log("filter by gender :",  category)

                            setData(documents.filter((row) => row.category == category))
                        } else
                            if (!gender && filterDate && category) {
                                setData(documents)
                                // console.log("filter by gender :",  category)

                                setData(documents.filter((row) => row.category == category && flt(filterDate, row.createdAt)))
                            } else
                                if (gender && filterDate && !category) {
                                    setData(documents)
                                    // console.log("filter by gender :",  category)

                                    setData(documents.filter((row) => row.gender == gender && flt(filterDate, row.createdAt)))
                                } else
                                    if (filterDate) {
                                        setData(documents)
                                        //  console.log("=filter by Date= :", filterDate)
                                        setData(documents.filter((row) => flt(filterDate, row.createdAt)))
                                    }

        function flt(filterDate, dbDate) {
            const dateFilter = parseFloat(filterDate)
            const today = timestamp.fromDate(new Date())
            const time = new Date(today.seconds * 1000);
            const cretedDate = dbDate
            const timedb = new Date(cretedDate.seconds * 1000);
            const last7Days = new Date((time - timedb))
            const thisDatr = new Date(time - dateFilter)
            return ((timedb < time) && (timedb > thisDatr))
        }

    }, [gender, category, filterDate, isChecked])


    // End Date Handler
    const handleCheckChange = () => {
        setChecked(!isChecked);
    };
    // const pageCount = Math.ceil(data.length / PER_PAGE);

    useEffect(() => {
        if (data) {
            setcpData(data
                .slice(offset, offset + PER_PAGE))
            setpCount(Math.ceil(data.length / PER_PAGE));
        }
    }, [data, currentPage])

    const ScrollToTop = () => window.scrollTo({ top: 40, behavior: "smooth" });

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
        ScrollToTop()
    }

    return (

        <div className="site-main">
            <Header />

            {/* PageHeader */}
            <PageHeader
                title="الاستفادة من المواهب ذات المستوى العالمي "
                subTitle="Are You up for the Challenge?"
                breadcrumb="مرشحين"
            />
            {/* PageHeader end */}


            <div className="ttm-row sidebar job-sidebar clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row" >
                        <div className="col-lg-4 widget-area sidebar-left job_list-widget-area" dir='rtl'>
                            <div className="job_list-widget">
                                <aside className="widget job-widget">
                                    <h3 className="widget-title">
                                        {/* <i className="flaticon flaticon-gender"></i> */}
                                        <BsCheck2All size={25} className='widget-icon' />
                                        الكل </h3>
                                    <form id="list4" className="list-filter">
                                        <div  >
                                            <label className="radio">
                                                <input type="radio" value="all" name="all"
                                                    checked={isChecked}
                                                    onChange={handleCheckChange}

                                                /> الكل
                                            </label>
                                            {/* <input className="w-auto mr-10 mt-5"
                                                id="cookies-consent"
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={handleCheckChange}

                                            /> */}

                                        </div>
                                    </form>
                                </aside>
                                <aside className="widget job-widget">
                                    <h3 className="widget-title"><i className="flaticon flaticon-calendar-1"></i>تاريخ الإعلان</h3>
                                    <form id="list1" className="list-filter">
                                        <div  >
                                            <label className="radio">
                                                <input type="radio" value="86400000" name="post_date"
                                                    onChange={e => (setFilterDate(e.target.value))}
                                                /> اليوم
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="604800000" name="post_date"
                                                    onChange={e => (setFilterDate(e.target.value))}
                                                /> آخر 7 ايام
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="1209600000" name="post_date"
                                                    onChange={e => (setFilterDate(e.target.value))}
                                                /> آخر 14 يومًا
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="1814400000" name="post_date"
                                                    onChange={e => (setFilterDate(e.target.value))}
                                                />آخر 21 يومًا
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="2592000000" name="post_date"
                                                    onChange={e => (setFilterDate(e.target.value))}
                                                /> آخر 30 يومًا
                                            </label>
                                        </div>
                                    </form>
                                </aside>
                                <aside className="widget job-widget">
                                    <h3 className="widget-title"><i className="flaticon flaticon-subfolder-1"></i>فئات </h3>
                                    <form id="list2" className="list-filter">
                                        <div  >
                                            <label className="radio">
                                                <input type="radio" value="1" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                />  محاسبة و مالية
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="2" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> تكنولوجيا
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="3" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> زراعة
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="4" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> بناء
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="5" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> صناعة
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="6" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> انتاج
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="7" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> نقل
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="8" name="categories"
                                                    onChange={e => (setCategory(e.target.value))}
                                                /> Digital Design & Social Media
                                            </label>
                                        </div>
                                    </form>
                                </aside>
                                {/* <aside className="widget job-widget">
                                    <h3 className="widget-title"><i className="flaticon flaticon-expert"></i>Experince</h3>
                                    <form id="list3" className="list-filter">
                                        <div  >
                                            <label className="radio">
                                                <input type="radio" value="0Year to 1Year" name="ex_year" />0Year to 1Year
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="1Year to 2Year" name="ex_year" />1Year to 2Year
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="2Year to 3Year" name="ex_year" />2Year to 3Year
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="3Year or more" defaultChecked name="ex_year" />3Year or more
                                            </label>
                                        </div>
                                    </form>
                                </aside> */}
                                <aside className="widget job-widget">
                                    <h3 className="widget-title"><i className="flaticon flaticon-gender"></i>Gender</h3>
                                    <form id="list4" className="list-filter">
                                        <div  >
                                            <label className="radio">
                                                <input type="radio" value="male" name="gender"
                                                    onChange={e => (setGender(e.target.value))}

                                                /> ذكر
                                            </label>
                                            <label className="radio">
                                                <input type="radio" value="female" name="gender"
                                                    onChange={e => (setGender(e.target.value))}

                                                /> أنثى
                                            </label>
                                        </div>
                                    </form>
                                </aside>

                            </div>
                            {/* <aside className="widget widget-download">
                                <ul className="download">
                                    <li><a href="#">Download.pdf</a><i className="ti ti-files"></i></li>
                                    <li><a href="#">Download.txt</a><i className="ti ti-files"></i></li>
                                </ul>
                            </aside> */}
                            <aside className="widget widget-banner text-theme-WhiteColor">
                                <div className="ttm-col-bgcolor-yes bg-theme-DarkColor text-theme-WhitColor col-bg-img-seven ttm-col-bgimage-yes ttm-bg p-40">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor" style={{ backgroundImage: 'url(/images/pics/col-bgimage-7.jpg)' }}>
                                        <div className="ttm-col-wrapper-bg-layer-inner bg-theme-DarkColor"></div>
                                    </div>
                                    <div className="layer-content text-center">
                                        <div className="widget-banner-inner">
                                            <h3 className="mb-15">Make a Difference with Online Resume!</h3>
                                            <p className="mb-30">حضر سيرتك الذاتية في دقائق بمساعدة MassRes !</p>
                                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                                href={process.env.PUBLIC_URL + '/start_cv'}>Create Your CV !</a>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>
                        <div className="col-lg-8 content-area">
                            <div className="row" dir='rtl'>
                                <div className="col-md-12">
                                    <div className="showing-result-block d-sm-flex align-items-center justify-content-between">
                                        <span className="showing-result">عرض  1–10   من  {data.length} موظف :</span>
                                        {/* <form>
                                            <div className="sort-by">Sort By:
                                                <select defaultValue="">
                                                    <option value="" disabled>A to Z</option>
                                                    <option value="popularity">A to Z</option>
                                                    <option value="rating">b</option>
                                                    <option value="date">c</option>
                                                    <option value="price">d</option>
                                                    <option value="price-desc">e</option>
                                                </select>
                                            </div>
                                        </form> */}
                                    </div>
                                </div>
                            </div>

                            {data ? <div className="row" dir='rtl'>
                                {currentPageData.map((candidate, index) => (
                                    !candidate.isEmployer ?
                                        <div className="col-lg-12" key={index}>
                                            {/* featured-imagebox */}
                                            <div className="featured-imagebox featured-imagebox-candidate bg-theme-GreyColor ">
                                                {candidate.profilePicture &&
                                                    <div className="featured-thumbnail">
                                                        <img src={candidate.profilePicture} alt="profile picture" />
                                                    </div>}
                                                {!candidate.profilePicture &&
                                                    <div className="featured-thumbnail">

                                                        <img src={Profile} alt="profile picture" />

                                                    </div>}
                                                <div className="featured-content">
                                                    <div className="featured-title title-white ">
                                                        <h3> <a href={process.env.PUBLIC_URL + "/candidate_details/" + candidate.id}> {candidate.name} </a></h3>
                                                    </div>
                                                    <div className="featured-bottom">

                                                        <div className="job-skill  " >
                                                            {candidate.skills ? candidate.skills.slice(0, 2).map((skill, index) => (
                                                                <span className="skill-tag" key={index}>{skill.skill}</span>

                                                            )) : <></>}
                                                        </div>

                                                        <div className="job-meta">
                                                            <span> <i className="fa fa-map-marker-alt"></i> {candidate.address} </span>
                                                            <span><i className="flaticon flaticon-subfolder-1"> </i> {candidate.occupation} </span>
                                                            <Link className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-border 
                                                  ttm-btn-color-white"
                                                                style={{ float: "left", marginTop: "0px" }}
                                                                to={process.env.PUBLIC_URL + "/candidate_details/" + candidate.id}
                                                            >الصفحة الشخصية </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>{/* featured-imagebox end */}
                                        </div> : ""
                                ))}
                                <div className="col-lg-12">
                                </div>

                                {/* 
                              // Link to react-paginate npm with css instructions :
                              https://www.npmjs.com/package/react-paginate
                              */}
                                <div className="col-lg-12" dir='ltr'>
                                    <div className="job-pagination-block">
                                        <ReactPaginate
                                            previousLabel={<GrPrevious />}
                                            nextLabel={<GrNext />}
                                            pageCount={pageCount}
                                            pageLinkClassName={"page-nav-link "}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination  "}
                                            previousLinkClassName={"page-nav-link no"}
                                            nextLinkClassName={"page-nav-link no"}
                                            linkClassName={"page-nav-link current"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={""}
                                            activeLinkClassName={"current"}
                                        />
                                    </div>
                                </div>
                            </div> : <></>}{/* row end */}
                        </div>
                    </div>{/* row end */}
                </div>
            </div>


            {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row" dir='rtl'>
                        <div className="col-lg-12">
                            <div className="d-lg-flex align-items-center justify-content-between">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment-5"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>قل وداعا  <span className=''> للبطالة ! </span></h3>
                                        </div>
                                        <div className="featured-desc">
                                            {/* <p>We have over 30 years experience oft Business porro qusquam dol ipsum quia dolor sit amet.</p> */}
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                                    href={process.env.PUBLIC_URL + '/job_list'}>وظائف ! </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}


            <Footer />

        </div>
    )
}


// export default Candidate_list;