import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import ProfilePic from '../assets/img/massavatar/massres-bus.png';
// import Header from '../components/layout/Header';
import HeaderLogo from '../components/layout/HeaderLogo';
import { AiFillEdit, AiOutlineArrowRight, AiOutlineArrowLeft, AiOutlineFilePdf, AiOutlineClose } from 'react-icons/ai';
import { RiDeleteBinFill } from 'react-icons/ri'
import { BiAddToQueue, BiBold, BiEdit, BiSave, BiUpload } from "react-icons/bi";
import { TfiCheckBox } from 'react-icons/tfi'
import { BsSave } from 'react-icons/bs'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// =========
import { useAuthContext } from '../hooks/useAuthContext';
import { usePostNestDocs } from '../hooks/usePostNestDocs';
import { useFetchNestDocs } from '../hooks/useFetchNestDocs';
import { useCollection } from '../hooks/useCollection';
import { projectStorage } from '../firebase/config';
import { useDocument } from '../hooks/useDocument';
import { EditorState } from 'draft-js';
import { ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { v4 as uuidv4 } from 'uuid'
const containerVariants = {
    hidden: {
        opacity: 0,
        x: '-100vw'
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: { type: 'spring', delay: 0.5 }
    },
    exit: {
        x: "-100vh",
        transition: { ease: 'easeInOut' }
    }
};

const nextVariants = {
    hidden: {
        x: '-20vw'
    },
    visible: {
        x: 0,
        transition: { ease: 'easeInOut' }

        // transition: { type: 'spring', stiffness: 120 }
    }
};
const nextVariantsRight = {
    hidden: {
        x: '20vw'
    },
    visible: {
        x: 0,
        transition: { ease: 'easeInOut' }

        // transition: { type: 'spring', stiffness: 120 }
    }
}
const buttonVariants = {
    hover: {
        scale: 1.1,
        // textShadow: "0px 0px 8px rgb(255,255,255)",
        fontWeight: "bold",
        boxShadow: "0px 0px 8px rgb(255,255,255)",

        transition: {
            duration: 0.5,
            yoyo: Infinity
        }
    }
}

const buttonVariantsUpload = {
    hover: {
        scale: 1.3,
        textShadow: "0px 0px 8px rgb(255,255,255)",
        // boxShadow: "0px 0px 8px rgb(255,255,255)",
        color: "#bc4123",
        transition: {
            duration: 0.7,
            yoyo: Infinity
        }

    }
}
const warningVariants = {

    visible: {
        scale: 0.1,
        textShadow: "0px 0px 8px rgb(255,255,255)",
        boxShadow: "0px 0px 8px rgb(255,255,255)",
        transition: {
            duration: 0.7,
            yoyo: Infinity
        }
    }
}

// var mylink
export default function FillJob(
    props
) {
    // console.log("props are: ",props)

    const [showFillJob, setShowFillJob] = useState(props.showFillJob)
    const jobTypes = ["", "دوام كامل ", "دوام كامل - عاجل ", "دوام جزئي ", "دوام جزئي - عاجل ", "عبر الانترنت ", "عبر الانترنت - عاجل "]
    const cats = ["", " محاسبة و مالية ", "تكنولوجيا  ", " زراعة ", " بناء ", " وظائف طبية ", " انتاج ", " نقل  ", " تأمين "]
    const locations=["","القدس","عمان","القاهرة","دمشق","بيروت","الجزائر","المغرب","","","" ]

    const printRef = React.useRef();
    const printRef2 = React.useRef();

    const x = useMotionValue(5)
    const { user } = useAuthContext();
    const [data, setData] = useState([])
    const [coData, setCoData] = useState()

    const [isSaved, setIsSaved] = useState(false)
    const [show, setShow] = useState(false)

    // console.log("userid is : ", user.uid)
    const { document, err } = useDocument(
        'employers', user.uid
    )

    // console.log("userid is ", user.uid)
    const { documents, error } = useFetchNestDocs(
        'employers', user.uid, "jobs"
    )
    const { addDocument, updateNestedDocument,updateDocument, response } = usePostNestDocs('employers', user.uid, "jobs")

    const [saving, setSaving] = useState(false)
    const [updating, setupdating] = useState(false)
    const [editing, setEditing] = useState(false)
    // const { document, error } = useDocument('employers', user.uid)
    // Job_Info
    const [jobName, setJobName] = useState("")
    const [jobEmail, setJobEmail] = useState("")
    const [experienceYear, setExperienceYear] = useState("")
    const [positionNumber, setPositionNumber] = useState("")
    const [jobCat, setJCategory] = useState("")
    const [jobType, setJobType] = useState("")
    const [jobLocation, setJobLocation] = useState("")
    const [jobQuali, setQual] = useState("")
    const [offeredSalary, setOfferedSalary] = useState("")
    // Job_Description
    const [jobDescState, setJobDescState] = useState(() => EditorState.createEmpty())
    const [convertedContentJ, setConvertedContentJ] = useState();
    // Job_Requirements
    const [reqs, setReqs] = useState([])
    const [req, setReq] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("");
    const [occupation, setOccupation] = useState("");
    const [nationality, setNationality] = useState("");
    const [dateOfBirth, setDate] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [gender, setGender] = useState("");


    // Cv_SOCIAL_LINKS_Info
    const [link, setLink] = useState("");
    const [facebook, setFacebook] = useState("");
    const [instagram, setInstagram] = useState("");
    const [twitter, setTwitter] = useState("");
    const [profilePicture, setProfileP] = useState("")
    // Cv_Work Exp_Info
    const [endDate, setEndDate] = useState("")
    // CV_Skills_Info
    const [jobSkill, setJobSkill] = useState("")
    const [jobSkills, setJobSkills] = useState([])
    // CV_EDUCATION_INFO
    const [category, setCategory] = useState("")

    const [schoolEndDate, setSchoolEnd] = useState("")
    // CV_ABOUT_INFO
    const [aboutEditorState, setAboutEditorState] = useState(() => EditorState.createEmpty())
    const [convertedContentAb, setConvertedContentAb] = useState();

    const [isCheckedSchool, setCheckedSchool] = useState(false)

    // const raw = convertToRaw(_contentState)
    // const [contentState, setContentState] = useState(raw)
    const [isChecked, setChecked] = useState(false)

    // const [tabId, setTabId] = useState(0);
    // const [images, setImages] = React.useState([]);
    // const [isImage, setIsImage] = useState(false)
    const [showComp, setShowComp] = useState(true)
    useEffect(() => {
        if (document) {
            // console.log("we have an employer document here :", document)
            setCoData(document)
            // console.log("setCoData is : ", coData)
        }
    }, [document])
    useEffect(() => {

        if (props.jobToUpdate) {

            // setData(props.jobToUpdate)
            // console.log( "data here is ===: ", props.jobToUpdate)
            const document = props.jobToUpdate
            setJobName(document.jobName ? document.jobName : "")
            setJobType(document.jobType ? document.jobType : "")
            setJCategory(document.jobCategory ? document.jobCategory : "")
            setJobLocation(document.jobLocation ? document.jobLocation : "")
            setQual(document.jobQualifications ? document.jobQualifications : "")
            setGender(document.gender ? document.gender : "")
            setExperienceYear(document.yearsOfExp ? document.yearsOfExp : "")
            setPositionNumber(document.positionNumber ? document.positionNumber : "")
            setOfferedSalary(document.offeredSalary ? document.offeredSalary : "")
            setAddress(document.jobAddress ? document.jobAddress : "")
            setJobEmail(document.jobEmail ? document.jobEmail : "")
            setJobDescState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(document.jobDescription))));
            setReqs(document.jobReqs ? document.jobReqs : "")
            setJobSkills(document.jobSkills ? document.jobSkills : "")
        }


    }, [props.jobToUpdate])
    // useEffect(() => {

    //     if (documents) {
    //         setData(documents)
    //     }


    // }, [documents, data, document])

    const handleEdit = () => {
        setEditing(true)
        // console.log("editing here")
    }



    useEffect(() => {
        if (isChecked) {
            const currentD = new Date().toLocaleDateString();
            let sp = currentD.split('/')
            const cureentDate = sp[2] + "/" + sp[1] + "/" + sp[0]
            setEndDate(cureentDate)
        }
    })
    useEffect(() => {
        if (isCheckedSchool) {
            const currenD = new Date().toLocaleDateString();
            let sp = currenD.split('/')
            const cureentDate = sp[2] + "/" + sp[1] + "/" + sp[0]
            setSchoolEnd(cureentDate)
        }
    })
    const handleEditorChangeJ = (state) => {
        setJobDescState(state);
        // console.log("state: ", state)
        convertContentToHTMLJ();
    }
    const convertContentToHTMLJ = () => {
        let currentContentAsHTML = convertToHTML(jobDescState.getCurrentContent());
        setConvertedContentJ(currentContentAsHTML);
        // console.log("Editor state:", currentContentAsHTML)
    }
    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }
    // Tabs Switching
    const [prev, setprev] = useState(0)
    let xx = "";
    const [tabId, setTabId] = useState(0);
    const [images, setImages] = React.useState([]);
    const [isImage, setIsImage] = useState(false)
    const maxNumber = 1;
    const maxSize = 5000000; //5 mega = 5000000
    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        // console.log("image list", imageList, addUpdateIndex);
        setImages(imageList);
    };

    async function incrementCounter() {
        const newt = tabId + 1
        const ScrollToTop = () => window.scrollTo({ top: 40, behavior: "smooth" });
        ScrollToTop()
        setTabId(newt)

        // console.log("tab is id : ", newt)
    }
    function decrementCounter() {
        const newt = tabId - 1
        const ScrollToTop = () => window.scrollTo({ top: 40, behavior: "smooth" });
        ScrollToTop()
        setTabId(newt)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSaving(true)
        // console.log("name is ? ", firstName ," ", lastName)
        const storageRef = projectStorage.ref()
        function imgUpload() {
            const imgName = images[images.length - 1].file
            const ref = storageRef.child("cv-profile-pictures/" + imgName.name)
            const uploadTask = ref.put(imgName);
            uploadTask.on('state_changed',
                (snapshot) => {
                    console.log('Image Three Upload is ' + 100 + '% done');
                },
                (error) => {
                    console.log("Somthing wrong happened, image is not uploaded");
                },
                () => {
                    ref.getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // console.log(downloadURL)
                        setProfileP(downloadURL)
                        updateDocument(user.uid, { profilePicture: downloadURL })
                    });
                }

            );


        }
        // images.length > 0 ? imgUpload() : console.log("")
    if(!showFillJob)
    {    await addDocument({
            jobName,
            jobType,
            jobCategory: jobCat,
            jobLocation,
            jobQualifications: jobQuali,
            gender,
            yearsOfExp: experienceYear,
            positionNumber,
            offeredSalary,
            jobAddress: address,
            jobEmail,
            jobDescription: convertedContentJ ? convertedContentJ : "",
            jobReqs: reqs ? reqs : "",
            jobSkills: jobSkills ? jobSkills : "",
        })}
        if (tabId == 4) {
            setShow(true)
        }
        setIsSaved(true)
        setSaving(false)


    }



    const updateJob = async (e) => {
        e.preventDefault()
        setupdating(true)
        // console.log("Updating here")
        let jobToUpdatee = (documents.filter((row) => row.id == props.jobToUpdate.id))
        let jobToUpdate= jobToUpdatee[0]
        jobToUpdate.jobName = jobName
        jobToUpdate.jobType = jobType
        jobToUpdate.jobCategory = jobCat
        jobToUpdate.jobLocation = jobLocation
        jobToUpdate.gender = gender
        jobToUpdate.yearsOfExp = experienceYear
        jobToUpdate.positionNumber = positionNumber
        jobToUpdate.offeredSalary = offeredSalary
        jobToUpdate.jobAddress = address
        jobToUpdate.jobEmail = jobEmail
        jobToUpdate.jobDescription = convertedContentJ ? convertedContentJ : " "
        jobToUpdate.jobReqs = reqs
        jobToUpdate.jobSkills = jobSkills
        console.log("Job is : ", jobToUpdate)
        await updateNestedDocument(props.jobToUpdate.id, jobToUpdate)
        setupdating(false)
        setIsSaved(true)

    }


    const reload = () => {
        window.location.reload(false)
    }


    useEffect(() => {
        if (isSaved) {
            window.location.reload(false)
        }
    })

    const addSkill = (e) => {
        e.preventDefault()

        const newSkill = {
            id: uuidv4(),
            jobSkill,

        }
        setJobSkills([...jobSkills, newSkill])
        setJobSkill("")
    }

    // Job_Reqs Add requirement Handler
    const addReq = (e) => {
        e.preventDefault()
        setEditing(false)
        const newReq = {
            id: uuidv4(),
            req,
        }
        setReqs([...reqs, newReq])
        setReq("")
    }
    const deleteReq = (id) => {

        setReqs(reqs.filter((row) => row.id !== id))
    }
    // Delete experience function
    const deleteSkill = (id) => setJobSkills(jobSkills.filter((row) => row.id !== id))
    // Delete experience function

    return (
        <div className="site-main  "
        >
            <HeaderLogo />

            {/* padding_zero-section */}

            <section className="ttm-row grid-section clearfix site-main-cv">

                <div className=" container-cv" dir='rtl'>
                    {/* row */}


                    <div className="row ">
                        {!show && <motion.div className={showFillJob ? "col-sm-12 col-md-12 col-lg-12" : "col-sm-12 col-md-12 col-lg-5"}
                            variants={containerVariants}
                            style={{ backgroundColor: "#404966" }}
                            initial="hidden"
                            animate="visible"
                            exit="exit">
                            <div className="ttm-col-bgcolor-yes ttm-bg bg-theme-GreyColor h-auto p-40 p-lg-30 mt-lg-30 mt-50 mb-50" >
                                <div className="layer-content"
                                >
                                    {/* section title */}
                                    <div className="section-title title-style-center_text">
                                        {tabId == 0 &&
                                            <div className="title-header">
                                                {showFillJob ? <h4 className="title">تعديل   <span className="text-theme-SkinColor">الوظيفة</span></h4>
                                                    : <h4 className="title">أخبرنا قليلا عن  <span className="text-theme-SkinColor">الوظيفة</span></h4>
                                                }
                                                <h3 className='subtitle-cv'>أخبرنا عن الوظيفة ، وكيف يمكن للموظفين الاتصال بك ، وما هي طبيعة الوظيفة. </h3>

                                            </div>}
                                        {tabId == 1 &&
                                            <div className="title-header">
                                                <h4 className="title">تفاصيل   <span className="text-theme-SkinColor">الوظيفة </span></h4>
                                                <h3 className='subtitle-cv'>دعنا نعرف المزيد عن الوظيفة. </h3>

                                            </div>}


                                        {tabId == 2 &&
                                            <div className="title-header">
                                                <h4 className="title"> متطلبات <span className="text-theme-SkinColor">الوظيفة</span></h4>
                                                <h3 className='subtitle-cv'> المعرفة والمهارات والقدرات المطلوبة. </h3>

                                            </div>}
                                        {tabId == 3 &&
                                            <div className="title-header">
                                                <h4 className="title">المهارات <span className="text-theme-SkinColor"> المطلوبة</span></h4>
                                                {/* <h3 className='subtitle-cv'>The jobSkills you add should reflect the requirements of the job you're applying for. </h3> */}

                                            </div>}
                                    </div>{/* section title end */}

                                    <form id="contact_form" className="contact_form wrap-form" onSubmit={handleSubmit} >

                                        <Tabs>
                                            {/*  ======================= Tab_One Start  ==================================== */}
                                            {tabId == 0 && <TabPanel>

                                                <motion.div className="row ttm-boxes-spacing-10px"
                                                    variants={prev ? nextVariantsRight : nextVariants}
                                                    initial="hidden"
                                                    animate="visible"
                                                    exit="exit">
                                                    <div className="title-header">
                                                        <h5>معلومات عن   <span className="text-theme-SkinColor">الوظيفة </span></h5>
                                                        <br></br>
                                                        {/* <h2 className="title">Request a Callback</h2> */}
                                                    </div>
                                                    {/*================ HEeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeer========================================== */}
                                                    <div className='col-md-12'></div>


                                                    <div className="col-md-12 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="jobName" type="text" placeholder="اسم الوظيفة "
                                                                // required="required"
                                                                required={jobName ? "false" : "required"}
                                                                value={jobName}
                                                                onChange={e => (setJobName(e.target.value))}
                                                            />
                                                        </label>
                                                        <div className="example-cv"> مثال - شاغر محاسب قانوني</div>
                                                        {/* <p>eg. Mohammed</p> */}
                                                    </div>
                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <i className="ti ti-list" ></i>
                                                            <select
                                                                style={{ lineHeight: "1.8" }}
                                                                name="Job Type"
                                                                aria-hidden="true"
                                                                placeholder=" نوع  الوظيفة "
                                                                onChange={e => (setJobType(e.target.value))}
                                                                value={jobType}
                                                            >

                                                                <option value=""> نوع الوظيفة</option>
                                                                <option value="1">دوام كامل </option>
                                                                <option value="2">دوام كامل - عاجل </option>
                                                                <option value="3">دوام جزئي </option>
                                                                <option value="4">دوام جزئي - عاجل </option>
                                                                <option value="5">عبر الانترنت</option>
                                                                <option value="6">عبر الانترنت  - عاجل</option>
                                                                {/* <option value="7">Transport</option> */}
                                                                {/* <option value="8">Insurance</option> */}

                                                            </select>
                                                        </label>
                                                    </div>

                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <i className="ti ti-list" ></i>
                                                            <select
                                                                style={{ lineHeight: "1.8" }}
                                                                name="Job Location"
                                                                aria-hidden="true"
                                                                placeholder=" الموقع "
                                                                onChange={e => (setJobLocation(e.target.value))}
                                                                value={jobLocation}>
                                                                <option value=""> الموقع </option>
                                                                <option value="1">القدس </option>
                                                                <option value="2">عمان </option>
                                                                <option value="3">القاهرة </option>
                                                                <option value="4">دمشق</option>
                                                                <option value="5">بيروت </option>
                                                                <option value="6">الجزائر</option>
                                                                <option value="7">المغرب</option>
                                                                <option value="8">أخرى</option>
                                                                {/* <option value="8">Insurance</option> */}

                                                            </select>
                                                        </label>
                                                    </div>
                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <i className="ti ti-list" ></i>
                                                            <select
                                                                style={{ lineHeight: "1.8" }}
                                                                name="Job Industry"
                                                                aria-hidden="true"
                                                                placeholder=" تصنيف الوظيفة "
                                                                onChange={e => (setJCategory(e.target.value))}
                                                                value={jobCat}>

                                                                <option value=""> تصنيف الوظيفة</option>
                                                                <option value="1">محاسبة و مالية</option>
                                                                <option value="2">تكنولوجيا</option>
                                                                <option value="3">زراعة</option>
                                                                <option value="4">بناء </option>
                                                                <option value="5">وظائف طبية</option>
                                                                <option value="6">انتاج</option>
                                                                <option value="7">نقل</option>
                                                                <option value="8">تأمين</option>
                                                            </select>
                                                        </label>
                                                    </div>
                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <i className="ti ti-list" ></i>
                                                            <select
                                                                style={{ lineHeight: "1.8" }}
                                                                name="Qualification"
                                                                aria-hidden="true"
                                                                placeholder=" مؤهلات"
                                                                onChange={e => (setQual(e.target.value))}
                                                                value={jobQuali}>
                                                                <option value=""> مؤهلات</option>
                                                                <option value="شهادة جامعية">شهادة جامعية</option>
                                                                <option value="Masters Degree">ماجيستير</option>
                                                                <option value="School Degree">شهادة مدرسية </option>
                                                                <option value="4">أخرى</option>
                                                                {/* <option value="5">Manufacturing</option>
                                                                <option value="6">Production</option>
                                                                <option value="7">Transport</option>
                                                                <option value="8">Insurance</option> */}

                                                            </select>
                                                        </label>
                                                    </div>
                                                    <div className='col-md-6 '>
                                                        <div className="list-filter  d-flex flex-row ttm-box-col-wrapper  ">
                                                            <label className="radio">
                                                                <input type="radio" value="male" name="gender"
                                                                    onChange={e => (setGender(e.target.value))}
                                                                    checked={gender == "male"}
                                                                /> ذكر
                                                            </label>
                                                            <label className="radio">
                                                                <input type="radio" value="female" name="gender"
                                                                    onChange={e => (setGender(e.target.value))}
                                                                    checked={(gender == "female")}

                                                                /> أنثى
                                                            </label>
                                                            <label className="radio">
                                                                <input type="radio" value="All" name="gender"
                                                                    onChange={e => (setGender(e.target.value))}
                                                                    checked={(gender == "All")}


                                                                /> الكل
                                                            </label>
                                                        </div>
                                                        <div className="example-cv"> جنس الموظف المفضل</div>

                                                    </div>
                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="experienceYear" type="text" placeholder="سنوات الخبرة "
                                                                // required={lastName ? "false" : "required"}
                                                                value={experienceYear}
                                                                onChange={e => (setExperienceYear(e.target.value))}


                                                            />
                                                        </label>
                                                        <div className="example-cv"> مثال - 3 سنوات </div>

                                                    </div>
                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="positionNumber" type="text" placeholder="عدد المناصب"
                                                                // required={lastName ? "false" : "required"}
                                                                value={positionNumber}
                                                                onChange={e => (setPositionNumber(e.target.value))}


                                                            />
                                                        </label>
                                                        <div className="example-cv"> مثال - 4</div>

                                                    </div>
                                                    <div className="col-md-6 ttm-box-col-wrapper" >

                                                        <label>
                                                            <input name="offeredSalary" type="text" placeholder="الراتب المعروض"
                                                                // required={occupation ? "false" : "required"}
                                                                onChange={e => (setOfferedSalary(e.target.value))}
                                                                value={offeredSalary}
                                                            />
                                                        </label>
                                                        <p className="example-cv"> مثال - $22k - $34k </p>
                                                    </div>

                                                    {/* <div className='col-md-4'></div> */}


                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="address" type="text" placeholder="العنوان "
                                                                onChange={e => (setAddress(e.target.value))}
                                                                value={address}
                                                            />
                                                        </label>
                                                        <p className="example-cv"> مثال : شارع القلعة , القدس </p>
                                                    </div>
                                                    <div className="col-md-6 ttm-box-col-wrapper">
                                                        <label>
                                                            <input name="jobEmail" type="text" placeholder="البريد الالكتروني "
                                                                onChange={e => (setJobEmail(e.target.value))}
                                                                value={jobEmail}
                                                            />
                                                        </label>
                                                        <p className="example-cv">   مثال : MH@gmail.com </p>
                                                    </div>
                                                </motion.div>
                                            </TabPanel>}
                                            {/*  ======================= Tab_Two   Start ================================= */}
                                            {tabId == 1 && <TabPanel>
                                                <motion.div className="row ttm-boxes-spacing-10px" id="tab2"
                                                    variants={prev ? nextVariantsRight : nextVariants}
                                                    initial="hidden"
                                                    animate="visible"
                                                    exit="exit">
                                                    <div>
                                                        <div className="col-md-12 ttm-box-col-wrapper">
                                                            <h4 className="title">وصف الوظيفة </h4>
                                                            <div style={{ border: "1px solid black", padding: '2px', minHeight: '300px' }} dir="ltr">
                                                                <Editor
                                                                    editorState={jobDescState}

                                                                    onEditorStateChange={handleEditorChangeJ}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </motion.div>
                                            </TabPanel>}
                                            {/* ======================Tab END ============================== */}
                                            {tabId == 2 &&
                                                <TabPanel >
                                                    <motion.div className="row ttm-boxes-spacing-10px" id="tab2"
                                                        variants={prev ? nextVariantsRight : nextVariants}

                                                        initial="hidden"
                                                        animate="visible"
                                                        exit="exit">

                                                        <div className='row'>

                                                            <div className='col-md-8 ttm-box-col-wrapper'>
                                                                <label>
                                                                    <input name="req" type="text" placeholder="أضف متطلب  "
                                                                        // required="required"

                                                                        value={req}
                                                                        onChange={(e) => { handleEdit(); setReq(e.target.value) }}
                                                                    // onChange={e => (setReq(e.target.value))}
                                                                    />
                                                                </label>
                                                            </div>
                                                            <div className=' col-md-4' >
                                                                <motion.button
                                                                    variants={buttonVariantsUpload}
                                                                    style={{ marginLeft: "25px" }}
                                                                    className='arrow-icon-left  text-theme-WhiteColor'
                                                                    whileHover={"hover"}
                                                                    onClick={addReq}
                                                                >
                                                                    {/* <button onClick={()=> {fun1(); fun2()}}>Show more</button> */}
                                                                    <BiAddToQueue size={25} className='cv-icon text-theme-WhiteColor' />
                                                                    {editing ? " حفظ  " : " اضافة متطلب "}
                                                                </motion.button>
                                                            </div>

                                                            <div >
                                                                {reqs.length > 0 ?
                                                                    <ul className="ttm-list ttm-list-style-icon ttm-textcolor-darkgrey col-md-12 ">
                                                                        <br></br>
                                                                        <h5> المتطلبات المضافة</h5>
                                                                        {reqs.map((req, index) => (

                                                                            <motion.div
                                                                                variants={prev ? nextVariantsRight : nextVariants}
                                                                                initial="hidden"
                                                                                animate="visible"
                                                                                exit="exit"
                                                                            > <li>

                                                                                </li>
                                                                                <li key={index} className="myli">
                                                                                    <tr className="row " style={{ padding: "5px", borderLeft: "2px solid #bc4123", borderBottom: "1px solid #e7e7e7", width: "100%", zIndex: "3", boxShadow: "0 0 6px 0 rgba(0, 0, 0, .09)", backgroundColor: "white", borderRadius: "15px" }}>
                                                                                        {/* <td className='col-sm-1 col-md-1' style={{ maxWidth: "fit-content" }}></td> */}
                                                                                        <td className='col-sm-10 col-md-12 ' style={{ lineHeight: "3" }}>
                                                                                            <span className='text-theme-DarkColor'>
                                                                                                <TfiCheckBox style={{ marginLeft: "5px" }} />
                                                                                                {req.req}
                                                                                            </span>
                                                                                            <motion.button variants={buttonVariantsUpload}

                                                                                                whileHover="hover"
                                                                                                style={{ paddingTop: "8px", float: "left", marginRight: "0" }}
                                                                                                onClick={() => deleteReq(req.id)}
                                                                                            >
                                                                                                <RiDeleteBinFill size={22} />
                                                                                            </motion.button></td>
                                                                                    </tr>
                                                                                </li></motion.div>))}</ul>
                                                                    : <div className=" col-md-12">
                                                                        <br></br>
                                                                        <h5> أمثلة  </h5>
                                                                        <ul className="ttm-list ttm-list-style-icon text-placeHolder-color-light">
                                                                            <li>
                                                                                <i className="ti ti-check-box"></i>
                                                                                <div className="ttm-list-li-content">لديك خبرة لا تقل عن 3 سنوات في العمل كمصمم منتج.</div>
                                                                            </li>
                                                                            <li><i className="ti ti-check-box"></i>
                                                                                <div className="ttm-list-li-content">لديك خبرة في استخدام Sketch و InVision أو Framer X</div>
                                                                            </li>
                                                                            <li><i className="ti ti-check-box"></i>
                                                                                <div className="ttm-list-li-content">أنت معتاد على استخدام Jira و Confluence في سير عملك</div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>}
                                                            </div>
                                                        </div>

                                                    </motion.div>
                                                </TabPanel>}
                                            {/* </motion.div> */}
                                            {tabId == 3 &&
                                                <TabPanel>
                                                    <motion.div className="row ttm-boxes-spacing-10px" id="tab2"
                                                        variants={prev ? nextVariantsRight : nextVariants}
                                                        initial="hidden"
                                                        animate="visible"
                                                        exit="exit">
                                                        {/* Skills Section   */}
                                                        <div className="col-md-12 ttm-box-col-wrapper">
                                                            <h5 className="title"> <AiFillEdit size={30} className='text-theme-SkinColor' /> مهارات  </h5>
                                                        </div>
                                                        <div className='row'>

                                                            <div className='col-md-8 ttm-box-col-wrapper '>
                                                                <label>
                                                                    <input name="jobSkill" type="text" placeholder="أضف مهارة وظيفية"
                                                                        value={jobSkill}
                                                                        onChange={(e) => { handleEdit(); setJobSkill(e.target.value) }}
                                                                    />
                                                                </label>
                                                            </div>
                                                            <div className=' col-md-4' >
                                                                <motion.button
                                                                    variants={buttonVariantsUpload}
                                                                    style={{ marginLeft: "25px" }}
                                                                    className='arrow-icon-left cv-icon text-theme-WhiteColor'
                                                                    whileHover={"hover"}
                                                                    onClick={addSkill}
                                                                >
                                                                    {/* <button onClick={()=> {fun1(); fun2()}}>Show more</button> */}
                                                                    <BiAddToQueue size={25} className='cv-icon text-theme-WhiteColor' />
                                                                    {editing ? "  حفظ المهارة " : "  أضف المهارة "}
                                                                </motion.button>
                                                            </div>

                                                            <div >
                                                                {jobSkills.length > 0 ?
                                                                    <ul className="ttm-list ttm-list-style-icon ttm-textcolor-darkgrey col-md-12 ">
                                                                        <br></br>
                                                                        <h5> المهارات المضافة </h5>
                                                                        {jobSkills.map((skill, index) => (

                                                                            <motion.div
                                                                                variants={prev ? nextVariantsRight : nextVariants}
                                                                                initial="hidden"
                                                                                animate="visible"
                                                                                exit="exit"
                                                                            > <li>

                                                                                </li>
                                                                                <li key={index} className="myli">
                                                                                    <tr className="row " style={{ padding: "5px", borderLeft: "2px solid #bc4123", borderBottom: "1px solid #e7e7e7", width: "100%", zIndex: "3", boxShadow: "0 0 6px 0 rgba(0, 0, 0, .09)", backgroundColor: "white", borderRadius: "15px" }}>
                                                                                        {/* <td className='col-sm-1 col-md-1' style={{ maxWidth: "fit-content" }}></td> */}
                                                                                        <td className='col-sm-10 col-md-12 ' style={{ lineHeight: "3" }}>
                                                                                            <span className='text-theme-DarkColor '>
                                                                                                <TfiCheckBox style={{ marginLeft: "5px" }} />
                                                                                                {skill.jobSkill}
                                                                                            </span>
                                                                                            <motion.button variants={buttonVariantsUpload}

                                                                                                whileHover="hover"
                                                                                                style={{ paddingTop: "8px", float: "left", marginRight: "0" }}
                                                                                                onClick={() => deleteSkill(skill.id)}
                                                                                            >
                                                                                                <RiDeleteBinFill size={22} />
                                                                                            </motion.button></td>

                                                                                    </tr>



                                                                                </li></motion.div>))}</ul>
                                                                    : <div className=" col-md-12">
                                                                        <br></br>
                                                                        <h5> أمثلة </h5>
                                                                        <ul className="ttm-list ttm-list-style-icon text-placeHolder-color-light">

                                                                            <li>
                                                                                <i className="ti ti-check-box"></i>
                                                                                <div className="ttm-list-li-content">HTML, CSS & Scss.</div>
                                                                            </li>
                                                                            <li><i className="ti ti-check-box"></i>
                                                                                <div className="ttm-list-li-content">Javascript</div>
                                                                            </li>
                                                                            <li><i className="ti ti-check-box"></i>
                                                                                <div className="ttm-list-li-content">Wordpress & elementor</div>
                                                                            </li>
                                                                        </ul>

                                                                    </div>}</div>
                                                        </div>



                                                    </motion.div>
                                                </TabPanel>
                                            }

                                            <TabList>
                                                <div className='row form-btn-tabs'>


                                                    {/* <Tab ></Tab> */}

                                                    {tabId == 0 &&
                                                        <div className='col-md-6'>
                                                            <Tab >

                                                                <Link >
                                                                    <motion.div onClick={() => { setprev(0); incrementCounter(); }}
                                                                    >
                                                                        <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                                            variants={buttonVariants}
                                                                            whileHover="hover"
                                                                        >
                                                                            {/* <span > <AiOutlineArrowRight size={25} className='arrow-icon-left' /></span> */}
                                                                            <span style={{ fontSize: "large" }}> التالي</span>
                                                                            <span > <AiOutlineArrowRight size={25} className='arrow-icon-right' /></span>
                                                                        </motion.button>

                                                                    </motion.div>
                                                                </Link>


                                                            </Tab></div>}
                                                    {tabId == 1 &&
                                                        <div className='col-md-6'>
                                                            <Tab onClick={() => { setprev(0); incrementCounter(); }}>

                                                                <Link >
                                                                    <motion.div
                                                                    >
                                                                        <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                                            variants={buttonVariants}
                                                                            whileHover="hover"
                                                                        >
                                                                            {/* <span > <AiOutlineArrowRight size={25} className='arrow-icon-left' /></span> */}
                                                                            <span style={{ fontSize: "large" }}> التالي</span>
                                                                            <span > <AiOutlineArrowRight size={25} className='arrow-icon-right' /></span>
                                                                        </motion.button>
                                                                    </motion.div>
                                                                </Link>


                                                            </Tab>  </div>}
                                                    {tabId == 2 &&
                                                        <div className='col-md-6'>

                                                            <Tab onClick={() => { setprev(0); incrementCounter(); }}>

                                                                <Link >
                                                                    <motion.div
                                                                    >
                                                                        <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                                            variants={buttonVariants}
                                                                            whileHover="hover"
                                                                        >
                                                                            <span style={{ fontSize: "large" }}> التالي</span>
                                                                            <span > <AiOutlineArrowRight size={25} className='arrow-icon-right' /></span>
                                                                        </motion.button>
                                                                    </motion.div>
                                                                </Link>


                                                            </Tab> </div>}


                                                    {tabId > 0 &&
                                                        <div className='col-md-6'>
                                                            <Tab >

                                                                <Link >
                                                                    <motion.div onClick={() => { decrementCounter(); setprev(1); }}
                                                                    >
                                                                        <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                                            variants={buttonVariants}
                                                                            whileHover="hover"
                                                                        >
                                                                            <span > <AiOutlineArrowLeft size={25} className='arrow-icon-left' /></span>
                                                                            <span style={{ fontSize: "large" }}> السابق </span>
                                                                            {/* <span > <AiOutlineArrowLeft size={25} className='arrow-icon-right' /></span> */}
                                                                        </motion.button>
                                                                    </motion.div>
                                                                </Link>


                                                            </Tab>
                                                        </div>}

                                                    {<div className='col-md-6 btn-save' >
                                                        {showFillJob ?
                                                            <motion.div >

                                                                <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100 "
                                                                    // type="submit"
                                                                    onClick={(e) => { updateJob(e) }}

                                                                    variants={buttonVariants}
                                                                    whileHover="hover"

                                                                >
                                                                    <span><BsSave size={22} style={{ marginRight: "10px" }} /></span>
                                                                    <span style={{ fontSize: "large" }}> {updating ? " جاري التعديل ..." : " تعديل  "} </span>
                                                                </motion.button>


                                                            </motion.div>
                                                            :
                                                            <motion.div >

                                                                <motion.button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100 "
                                                                    type="submit"
                                                                    
                                                                  

                                                                    variants={buttonVariants}
                                                                    whileHover="hover"

                                                                >
                                                                    <span><BsSave size={22} style={{ marginRight: "10px" }} /></span>
                                                                    <span style={{ fontSize: "large" }}> {saving ? " جاري الحفظ ..." : " حفظ  "} </span>
                                                                </motion.button>


                                                            </motion.div>}
                                                    </div>}
                                                    {showFillJob && <div className='col-md-6'>
                                                        <Tab >

                                                            <Link >
                                                                <motion.div onClick={() => { decrementCounter(); setprev(1); }}
                                                                >
                                                                    <motion.button className=" ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                                                                        variants={buttonVariants}
                                                                        onClick={reload}
                                                                        whileHover="hover"
                                                                    >
                                                                        <span > <AiOutlineClose size={25} className='arrow-icon-left' /></span>
                                                                        <span style={{ fontSize: "large" }}> خروج   </span>
                                                                        <span > <AiOutlineClose size={25} className='arrow-icon-right' /></span>
                                                                        {/* <span > <AiOutlineArrowLeft size={25} className='arrow-icon-right' /></span> */}
                                                                    </motion.button>
                                                                </motion.div>
                                                            </Link>


                                                        </Tab>
                                                    </div>}
                                                </div>

                                            </TabList>

                                        </Tabs>
                                    </form>
                                </div>
                            </div>
                        </motion.div>}
                        {/* ==================================================View Job_PDF ===================================== */}
                        {
                            !showFillJob &&
                            <div className={show ? "col-lg-7 " : " col-sm-12 col-md-12 col-lg-7"} dir='ltr'
                                style={{ backgroundColor: "#404966", margin: "auto" }}>

                                <div className="ttm-col-bgcolor-yes ttm-bg bg-theme-GreyColor  p-20 p-lg-30 mt-lg-30 mt-50 mb-50" ref={printRef}>
                                    <div className="layer-content">
                                        <div className=" sidebar job-sidebar clearfix" >
                                            <div className="">
                                                <div className="row">
                                                    <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
                                                        <div className="job_list-widget" dir='rtl'>
                                                            <aside className="widget job-widget">
                                                                <h3 className="widget-title"><i className="ti ti-files"></i>معلومات عن الوظيفة </h3>
                                                                <ul>
                                                                    {jobType && <li className="d-flex"><b className="mr-5">نوع الوظيفة : </b> {jobTypes[jobType]}</li>}
                                                                    {jobLocation && <li className="d-flex"><b className="mr-5"> الموقع : </b> {jobLocation}</li>}
                                                                    {offeredSalary && <li className="d-flex"><b className="mr-5">الراتب : </b> {offeredSalary}</li>}
                                                                    {/* <li className="d-flex"><b className="mr-5">Posted:</b>1 Week Ago</li> */}
                                                                    {experienceYear && <li className="d-flex"><b className="mr-5">سنوات خيرة : </b> {experienceYear}</li>}
                                                                    {jobCat && <li className="d-flex"><b className="mr-5">تصنيف الوظيفة : </b> {cats[jobCat]}</li>}
                                                                    {jobQuali && <li className="d-flex"><b className="mr-5"> مؤهلات : </b> {jobQuali}</li>}
                                                                    {positionNumber && <li className="d-flex"><b className="mr-5">عدد الشواغر  : </b> {positionNumber}</li>}
                                                                </ul>
                                                            </aside>
                                                            <aside className="widget form-widget">
                                                                <h3 className="widget-title"><i className="ti ti-email"></i>تواصل معنا </h3>
                                                                <form className="wrap-form">
                                                                    <label>
                                                                        <input name="name" type="text" placeholder="اسمك " required="required" />
                                                                    </label>
                                                                    <label>
                                                                        <input name="email" type="text" placeholder="بريدك الالكتروني " required="required" />
                                                                    </label>
                                                                    <label>
                                                                        <textarea name="message" rows="3" placeholder="الموضوع " required="required"></textarea>
                                                                    </label>
                                                                    <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100" type="submit">ارسال ! </button>
                                                                </form>
                                                            </aside>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-8 content-area text-right">
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12">
                                                                {/* featured-imagebox */}
                                                                <div className="featured-imagebox featured-imagebox-job fill bg-theme-GreyColor m-0">
                                                                    {/* <div className="featured-thumbnail">
                                                                        <img src="https://via.placeholder.com/210x204?text=210x204+job-01.png" />
                                                                    </div> */}
                                                                    {/* {document&& <p>We have a document here {document.profilePicture?document.profilePicture:"no picture here "}</p>} */}
                                                                    {document &&

                                                                        <div className="featured-thumbnail" >
                                                                            <img src={document.profilePicture ? document.profilePicture : "ProfilePic"} alt="profile picture" width="100" height="100" />
                                                                            {/* <p>{document}</p> */}
                                                                        </div>
                                                                        // : <div className="featured-thumbnail">
                                                                        //         <img src="https://via.placeholder.com/210x204?text=210x204+job-01.png" />
                                                                        //     </div>
                                                                    }
                                                                    <div className="featured-content">
                                                                        <div className="featured-title">
                                                                            <h3>{jobName ? jobName : "شاغر محاسب قانوني"}</h3>
                                                                        </div>

                                                                        <div className="featured-bottom" dir='rtl'>
                                                                            <div className="job-meta">
                                                                                <span><i className="fa fa-map-marker-alt"></i> {jobLocation ? jobLocation : "California"}</span>
                                                                                <span><i className="fa fa-filter"></i> {jobCat ? cats[jobCat] : "Automotive Jobs"}</span>
                                                                            </div>
                                                                            <div className="job-time">
                                                                                {!jobType && <span className="green">دوام كامل </span>}
                                                                                {jobType && <span className={"green" + jobType}> {jobTypes[jobType]}</span>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>{/* featured-imagebox end */}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="overview-box">
                                                                    <div className="title">
                                                                        <h5>الوصف الوظيفي </h5>
                                                                    </div>
                                                                    <div>
                                                                        {convertedContentJ &&
                                                                            <div className="desc">
                                                                                <p dangerouslySetInnerHTML={createMarkup(convertedContentJ)}></p>
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                    {!convertedContentJ && <div className="desc">
                                                                        <p>“وريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت

                                                                            لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت

                                                                            لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص ”</p>
                                                                        <p>“وريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت

                                                                            لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت

                                                                            لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص  ”</p>
                                                                    </div>}
                                                                </div>
                                                                <div className="overview-box">
                                                                    <div className="title">
                                                                        <h5>المعرفة  والقدرات المطلوبة</h5>
                                                                    </div>
                                                                    <div className="desc">
                                                                        <ul className="ttm-list ttm-list-style-icon ttm-textcolor-darkgrey" dir='rtl'>
                                                                            {reqs.length > 0 ?

                                                                                reqs.map((req, index) => (
                                                                                    <li key={index}>
                                                                                        <i className="ti ti-check-box"></i>
                                                                                        <div className="ttm-list-li-content">{req.req}</div>
                                                                                    </li>
                                                                                ))
                                                                                : <>
                                                                                    <li><i className="ti ti-check-box"></i>
                                                                                        <div className="ttm-list-li-content">لديك خبرة لا تقل عن 3 سنوات في العمل كمصمم منتج.</div>
                                                                                    </li>
                                                                                    <li><i className="ti ti-check-box"></i>
                                                                                        <div className="ttm-list-li-content">لديك خبرة في استخدام Sketch و InVision أو Framer X</div>
                                                                                    </li>
                                                                                    <li><i className="ti ti-check-box"></i>
                                                                                        <div className="ttm-list-li-content">أنت معتاد على استخدام Jira و Confluence في سير عملك </div>
                                                                                    </li>
                                                                                    <li><i className="ti ti-check-box"></i>
                                                                                        <div className="ttm-list-li-content">لديك خبرة في استخدام Sketch و InVision أو Framer X</div>
                                                                                    </li>
                                                                                    <li>
                                                                                        <i className="ti ti-check-box"></i>
                                                                                        <div className="ttm-list-li-content">لديك خبرة لا تقل عن 3 سنوات في العمل كمصمم منتج.</div>
                                                                                    </li>
                                                                                </>}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="overview-box">
                                                                    <div className="title">
                                                                        <h5>المهارات المطلوبة </h5>
                                                                    </div>
                                                                    <div className="desc" dir='rtl'>
                                                                        <ul className="ttm-list ttm-list-style-icon ttm-textcolor-darkgrey">
                                                                            {jobSkills.length > 0 ?

                                                                                jobSkills.map((jobSkill, index) => (
                                                                                    <li>
                                                                                        <i className="ti ti-check-box"></i>
                                                                                        <div className="ttm-list-li-content">{jobSkill.jobSkill}</div>
                                                                                    </li>
                                                                                ))
                                                                                : <>
                                                                                    <li>
                                                                                        <i className="ti ti-check-box"></i>
                                                                                        <div className="ttm-list-li-content">HTML, CSS & Scss</div>
                                                                                    </li>
                                                                                    <li><i className="ti ti-check-box"></i>
                                                                                        <div className="ttm-list-li-content">Javascript</div>
                                                                                    </li>
                                                                                    <li><i className="ti ti-check-box"></i>
                                                                                        <div className="ttm-list-li-content">PHP</div>
                                                                                    </li>
                                                                                    <li><i className="ti ti-check-box"></i>
                                                                                        <div className="ttm-list-li-content">Photoshop</div>
                                                                                    </li>
                                                                                    <li><i className="ti ti-check-box"></i>
                                                                                        <div className="ttm-list-li-content">Illustrator</div>
                                                                                    </li>
                                                                                    <li>
                                                                                        <i className="ti ti-check-box"></i>
                                                                                        <div className="ttm-list-li-content">Wordpress & elementor</div>
                                                                                    </li>
                                                                                </>}




                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                {/* {languages && <div className="overview-box" >
                                                                    <div className="title">
                                                                        <h5>Languages </h5>
                                                                    </div>
                                                                    {languages.map((item, index) => (
                                                                        <div className="desc pb-30" key={index}>
                                                                            <div className="ttm-progress-bar clearfix">
                                                                                <h3 className="progressbar-title" style={{ letterSpacing: "normal" }}>{item.language}</h3>
                                                                                <ProgressBar rect percentage={item.languageLevel} />
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>} */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div></div>
            </section>


        </div>
    )
}



